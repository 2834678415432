import React, { useState, useEffect } from "react";
import { Table, Tag } from "antd";
import { StopOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import {
  usePurchaseOrders,
  useVendors,
  useProjects,
} from "../../Contexts/useSpecificData";
import { formatMoney } from "../../Formatters/helpers";
import { getVendorName } from "../../Formatters/getVendorName";
import { formatBuyerName } from "../../Formatters/formatBuyerName";
import { getProjectName } from "../../Formatters/getProjectName";
import TableHeader from "../../Styled/TableHeader";

const ItemsTable = ({ onFilterChange }) => {
  const { data: purchaseOrders, loading: purchaseOrdersLoading } =
    usePurchaseOrders();
  const {
    data: vendors,
    loading: vendorsLoading,
    error: vendorsError,
  } = useVendors();
  const {
    data: projects,
    loading: projectsLoading,
    error: projectsError,
  } = useProjects();

  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [filteredInfo, setFilteredInfo] = useState({}); // Track table filters

  const [vendorFilters, setVendorFilters] = useState([]);
  const [buyerFilters, setBuyerFilters] = useState([]);
  const [projectFilters, setProjectFilters] = useState([]);
  const [lineStatusFilters, setLineStatusFilters] = useState([]);
  const [addressFilters, setAddressFilters] = useState([]);
  const [categoryFilters, setCategoryFilters] = useState([]); // <-- NEW for Category

  // Function to generate unique filters for a given column
  const getUniqueFilters = (dataSource, dataIndex) => {
    return Array.from(
      new Set(
        dataSource.map((item) => {
          if (dataIndex === "vendor") {
            return item.vendorName;
          } else if (dataIndex === "buyer") {
            return formatBuyerName(item[dataIndex]);
          } else if (dataIndex === "projectNumber") {
            return getProjectName(item[dataIndex], projects);
          } else {
            return item[dataIndex];
          }
        })
      )
    )
      .filter(Boolean)
      .sort((a, b) => a.localeCompare(b)) // A-Z sorting
      .map((value) => ({ text: value, value }));
  };

  // Function to update filter options based on the current data
  const updateFilterOptions = (filteredData) => {
    setVendorFilters(getUniqueFilters(filteredData, "vendor"));
    setBuyerFilters(getUniqueFilters(filteredData, "buyer"));
    setProjectFilters(getUniqueFilters(filteredData, "projectNumber"));
    setLineStatusFilters(getUniqueFilters(filteredData, "lineStatus"));
    setAddressFilters(getUniqueFilters(filteredData, "shipToAddress"));
    setCategoryFilters(getUniqueFilters(filteredData, "category")); // <-- NEW
  };

  // Function to apply both search and table filters
  const applyFilters = () => {
    let filteredData = originalData;

    // 1) Apply table filters (exact matching for typical antd behavior)
    if (Object.keys(filteredInfo).length > 0) {
      Object.keys(filteredInfo).forEach((key) => {
        if (filteredInfo[key] && filteredInfo[key].length > 0) {
          filteredData = filteredData.filter((record) => {
            // Determine which field to compare
            let valueToCompare;
            if (key === "vendor") {
              valueToCompare = record.vendorName;
            } else if (key === "buyer") {
              valueToCompare = formatBuyerName(record.buyer);
            } else if (key === "projectNumber") {
              valueToCompare = getProjectName(record.projectNumber, projects);
            } else {
              // This covers "shipToAddress", "lineStatus", "timeStatus", "category", etc.
              valueToCompare = record[key];
            }
            // Keep the record if its value is in the array of selected filters
            return valueToCompare && filteredInfo[key].includes(valueToCompare);
          });
        }
      });
    }

    // 2) Apply the search filter
    if (searchTerm.trim() !== "") {
      const searchTerms = searchTerm.split(/\s+/).filter((term) => term);
      filteredData = filteredData.filter((record) =>
        ["itemNumber", "itemDescription", "poNumber", "vendorName"].some(
          (key) =>
            searchTerms.every((term) =>
              record[key]?.toString().toLowerCase().includes(term)
            )
        )
      );
    }

    setData(filteredData);
    updateFilterOptions(filteredData);
    onFilterChange(filteredData);
  };

  // On mount (and whenever data changes), flatten the lines into rows
  useEffect(() => {
    if (!purchaseOrdersLoading && !vendorsLoading && !projectsLoading) {
      const flattenedData = purchaseOrders.flatMap((order) => {
        // Validate critical dates
        const orderedDate = dayjs(order.orderedDate);
        const requiredDate = dayjs(order.requiredDate);

        if (!orderedDate.isValid() || !requiredDate.isValid()) {
          console.warn(`Invalid dates for PO: ${order.poNumber}`);
          return [];
        }

        return order.lines
          .map((line) => {
            const receivedDate = dayjs(line.receivedDate);
            if (line.receivedDate && !receivedDate.isValid()) {
              console.warn(
                `Invalid receivedDate for line in PO: ${order.poNumber}`
              );
              return null;
            }

            const today = dayjs().startOf("day");
            const isLate =
              line.qtyReceived >= line.qtyOrdered &&
              line.receivedDate &&
              receivedDate.isAfter(requiredDate);
            const timeStatus =
              line.qtyReceived < line.qtyOrdered && today.isAfter(requiredDate)
                ? "Late"
                : isLate
                ? "Late"
                : "On Time";

            return {
              ...line,
              poNumber: order.poNumber,
              shipToAddress: order.shipToAddress,
              vendor: order.vendor,
              vendorName: getVendorName(order.vendor, vendors),
              orderedDate,
              requiredDate,
              buyer: order.buyer,
              projectNumber: order.projectNumber,
              total: line.qtyOrdered * line.itemPrice,
              lineStatus:
                line.qtyReceived >= line.qtyOrdered
                  ? "Closed"
                  : line.qtyReceived > 0
                  ? "Partial"
                  : "Open",
              timeStatus,
            };
          })
          .filter(Boolean);
      });

      setOriginalData(flattenedData);
      setLoading(false);
      updateFilterOptions(flattenedData);
      onFilterChange(flattenedData);
    }
  }, [
    purchaseOrders,
    purchaseOrdersLoading,
    vendorsLoading,
    projectsLoading,
    vendors,
    projects,
    onFilterChange,
  ]);

  // Re-apply filters whenever searchTerm or filteredInfo changes
  useEffect(() => {
    applyFilters();
  }, [searchTerm, filteredInfo, originalData]);

  // Search handler
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
  };

  // Table onChange handler (manages antd table filters)
  const handleFilterChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
  };

  // Remove all filters + search
  const removeFilters = () => {
    setFilteredInfo({});
    setSearchTerm("");
    setData(originalData);
    onFilterChange(originalData);
  };

  // Column definitions
  const columns = [
    {
      title: "Address",
      dataIndex: "shipToAddress",
      key: "shipToAddress",
      sorter: (a, b) => a.shipToAddress.localeCompare(b.shipToAddress),
      filteredValue: filteredInfo.shipToAddress || null,
      filters: addressFilters,
      filterSearch: true,
      onFilter: (value, record) =>
        record.shipToAddress?.toLowerCase().includes(value.toLowerCase()),
      render: (text) => {
        if (!text) return null;
        const [firstPart, ...rest] = text.split(",");
        const secondPart = rest.join(",").trim();
        return (
          <div>
            <div>{firstPart.trim()}</div>
            {secondPart && <div>{secondPart}</div>}
          </div>
        );
      },
    },
    {
      title: "Item Number",
      dataIndex: "itemNumber",
      key: "itemNumber",
      className: "no-wrap",
      filterSearch: true,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      filters: categoryFilters, // <-- NEW
      filteredValue: filteredInfo.category || null, // <-- NEW
      filterSearch: true,
      onFilter: (value, record) => record.category === value,
      // If you want partial matching here, you can do:
      // onFilter: (value, record) =>
      //   record.category?.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      filterSearch: true,
      width: 325,
    },
    {
      title: "PO Number",
      dataIndex: "poNumber",
      key: "poNumber",
      className: "no-wrap",
      width: 60,
      filterSearch: true,
      sorter: (a, b) => a.poNumber.localeCompare(b.poNumber),
      defaultSortOrder: "descend",
    },
    {
      title: "Vendor",
      dataIndex: "vendorName",
      key: "vendor",
      width: 150,
      filters: vendorFilters,
      filterSearch: true,
      filteredValue: filteredInfo.vendor || null,
      onFilter: (value, record) => record.vendorName === value,
      render: (text) => {
        if (vendorsLoading) return "Loading...";
        if (vendorsError) return "Error loading vendors";
        return text || "N/A";
      },
    },
    {
      title: "Date Submitted",
      dataIndex: "orderedDate",
      key: "orderedDate",
      render: (text) => dayjs(text).format("MM/DD/YYYY"),
      sorter: (a, b) => dayjs(a.orderedDate).diff(dayjs(b.orderedDate)),
    },
    {
      title: "Required Date",
      dataIndex: "requiredDate",
      key: "requiredDate",
      render: (text) => dayjs(text).format("MM/DD/YYYY"),
      sorter: (a, b) => dayjs(a.requiredDate).diff(dayjs(b.requiredDate)),
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
      filters: buyerFilters,
      filteredValue: filteredInfo.buyer || null,
      onFilter: (value, record) => formatBuyerName(record.buyer) === value,
      render: (text) => formatBuyerName(text) || "N/A",
      filterSearch: true,
    },
    {
      title: "Project",
      dataIndex: "projectNumber",
      key: "projectNumber",
      filters: projectFilters,
      filteredValue: filteredInfo.projectNumber || null,
      onFilter: (value, record) =>
        getProjectName(record.projectNumber, projects) === value,
      render: (text) => getProjectName(text, projects) || "N/A",
      filterSearch: true,
    },
    {
      title: "Received",
      dataIndex: "qtyReceived",
      key: "qtyReceived",
      render: (text, record) => {
        const { qtyReceived, qtyOrdered } = record;
        if (!qtyOrdered) {
          return <StopOutlined style={{ color: "#d3d3d3" }} />;
        }
        return <span>{`${qtyReceived || 0} / ${qtyOrdered}`}</span>;
      },
    },
    {
      title: "Unit Price",
      dataIndex: "itemPrice",
      key: "itemPrice",
      render: (text) => formatMoney(text),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => formatMoney(text),
    },
    {
      title: "Line Status",
      dataIndex: "lineStatus",
      key: "lineStatus",
      filters: lineStatusFilters,
      filteredValue: filteredInfo.lineStatus || null,
      onFilter: (value, record) => record.lineStatus === value,
      render: (status) => {
        let color = "blue";
        if (status === "Closed") color = "green";
        if (status === "Partial") color = "orange";
        return (
          <Tag style={{ fontSize: 11 }} color={color}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Time Status",
      dataIndex: "timeStatus",
      key: "timeStatus",
      filters: [
        { text: "On Time", value: "On Time" },
        { text: "Late", value: "Late" },
      ],
      filteredValue: filteredInfo.timeStatus || null,
      onFilter: (value, record) => record.timeStatus === value,
      render: (text, record) => {
        const color = record.timeStatus === "Late" ? "red" : "green";
        return (
          <Tag style={{ fontSize: 11 }} color={color}>
            {record.timeStatus}
          </Tag>
        );
      },
    },
    {
      title: "Date Received",
      dataIndex: "receivedDate",
      key: "receivedDate",
      sorter: (a, b) => {
        if (!a.receivedDate && !b.receivedDate) return 0;
        if (!a.receivedDate) return -1;
        if (!b.receivedDate) return 1;
        return dayjs(a.receivedDate).diff(dayjs(b.receivedDate));
      },
      render: (text) =>
        text ? (
          dayjs(text).format("MM/DD/YYYY")
        ) : (
          <StopOutlined style={{ color: "#d3d3d3" }} />
        ),
    },
  ];

  return (
    <>
      <TableHeader
        title="Items"
        totalResults={data.length}
        totalItems={originalData.length}
        searchValue={searchTerm}
        onSearchChange={handleSearch}
        removeFilters={removeFilters}
        showRemoveFilters={true}
      />
      <Table
        className="smaller-table"
        dataSource={data}
        columns={columns}
        pagination={{
          pageSizeOptions: ["10", "25", "50", "100", "250"],
          showSizeChanger: true,
        }}
        rowKey={(record) =>
          `${record.poNumber || "no-po"}-${record.itemNumber || "no-item"}-${
            record.itemDescription || "no-desc"
          }`
        }
        loading={
          loading || purchaseOrdersLoading || vendorsLoading || projectsLoading
        }
        onChange={handleFilterChange}
        locale={{
          emptyText: searchTerm
            ? "No items match your search."
            : "No data available.",
        }}
      />
    </>
  );
};

export default ItemsTable;
