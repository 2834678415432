import React from "react";
import PreconCard from "../../Pages/projectmanagement/PreconCard";
import ProductionCard from "../Production/ProductionCard";
import HeaderText from "../../Styled/HeaderText";
import { Badge } from "antd";

function ProjectProgress({ project, viewOnly=false }) {

  return (
    <div style={{}}>
      <HeaderText medium text="Preconstruction" />

      {/* 1) Preconstruction Card in VIEW-ONLY mode */}
      <PreconCard viewOnly={viewOnly} project={project} />

      <div style={{ marginTop: 24 }}>
        <Badge size="small" offset={[8,0]} count={project?.modules?.length}>
          <HeaderText medium text="Production" />
        </Badge>

        {project.modules &&
          project.modules.map((mod) => (
            <div key={mod.id} style={{ marginBottom: 0 }}>
              <ProductionCard viewOnly={viewOnly} module={mod} project={project} />
            </div>
          ))}
      </div>
    </div>
  );
}

export default ProjectProgress;
