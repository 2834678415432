import React, { useRef, useEffect, useState } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { Input } from "antd";

const libraries = ["places"];

const AddressAutocomplete = ({ form, value, onChange }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const inputRef = useRef(null);
  const autocompleteRef = useRef(null);
  // State to track if a suggestion was selected
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (isLoaded && inputRef.current) {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current.input,
        // { types: ["geocode", "establishment"] } 
      );
      autocompleteRef.current.addListener("place_changed", onPlaceChanged);
    }

    // Cleanup listener on unmount
    return () => {
      if (autocompleteRef.current) {
        window.google.maps.event.clearInstanceListeners(
          autocompleteRef.current
        );
      }
    };
  }, [isLoaded]);

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    console.log("Selected place:", place);
    // Only consider it valid if place_id exists (or any other property you rely on)
    if (place && place.place_id) {
      setIsSelected(true);
      const formattedAddress = place.formatted_address || "";
      if (onChange) {
        onChange(formattedAddress);
      }
      form.setFieldsValue({
        googleAddress: place,
        address: formattedAddress,
      });
    }
  };

  const handleChange = (e) => {
    const newAddress = e.target.value;
    // Reset the selection flag if user types or pastes manually
    setIsSelected(false);
    if (onChange) {
      onChange(newAddress);
    }
    form.setFieldsValue({ address: newAddress });
  };

  // On blur, check if a valid suggestion was selected.
  const handleBlur = () => {
    if (!isSelected) {
      // Option 1: Clear the input so the user must reselect
      if (onChange) {
        onChange("");
      }
      form.setFieldsValue({ address: "" });
      // Option 2: Alternatively, display an error message here
      // e.g., form.setFields([{ name: 'address', errors: ['Please select an address from the suggestions.'] }]);
    }
  };

  return (
    <Input
      ref={inputRef}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder="Enter address"
    />
  );
};

export default AddressAutocomplete;
