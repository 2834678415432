import React from "react";
import { Spin } from "antd";
import { COLORS } from "../constants";
import { LoadingOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

/**
 * Loader Component
 *
 * Displays a loading spinner centered within its container.
 * Can be configured to cover the entire viewport with a semi-transparent background.
 *
 * Props:
 * - size: Size of the spinner ("small", "default", "large")
 * - color: Color of the spinner
 * - absolute: If true, the loader covers the entire viewport
 * - loading: Controls the visibility of the loader
 */
export const Loader = ({
  size = "small",
  color = COLORS.PRIMARY,
  absolute = false,
  loading = true,
}) => {
  // If not loading, render nothing
  if (!loading) return null;

  // Create a custom spinner icon with the desired color
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color }} spin />;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: absolute ? "100vh" : "80vh", // Full viewport height when absolute
        width: absolute ? "100vw" : "100%", // Full viewport width when absolute
        position: absolute ? "fixed" : "relative", // Fixed positioning for full-screen overlay
        background: absolute ? "rgba(255, 255, 255, 0.5)" : "transparent",
        zIndex: 1000,
        top: 0,
        left: 0,
      }}
      aria-live="assertive" // For accessibility: announces updates to screen readers
      aria-busy="true" // Indicates that the element is loading
    >
      <Spin size={size} />
    </div>
  );
};

// Define PropTypes for better type checking and documentation
Loader.propTypes = {
  /**
   * Size of the spinner.
   * Can be "small", "default", or "large".
   */
  size: PropTypes.oneOf(["small", "default", "large"]),

  /**
   * Color of the spinner.
   * Accepts any valid CSS color string.
   */
  color: PropTypes.string,

  /**
   * If true, the loader covers the entire viewport with a semi-transparent background.
   */
  absolute: PropTypes.bool,

  /**
   * Controls the visibility of the loader.
   * If false, the loader won't be rendered.
   */
  loading: PropTypes.bool,
};

// Export the component as default for easier imports
export default Loader;
