import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  message,
  Modal,
  Select,
  Input,
  Tag,
  Image,
  Tooltip,
  Button,
  Row,
  Col,
  Upload,
} from "antd";
import { EyeOutlined, UploadOutlined } from "@ant-design/icons";
import { useTickets } from "../../Contexts/useSpecificData";
import { SearchInput } from "../../Styled/SearchInput";
import { updateById } from "../../Functions/updateById";
import { TABLES, COLORS } from "../../constants";
import { useUser } from "@clerk/clerk-react";
import { s3Upload } from "../../Functions/s3Upload";
import { dataURIToBlob } from "../../Formatters/dataURIToBlob";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";

const { Option } = Select;
const { TextArea } = Input;

const getStatusColor = (status) => {
  switch (status) {
    case "Open":
      return "green";
    case "Closed":
      return "red";
    default:
      return "gray";
  }
};

function Tickets() {
  const { data: tickets, loading, error, refresh } = useTickets();
  const { user } = useUser();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [resolution, setResolution] = useState("");
  const [resolutionImages, setResolutionImages] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const resolutionTextAreaRef = useRef(null);

  useEffect(() => {
document.title = "Tickets";
  }, []);

  // State and handlers for Resolution Images Modal
  const [resolutionModalVisible, setResolutionModalVisible] = useState(false);
  const [currentResolutionImages, setCurrentResolutionImages] = useState([]);

  // State and handlers for Uploaded Files Modal
  const [uploadedFilesModalVisible, setUploadedFilesModalVisible] =
    useState(false);
  const [currentUploadedFiles, setCurrentUploadedFiles] = useState([]);

  const showResolutionImagesModal = (images) => {
    setCurrentResolutionImages(images);
    setResolutionModalVisible(true);
  };

  const handleResolutionModalCancel = () => {
    setResolutionModalVisible(false);
    setCurrentResolutionImages([]);
  };

  const showUploadedFilesModal = (files) => {
    setCurrentUploadedFiles(files);
    setUploadedFilesModalVisible(true);
  };

  const handleUploadedFilesModalCancel = () => {
    setUploadedFilesModalVisible(false);
    setCurrentUploadedFiles([]);
  };

  const onSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredTickets = tickets.filter((ticket) => {
    const { issueDescription, userEmail, status, currentURL } = ticket;
    return (
      issueDescription.toLowerCase().includes(searchTerm) ||
      userEmail.toLowerCase().includes(searchTerm) ||
      (status || "Open").toLowerCase().includes(searchTerm) ||
      currentURL.toLowerCase().includes(searchTerm)
    );
  });

  const showModal = (ticket) => {
    setSelectedTicket({ ...ticket, status: ticket.status || "Open" });
    setResolution(ticket.resolution || "");
    setResolutionImages(ticket.resolutionImages || []);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (!selectedTicket) return;

    if (selectedTicket.status === "Closed" && resolution.trim() === "") {
      message.error("Please provide a resolution before closing the ticket.");
      return;
    }

    try {
      await updateById(
        TABLES.TICKETS,
        {
          status: selectedTicket.status,
          resolution,
          resolutionImages,
          resolvedBy: user.primaryEmailAddress?.emailAddress || "Unknown",
        },
        selectedTicket.id
      );

      message.success("Ticket updated successfully.");
      setIsModalVisible(false);
      setSelectedTicket(null);
      setResolution("");
      setResolutionImages([]);
      refresh();
    } catch (error) {
      console.error("Error updating ticket:", error);
      message.error("Failed to update ticket.");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedTicket(null);
    setResolution("");
    setResolutionImages([]);
  };

  const onResolutionChange = (e) => {
    setResolution(e.target.value);
  };

  const handlePaste = (e) => {
    const items = e.clipboardData.items;
    if (!items) return;

    const images = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf("image") !== -1) {
        const file = item.getAsFile();
        if (file) {
          images.push(file);
        }
      }
    }

    if (images.length > 0) {
      e.preventDefault();
      const uploadAndAddImage = async (file) => {
        try {
          const fileName = `resolution_${Date.now()}_${
            file.name || "pasted-image.png"
          }`;
          const url = await s3Upload(file, fileName);
          setResolutionImages((prev) => [...prev, url]);
          message.success("Screenshot pasted and uploaded successfully!");
        } catch (error) {
          console.error("Error uploading pasted image:", error);
          message.error("Failed to upload pasted image.");
        }
      };

      images.forEach((file) => {
        uploadAndAddImage(file);
      });
    }
  };

  const handleResolutionUploadChange = async ({ file, fileList }) => {
    if (file.status === "removed") {
      setResolutionImages((prev) => prev.filter((url) => url !== file.url));
    }
  };

  const beforeResolutionUpload = (file) => {
    const uploadFile = async () => {
      try {
        const fileName = `resolution_${Date.now()}_${file.name}`;
        const url = await s3Upload(file, fileName);
        setResolutionImages((prev) => [...prev, url]);
        message.success(`${file.name} uploaded successfully.`);
      } catch (error) {
        console.error("Error uploading file:", error);
        message.error(`Failed to upload ${file.name}.`);
      }
    };

    uploadFile();
    return false;
  };

  const columns = [
    {
      title: "Issue Description",
      dataIndex: "issueDescription",
      key: "issueDescription",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "User Email",
      dataIndex: "userEmail",
      key: "userEmail",
      render: (text) => <span>{text}</span>,
      filters: [...new Set(tickets.map((ticket) => ticket.userEmail))].map(
        (email) => ({ text: email, value: email })
      ),
      onFilter: (value, record) => record.userEmail === value,
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text) => new Date(text).toLocaleString(),
      sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        const displayStatus = status || "Open";
        return (
          <Tag color={getStatusColor(displayStatus)} key={displayStatus}>
            {displayStatus}
          </Tag>
        );
      },
      filters: [
        { text: "Open", value: "Open" },
        { text: "Closed", value: "Closed" },
      ],
      onFilter: (value, record) => (record.status || "Open") === value,
      defaultFilteredValue: ["Open"],
    },
    {
      title: "Resolution",
      dataIndex: "resolution",
      key: "resolution",
      render: (resolution, record) =>
        resolution ? (
          <Tooltip title={resolution}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>{resolution.substring(0, 30)}...</span>
              {record.resolutionImages &&
                record.resolutionImages.length > 0 && (
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      marginLeft: 8,
                      fontSize: 16,
                      cursor: "pointer",
                      color: COLORS.PRIMARY,
                    }}
                    onClick={() =>
                      showResolutionImagesModal(record.resolutionImages)
                    }
                  />
                )}
            </div>
          </Tooltip>
        ) : (
          <Tag color="red">Pending</Tag>
        ),
    },
    {
      title: "Current URL",
      dataIndex: "currentURL",
      key: "currentURL",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "Files",
      dataIndex: "uploadedFiles",
      key: "uploadedFiles",
      render: (uploadedFiles) => {
        if (!uploadedFiles || uploadedFiles.length === 0) return null;
        return (
          <FontAwesomeIcon
            icon={faFile}
            style={{ fontSize: 16, cursor: "pointer", color: COLORS.PRIMARY }}
            onClick={() => showUploadedFilesModal(uploadedFiles)}
          />
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={() => showModal(record)}
        >
          View
        </Button>
      ),
    },
  ];

  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col
          span={24}
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          <div>
            Tickets
            <span
              style={{
                opacity: 0.7,
                fontStyle: "italic",
                fontSize: 16,
                marginLeft: 4,
              }}
            >
              (
              {
                filteredTickets.filter((ticket) => ticket.status !== "Closed")
                  .length
              }{" "}
              Open)
            </span>
          </div>
        </Col>
        <Col span={24}>
          <SearchInput
            placeholder="Search by status, email, description, or URL"
            onChange={onSearchChange}
            allowClear
          />
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={filteredTickets}
            rowKey={(record) => record.id}
            loading={loading}
            pagination={{ pageSize: 10 }}
            size="small"
            className="small-table"
          />
        </Col>
      </Row>

      {/* Modal for Viewing and Editing Ticket */}
      <Modal
        title="View & Edit Ticket"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Save"
        width={800}
      >
        {selectedTicket && (
          <div>
            <p>
              <strong>Issue Description:</strong>{" "}
              {selectedTicket.issueDescription}
            </p>
            <p>
              <strong>User Email:</strong> {selectedTicket.userEmail}
            </p>
            <p>
              <strong>Current URL:</strong>{" "}
              <a
                href={selectedTicket.currentURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {selectedTicket.currentURL}
              </a>
            </p>
            <p>
              <strong>Timestamp:</strong>{" "}
              {new Date(selectedTicket.timestamp).toLocaleString()}
            </p>

            {selectedTicket.uploadedFiles &&
              selectedTicket.uploadedFiles.length > 0 && (
                <div
                  style={{ display: "flex", gap: "16px", alignItems: "center" }}
                >
                  <strong>Files:</strong>
                  <FontAwesomeIcon
                    icon={faFile}
                    style={{
                      fontSize: 20,
                      cursor: "pointer",
                      color: COLORS.PRIMARY,
                    }}
                    onClick={() =>
                      showUploadedFilesModal(selectedTicket.uploadedFiles)
                    }
                  />
                </div>
              )}

            <div style={{ marginBottom: "16px" }}>
              <strong>Status:</strong>
              <Select
                value={selectedTicket.status || "Open"}
                onChange={(value) =>
                  setSelectedTicket({ ...selectedTicket, status: value })
                }
                style={{ width: "100%", marginTop: "8px" }}
              >
                <Option value="Open">Open</Option>
                <Option value="Closed">Closed</Option>
              </Select>
            </div>

            <div>
              <strong>Resolution:</strong>
              <TextArea
                rows={4}
                value={resolution}
                onChange={onResolutionChange}
                placeholder="Enter resolution details or paste screenshots here..."
                style={{ marginTop: "8px" }}
                onPaste={handlePaste}
                ref={resolutionTextAreaRef}
              />
            </div>

            <div style={{ marginTop: "16px" }}>
              <strong>Upload Resolution Images:</strong>
              <Upload
                multiple
                listType="picture"
                beforeUpload={beforeResolutionUpload}
                onChange={handleResolutionUploadChange}
                onRemove={(file) => {
                  setResolutionImages((prev) =>
                    prev.filter((url) => url !== file.url)
                  );
                }}
                fileList={resolutionImages.map((url, index) => ({
                  uid: index,
                  name: `Image ${index + 1}`,
                  status: "done",
                  url,
                }))}
                accept="image/*"
              >
                <Button style={{ marginLeft: 8 }} icon={<UploadOutlined />}>
                  Select Files
                </Button>
              </Upload>
            </div>

            {resolutionImages.length > 0 && (
              <div style={{ marginTop: "16px" }}>
                <strong>Uploaded Resolution Images:</strong>
                <Image.PreviewGroup>
                  {resolutionImages.map((url, index) => (
                    <Image key={index} width={100} src={url} />
                  ))}
                </Image.PreviewGroup>
              </div>
            )}
          </div>
        )}
      </Modal>

      {/* Modal for Resolution Files Preview */}
      <Modal
        title="Resolution Files"
        open={resolutionModalVisible}
        onCancel={handleResolutionModalCancel}
        footer={[
          <Button
            key="close"
            type="primary"
            onClick={handleResolutionModalCancel}
          >
            Close
          </Button>,
        ]}
      >
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {currentResolutionImages.map((url, index) => (
            <li key={index} style={{ marginBottom: "8px" }}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFile} style={{ marginRight: "8px" }} />
                File {index + 1}
              </a>
            </li>
          ))}
        </ul>
      </Modal>

      {/* Modal for Uploaded Files Preview */}
      <Modal
        title="Uploaded Files"
        open={uploadedFilesModalVisible}
        onCancel={handleUploadedFilesModalCancel}
        footer={[
          <Button
            key="close"
            type="primary"
            onClick={handleUploadedFilesModalCancel}
          >
            Close
          </Button>,
        ]}
      >
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {currentUploadedFiles.map((url, index) => (
            <li key={index} style={{ marginBottom: "8px" }}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFile} style={{ marginRight: "8px" }} />
                File {index + 1}
              </a>
            </li>
          ))}
        </ul>
      </Modal>
    </>
  );
}

export default Tickets;
