// src/hooks/useLastThreeMonthsProjects.js

import { useMemo } from "react";
import dayjs from "dayjs";
import { useProjects } from "./useSpecificData"; // Adjust the path as necessary

const useLastThreeMonthsProjects = () => {
  const { data: projects, loading, error, refresh } = useProjects();

  const filteredProjects = useMemo(() => {
    if (!projects || projects.length === 0) return [];

    const threeMonthsAgo = dayjs().subtract(3, "month");

    return projects.filter((project) => {
      const { modules } = project;

      // Include projects with no modules
      if (!Array.isArray(modules) || modules.length === 0) {
        return true;
      }

      // Check if all modules have a valid endDate
      const allHaveEndDate = modules.every(
        (module) => module.endDate && dayjs(module.endDate).isValid()
      );

      if (!allHaveEndDate) {
        // Include project if any module is missing an endDate
        return true;
      }

      // Find the most recent endDate among all modules
      const mostRecentEndDate = modules
        .map((module) => dayjs(module.endDate))
        .reduce(
          (latest, current) => (current.isAfter(latest) ? current : latest),
          dayjs("1970-01-01")
        ); // Initialize with a very old date

      // Include the project if the most recent endDate is within the last three months
      return mostRecentEndDate.isAfter(threeMonthsAgo);
    });
  }, [projects]);

  return {
    data: filteredProjects,
    loading,
    error,
    refresh,
  };
};

export default useLastThreeMonthsProjects;
