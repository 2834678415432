// src/Components/Shipment/ShipmentDetailsCard.js

import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Space, message } from "antd";
import { IconMan, IconMap, IconTruck, IconUser, IconWeight } from "@tabler/icons-react";
import {
  useCustomers,
  useFreightAgents,
  useProjects,
} from "../../Contexts/useSpecificData";
import { getShipmentItems } from "../Logistics/getShipmentItems";
import ShippingItemCard from "./ShippingItemCard";
import { Loader } from "../../Styled/Loader";
import PropTypes from "prop-types";

const { Title } = Typography;

const ShipmentDetailsCard = ({ shipment }) => {
  // Destructure data and loading states from custom hooks
  const { data: freightAgents, loading: loadingFreightAgents } =
    useFreightAgents();
  const { data: customers, loading: loadingCustomers } = useCustomers();
  const { data: projects, loading: loadingProjects } = useProjects();

  // Find the associated project
  const project = projects?.find((p) => p.id === shipment.projectId);

  // Extract IDs safely using optional chaining
  const freightAgentId = shipment.bol?.freightAgentId || null;
  const customerId = project?.customerId || null;

  // Local state to store fetched data
  const [freightAgent, setFreightAgent] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [shipmentItems, setShipmentItems] = useState(null);

  useEffect(() => {
    // Fetch Freight Agent details
    if (freightAgents && freightAgentId) {
      const agent = freightAgents.find((fa) => fa.id === freightAgentId);
      setFreightAgent(agent || null);
    } else {
      setFreightAgent(null);
    }

    // Fetch Customer details
    if (customers && customerId) {
      const cust = customers.find((c) => c.id === customerId);
      setCustomer(cust?.companyName || null);
    } else {
      setCustomer(null);
    }

    // Fetch Shipment Items
    if (project && shipment) {
      const items = getShipmentItems(project, shipment.id);
      setShipmentItems(items);
    } else {
      setShipmentItems(null);
    }
  }, [freightAgents, freightAgentId, customers, customerId, project, shipment]);

  // Calculate total gross weight
  const totalGrossWeight = shipmentItems
    ? [...shipmentItems.truckModules, ...shipmentItems.truckPallets].reduce(
        (sum, item) => sum + (item.weight || 0),
        0
      )
    : 0;

  // If any of the data is still loading, display the Loader
  if (loadingFreightAgents || loadingCustomers || loadingProjects) {
    return <Loader />;
  }

  return (
    <>
      {/* Display Shipment Info */}
      {shipment ? (
        <div style={{  }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",

            }}
          >
            <p style={{ fontSize: 16, fontWeight: 600 }}>
              {shipment.bol?.bolNumber || "N/A"}
            </p>
            <p>
              <strong>Project:</strong> {project?.projectName || "N/A"}
            </p>
          </div>
          <p>
            <strong>Freight Agent:</strong> {freightAgent?.companyName || "N/A"}
          </p>
          <p>
            <IconTruck size={16} style={{ marginRight: 5 }} />
            <strong>Truck Type:</strong> {shipment?.truckType || "N/A"}
          </p>
          <p>
            <IconUser size={16} style={{ marginRight: 5 }} />
            <strong>Customer:</strong> {customer || "N/A"}
          </p>
          <p>
            <IconMap size={16} style={{ marginRight: 5 }} />
            <strong>Pickup Address:</strong>{" "}
            {shipment.origin?.address || "Unknown"}
          </p>
          <p>
            <IconMap size={16} style={{ marginRight: 5 }} />
            <strong>Drop-off Address:</strong>{" "}
            {shipment.destination?.address || "Unknown"}
          </p>
          <p>
            <IconWeight size={16} style={{ marginRight: 5 }} />
            <strong>Gross Weight:</strong>{" "}
            {totalGrossWeight ? `${totalGrossWeight} lbs` : "N/A"}
          </p>

          {/* Shipping Items */}
          {shipmentItems && (
            <div style={{ marginTop: 8 }}>
              {/* Truck Modules */}
              {shipmentItems.truckModules.length > 0 && (
                <>

                  <Row gutter={[8, 8]}>
                    {shipmentItems.truckModules.map((module) => (
                      <Col xs={24} sm={24} key={module.id}>
                        <ShippingItemCard
                          item={module}
                          project={project}
                          isModule
                          onRemove={() => {
                            // Implement removal logic if needed
                            message.success(`Module ${module.number} removed.`);
                          }}
                        />
                      </Col>
                    ))}
                  </Row>
                </>
              )}

              {/* Truck Pallets */}
              {shipmentItems.truckPallets.length > 0 && (
                <>

                  <Row gutter={[16, 16]}>
                    {shipmentItems.truckPallets.map((pallet) => (
                      <Col xs={24} sm={24} key={pallet.id}>
                        <ShippingItemCard
                          item={pallet}
                          project={project}
                          isModule={false}
                          onRemove={() => {
                            // Implement removal logic if needed
                            message.success(
                              `Pallet ${pallet.palletName} removed.`
                            );
                          }}
                        />
                      </Col>
                    ))}
                  </Row>
                </>
              )}
            </div>
          )}
        </div>
      ) : (
        <p>No shipment data available.</p>
      )}
    </>
  );
};

// PropTypes for type checking
ShipmentDetailsCard.propTypes = {
  shipment: PropTypes.object.isRequired,
};

export default ShipmentDetailsCard;
