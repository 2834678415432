import React, { useState, useEffect } from "react";
import { Modal, Form, Select, Button, Row, Col } from "antd";
import { addItemsToTable } from "../../Functions/addAria";
import { updateById } from "../../Functions/updateById";
import { TABLES, TRUCK_TYPES } from "../../constants";
import DeliveryRoute from "../Projects/DeliveryRoute";
import PlaceObjectAutocomplete from "../Google/PlaceObjectAutocomplete";
import {
  useProjects,
  useManufacturingLocations,
} from "../../Contexts/useSpecificData";
import useCurrentUser from "../../hooks/useCurrentUser";

function AddOutboundShipmentModal({
  visible,
  onCancel,
  shipment, // existing shipment if editing, null if adding new
  existingShipments = [],
  onUpdateSuccess,
}) {
  const [form] = Form.useForm();
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedManufacturing, setSelectedManufacturing] = useState(null);
  const [destinationPlace, setDestinationPlace] = useState(null);
  const [tripDistance, setTripDistance] = useState(null);
  const [estimatedDays, setEstimatedDays] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data: projects = [] } = useProjects();
  const { data: manufacturingLocations = [] } = useManufacturingLocations();
  const { userName } = useCurrentUser();

  // Filter out projects that do not have a purchase order, then sort by project name.
  const sortedProjects = projects
    .filter((proj) => proj.purchaseOrder)
    .sort((a, b) => a.projectName.localeCompare(b.projectName));

  // Populate form if editing
  useEffect(() => {
    if (shipment) {
      const {
        origin,
        destination,
        tripDistance,
        estimatedDays,
        projectId,
        truckType,
      } = shipment;

      setSelectedProject(projectId);
      setSelectedManufacturing(origin);
      setDestinationPlace({
        formatted_address: destination.address,
        geometry: {
          location: {
            lat: () => destination.latitude,
            lng: () => destination.longitude,
          },
        },
        place_id: destination.placeId,
      });
      setTripDistance(tripDistance);
      setEstimatedDays(estimatedDays);

      form.setFieldsValue({
        projectId,
        manufacturingLocation: origin?.id,
        destinationAddress: destination.address,
        truckType: truckType || undefined,
      });
    } else {
      // Reset for new
      setSelectedProject(null);
      setSelectedManufacturing(null);
      setDestinationPlace(null);
      setTripDistance(null);
      setEstimatedDays(null);
      form.resetFields();
    }
  }, [shipment, form]);

  // On project change, auto-fill manufacturing & address if possible
  const handleProjectChange = (value) => {
    const selectedProj = projects.find((proj) => proj.id === value);
    setSelectedProject(value);

    const defaultManufacturing = manufacturingLocations.find(
      (loc) => loc.id === selectedProj?.manufacturingLocation?.id
    );
    setSelectedManufacturing(defaultManufacturing);

    setDestinationPlace({
      formatted_address: selectedProj?.formattedAddress,
      geometry: {
        location: {
          lat: () => selectedProj?.latitude,
          lng: () => selectedProj?.longitude,
        },
      },
      place_id: selectedProj?.placeId,
    });

    form.setFieldsValue({
      manufacturingLocation: defaultManufacturing?.id,
      destinationAddress: selectedProj?.formattedAddress,
    });

    setTripDistance(null);
    setEstimatedDays(null);
  };

  // On manufacturing location change
  const handleManufacturingChange = (value) => {
    const location = manufacturingLocations.find((loc) => loc.id === value);
    setSelectedManufacturing(location);
    setTripDistance(null);
    setEstimatedDays(null);
  };

  // On destination address change
  const handleDestinationChange = (place) => {
    if (!place?.geometry) {
      setDestinationPlace(null);
      return;
    }
    setDestinationPlace(place);
    setTripDistance(null);
    setEstimatedDays(null);
  };

  // Called when route is calculated
  const handleRouteCalculated = (distance, days) => {
    setTripDistance(distance);
    setEstimatedDays(days);
  };

  // Helper: update history in Firestore
  async function pushShipmentHistory(shipmentId, actionDesc) {
    if (!shipmentId) return;
    try {
      const oldHistory = shipment?.history || [];
      const newHistory = [
        ...oldHistory,
        {
          action: actionDesc,
          submittedBy: userName,
          date: new Date().toISOString(),
        },
      ];
      await updateById(
        TABLES.OUTBOUND_SHIPMENTS,
        { history: newHistory },
        shipmentId
      );
    } catch (err) {
      console.error("pushShipmentHistory error:", err);
    }
  }

  // Save (OK button)
  const handleOk = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      // Build origin/destination from state
      const origin = {
        id: selectedManufacturing?.id,
        address: selectedManufacturing?.address,
        latitude: selectedManufacturing?.latitude,
        longitude: selectedManufacturing?.longitude,
        nickname: selectedManufacturing?.nickname,
        placeId: selectedManufacturing?.placeId,
      };

      const destination = {
        address: destinationPlace?.formatted_address || "",
        latitude: destinationPlace?.geometry?.location.lat(),
        longitude: destinationPlace?.geometry?.location.lng(),
        placeId: destinationPlace?.place_id,
      };

      // The document to be created/updated
      const newItem = {
        origin,
        destination,
        tripDistance,
        estimatedDays,
        manufacturingLocationId: values.manufacturingLocation,
        projectId: selectedProject,
        truckType: values.truckType,
      };

      if (!shipment) {
        // CREATE
        const nextNumber = (existingShipments?.length || 0) + 1;
        newItem.shipmentNumber = "OS-" + nextNumber;

        // Add default history
        newItem.history = [
          {
            action: "Shipment Created",
            submittedBy: userName,
            date: new Date().toISOString(),
          },
        ];

        await addItemsToTable(TABLES.OUTBOUND_SHIPMENTS, newItem);
      } else {
        // UPDATE
        // Build an "old" info set only for the fields we care about
        const old = {
          projectId: shipment.projectId,
          manufacturingLocationId: shipment.manufacturingLocationId,
          truckType: shipment.truckType,
          destinationAddress: shipment.destination?.address,
        };

        await updateById(TABLES.OUTBOUND_SHIPMENTS, newItem, shipment.id);

        // figure out what's changed (only among project, mfg location, truckType, address)
        const changedParts = [];

        // For old vs new project
        if (old.projectId !== newItem.projectId) {
          const oldProj = projects.find((p) => p.id === old.projectId);
          const newProj = projects.find((p) => p.id === newItem.projectId);
          const oldName = oldProj ? oldProj.projectName : "None";
          const newName = newProj ? newProj.projectName : "None";
          changedParts.push(`Project: ${oldName} => ${newName}`);
        }

        // For old vs new manufacturing location
        if (old.manufacturingLocationId !== newItem.manufacturingLocationId) {
          const oldLoc = manufacturingLocations.find(
            (l) => l.id === old.manufacturingLocationId
          );
          const newLoc = manufacturingLocations.find(
            (l) => l.id === newItem.manufacturingLocationId
          );
          const oldNick = oldLoc ? oldLoc.nickname : "None";
          const newNick = newLoc ? newLoc.nickname : "None";
          changedParts.push(`Manufacturing location: ${oldNick} => ${newNick}`);
        }

        // For old vs new truck type
        if (old.truckType !== newItem.truckType) {
          changedParts.push(
            `Truck type: ${old.truckType || "None"} => ${newItem.truckType}`
          );
        }

        // For old vs new Destination address
        if (old.destinationAddress !== newItem.destination.address) {
          changedParts.push(
            `Destination: ${old.destinationAddress || "None"} => ${
              newItem.destination.address || "None"
            }`
          );
        }

        // If any changes, update the history
        if (changedParts.length > 0) {
          const desc = `Shipment updated: ${changedParts.join(" / ")}`;
          await pushShipmentHistory(shipment.id, desc);
        }
      }

      setLoading(false);
      onCancel();
      onUpdateSuccess?.();
    } catch (error) {
      console.error("Error saving shipment:", error);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      onOk={handleOk}
      confirmLoading={loading}
      width={800}
      title={shipment ? "Edit Outbound Shipment" : "Add Outbound Shipment"}
      footer={[
        <div style={{ marginTop: 16 }} key="footer-buttons">
          <Button
            key="cancel"
            onClick={onCancel}
            style={{ marginRight: 16 }}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            key="save"
            type="primary"
            onClick={handleOk}
            loading={loading}
          >
            Save Shipment
          </Button>
        </div>,
      ]}
    >
      <Form form={form} layout="vertical">
        {/* Project Selection */}
        <Form.Item
          name="projectId"
          label="Project"
          rules={[{ required: true, message: "Select a project" }]}
        >
          <Select
            placeholder="Select a project"
            showSearch
            optionFilterProp="children"
            onChange={handleProjectChange}
            value={selectedProject}
          >
            {sortedProjects.map((proj) => (
              <Select.Option key={proj.id} value={proj.id}>
                {proj.projectName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {/* Row for Manufacturing Location and Truck Type */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="manufacturingLocation"
              label="Manufacturing Location"
              rules={[
                { required: true, message: "Select a manufacturing location" },
              ]}
            >
              <Select
                placeholder="Select manufacturing location"
                onChange={handleManufacturingChange}
                value={selectedManufacturing?.id}
              >
                {manufacturingLocations.map((loc) => (
                  <Select.Option key={loc.id} value={loc.id}>
                    {loc.nickname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="truckType"
              label="Truck Type"
              rules={[{ required: true, message: "Select a truck type" }]}
            >
              <Select showSearch placeholder="Select a truck type">
                {TRUCK_TYPES.map((type) => (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Destination Address */}
        <Form.Item
          name="destinationAddress"
          label="Destination Address"
          rules={[{ required: true, message: "Enter a destination address" }]}
        >
          <PlaceObjectAutocomplete
            form={form}
            onChange={handleDestinationChange}
            value={destinationPlace?.formatted_address}
          />
        </Form.Item>
      </Form>

      {/* Delivery Route */}
      <DeliveryRoute
        key={`${selectedManufacturing?.id}-${destinationPlace?.place_id}`} // Force re-render
        originPlaceId={selectedManufacturing?.placeId || null}
        destinationPlaceId={destinationPlace?.place_id || null}
        onRouteCalculated={handleRouteCalculated}
      />
    </Modal>
  );
}

export default AddOutboundShipmentModal;
