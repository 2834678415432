import React, { useState, useMemo, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Tag,
  Modal,
  Tooltip,
  Input,
  Drawer,
} from "antd";
import {
  PlusOutlined,
  FilePdfOutlined,
  StopOutlined,
  FileOutlined,
  SearchOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import HeaderText from "../../Styled/HeaderText";
import { SearchInput } from "../../Styled/SearchInput";
import AddOutboundShipmentModal from "../../Components/Logistics/AddOutboundShipmentModal";
import {
  useOutboundShipments,
  useManufacturingLocations,
  useProjects,
} from "../../Contexts/useSpecificData";
import { getShipmentItems } from "../../Components/Logistics/getShipmentItems";
import PriceTag from "../../Styled/PriceTag";
import { COLORS } from "../../constants";
import { formatDate } from "../../Formatters/helpers";
import OutboundTimeline from "../../Components/Logistics/OutboundTimeline"; // <<-- import your timeline

function OutboundShipments() {
  const { data: projects } = useProjects();
  const { data: shipments } = useOutboundShipments();
  const { data: manufacturingLocations } = useManufacturingLocations();

  const [modalVisible, setModalVisible] = useState(false);
  const [editingShipment, setEditingShipment] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // Global search query

  // For PO modal
  const [poModalVisible, setPoModalVisible] = useState(false);
  const [selectedPO, setSelectedPO] = useState(null);
  const [selectedShipment, setSelectedShipment] = useState(null);

  // For BOL modal
  const [bolModalVisible, setBolModalVisible] = useState(false);
  const [bolLinks, setBolLinks] = useState({
    signed: null,
    countersigned: null,
  });
  const [selectedBOL, setSelectedBOL] = useState(null);

  // For History Drawer
  const [historyDrawerVisible, setHistoryDrawerVisible] = useState(false);
  const [historyShipment, setHistoryShipment] = useState(null);

  useEffect(() => {
    document.title = "Outbound Shipments";
  }, []);

  // Prepare filter options for Pickup Location
  const pickupLocationFilters = useMemo(() => {
    if (!shipments) return [];
    const locations = new Set();
    shipments.forEach((sh) => {
      const loc = sh.origin?.nickname || sh.origin?.address;
      if (loc) locations.add(loc);
    });
    return Array.from(locations).map((loc) => ({ text: loc, value: loc }));
  }, [shipments]);

  // Prepare filter options for Project
  const projectFilters = useMemo(() => {
    if (!projects || !shipments) return [];
    const projectIds = new Set(
      shipments.map((sh) => sh.projectId).filter(Boolean)
    );
    return projects
      .filter((p) => projectIds.has(p.id))
      .map((p) => ({ text: p.projectName, value: p.id }));
  }, [projects, shipments]);

  // Table columns
  const columns = [
    {
      title: "Shipment ID",
      dataIndex: "shipmentNumber",
      key: "shipmentNumber",
      sorter: (a, b) =>
        (a.shipmentNumber || "").localeCompare(b.shipmentNumber || ""),
      defaultSortOrder: "descend",
      render: (text, record) => {
        // We'll show the shipmentNumber link plus a History icon
        return (
          <div style={{ display: "flex", alignItems: "center",justifyContent: "space-between" }}>
            {/* Original link to freight page */}
            <a href={`/logistics/outbound/${record.id}/freight`}>{text}</a>

            {/* New History icon that opens the Drawer */}
            <HistoryOutlined
              style={{ cursor: "pointer", color: COLORS.PRIMARY }}
              onClick={() => {
                setHistoryShipment(record);
                setHistoryDrawerVisible(true);
              }}
            />
          </div>
        );
      },
    },
    {
      title: "Pickup Address",
      dataIndex: ["origin", "nickname"],
      key: "startAddress",
      render: (text, record) => {
        const nickname = record.origin?.nickname;
        const address = record.origin?.address;
        return nickname || address || <StopOutlined style={{ color: "red" }} />;
      },
      filters: pickupLocationFilters,
      filterSearch: true,
      onFilter: (value, record) => {
        const loc = record.origin?.nickname || record.origin?.address || "";
        return loc === value;
      },
    },
    {
      title: "Drop Off Address",
      key: "endAddress",
      render: (text, record) => {
        const fullAddress = record.destination?.address || "";
        if (fullAddress) {
          // Attempt to parse
          const match = fullAddress.match(
            /.*, ([A-Za-z\s]+), ([A-Z]{2})(?: \d{5})?, USA$/i
          );
          if (match) {
            const city = match[1];
            const state = match[2];
            return `${city}, ${state}`;
          }
        }
        // Fallback
        const city = record.destination?.city;
        const state = record.destination?.state;
        if (city && state) {
          return `${city}, ${state}`;
        }
        return <StopOutlined style={{ color: "red" }} />;
      },
    },
    {
      title: "Status",
      key: "status",
      filters: [
        { text: "Unshipped", value: "Unshipped" },
        { text: "Shipped", value: "Shipped" },
        { text: "Delivered", value: "Delivered" },
      ],
      onFilter: (value, record) => {
        if (value === "Delivered") return !!record.bol?.countersigned;
        if (value === "Shipped")
          return !!record.bol?.signed && !record.bol?.countersigned;
        if (value === "Unshipped") return !record.bol?.signed;
        return false;
      },
      render: (text, record) => {
        if (record.bol?.countersigned) {
          return <Tag color="green">Delivered</Tag>;
        } else if (record.bol?.signed) {
          return <Tag color="blue">Shipped</Tag>;
        }
        return <Tag color="red">Unshipped</Tag>;
      },
    },
    {
      title: "Project",
      dataIndex: "projectId",
      key: "projectId",
      filters: projectFilters,
      filterSearch: true,
      onFilter: (value, record) => record.projectId === value,
      render: (projectId) => {
        const proj = projects?.find((p) => p.id === projectId);
        return proj ? proj.projectName : "No Project";
      },
    },
    {
      title: "Freight",
      key: "onTruck",
      render: (text, record) => {
        const shipmentProject = projects.find((p) => p.id === record.projectId);
        if (!shipmentProject) {
          return <Tag color="red">No Project</Tag>;
        }
        const { truckModules, truckPallets } = getShipmentItems(
          shipmentProject,
          record.id
        );
        if (truckModules.length === 0 && truckPallets.length === 0) {
          return null;
        }
        return (
          <>
            {truckModules.map((module) => (
              <Tag color="blue" key={`module-${module.id}`}>
                {`${shipmentProject.projectName}-${module.number || module.id}`}
              </Tag>
            ))}
            {truckPallets.map((pallet) => (
              <Tag color="purple" key={`pallet-${pallet.id}`}>
                {pallet.palletName || pallet.id}
              </Tag>
            ))}
          </>
        );
      },
    },
    {
      title: "Purchase Order",
      key: "purchaseOrder",
      children: [
        {
          title: "PO Number",
          dataIndex: ["purchaseOrder", "poNumber"],
          key: "purchaseOrderNumber",
          sorter: (a, b) =>
            (a.purchaseOrder?.poNumber || "").localeCompare(
              b.purchaseOrder?.poNumber || ""
            ),
          render: (poNumber, record) =>
            poNumber ? (
              <a
                href="#!"
                onClick={() => {
                  setSelectedShipment(record);
                  setSelectedPO({
                    files: record.purchaseOrder?.files,
                    poNumber: poNumber,
                  });
                  setPoModalVisible(true);
                }}
              >
                {poNumber}
              </a>
            ) : (
              <StopOutlined style={{ color: "red" }} />
            ),
        },
        {
          title: "Amount",
          dataIndex: ["purchaseOrder", "amount"],
          key: "purchaseOrderAmount",
          sorter: (a, b) => {
            const aAmount = a.purchaseOrder?.amount ?? 0;
            const bAmount = b.purchaseOrder?.amount ?? 0;
            return aAmount - bAmount;
          },
          render: (amount) =>
            amount ? (
              <PriceTag amount={amount} />
            ) : (
              <StopOutlined style={{ color: "red" }} />
            ),
        },
        {
          title: "Date Sent",
          key: "purchaseOrderDateSent",
          sorter: (a, b) => {
            const aFiles = a.purchaseOrder?.files || [];
            const bFiles = b.purchaseOrder?.files || [];
            const aDate = aFiles.length
              ? new Date(aFiles[aFiles.length - 1].dateSent)
              : new Date(0);
            const bDate = bFiles.length
              ? new Date(bFiles[bFiles.length - 1].dateSent)
              : new Date(0);
            return aDate - bDate;
          },
          render: (unused, record) => {
            const files = record.purchaseOrder?.files || [];
            if (!files.length) {
              return <StopOutlined style={{ color: "red" }} />;
            }
            const lastFile = files[files.length - 1];
            if (!lastFile.dateSent) {
              return <StopOutlined style={{ color: "red" }} />;
            }
            return formatDate(lastFile.dateSent);
          },
        },
      ],
    },
    {
      title: "BOL",
      key: "bol",
      render: (text, record) => {
        const signedUrl = record.bol?.signed?.pdfUrl;
        const countersignedUrl = record.bol?.countersigned?.pdfUrl;
        const hasBOL = signedUrl || countersignedUrl;
        if (!hasBOL) {
          return <StopOutlined style={{ color: "red" }} />;
        }
        return (
          <a
            href="#!"
            onClick={() => {
              setBolLinks({
                signed: signedUrl,
                countersigned: countersignedUrl,
              });
              setSelectedShipment(record);
              setSelectedBOL(record.bol?.bolNumber);
              setBolModalVisible(true);
            }}
          >
            {record.bol?.bolNumber || "View BOL"}
          </a>
        );
      },
    },
  ];

  // Filter shipments globally
  const filteredShipments = useMemo(() => {
    if (!searchQuery) return shipments || [];
    const q = searchQuery.toLowerCase();
    return (shipments || []).filter((sh) => {
      const proj = projects.find((p) => p.id === sh.projectId);
      const projectName = proj ? proj.projectName.toLowerCase() : "";
      const shipmentId = (sh.shipmentNumber || "").toLowerCase();
      const pickupLocation = (
        sh.origin?.nickname ||
        sh.origin?.address ||
        ""
      ).toLowerCase();
      const dropoffCity = (sh.destination?.city || "").toLowerCase();
      const dropoffState = (sh.destination?.state || "").toLowerCase();

      let freightMatch = false;
      if (proj) {
        const { truckModules, truckPallets } = getShipmentItems(proj, sh.id);
        freightMatch = [...truckModules, ...truckPallets].some((item) => {
          const labelText = (
            proj.projectName +
            "-" +
            (item.number || item.palletName || "")
          ).toLowerCase();
          return labelText.includes(q);
        });
      }

      let poMatch = false;
      if (sh.purchaseOrder) {
        const poNumber = (sh.purchaseOrder.poNumber || "").toLowerCase();
        poMatch = poNumber.includes(q);
        if (!poMatch && sh.purchaseOrder.files?.length) {
          poMatch = sh.purchaseOrder.files.some((file, index) => {
            return (
              `rev_${index}`.includes(q) ||
              (file.url && file.url.toLowerCase().includes(q))
            );
          });
        }
      }

      let amountMatch = false;
      if (sh.purchaseOrder?.amount) {
        amountMatch = sh.purchaseOrder.amount.toString().includes(q);
      }

      let bolMatch = false;
      if (sh.bol) {
        bolMatch = (sh.bol.bolNumber || "").toLowerCase().includes(q);
      }

      return (
        shipmentId.includes(q) ||
        projectName.includes(q) ||
        pickupLocation.includes(q) ||
        dropoffCity.includes(q) ||
        dropoffState.includes(q) ||
        freightMatch ||
        poMatch ||
        amountMatch ||
        bolMatch
      );
    });
  }, [searchQuery, shipments, projects]);

  return (
    <Row gutter={[0, 16]}>
      <Col span={12}>
        <HeaderText text="Outbound Shipments" />
      </Col>
      <Col span={12} style={{ textAlign: "right" }}>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            setEditingShipment(null);
            setModalVisible(true);
          }}
        >
          Add Outbound Shipment
        </Button>
      </Col>
      <Col span={24}>
        <SearchInput
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          resultsLength={filteredShipments.length}
        />
      </Col>
      <Col span={24}>
        <Table
          size="small"
          className="small-table"
          dataSource={filteredShipments}
          columns={columns}
          rowKey="id"
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "25", "50", "100"],
          }}
        />
      </Col>

      {/* Add/Edit Shipment Modal */}
      {modalVisible && (
        <AddOutboundShipmentModal
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          shipment={editingShipment}
          manufacturingLocations={manufacturingLocations || []}
          existingShipments={shipments || []}
        />
      )}

      {/* Purchase Order PDFs Modal */}
      <Modal
        visible={poModalVisible}
        title={
          selectedShipment
            ? `${selectedShipment.shipmentNumber} - Purchase Order PDFs${
                selectedPO?.poNumber ? ` - PO# ${selectedPO.poNumber}` : ""
              }`
            : "Purchase Order PDFs"
        }
        footer={null}
        onCancel={() => setPoModalVisible(false)}
        width={600}
      >
        {selectedPO && selectedPO.files && selectedPO.files.length > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "12px",
              flexWrap: "wrap",
              gap: "16px",
            }}
          >
            {selectedPO.files.map((pdf, index) => {
              // pdf is presumably an object with { url, dateSent? }
              const pdfUrl = pdf.url || "#!";
              return (
                <div
                  key={`po-pdf-${index}`}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                    <FilePdfOutlined
                      style={{
                        fontSize: "24px",
                        color: COLORS.PRIMARY,
                        margin: "4px 0px",
                      }}
                    />
                  </a>
                  <div style={{ fontSize: "0.85rem" }}>{`Rev_${index}`}</div>
                  {pdf.dateSent && (
                    <div style={{ fontSize: "0.85rem" }}>
                      {formatDate(pdf.dateSent)}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <p>No PDFs available.</p>
        )}
      </Modal>

      {/* BOL Modal */}
      <Modal
        visible={bolModalVisible}
        title={
          selectedShipment
            ? `${selectedShipment.shipmentNumber} - Bill of Lading${
                selectedBOL ? ` - ${selectedBOL}` : ""
              }`
            : "Bill of Lading"
        }
        footer={null}
        onCancel={() => setBolModalVisible(false)}
        width={600}
      >
        {bolLinks.signed || bolLinks.countersigned ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "32px",
              padding: "12px",
            }}
          >
            {/* LEFT: Carrier BOL */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "120px",
              }}
            >
              {bolLinks.signed ? (
                <>
                  <Tooltip title="View Signed BOL">
                    <a
                      href={bolLinks.signed}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FilePdfOutlined
                        style={{
                          fontSize: "24px",
                          color: COLORS.PRIMARY,
                          marginBottom: 4,
                        }}
                      />
                    </a>
                  </Tooltip>
                  <div>Carrier BOL</div>
                  {/* date if present */}
                  {selectedShipment?.bol?.signed?.pickupDate && (
                    <div>
                      {formatDate(selectedShipment.bol.signed.pickupDate)}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <StopOutlined
                    style={{
                      fontSize: "24px",
                      color: "red",
                      marginBottom: 4,
                    }}
                  />
                  <div>Signed</div>
                </>
              )}
            </div>

            {/* RIGHT: Customer Signed BOL */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "120px",
              }}
            >
              {bolLinks.countersigned ? (
                <>
                  <Tooltip title="View Customer Signed BOL">
                    <a
                      href={bolLinks.countersigned}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FilePdfOutlined
                        style={{
                          fontSize: "24px",
                          color: COLORS.PRIMARY,
                          marginBottom: 4,
                        }}
                      />
                    </a>
                  </Tooltip>
                  <div>Delivered BOL</div>
                  {selectedShipment?.bol?.countersigned?.dateSigned && (
                    <div>
                      {formatDate(
                        selectedShipment.bol.countersigned.dateSigned
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <StopOutlined
                    style={{
                      fontSize: "24px",
                      color: "red",
                      marginBottom: 4,
                    }}
                  />
                  <div>Customer Signed</div>
                </>
              )}
            </div>
          </div>
        ) : (
          <p>No BOL available.</p>
        )}
      </Modal>

      {/* Timeline Drawer */}
      <Drawer
        title={`Shipment History${
          historyShipment ? ` - ${historyShipment.shipmentNumber}` : ""
        }`}
        placement="right"
        width={600}
        onClose={() => setHistoryDrawerVisible(false)}
        open={historyDrawerVisible}
      >
        {historyShipment && <OutboundTimeline shipment={historyShipment} />}
      </Drawer>
    </Row>
  );
}

export default OutboundShipments;
