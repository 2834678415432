import React, { useState, useMemo, useEffect } from "react";
import { Table, Row, Col, Button, Tooltip } from "antd";
import {
  useCustomers,
  useProjects,
  useEstimates,
} from "../../Contexts/useSpecificData";
import HeaderText from "../../Styled/HeaderText";
import { SearchInput } from "../../Styled/SearchInput";
import AddCustomerModal from "./AddCustomerModal";
import {
  PlusOutlined,
  StopOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { COLORS } from "../../constants";
import DisplayPhoneNumber from "../../Formatters/DisplayPhoneNumber";
import { formatDate } from "../../Formatters/helpers";

function Customers() {
  const { data: customers, refresh: refreshCustomers } = useCustomers();
  const { data: projects } = useProjects();
  const { data: estimates } = useEstimates();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleAddSuccess = () => {
    refreshCustomers();
    setIsModalVisible(false);
    setSelectedCustomer(null);
  };

  // Memoize sorted customers to optimize performance
  const sortedCustomers = useMemo(() => {
    if (!customers) return [];
    return [...customers].sort((a, b) =>
      (a.companyName || "").localeCompare(b.companyName || "")
    );
  }, [customers]);

    // ----------- NEW: Set Page/Tab Title -----------
    useEffect(() => {
      document.title = "Customers";
    }, []);
    // ----------------------------------------------

  // Memoize filtered customers based on search term
  const filteredCustomers = useMemo(() => {
    if (!sortedCustomers) return [];
    return sortedCustomers.filter((customer) => {
      const term = searchTerm.toLowerCase();
      const companyName = (customer.companyName || "").toLowerCase();
      const primaryContact = customer.contacts
        ? customer.contacts.find((contact) => contact.primary)
        : null;
      const contactName = primaryContact
        ? `${primaryContact.firstName || ""} ${
            primaryContact.lastName || ""
          }`.toLowerCase()
        : "";
      const contactEmail = primaryContact
        ? (primaryContact.email || "").toLowerCase()
        : "";
      const contactPhone = primaryContact
        ? (primaryContact.phone || "").toLowerCase()
        : "";

      return (
        companyName.includes(term) ||
        contactName.includes(term) ||
        contactEmail.includes(term) ||
        contactPhone.includes(term)
      );
    });
  }, [sortedCustomers, searchTerm]);

  // Prepare the data source for the table
  const dataSource = useMemo(() => {
    if (!filteredCustomers) return [];

    return filteredCustomers.map((customer) => {
      const primaryContact = customer.contacts
        ? customer.contacts.find((contact) => contact.primary)
        : null;

      const estimatesCount = estimates
        ? estimates.filter((estimate) => estimate.customerId === customer.id)
            .length
        : 0;

      const projectsCount = projects
        ? projects.filter((project) => project.customerId === customer.id)
            .length
        : 0;

      return {
        key: customer.id,
        ...customer,
        primaryContact,
        estimatesCount,
        projectsCount,
      };
    });
  }, [filteredCustomers, projects, estimates]);

  const columns = [
    {
      title: "Date Created",
      key: "created",
      dataIndex: "created",
      sorter: (a, b) =>
        new Date(a.created || 0).getTime() - new Date(b.created || 0).getTime(),
      render: (text) =>
        text ? (
          formatDate(text)
        ) : (
          <StopOutlined style={{ color: COLORS.WARNING }} />
        ),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      sorter: (a, b) =>
        (a.companyName || "").localeCompare(b.companyName || ""),
      render: (text, record) => (
        <a
          onClick={() => {
            // Filter out unnecessary fields
            const {
              estimatesCount,
              primaryContact,
              key,
              projectsCount,
              ...cleanedCustomer
            } = record;
            setSelectedCustomer(cleanedCustomer); // Only pass the necessary fields
            setIsModalVisible(true);
          }}
        >
          {text || (
            <Tooltip title="No Company Name">
              <QuestionCircleOutlined />
            </Tooltip>
          )}
        </a>
      ),
    },
    {
      title: "Primary Contact Name",
      key: "primaryContactName",
      render: (text, record) =>
        record.primaryContact ? (
          `${record.primaryContact.firstName || ""} ${
            record.primaryContact.lastName || ""
          }`
        ) : (
          <Tooltip title="No Primary Contact">
            <StopOutlined style={{ color: COLORS.WARNING }} />
          </Tooltip>
        ),
    },
    {
      title: "Primary Contact Email",
      dataIndex: ["primaryContact", "email"],
      key: "primaryContactEmail",
      render: (text, record) =>
        record.primaryContact ? (
          <div>{record.primaryContact.email}</div>
        ) : (
          <Tooltip title="No Primary Contact Email">
            <StopOutlined style={{ color: COLORS.WARNING }} />
          </Tooltip>
        ),
    },
    {
      title: "Phone Number",
      dataIndex: ["primaryContact", "phone"],
      key: "primaryContactPhone",
      render: (text) =>
        text ? (
          <DisplayPhoneNumber phoneNumber={text} />
        ) : (
          <StopOutlined style={{ color: COLORS.WARNING }} />
        ),
    },
    {
      title: "Estimates",
      key: "estimates",
      dataIndex: "estimatesCount",
      sorter: (a, b) => a.estimatesCount - b.estimatesCount,
      render: (text, record) =>
        record.estimatesCount || <StopOutlined style={{ color: "red" }} />,
    },
    {
      title: "Projects",
      key: "projects",
      dataIndex: "projectsCount",
      sorter: (a, b) => a.projectsCount - b.projectsCount,
      render: (text, record) =>
        record.projectsCount || <StopOutlined style={{ color: "red" }} />,
    },
    // {
    //   title: "Prefix",
    //   dataIndex: "prefix",
    //   key: "prefix",
    //   sorter: (a, b) => (a.prefix || "").localeCompare(b.prefix || ""),
    // },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <HeaderText text={"Customers"} />
      </Col>
      <Col span={12} style={{ textAlign: "right" }}>
        <AddCustomerModal onAddSuccess={handleAddSuccess}>
          <Button type="primary" icon={<PlusOutlined />}>
            Add Customer
          </Button>
        </AddCustomerModal>
      </Col>
      <Col span={24}>
        <SearchInput
          placeholder="Search Customers"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          resultsLength={filteredCustomers.length}
        />
      </Col>
      <Col span={24}>
        <Table
          size="small"
          columns={columns}
          dataSource={dataSource}
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
            }}
          loading={!customers}
          rowKey="id"
        />
      </Col>

      {/* AddCustomerModal for Editing */}
      <AddCustomerModal
        customer={selectedCustomer}
        visible={isModalVisible}
        onAddSuccess={handleAddSuccess}
        onCancel={() => {
          setIsModalVisible(false);
          setSelectedCustomer(null);
        }}
      />
    </Row>
  );
}

export default Customers;
