// src/components/ProjectModules/AddProjectModulesModal.jsx
import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Table,
  Row,
  Col,
  Typography,
  message,
  Tag,
  Card
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined,
  WarningOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { PRODUCT_TYPES } from "../../constants";
import PriceTag from "../../Styled/PriceTag";
import { formatMoney } from "../../Formatters/helpers";
import HeaderText from "../../Styled/HeaderText";

const { Text } = Typography;
const { Option } = Select;

// Define a tolerance for delta to consider it as zero
const DELTA_TOLERANCE = 0.01;

// Helper function to pad numbers
const padNumber = (num) => {
  return num < 10 ? `0${num}` : `${num}`;
};

const AddProjectModulesModal = ({
  visible,
  onSave,
  onCancel,
  projectId,
  initialModules,
  requiredTotal, // The total that modules need to match
}) => {
  const [modalModules, setModalModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addForm] = Form.useForm();
  const [delta, setDelta] = useState(0); // Difference between requiredTotal and modulesTotal
  const [modulesTotal, setModulesTotal] = useState(0); // Current total of module amounts
  const [distributing, setDistributing] = useState(false); // Loading state for distribution

  // Initialize modalModules when modal becomes visible
  useEffect(() => {
    if (visible) {
      // Ensure each initial module has a unique 'id' and numeric 'amount'
      const initializedModules = initialModules.map((mod, index) => ({
        ...mod,
        id: mod.id || uuidv4(),
        number: padNumber(index + 1),
        amount: Number(mod.amount) || 0,
        // Provide defaults if length/width/height in inches are missing:
        lengthIn: mod.lengthIn || 0,
        widthIn: mod.widthIn || 0,
        heightIn: mod.heightIn || 0,
      }));
      setModalModules(initializedModules); // Create a copy for editing
      calculateDelta(initializedModules);
      console.log("Initialized Modules:", initializedModules);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, initialModules]);

  // Function to calculate the delta with rounding
  const calculateDelta = (modules) => {
    const total = modules.reduce(
      (acc, mod) => acc + (Number(mod.amount) || 0),
      0
    );
    const modulesTotal = parseFloat(total.toFixed(2));
    const difference = parseFloat((requiredTotal - modulesTotal).toFixed(2));
    setModulesTotal(modulesTotal);
    setDelta(difference);
    console.log(`Required Total: ${requiredTotal}`);
    console.log(`Modules Total: ${modulesTotal}`);
    console.log(`Delta Calculated: ${difference}`);
  };

  // Add multiple modules based on number and attributes
  const handleAddModules = (values) => {
    const {
      numberOfModules,
      moduleDescription,
      length,
      lengthIn, // NEW (inches)
      width,
      widthIn, // NEW (inches)
      height,
      heightIn, // NEW (inches)
      weight,
      productType,
      amount,
    } = values;

    const startingIndex = modalModules.length + 1;

    // Create new modules, each with ft + in fields
    const newModules = Array.from({ length: numberOfModules }, (_, index) => ({
      id: uuidv4(),
      number: padNumber(startingIndex + index),
      moduleDescription,
      length,
      lengthIn, // store inches
      width,
      widthIn, // store inches
      height,
      heightIn, // store inches
      weight,
      productType,
      amount: Number(amount) || 0,
    }));

    const updatedModules = [...modalModules, ...newModules];
    setModalModules(updatedModules);
    calculateDelta(updatedModules);
    addForm.resetFields();
    message.success(`${numberOfModules} module(s) added successfully!`);
    console.log("Modules after adding:", updatedModules);
  };

  // Handle changes in table cells
  const handleTableChange = (record, field, value) => {
    const updatedModules = modalModules.map((mod) => {
      if (mod.id === record.id) {
        const updatedValue = field === "amount" ? Number(value) || 0 : value;
        return {
          ...mod,
          [field]: updatedValue,
        };
      }
      return mod;
    });
    setModalModules(updatedModules);
    calculateDelta(updatedModules);
    console.log(`Modules after change in '${field}':`, updatedModules);
  };

  // Remove a module from modalModules
  const handleRemoveModule = (id) => {
    const updatedModules = modalModules.filter((mod) => mod.id !== id);
    // Reassign numbers to maintain sequence
    const renumberedModules = updatedModules.map((mod, index) => ({
      ...mod,
      number: padNumber(index + 1),
    }));
    setModalModules(renumberedModules);
    calculateDelta(renumberedModules);
    message.success("Module removed successfully!");
    console.log("Modules after removal:", renumberedModules);
  };

  // Remove all modules from modalModules
  const handleRemoveAllModules = () => {
    if (modalModules.length === 0) {
      message.info("No modules to remove.");
      return;
    }
    Modal.confirm({
      title: "Are you sure you want to remove all modules?",
      icon: <DeleteOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setModalModules([]);
        calculateDelta([]);
        message.success("All modules have been removed.");
        console.log("All modules removed.");
      },
    });
  };

  // Handle saving all changes
  const handleSave = async () => {
    try {
      setLoading(true);
      // Additional validations can be performed here if necessary

      // Since the actual saving logic is handled in the parent component,
      // we simply call onSave with the updated modules.
      onSave(modalModules);
      setLoading(false);
      message.success("Modules saved successfully!");
      console.log("Modules saved:", modalModules);
    } catch (error) {
      console.error(error);
      message.error("Failed to save modules. Please try again.");
      setLoading(false);
    }
  };

  // Function to distribute delta evenly among modules
  const handleDistributeEvenly = () => {
    if (modalModules.length === 0) {
      message.info("No modules to distribute.");
      return;
    }

    setDistributing(true);

    // Calculate the base adjustment per module
    const baseAdjustment = parseFloat((delta / modalModules.length).toFixed(2));
    console.log(`Base adjustment per module: ${baseAdjustment}`);

    // Calculate total adjustment applied
    const totalAdjustment = parseFloat(
      (baseAdjustment * modalModules.length).toFixed(2)
    );
    console.log(`Total adjustment applied: ${totalAdjustment}`);

    // Calculate any remaining delta due to rounding
    let remainingDelta = parseFloat((delta - totalAdjustment).toFixed(2));
    console.log(`Remaining delta to distribute: ${remainingDelta}`);

    // Distribute the remaining delta to the first few modules
    const updatedModules = modalModules.map((mod) => {
      let adjustment = baseAdjustment;
      if (remainingDelta !== 0) {
        // Adjust by 0.01 or -0.01 depending on the remaining delta
        const adjustmentStep = remainingDelta > 0 ? 0.01 : -0.01;
        adjustment += adjustmentStep;
        remainingDelta = parseFloat(
          (remainingDelta - adjustmentStep).toFixed(2)
        );
      }
      const newAmount = parseFloat(
        (Number(mod.amount) + adjustment).toFixed(2)
      );
      return {
        ...mod,
        amount: newAmount,
      };
    });

    setModalModules(updatedModules);
    calculateDelta(updatedModules);
    setDistributing(false);
    message.success("Amounts distributed evenly.");
    console.log("Modules after distribution:", updatedModules);
  };

  // Define columns for the editable table
  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 60,
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "Module Description",
      dataIndex: "moduleDescription",
      key: "moduleDescription",
      width: 200,
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) =>
            handleTableChange(record, "moduleDescription", e.target.value)
          }
          placeholder="Description"
        />
      ),
    },
    // --- LENGTH (ft) ---
    {
      title: "Length (ft)",
      dataIndex: "length",
      key: "length",
      width: 100,
      render: (text, record) => (
        <InputNumber
          min={0}
          value={text}
          onChange={(value) => handleTableChange(record, "length", value)}
          style={{ width: "100%" }}
          placeholder="Length"
          addonAfter="ft"
        />
      ),
    },
    // --- LENGTH (in) ---
    {
      title: "Length (in)",
      dataIndex: "lengthIn",
      key: "lengthIn",
      width: 100,
      render: (text, record) => (
        <InputNumber
          min={0}
          max={11.99}
          value={text}
          onChange={(value) => handleTableChange(record, "lengthIn", value)}
          style={{ width: "100%" }}
          placeholder="Inches"
          addonAfter="in"
        />
      ),
    },
    // --- WIDTH (ft) ---
    {
      title: "Width (ft)",
      dataIndex: "width",
      key: "width",
      width: 100,
      render: (text, record) => (
        <InputNumber
          min={0}
          value={text}
          onChange={(value) => handleTableChange(record, "width", value)}
          style={{ width: "100%" }}
          placeholder="Width"
          addonAfter="ft"
        />
      ),
    },
    // --- WIDTH (in) ---
    {
      title: "Width (in)",
      dataIndex: "widthIn",
      key: "widthIn",
      width: 100,
      render: (text, record) => (
        <InputNumber
          min={0}
          max={11.99}
          value={text}
          onChange={(value) => handleTableChange(record, "widthIn", value)}
          style={{ width: "100%" }}
          placeholder="Inches"
          addonAfter="in"
        />
      ),
    },
    // --- HEIGHT (ft) ---
    {
      title: "Height (ft)",
      dataIndex: "height",
      key: "height",
      width: 100,
      render: (text, record) => (
        <InputNumber
          min={0}
          value={text}
          onChange={(value) => handleTableChange(record, "height", value)}
          style={{ width: "100%" }}
          placeholder="Height"
          addonAfter="ft"
        />
      ),
    },
    // --- HEIGHT (in) ---
    {
      title: "Height (in)",
      dataIndex: "heightIn",
      key: "heightIn",
      width: 100,
      render: (text, record) => (
        <InputNumber
          min={0}
          max={11.99}
          value={text}
          onChange={(value) => handleTableChange(record, "heightIn", value)}
          style={{ width: "100%" }}
          placeholder="Inches"
          addonAfter="in"
        />
      ),
    },
    {
      title: "Weight (lbs)",
      dataIndex: "weight",
      key: "weight",
      width: 120,
      render: (text, record) => (
        <InputNumber
          min={0}
          value={text}
          onChange={(value) => handleTableChange(record, "weight", value)}
          style={{ width: "100%" }}
          placeholder="Weight"
          addonAfter="lbs"
        />
      ),
    },
    {
      title: "Product Type",
      dataIndex: "productType",
      key: "productType",
      width: 150,
      render: (text, record) => (
        <Select
          value={text}
          onChange={(value) => handleTableChange(record, "productType", value)}
          style={{ width: "100%" }}
          placeholder="Select Type"
        >
          {PRODUCT_TYPES.map((type) => (
            <Option key={type} value={type}>
              {type}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Amount ($)",
      dataIndex: "amount",
      key: "amount",
      width: 120,
      render: (text, record) => (
        <InputNumber
          min={0}
          value={text}
          onChange={(value) => handleTableChange(record, "amount", value)}
          style={{ width: "100%" }}
          placeholder="Amount"
          prefix="$"
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 100,
      render: (_, record) => (
        <Button
          type="link"
          danger
          onClick={() => handleRemoveModule(record.id)}
          icon={<DeleteOutlined />}
        >
          Remove
        </Button>
      ),
    },
  ];

  // Function to determine if delta is effectively zero
  const isDeltaZero = Math.abs(delta) <= DELTA_TOLERANCE;

  return (
    <Modal
      title={
        visible && initialModules.length > 0 ? "Edit Modules" : "Add Modules"
      }
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="removeAll"
          danger
          onClick={handleRemoveAllModules}
          icon={<DeleteOutlined />}
          disabled={modalModules.length === 0}
        >
          Remove All
        </Button>,
        <Button key="cancel" onClick={onCancel} icon={<CloseOutlined />}>
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={handleSave}
          loading={loading}
          icon={<SaveOutlined />}
          disabled={!isDeltaZero} // Disable save if delta is not within tolerance
        >
          Save
        </Button>,
      ]}
      width={"98%"}
      destroyOnClose
    >
      {/* Display Delta Information */}
      <Row gutter={16} style={{ marginBottom: "16px" }} align="middle">
        <Col span={24} style={{ display: "flex", alignItems: "center" }}>
          {!isDeltaZero ? (
            <Tag
              color="red"
              icon={<WarningOutlined />}
              style={{ marginRight: 16 }}
            >
              {delta > 0
                ? `Modules total is ${formatMoney(
                    modulesTotal
                  )}, which is less than the required amount of ${formatMoney(
                    requiredTotal
                  )} by ${formatMoney(delta)}. Please add ${formatMoney(
                    delta
                  )} to match.`
                : `Modules total is ${formatMoney(
                    modulesTotal
                  )}, which exceeds the required amount of ${formatMoney(
                    requiredTotal
                  )} by ${formatMoney(
                    Math.abs(delta)
                  )}. Please subtract ${formatMoney(
                    Math.abs(delta)
                  )} from modules to match.`}
            </Tag>
          ) : (
            <Tag color="green" icon={<CheckOutlined />}>
              {`Modules total matches the required amount of ${formatMoney(
                requiredTotal
              )}`}
            </Tag>
          )}

          {/* Add "Distribute Evenly" Button */}
          {!isDeltaZero && (
            <Button
              type="default"
              onClick={handleDistributeEvenly}
              loading={distributing}
              disabled={modalModules.length === 0}
            >
              Distribute Evenly
            </Button>
          )}
        </Col>
      </Row>

      {/* Form to add multiple modules */}
      <Form
        form={addForm}
        layout="vertical"
        name="addModulesForm"
        onFinish={handleAddModules}
        initialValues={{
          numberOfModules: 1,
          moduleDescription: "",
          length: 0,
          lengthIn: 0,
          width: 0,
          widthIn: 0,
          height: 0,
          heightIn: 0,
          weight: 0,
          productType: "",
          amount: 0,
        }}
      >
        <Row gutter={16} style={{}}>
          <Card
            title="Add Bulk Modules"
            style={{ width: "100%" }}
            extra={
              <div style={{ display: "flex",alignItems:"center"}}>
                {" "}
                <span style={{marginRight:12}}>Number of Modules:</span>
                <Form.Item
                  noStyle
                  label="Number of Modules"
                  name="numberOfModules"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the number of modules to add",
                    },
                  ]}
                >
                  <div style={{ display: "flex" }}>
                    <InputNumber
                      min={1}
                      style={{ flex: 1, marginRight: 8 }}
                      type="number"
                    />
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      htmlType="submit"
                    >
                      Add
                    </Button>
                  </div>
                </Form.Item>
              </div>
            }
          >


            {/* --- First Row: Length/Width/Height (ft & in), Weight --- */}
            <Row gutter={16} style={{ marginBottom: 16, width: "100%" }}>
              {/* Length (ft) */}
              <Col span={3}>
                <Form.Item
                  
                  label="Length (ft)"
                  name="length"
                  rules={[
                    { required: true, message: "Please enter length (ft)" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    placeholder="Length"
                    addonAfter="ft"
                  />
                </Form.Item>
              </Col>
              {/* Length (in) */}
              <Col span={3}>
                <Form.Item
                  
                  label="Length (in)"
                  name="lengthIn"
                  rules={[
                    { required: true, message: "Please enter length (in)" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={11.99}
                    style={{ width: "100%" }}
                    placeholder="Inches"
                    addonAfter="in"
                  />
                </Form.Item>
              </Col>

              {/* Width (ft) */}
              <Col span={3}>
                <Form.Item
                  
                  label="Width (ft)"
                  name="width"
                  rules={[
                    { required: true, message: "Please enter width (ft)" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    placeholder="Width"
                    addonAfter="ft"
                  />
                </Form.Item>
              </Col>
              {/* Width (in) */}
              <Col span={3}>
                <Form.Item
                  
                  label="Width (in)"
                  name="widthIn"
                  rules={[
                    { required: true, message: "Please enter width (in)" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={11.99}
                    style={{ width: "100%" }}
                    placeholder="Inches"
                    addonAfter="in"
                  />
                </Form.Item>
              </Col>

              {/* Height (ft) */}
              <Col span={3}>
                <Form.Item
                  
                  label="Height (ft)"
                  name="height"
                  rules={[
                    { required: true, message: "Please enter height (ft)" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    placeholder="Height"
                    addonAfter="ft"
                  />
                </Form.Item>
              </Col>
              {/* Height (in) */}
              <Col span={3}>
                <Form.Item
                  
                  label="Height (in)"
                  name="heightIn"
                  rules={[
                    { required: true, message: "Please enter height (in)" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={11.99}
                    style={{ width: "100%" }}
                    placeholder="Inches"
                    addonAfter="in"
                  />
                </Form.Item>
              </Col>

              {/* Weight (lbs) */}
              <Col span={6}>
                <Form.Item
                  
                  label="Weight (lbs)"
                  name="weight"
                  rules={[
                    { required: true, message: "Please enter the weight" },
                  ]}
                >
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    placeholder="Weight"
                    addonAfter="lbs"
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* --- Second Row: Product Type, Amount, Description, # of Modules + Add Button --- */}
            <Row gutter={16} style={{ width: "100%" }}>
              {/* Product Type */}
              <Col span={6}>
                <Form.Item
                  label="Product Type"
                  name="productType"
                  rules={[
                    { required: true, message: "Please select a product type" },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Select product type"
                  >
                    {PRODUCT_TYPES.map((type) => (
                      <Option key={type} value={type}>
                        {type}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* Amount ($) */}
              <Col span={4}>
                <Form.Item
                  
                  label="Amount"
                  name="amount"
                  rules={[
                    { required: true, message: "Please enter the amount" },
                  ]}
                >
                  <InputNumber
                    type="number"
                    min={0}
                    style={{ width: "100%" }}
                    prefix="$"
                    placeholder="Amount"
                  />
                </Form.Item>
              </Col>

              {/* Description */}
              <Col span={11}>
                <Form.Item
                  
                  label="Description"
                  name="moduleDescription"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the module description",
                    },
                  ]}
                >
                  <Input.TextArea
                    autoSize
                    placeholder="Enter module description"
                  />
                </Form.Item>
              </Col>

              {/* Number of Modules + Add Button */}
              <Col span={6}></Col>
            </Row>
          </Card>
        </Row>
      </Form>

      {/* Editable Table within Modal */}
      <Table
        dataSource={modalModules}
        columns={columns}
        rowKey="id"
        pagination={false}
        bordered
        style={{ marginTop: 8 }}
      />
    </Modal>
  );
};

export default AddProjectModulesModal;
