import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { formatCommas } from "../../Formatters/helpers";

dayjs.extend(advancedFormat);

/**
 * Generate an Outbound Bill of Lading
 */
export default function generateHtmlOutboundBOL({
  // Shipper
  shipperName = "BMarko Structures, LLC",
  shipperContact = "Christina Parker",
  shipperAddress1 = "2624 Weaver Way, Suite 200",
  shipperAddress2 = "Atlanta, GA 30340",
  shipperPhone = "+1 678-648-6244",

  // Freight Agent
  freightAgentName = "",
  freightAgentCompany = "",
  freightAgentPhone = "",

  // Receiver
  receiverCompany = "", //  <-- Pulled from project.companyName
  receiverName = "", //  <-- Pulled from user input: recipientName
  receiverPhone = "", //  <-- Pulled from user input: recipientPhone

  // BOL fields
  bolNumber = "BOL-1",
  projectName = "",

  // Pickup & dropoff
  origin = "Shipper address here",
  destination = "Destination address here",

  // Carrier Information
  carrierName = "",
  carrierCompany = "",
  carrierPhone = "",
  carrierSignature = "", // <-- Data URL of the carrier signature
  pickupDate = "",

  // Signature
  userSignature = "", // <-- Data URL of the user signature

  items = [],
  customDescription,
}) {
  const today = dayjs().format("MM/DD/YYYY");

  // If origin/destination end with ", USA" remove it (just a cleanup example)
  if (origin && origin.endsWith(", USA")) {
    origin = origin.replace(/,\s*USA$/, "");
  }
  if (destination && destination.endsWith(", USA")) {
    destination = destination.replace(/,\s*USA$/, "");
  }

  // Helper to format dimensions
  function formatDimension(ftVal, inVal) {
    const ftText = ftVal !== undefined ? `${ftVal}ft` : "?ft";
    const inValue = Number.isFinite(inVal) ? inVal : 0;
    const inText = inValue === 0 ? "" : ` ${inValue}in`;
    return ftText + inText;
  }

  console.log("items", items);

  // Build item rows
  const itemRowsHtml = items
    .map((item) => {
      const {
        type,
        description = "N/A",
        weight,
        length,
        width,
        height,
        lengthIn,
        widthIn,
        heightIn,
      } = item;
      const weightString = weight ? `${formatCommas(weight)} lbs` : "?";
      const dimensionString = [
        formatDimension(length, lengthIn),
        formatDimension(width, widthIn),
        formatDimension(height, heightIn),
      ].join(" x ");

      return `
        <tr>
          <td class="type-cell">${type || "N/A"}</td>
          <td class="description-cell">${description}</td>
          <td class="dimensions-cell">${dimensionString}</td>
          <td class="weight-cell">${weightString}</td>
        </tr>
      `;
    })
    .join("");
  
  console.log("itemRowsHtml", itemRowsHtml);

  // Calculate total (gross) weight
  const totalWeight = items.reduce((acc, it) => acc + (it.weight ?? 0), 0);
  const formattedGrossWeight = `${formatCommas(totalWeight)} lbs`;

  // Notes table if provided
  const notesTableHtml = customDescription
    ? `
      <table class="notes-table">
        <thead>
          <tr><th>Notes</th></tr>
        </thead>
        <tbody>
          <tr><td>${customDescription}</td></tr>
        </tbody>
      </table>
    `
    : "";

  return `
    <html>
      <head>
        <style>
          /* Calibri Font */
          @import url('https://fonts.googleapis.com/css2?family=Calibri:wght@400;500;600&display=swap');

          .pdf-preview {
            font-family: 'Calibri', sans-serif;
            margin: 0;
            padding: 0;
            font-size: 12px;
          }

          .pdf-preview * {
            font-size: 12px;
            box-sizing: border-box;
          }
          .rfq-body {
            font-size: 12px;
            padding: 20px;
          }
          
          .header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 16px;
          }
          .divider {
            width: 100%;
            height: 2px;
            background-color: #ED8293;
            margin: 8px 0;
          }
          .left-header {
            width: 50%;
          }
          .right-header {
            text-align: right;
            width: 50%;
          }
          .logo img {
            max-width: 280px;
            height: auto;
          }

          /* Heading (22px) */
          .rfq-title {
            font-size: 22px;
            font-weight: 600;
          }

          .info-titles {
            font-weight: 600;
            margin-bottom: 4px;
            text-transform: uppercase;
          }

          /* Items table */
          .items-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 16px;
            table-layout: fixed;
          }
          .items-table th,
          .items-table td {
            border: 2px solid black;
            padding: 8px;
            word-wrap: break-word;
            box-sizing: border-box;
            text-align: left !important;
          }
          .items-table th {
            background-color: #f0f0f0;
            font-weight: 600;
            text-transform: uppercase;
          }
          .type-cell { width: 20%; }
          .description-cell { width: 20%; }
          .dimensions-cell { width: 40%; }
          .weight-cell { width: 20%; }

          .gross-weight-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 16px;
            table-layout: fixed;
          }
          .gross-weight-table th,
          .gross-weight-table td {
            border: 2px solid black;
            padding: 8px;
            text-align: left;
          }
          .gross-weight-table th {
            background-color: #f0f0f0;
            font-weight: 600;
            text-transform: uppercase;
          }

          .notes-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 16px;
            table-layout: fixed;
          }
          .notes-table th,
          .notes-table td {
            border: 2px solid black;
            padding: 8px;
            word-wrap: break-word;
            text-align: left;
          }
          .notes-table th {
            font-weight: 600;
            text-transform: uppercase;
          }

          .signature-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 16px;
            table-layout: fixed;
          }
          .signature-table th,
          .signature-table td {
            border: 2px solid black;
            padding: 8px;
            word-wrap: break-word;
            vertical-align: top;
          }
          .signature-table th {
            background-color: #f0f0f0;
            font-weight: 600;
            text-transform: uppercase;
            text-align: left;
          }
          .signature-table label {
            display: inline-block;
            margin-bottom: 4px;
            font-weight: 600;
          }
          .signature-img {
            max-width: 100%;
            height: 30px;
            text-align: center; 
          }
        </style>
      </head>
      <body>
        <div class="pdf-preview">
          <div class="rfq-body">

            <!-- Header: Logo, FREIGHT BILL OF LADING, BOL #, Project -->
            <div class="header">
              <div class="left-header">
                <div class="logo">
                  <img 
                    src="https://images-for-aria.s3.us-east-2.amazonaws.com/bm_logo_lettered.png"
                    alt="BMarko Logo"
                  />
                </div>

              </div>
              <div class="right-header">
                <div class="rfq-title">Freight</div>
                <div class="rfq-title" style="margin-bottom:8px;">Bill of Lading</div>
                <table style="margin-left:auto;">
                  <tr>
                    <td style="text-align:right; font-weight:bold;">BOL #:</td>
                    <td style="padding-left:8px;">${bolNumber || today}</td>
                  </tr>
                  <tr>
                    <td style="text-align:right; font-weight:bold;">Project:</td>
                    <td style="padding-left:8px;text-align:left">${projectName}</td>
                  </tr>

                </table>
              </div>
            </div>

            <div class="divider"></div>

            <!-- Row #1: SHIPPER, FREIGHT AGENT, RECEIVER -->
            <table style="width:100%; border-collapse:collapse;">
              <tr>
                <!-- SHIPPER -->
                <td style="width:33%; vertical-align:top; text-align:center;">
                  <div class="info-titles">SHIPPER</div>
                  <div>
                    ${shipperName}<br/>
                    ${shipperContact}<br/>
                    ${shipperPhone}
                  </div>
                </td>
                <!-- FREIGHT AGENT -->
                <td style="width:33%; vertical-align:top; text-align:center;">
                  <div class="info-titles">FREIGHT AGENT</div>
                  <div>
                    ${freightAgentCompany}<br/>
                    ${freightAgentName}<br/>
                    ${freightAgentPhone}
                  </div>
                </td>
                <!-- RECEIVER -->
                <td style="width:34%; vertical-align:top; text-align:center;">
                  <div class="info-titles">RECEIVER</div>
                  <div>
                    ${receiverCompany}<br/>
                    ${receiverName ? `${receiverName}<br/>` : ""}
                    ${receiverPhone ? receiverPhone : ""}
                  </div>
                </td>
              </tr>
            </table>
            <div class="divider"></div>

            <!-- Row #2: Pickup Address, Dropoff Address -->
            <table style="width:100%; border-collapse:collapse;">
              <tr>
                <td style="width:50%; vertical-align:top; text-align:center;">
                  <div class="info-titles">Pickup Address</div>
                  <div>${origin || "N/A"}</div>
                </td>
                <td style="width:50%; vertical-align:top; text-align:center;">
                  <div class="info-titles">Dropoff Address</div>
                  <div>${destination || "N/A"}</div>
                </td>
              </tr>
            </table>

            <!-- Items Table -->
            <table class="items-table">
              <thead>
                <tr>
                  <th class="type-cell">Type</th>
                  <th class="description-cell">Description</th>
                  <th class="dimensions-cell">Dimensions (LWH)</th>
                  <th class="weight-cell">Weight</th>
                </tr>
              </thead>
              <tbody>
                ${
                  itemRowsHtml ||
                  `<tr><td colspan="4" style="text-align:center;">No items</td></tr>`
                }
              </tbody>
            </table>

            <!-- Gross Weight + Optional Notes -->
            <div style="width: 100%; display: flex; justify-content: space-between; align-items: flex-start;">
              <div style="flex: .75; margin-right: 16px;">
                ${notesTableHtml}
              </div>
              <div style="width: 20%;">
                <table class="gross-weight-table">
                  <thead>
                    <tr>
                      <th>Gross Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>${formattedGrossWeight}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Carrier Signature Section -->
            <table class="signature-table" style="margin-top:16px;">
              <tbody>
                <tr>
                  <td>
                    <label>Carrier Name &amp; Company</label>
                    <div style="min-height:30px;">${
                      carrierName ? carrierName + " / " : ""
                    }${carrierCompany}</div>
                  </td>
                  <td>
                    <label>Carrier Phone</label>
                    <div style="min-height:30px;">${carrierPhone}</div>
                  </td>
                </tr>
                <tr>
 
                  <td colspan="2">
                    <label style="width:100%">Carrier Signature</label>
                    ${
                      carrierSignature
                        ? `<div style="text-align:center"><img src="${carrierSignature}" alt="Shipper Signature" class="signature-img" /></div>`
                        : `<div style="min-height:30px;"></div>`
                    }
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- Shipper Signature Section -->
            <table class="signature-table" style="margin-top:16px;">
              <tbody>
                <tr>
                  <td>
                    <label style="width:100%">Shipper Signature (BMarko)</label>
                    ${
                      userSignature
                        ? `<div style="text-align:center"><img src="${userSignature}" alt="Shipper Signature" class="signature-img" /></div>`
                        : `<div style="min-height:30px;"></div>`
                    }
                  </td>
                  <td>
                    <label>Pickup Date</label>
                    <div>${pickupDate}</div>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- Receiver Signature Section -->
            <table class="signature-table" style="margin-top:16px;">
              <tbody>
                <tr>
                  <td>
                    <label>Receiver Name</label>
                        <div style="min-height:30px;"></div>
                  </td>
                  <td>
                    <label>Date</label>
                          <div style="min-height:30px;"></div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <label>Receiver Signature</label>
                    <div style="min-height:30px;"></div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </body>
    </html>
  `;
}
