// src/components/FreightAgents/AddFreightAgentModal.jsx

import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Button,
  message,
  Select,
  Upload,
  Checkbox,
  Collapse,
  DatePicker,
  Row,
  Col,
  Radio,
} from "antd";
import {
  UploadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid"; // For generating UUIDs
import { addItemsToTable } from "../../Functions/addAria";
import { updateById } from "../../Functions/updateById";
import { COLORS, TABLES } from "../../constants";
import PhoneNumberInput from "../../Formatters/PhoneNumberInput";
import { useFreightAgents } from "../../Contexts/useSpecificData";
import { s3Upload } from "../../Functions/s3Upload"; // Import the s3Upload function
import dayjs from "dayjs";

const { Option } = Select;
const { Panel } = Collapse;
const { Dragger } = Upload;

const AddFreightAgentModal = ({
  children,
  agent,
  onAddSuccess,
  title,
  visible: propVisible,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [typeOptions, setTypeOptions] = useState([]);

  const { data: freightAgents } = useFreightAgents();

  // Determine if the component is controlled externally
  const isControlled = propVisible !== undefined;
  const modalVisible = isControlled ? propVisible : visible;

  useEffect(() => {
    // Fetch unique types from existing freight agents
    if (freightAgents) {
      const uniqueTypes = [
        ...new Set(
          freightAgents.map((agent) => agent.type).filter((type) => type)
        ),
      ];
      setTypeOptions(uniqueTypes);
    }
  }, [freightAgents]);

  useEffect(() => {
    if (modalVisible) {
      if (agent) {
        // Edit Mode
const contactsWithIds = (agent.contacts || []).map((contact) => ({
  id: contact.id || uuidv4(),
  firstName: contact.firstName || "",
  lastName: contact.lastName || "",
  email: contact.email || "",
  phone: contact.phone || "",
  phoneExtension: contact.phoneExtension || "",
}));

const primaryContact = (agent.contacts || []).find((c) => c.primary);


        form.setFieldsValue({
          companyName: agent.companyName || "",
          type: agent.type || "",
          notes: agent.notes || "",
          coiExpiration: agent.coiExpiration
            ? dayjs(agent.coiExpiration)
            : null,
          disabled: agent.disabled || false,
          contacts: contactsWithIds,
          primaryContactId:
            primaryContact?.id ||
            (contactsWithIds.length > 0 ? contactsWithIds[0].id : ""),
        });

        // Set uploaded files if editing
        if (agent.documents && agent.documents.length > 0) {
          const initialFileList = agent.documents.map((url, index) => ({
            uid: index.toString(),
            name: `COI Document ${index + 1}`,
            status: "done",
            url: url,
          }));
          setFileList(initialFileList);
        }

        // Debugging: Log the form values
        console.log("Form Values After Edit:", form.getFieldsValue());
      } else {
        // Add Mode: Initialize with one empty contact
        form.resetFields();
        setFileList([]);
        const newContactId = uuidv4();
        form.setFieldsValue({
          contacts: [
            {
              id: newContactId,
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              phoneExtension: "",
            },
          ],
          primaryContactId: newContactId, // Automatically set the first contact as primary
        });
      }
    } else {
      // Reset form and fileList when modal is closed
      form.resetFields();
      setFileList([]);
    }
  }, [agent, form, modalVisible]);

  // Watch primaryContactId and contacts
  const primaryContactId = Form.useWatch("primaryContactId", form);
  const contacts = Form.useWatch("contacts", form);

  useEffect(() => {
    // Debugging: Log the watched values
    console.log("Primary Contact ID:", primaryContactId);
    console.log("Contacts:", contacts);
  }, [primaryContactId, contacts]);

  useEffect(() => {
    if (!agent && modalVisible) {
      // Only when adding a new freight agent
      if (
        Array.isArray(contacts) &&
        contacts.length === 1 &&
        !primaryContactId
      ) {
        // Automatically set the first contact as primary
        form.setFieldsValue({ primaryContactId: contacts[0].id });
      }
    }
  }, [contacts, primaryContactId, agent, modalVisible, form]);

  const handleOk = async () => {
    try {
      // Validate form fields
      const values = await form.validateFields();

      const {
        companyName,
        type,
        notes,
        coiExpiration,
        disabled,
        contacts,
        primaryContactId,
      } = values;

      if (!Array.isArray(contacts) || contacts.length === 0) {
        message.error("At least one contact is required.");
        return;
      }

      // Ensure one primary contact is selected
      const primaryContactExists = contacts.some(
        (contact) => contact.id === primaryContactId
      );
      if (!primaryContactExists) {
        message.error("Please select a primary contact.");
        return;
      }

      setUploading(true);

      // Handle file uploads
      const uploadedDocuments = [];
      if (fileList.length > 0) {
        for (let file of fileList) {
          if (!file.url && file.originFileObj) {
            const fileName = `${uuidv4()}_${file.name}`;
            try {
              const fileUrl = await s3Upload(file.originFileObj, fileName);
              uploadedDocuments.push(fileUrl);
            } catch (uploadError) {
              console.error("Error uploading file:", uploadError);
              message.error(`Failed to upload ${file.name}`);
              // Abort the submission on upload failure
              setUploading(false);
              return;
            }
          } else if (file.url) {
            // Existing uploaded file
            uploadedDocuments.push(file.url);
          }
        }
      }

      // Prepare contacts array with primary flag
      const processedContacts = contacts.map((contact) => ({
        ...contact,
        primary: contact.id === primaryContactId,
      }));

      const payload = {
        ...agent,
        companyName,
        type: Array.isArray(type) ? type[0] : type, // Ensure type is a single value
        notes,
        coiExpiration: coiExpiration ? dayjs(coiExpiration).toISOString() : "",
        documents: uploadedDocuments,
        disabled,
        contacts: processedContacts,
        updated: new Date().toISOString(),
      };

      if (!agent) {
        // Add new freight agent
        payload.id = uuidv4();
        payload.created = new Date().toISOString();
        await addItemsToTable(TABLES.FREIGHT_AGENTS, payload);
        message.success("Freight Agent added successfully!");
      } else {
        // Update existing freight agent
        await updateById(TABLES.FREIGHT_AGENTS, payload, agent.id);
        message.success("Freight Agent updated successfully!");
      }

      onAddSuccess(payload);
      setUploading(false);
      if (!isControlled) {
        setVisible(false);
      }
    } catch (error) {
      console.error("Submission Failed:", error);
      // Display specific error messages based on validation
      if (error && error.errorFields) {
        // Ant Design validation errors are already displayed by Form.Item
      } else if (error instanceof Error) {
        message.error(error.message);
      } else {
        message.error(
          "Please ensure all required fields are filled correctly."
        );
      }
      setUploading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setFileList([]);
    if (onCancel) {
      onCancel();
    }
    if (!isControlled) {
      setVisible(false);
    }
  };

  const showModal = () => {
    if (!isControlled) {
      setVisible(true);
    }
  };

  /**
   * Handle file upload before upload (prevent default upload behavior)
   */
  const beforeUpload = (file) => {
    // Add file type validations here
    const isValidType =
      file.type === "application/pdf" || file.type.startsWith("image/");
    if (!isValidType) {
      message.error("You can only upload PDF or image files!");
    }
    return isValidType || Upload.LIST_IGNORE;
  };

  // Handle file list change
  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  /**
   * Ensure only one primary contact is selected
   */
  const handlePrimaryContactChange = (changedValues, allValues) => {
    if (changedValues.primaryContactId !== undefined) {
      const newPrimaryId = changedValues.primaryContactId
        ? changedValues.primaryContactId
        : "";

      form.setFieldsValue({
        primaryContactId: newPrimaryId,
      });
    }

    if (changedValues.type) {
      // Ensure only one type is selected
      const newType = Array.isArray(changedValues.type)
        ? changedValues.type.slice(-1)
        : changedValues.type;
      form.setFieldsValue({
        type: newType,
      });
    }

    // Handle setting primaryContactId when adding the first contact
    if (changedValues.contacts) {
      const contacts = allValues.contacts || [];
      const currentPrimaryId = allValues.primaryContactId;

      if (contacts.length === 1 && !currentPrimaryId) {
        // Automatically set the first contact as primary
        form.setFieldsValue({ primaryContactId: contacts[0].id });
      }
    }
  };

  return (
    <>
      {children &&
        React.Children.map(children, (child) =>
          React.cloneElement(child, { onClick: showModal })
        )}
      <Modal
        title={title || (agent ? "Edit Freight Agent" : "Add Freight Agent")}
        visible={modalVisible}
        onOk={handleOk}
        width={800}
        onCancel={handleCancel}
        confirmLoading={uploading}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={uploading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={uploading}
            onClick={handleOk}
          >
            {agent ? "Save Changes" : "Add Freight Agent"}
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onValuesChange={handlePrimaryContactChange}
        >
          {/* Hidden Form Item for Primary Contact ID */}
          <Form.Item name="primaryContactId" hidden>
            <Input />
          </Form.Item>

          {/* Company Name and Blacklisted Checkbox on Same Row */}
          <Row gutter={16}>
            <Col span={agent ? 18 : 24}>
              <Form.Item
                name="companyName"
                label="Company Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the company name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            {agent && (
              <Col span={6}>
                <Form.Item
                  name="disabled"
                  label="Blacklisted"
                  valuePropName="checked"
                >
                  <Checkbox></Checkbox>
                </Form.Item>
              </Col>
            )}
          </Row>

          {/* Type */}
          <Form.Item
            name="type"
            label={
              <>
                Type{" "}
                <span
                  style={{ fontWeight: "normal", opacity: 0.7, marginLeft: 8 }}
                >
                  (type in a new type and press enter if new type is needed)
                </span>
              </>
            }
            rules={[
              {
                required: true,
                message: "Please select or create a type",
              },
            ]}
          >
            <Select
              mode="tags"
              placeholder="Select or create a type"
              dropdownRender={(menu) => <>{menu}</>}
              // Custom logic to ensure only one type is selected
              onChange={(value) => {
                if (value.length > 1) {
                  // Keep only the last selected type
                  form.setFieldsValue({
                    type: [value[value.length - 1]],
                  });
                }
              }}
            >
              {typeOptions.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* Notes */}
          <Form.Item name="notes" label="Notes">
            <Input.TextArea autoSize placeholder="Enter notes" />
          </Form.Item>

          {/* COI Expiration and COI Documents on Same Row */}
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item name="coiExpiration" label="COI Expiration">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col span={18}>
              <Form.Item name="documents" label="COI Documents">
                <Dragger
                  beforeUpload={beforeUpload}
                  onChange={handleFileChange}
                  multiple
                  fileList={fileList}
                  accept=".pdf,image/*"
                  listType="picture"
                  drag
                >
                  <p className="ant-upload-drag-icon">
                    <UploadOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag files to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for multiple files. Only PDF and image files are
                    allowed.
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>

          {/* Contacts */}
          <Collapse defaultActiveKey={["1"]} style={{ marginTop: 24 }}>
            <Panel header="Contacts" key="1">
              <Form.List name="contacts">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(
                      ({ key, name, fieldKey, ...restField }, index) => {
                        // Retrieve the contact's ID from the form values
                        const currentContacts =
                          form.getFieldValue("contacts") || [];
                        const contactId = currentContacts[name]?.id;

                        return (
                          <div
                            key={key}
                            style={{
                              border: "1px solid #f0f0f0",
                              padding: 16,
                              borderRadius: 4,
                              marginBottom: 16,
                              position: "relative",
                            }}
                          >
                            {fields.length > 1 && (
                              <MinusCircleOutlined
                                style={{
                                  position: "absolute",
                                  top: 8,
                                  right: 8,
                                  color: COLORS.WARNING,
                                  fontSize: 18,
                                }}
                                onClick={() => {
                                  remove(name);
                                  // After removing, if the removed contact was primary, set a new primary
                                  if (primaryContactId === contactId) {
                                    const remainingContacts =
                                      form.getFieldValue("contacts") || [];
                                    if (remainingContacts.length > 0) {
                                      form.setFieldsValue({
                                        primaryContactId:
                                          remainingContacts[0].id,
                                      });
                                    } else {
                                      form.setFieldsValue({
                                        primaryContactId: "",
                                      });
                                    }
                                  }
                                }}
                              />
                            )}
                            <Row gutter={16}>
                              {/* First Name */}
                              <Col span={8}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "firstName"]}
                                  fieldKey={[fieldKey, "firstName"]}
                                  label="First Name"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter the first name",
                                    },
                                  ]}
                                >
                                  <Input placeholder="First Name" />
                                </Form.Item>
                              </Col>

                              {/* Last Name */}
                              <Col span={8}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "lastName"]}
                                  fieldKey={[fieldKey, "lastName"]}
                                  label="Last Name"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter the last name",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Last Name" />
                                </Form.Item>
                              </Col>

                              {/* Primary Contact Radio Button */}
                              <Col
                                span={8}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Form.Item
      
                                  // Removed 'name' prop to prevent conflict
                                  style={{ marginBottom: 0 }}
                                >
                                  <Radio
                                    checked={
                                      form.getFieldValue("primaryContactId") ===
                                      contactId
                                    }
                                    onChange={() => {
                                      form.setFieldsValue({
                                        primaryContactId: contactId,
                                      });
                                    }}
                                  >
                                    Primary Contact
                                  </Radio>
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={[16, 0]}>
                              {/* Email */}
                              <Col span={8}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "email"]}
                                  fieldKey={[fieldKey, "email"]}
                                  label="Email"
                                  rules={[
                    
                                {
                                  required: true,
                                      type: "email",
                                      message: "Please enter a valid email",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Email" />
                                </Form.Item>
                              </Col>

                              {/* Phone Number */}
                              <Col span={8}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "phone"]}
                                  fieldKey={[fieldKey, "phone"]}
                                  label="Phone Number"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter the phone number",
                                    },
                                  ]}
                                >
                                  <PhoneNumberInput
                                    defaultCountry="us"
                                    disableDropdown={false}
                                    placeholder="Phone Number"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "phoneExtension"]}
                                  fieldKey={[fieldKey, "phoneExtension"]}
                                  label="Phone Extension"
                                >
                                  <Input placeholder="Phone Extension" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        );
                      }
                    )}

                    {/* Add Contact Button */}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          const newId = uuidv4();
                          add({
                            id: newId,
                            firstName: "",
                            lastName: "",
                            email: "",
                            phone: "",
                            phoneExtension: "",
                          });
                          // After adding a new contact, set it as primary if it's the first contact
                          const currentContacts =
                            form.getFieldValue("contacts") || [];
                          if (currentContacts.length === 1) {
                            form.setFieldsValue({
                              primaryContactId: newId,
                            });
                          }
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Contact
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* Primary Contact Validation */}
              <Form.Item
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.primaryContactId !==
                    currentValues.primaryContactId ||
                  prevValues.contacts !== currentValues.contacts
                }
                noStyle
              >
                {() => {
                  const primaryContactId =
                    form.getFieldValue("primaryContactId");
                  const contacts = form.getFieldValue("contacts") || [];
                  return primaryContactId ? null : (
                    <Form.Item style={{ marginTop: -16 }}>
                      <span style={{ color: "red" }}>
                        Please select a primary contact.
                      </span>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Panel>
          </Collapse>
        </Form>
      </Modal>
    </>
  );
};

export default AddFreightAgentModal;
