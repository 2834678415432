import axios from "axios";

// For example, if your new endpoint is "/aria/updateAllById/{table}",
// build the base URL here. Adjust as needed for your environment.
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL + "/aria/updateAllById";

/**
 * Updates multiple items in the specified table by their IDs.
 * Expects an array of objects, each containing an 'id' field plus other fields to update.
 *
 * @param {string} tableName  The name of the DynamoDB table.
 * @param {Object[]} items    An array of items. Each item must have an 'id' property and any fields to update.
 * @returns {Promise<any>}    Resolves with the response data or throws an Error if the request fails.
 */
export async function updateAllById(tableName, items) {
  console.log("Table name is:", tableName);
  console.log("Items to update:", items);

  // Construct the endpoint URL: /aria/updateAllById/{tableName}
  const url = `${API_BASE_URL}/${tableName}`;

  try {
      const response = await axios.put(url, items);
      console.log("Bulk Update Operation:", response.data);
    return response.data;
  } catch (error) {
    throw new Error(
      `Error batch-updating items in table "${tableName}": ${
        error.response ? error.response.statusText : error.message
      }`
    );
  }
}
