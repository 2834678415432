import React, { useState } from "react";
import {
  Badge,
  Button,
  Tag,
  Tooltip,
  Typography,
  Steps,
  Modal,
  Form,
  InputNumber,
  Select,
  DatePicker,
  message,
  Checkbox,
  Timeline,
} from "antd";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  EditOutlined,
  HistoryOutlined,
  CheckCircleFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBrush,
  faArchway,
  faFlagCheckered,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import { updateById } from "../../Functions/updateById";
import { TABLES, COLORS } from "../../constants";
import PriceTag from "../../Styled/PriceTag";
import { formatDate } from "../../Formatters/helpers";
import { getFirstAndLastNameFromEmail } from "../../Formatters/getNameFromEmail";
import { useUser } from "@clerk/clerk-react";
import { getModuleStatus } from "../../utils/getModuleStatus";
import { useOutboundShipments } from "../../Contexts/useSpecificData";

// <-- Import your custom WeldingIcon and FramingIcon
import { WeldingIcon, FramingIcon } from "../../Styled/CustomIcons";
import StatusTag from "../../Styled/StatusTag";
import { useMediaQueryContext } from "../../Contexts/MediaQueryContext";

const { Text } = Typography;
const { Option } = Select;
const { Step } = Steps;

/** Default production flow (excluding "Issued for Production") */
const DEFAULT_FLOW = [
  "Fabrication",
  "Framing and Roughin",
  "Painting",
  "Outfitting",
  "Completed",
];

/** Icon size constants */
const WELDING_ICON_SIZE = 24;
const FRAMING_ICON_SIZE = 24;

/** Icon mapping - replace Fabrication and Framing and Roughin with custom icons */
const statusIconMapping = {
  Fabrication: WeldingIcon,
  "Framing and Roughin": FramingIcon,
  Painting: faBrush,
  Outfitting: faArchway,
  Completed: faFlagCheckered,
};

/** Retrieve either a FontAwesome icon or a custom React component */
function getIconForStage(stage) {
  return statusIconMapping[stage] || faFlagCheckered;
}

/** Add business days (skip weekends) */
function addBusinessDays(date, days) {
  let result = dayjs(date);
  let added = 0;
  while (added < days) {
    result = result.add(1, "day");
    if (result.day() !== 0 && result.day() !== 6) {
      added++;
    }
  }
  return result;
}

/** Weekend restrictions for DatePickers */
function disableWeekends(current) {
  return current && (current.day() === 0 || current.day() === 6);
}
function disableFutureAndWeekends(current) {
  return (
    current &&
    (current.day() === 0 ||
      current.day() === 6 ||
      current.isAfter(dayjs(), "day"))
  );
}

/**
 * Check if the previous module is at least in Fabrication
 * so that this module can move forward.
 * If this module is #01, no gating.
 */
function canMoveForwardInSequence(project, module) {
  try {
    const myNum = parseInt(module.number, 10);
    if (isNaN(myNum) || myNum <= 1) return true; // "01" has no gating
    const prevStr = String(myNum - 1).padStart(2, "0");
    const prevModule = project.modules.find((m) => m.number === prevStr);
    console.log("prevModule:", prevModule);
    console.log("prevModule.production:", prevModule.production);
    if (!prevModule) return true;
    const prevStatus = prevModule.production?.status || "Issued for Production";
    if(prevStatus === "Shipped") return true;
    return DEFAULT_FLOW.includes(prevStatus);
  } catch (err) {
    console.error("canMoveForwardInSequence error:", err);
    return true;
  }
}

function ProductionCard({ project, module, viewOnly = false }) {
  const { isDesktop } = useMediaQueryContext();
  const [isMoveModalVisible, setIsMoveModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);

  const [historyModule, setHistoryModule] = useState(null);
  const [moveLoading, setMoveLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  // AntD Forms
  const [moveForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const nextStageChoice = Form.useWatch("nextStageChoice", moveForm);

  // Clerk user
  const user = useUser().user;
  const userEmail = user?.emailAddresses[0].emailAddress || "Unknown";

  // Data context
  const { data: outboundShipments } = useOutboundShipments();

  // Compute module status
  const currentStatus = getModuleStatus(module, project, outboundShipments);
  const atIssuedForProduction = currentStatus === "Issued for Production";
  const reachedProduction = DEFAULT_FLOW.includes(currentStatus);
  const isDelayed = project.clientDelay === true;
  const isShipped = currentStatus === "Shipped";

  // Badge/Ribbon logic
  let showRibbon = false;
  let ribbonText = "";
  let ribbonColor = "gray";

  if (isDelayed) {
    showRibbon = true;
    ribbonText = "CLIENT DELAY";
    ribbonColor = "red";
  } else if (isShipped) {
    showRibbon = true;
    ribbonText = (
      <div style={{ display: "flex", alignItems: "center" }}>
        <FontAwesomeIcon icon={faTruck} style={{ marginRight: 4 }} />
        SHIPPED
      </div>
    );
    ribbonColor = COLORS.PRIMARY;
  } else if (!atIssuedForProduction && !reachedProduction) {
    showRibbon = true;
    ribbonText = "IN PRECONSTRUCTION";
    ribbonColor = "gray";
  } else if (currentStatus === "Completed") {
    showRibbon = true;
    ribbonText = "COMPLETED";
    ribbonColor = "#3f8600";
  } else if (atIssuedForProduction && !reachedProduction) {
    showRibbon = true;
    ribbonText = "ISSUED FOR PRODUCTION";
    ribbonColor = "#3f8600";
  }

  /** Possibly re-ordered flow */
  function getOrderedFlow() {
    const prod = module.production || {};
    const baseFlow = prod.orderedStages || DEFAULT_FLOW;
    const skipArr = prod.skippedStages || [];
    return baseFlow.filter((st) => !skipArr.includes(st));
  }
  const finalFlow = getOrderedFlow();

  /** Step status logic: "finish" if actual date, "process" if current, else "wait" */
  function getStepStatus(stage) {
    const prod = module.production || {};
    const actualField = `actual${stage.replace(/\s+/g, "")}Complete`;
    const hasActualDate = !!prod[actualField];

    if (hasActualDate) return "finish";
    if (currentStatus === stage) return "process";
    return "wait";
  }

  /**
   * If the user tries to move forward but doesn't have:
   * 1) If atIssuedForProduction => must have `plannedFabricationComplete`
   * 2) If already in production => must have plannedDays for the next stage (unless it's skipped)
   */
  function checkPlannedDataBeforeForward(nextStage) {
    const prod = module.production || {};

    // (1) If from "Issued for Production" => must have plannedFabricationComplete
    if (atIssuedForProduction && !prod.plannedFabricationComplete) {
      message.warning(
        "You must set planned Fabrication date(s) before moving to Fabrication."
      );
      showEditModal();
      return false; // do not proceed
    }

    // (2) If we're already in production => must have plannedDays for next stage
    // unless next stage is "Completed" or is in skippedStages
    if (reachedProduction && nextStage && nextStage !== "Completed") {
      const skippingNextStage = prod.skippedStages?.includes(nextStage);
      if (!skippingNextStage) {
        // Check if there's an entry in plannedDays for `nextStage`
        const hasPlanned =
          prod.plannedDays && prod.plannedDays[nextStage] != null;
        if (!hasPlanned) {
          message.warning(
            `No planned days found for "${nextStage}". Please set planned days before moving forward.`
          );
          showEditModal();
          return false;
        }
      }
    }

    // All good
    return true;
  }

  // Show Move Modal
  function showMoveModal(direction) {
    if (viewOnly) return;
    if (isDelayed) {
      message.warning("Cannot move while project is in client delay.");
      return;
    }

    // Gating logic: previous module must be in at least "Fabrication"
    if (direction === "forward" && !canMoveForwardInSequence(project, module)) {
      message.warning(
        "Cannot move forward. The previous module hasn't begun Fabrication."
      );
      return;
    }

    if (direction === "forward") {
      // Figure out next stage
      // if not in production yet => next is "Fabrication"
      // else we find the next from the finalFlow
      let nextStage = "Fabrication";
      if (reachedProduction && currentStatus !== "Completed") {
        // We'll guess the next stage from the current
        nextStage = getTargetStatus(currentStatus, "forward", nextStageChoice);
      }

      // Check if the user must fill in planned data
      const ok = checkPlannedDataBeforeForward(nextStage);
      if (!ok) return; // do not open Move modal if missing data
    }

    // If we pass all checks, open the Move modal
    moveForm.resetFields();
    moveForm.setFieldsValue({ direction });

    // Provide defaults if going forward from "Issued"
    if (direction === "forward") {
      const defDays = {};
      DEFAULT_FLOW.forEach((st) => {
        if (st !== "Fabrication" && st !== "Completed") {
          defDays[st] = 3;
        }
      });
      moveForm.setFieldsValue({
        plannedDays: defDays,
        skipFramingRoughin:
          module.production?.skippedStages?.includes("Framing and Roughin") ||
          false,
      });
    }

    setIsMoveModalVisible(true);
  }

  function handleMoveCancel() {
    setIsMoveModalVisible(false);
    moveForm.resetFields();
  }

  // Return next/prev stage from the flow
  function getTargetStatus(current, direction, nextStageChoice) {
    const prod = module.production || {};
    const baseFlow = prod.orderedStages || DEFAULT_FLOW;
    const skipArr = prod.skippedStages || [];
    const flow = baseFlow.filter((st) => !skipArr.includes(st));

    const idx = flow.indexOf(current);
    if (direction === "backward") {
      if (current === "Fabrication") {
        return "Issued for Production";
      }
      return idx > 0 ? flow[idx - 1] : null;
    } else {
      // forward
      if (idx >= 0 && idx < flow.length - 1) {
        return flow[idx + 1];
      }
      return null;
    }
  }

  // Move form submission
  async function handleMove(values) {
    setMoveLoading(true);
    try {
      const {
        direction,
        skipFramingRoughin,
        startDate,
        plannedDays,
        actualCompleteDate,
        nextStageChoice,
      } = values;

      let prod = module.production ? { ...module.production } : {};
      if (!prod.history) prod.history = [];

      if (direction === "forward") {
        // from "Issued for Production" => "Fabrication"
        if (!reachedProduction) {
          if (!startDate) {
            message.error("Planned Fabrication Complete Date is required.");
            setMoveLoading(false);
            return;
          }

          prod.startDate = dayjs().toISOString();
          const plannedFab = dayjs(startDate).format("YYYY-MM-DD");
          prod.plannedFabricationComplete = plannedFab;

          prod.plannedDays = { ...(prod.plannedDays || {}) };
          let prev = dayjs(plannedFab);
          DEFAULT_FLOW.forEach((st) => {
            if (st === "Fabrication" || st === "Completed") return;
            if (st === "Framing and Roughin" && skipFramingRoughin) return;
            if (plannedDays[st] === undefined) {
              throw new Error(`Missing planned days for ${st}`);
            }
            prod.plannedDays[st] = plannedDays[st];
            const dateComputed = addBusinessDays(prev, plannedDays[st]).format(
              "YYYY-MM-DD"
            );
            prod[`planned${st.replace(/\s+/g, "")}Complete`] = dateComputed;
            prev = dayjs(dateComputed);
          });

          prod.skippedStages = skipFramingRoughin
            ? ["Framing and Roughin"]
            : [];
          prod.status = "Fabrication";
          prod.history.push({
            action: "Moved to Fabrication",
            submittedBy: userEmail,
            date: new Date().toISOString(),
          });
        } else {
          // already in production => next stage
          if (!actualCompleteDate) {
            message.error("Actual Complete Date is required.");
            setMoveLoading(false);
            return;
          }
          const actualDate = dayjs(actualCompleteDate).format("YYYY-MM-DD");
          prod[`actual${currentStatus.replace(/\s+/g, "")}Complete`] =
            actualDate;

          let nextStage = getTargetStatus(
            currentStatus,
            "forward",
            nextStageChoice
          );
          // If skipping Framing => reorder
          if (
            currentStatus === "Fabrication" &&
            !prod.skippedStages?.includes("Framing and Roughin") &&
            nextStageChoice === "Painting"
          ) {
            prod.orderedStages = [
              "Fabrication",
              "Painting",
              "Framing and Roughin",
              "Outfitting",
              "Completed",
            ];
            nextStage = "Painting";
          }
          if (!nextStage) {
            message.error("No further stage available.");
            setMoveLoading(false);
            return;
          }

          prod.status = nextStage;
          prod.history.push({
            action: `Moved to ${nextStage}`,
            submittedBy: userEmail,
            date: new Date().toISOString(),
          });
        }
      } else {
        // direction = "backward"
        const backStage = getTargetStatus(currentStatus, "backward");
        if (!backStage) {
          message.error("Cannot move backward from earliest stage.");
          setMoveLoading(false);
          return;
        }
        if (backStage === "Issued for Production") {
          // remove all planned data
          prod.startDate = null;
          prod.plannedDays = {};
          prod.skippedStages = [];
          prod.orderedStages = null;
          prod.status = "Issued for Production";
        } else {
          const fieldName = `actual${backStage.replace(/\s+/g, "")}Complete`;
          delete prod[fieldName];
          prod.status = backStage;
          if (backStage === "Fabrication") {
            prod.orderedStages = null;
          }
        }
        prod.history.push({
          action: `Moved back to ${backStage}`,
          submittedBy: userEmail,
          date: new Date().toISOString(),
        });
        moveForm.resetFields(["nextStageChoice"]);
      }

      // Save
      const updatedModule = { ...module, production: prod };
      const updatedProject = {
        ...project,
        modules: project.modules.map((m) =>
          m.id === module.id ? updatedModule : m
        ),
      };
      await updateById(TABLES.PROJECTS, updatedProject, project.id);

      message.success("Module status updated.");
      setIsMoveModalVisible(false);
    } catch (err) {
      console.error(err);
      message.error("Failed to move module. " + err.message);
    } finally {
      setMoveLoading(false);
    }
  }

  // Show Edit Modal (planned dates)
  function showEditModal() {
    if (viewOnly) return;
    if (isDelayed) {
      message.warning("Cannot edit planned days while project is delayed.");
      return;
    }

    // Reset + initialize
    editForm.resetFields();
    const prod = module.production || {};

    // If already have a plannedFabricationComplete, set that; otherwise default to today
    const initialFabDate = prod.plannedFabricationComplete
      ? dayjs(prod.plannedFabricationComplete)
      : dayjs();

    // For each stage except Fabrication/Completed, default to 3 if missing
    const initDays = { ...(prod.plannedDays || {}) };
    DEFAULT_FLOW.forEach((st) => {
      if (st !== "Fabrication" && st !== "Completed" && initDays[st] == null) {
        initDays[st] = 3;
      }
    });

    editForm.setFieldsValue({
      skipFramingRoughin:
        prod.skippedStages?.includes("Framing and Roughin") || false,
      plannedFabricationComplete: initialFabDate,
      plannedDays: initDays,
    });

    setIsEditModalVisible(true);
  }

  function handleEditCancel() {
    setIsEditModalVisible(false);
    editForm.resetFields();
  }

  // Handle form submit for editing planned days
  async function handleEdit(vals) {
    setEditLoading(true);
    try {
      let prod = module.production ? { ...module.production } : {};
      if (!prod.history) prod.history = [];

      // track changes
      if (!prod.changedDates) {
        prod.changedDates = { oldPlannedDates: [] };
      } else if (!prod.changedDates.oldPlannedDates) {
        prod.changedDates.oldPlannedDates = [];
      }

      const oldPlannedDates = {
        plannedFabricationComplete: prod.plannedFabricationComplete,
        plannedDays: { ...(prod.plannedDays || {}) },
      };
      DEFAULT_FLOW.forEach((st) => {
        if (st === "Completed") return;
        const plannedField =
          st === "Fabrication"
            ? "plannedFabricationComplete"
            : `planned${st.replace(/\s+/g, "")}Complete`;
        oldPlannedDates[plannedField] = prod[plannedField] || null;
      });

      // see if changed
      const oldFabDate = prod.plannedFabricationComplete;
      const newFabDate = vals.plannedFabricationComplete
        ? dayjs(vals.plannedFabricationComplete).format("YYYY-MM-DD")
        : null;
      const oldDays = JSON.stringify(prod.plannedDays || {});
      const newDays = JSON.stringify(vals.plannedDays || {});
      let plannedDatesChanged = false;
      if (oldFabDate !== newFabDate || oldDays !== newDays) {
        plannedDatesChanged = true;
      }

      if (plannedDatesChanged) {
        prod.changedDates.oldPlannedDates.push(oldPlannedDates);
        prod.history.push({
          action: "Planned dates updated",
          submittedBy: userEmail,
          date: new Date().toISOString(),
        });
      }

      // skip framing?
      if (vals.skipFramingRoughin) {
        if (!prod.skippedStages) prod.skippedStages = [];
        if (!prod.skippedStages.includes("Framing and Roughin")) {
          prod.skippedStages.push("Framing and Roughin");
        }
      } else {
        prod.skippedStages = (prod.skippedStages || []).filter(
          (s) => s !== "Framing and Roughin"
        );
      }

      // update plannedDays
      prod.plannedDays = {
        ...(prod.plannedDays || {}),
        ...(vals.plannedDays || {}),
      };
      prod.plannedFabricationComplete = newFabDate;

      // recalc subsequent stages
      if (prod.plannedFabricationComplete) {
        let prev = dayjs(prod.plannedFabricationComplete);
        DEFAULT_FLOW.forEach((st) => {
          if (st === "Fabrication" || st === "Completed") return;
          if (st === "Framing and Roughin" && prod.skippedStages.includes(st))
            return;
          const field = `planned${st.replace(/\s+/g, "")}Complete`;
          const days = prod.plannedDays[st] || 3;
          const pDate = addBusinessDays(prev, days).format("YYYY-MM-DD");
          prod[field] = pDate;
          prev = dayjs(pDate);
        });
      }

      // Save
      const updatedModule = { ...module, production: prod };
      const updatedProject = {
        ...project,
        modules: project.modules.map((m) =>
          m.id === module.id ? updatedModule : m
        ),
      };
      await updateById(TABLES.PROJECTS, updatedProject, project.id);

      message.success("Module details updated.");
      setIsEditModalVisible(false);
    } catch (err) {
      console.error(err);
      message.error("Failed to update module. " + err.message);
    } finally {
      setEditLoading(false);
    }
  }

  // Remove client delay
  const removeClientDelay = async (project) => {
    Modal.confirm({
      title: "Remove Client Delay",
      icon: <ExclamationCircleOutlined />,
      content:
        "Are you sure you want to remove the client delay from this project?",
      onOk: async () => {
        try {
          const updatedModules = project.modules.map((m) => {
            let prod = { ...(m.production || {}) };
            prod.clientDelay = false;
            if (!prod.history) prod.history = [];
            prod.history.push({
              action: "Client Delay Removed",
              submittedBy: userEmail,
              date: new Date().toISOString(),
            });
            return { ...m, production: prod };
          });
          const updatedProject = {
            ...project,
            clientDelay: false,
            modules: updatedModules,
          };
          await updateById(TABLES.PROJECTS, updatedProject, project.id);
          message.success("Client delay removed successfully.");
        } catch (err) {
          console.error(err);
          message.error("Failed to remove client delay.");
        }
      },
    });
  };

  // Render history
  function renderHistoryModal() {
    const hist = module.production?.history || [];
    if (!hist.length) {
      return <Text>No history available.</Text>;
    }
    const sorted = [...hist].sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    return (
      <Timeline>
        {sorted.map((evt, i) => (
          <Timeline.Item
            key={i}
            color={
              evt.action === "Client Delay"
                ? "red"
                : evt.action === "Client Delay Removed"
                ? "green"
                : evt.action.includes("Moved")
                ? "blue"
                : evt.action.includes("Planned dates updated")
                ? "purple"
                : "gray"
            }
          >
            <Text strong>{evt.action}</Text>
            <br />
            <Text type="secondary">
              {getFirstAndLastNameFromEmail(evt.submittedBy)}
            </Text>
            <br />
            <Text type="secondary">
              {dayjs(evt.date).format("MMMM D, YYYY h:mm A")}
            </Text>
          </Timeline.Item>
        ))}
      </Timeline>
    );
  }

  // Main card
  const cardContent = (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        border: "1px solid #f0f0f0",
        padding: 8,
        backgroundColor: isDelayed
          ? "rgba(255, 0, 0, 0.1)"
          : isShipped
          ? "rgba(173, 216, 230, 0.15)"
          : !atIssuedForProduction && !reachedProduction
          ? "rgba(128, 128, 128, 0.1)"
          : currentStatus === "Completed"
          ? "rgba(0, 128, 0, 0.1)"
          : currentStatus === "Fabrication" && !module.production?.startDate
          ? "rgba(128, 128, 128, 0.1)"
          : "#fff",
        width: "100%",
        cursor: isDelayed && !viewOnly ? "pointer" : "default",
      }}
      className="box-shadow"
      onClick={(e) => {
        // Allow removing client delay by clicking on the card
        if (!viewOnly && isDelayed) {
          const tagName = (e.target.tagName || "").toLowerCase();
          if (
            tagName !== "button" &&
            tagName !== "a" &&
            !e.target.classList.contains("ant-btn") &&
            !e.target.closest(".ant-btn")
          ) {
            Modal.confirm({
              title: "Remove Client Delay",
              icon: <ExclamationCircleOutlined />,
              content:
                "Are you sure you want to remove client delay for all modules?",
              onOk: async () => {
                try {
                  const updatedModules = project.modules.map((m) => {
                    let prod = { ...(m.production || {}) };
                    if (!prod.history) prod.history = [];
                    prod.clientDelay = false;
                    prod.history.push({
                      action: "Client Delay Removed",
                      submittedBy: userEmail,
                      date: new Date().toISOString(),
                    });
                    return { ...m, production: prod };
                  });
                  const updatedProject = {
                    ...project,
                    clientDelay: false,
                    modules: updatedModules,
                  };
                  await updateById(TABLES.PROJECTS, updatedProject, project.id);
                  message.success("Client delay removed from all modules.");
                } catch (err) {
                  console.error(err);
                  message.error("Failed to remove client delay.");
                }
              },
            });
          }
        }
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: !isDesktop ? "column" : "row",
        }}
      >
        {/* Left side: module info, status, buttons */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 8,
            flexWrap: "wrap",
          }}
        >
          {/* Module ID + Price + Type */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                fontSize: 16,
                fontWeight: "bold",
                background: COLORS.PRIMARY,
                color: "#fff",
                padding: "3px 6px",
                borderRadius: 4,
              }}
            >
              {project.projectName}-{module.number}
            </div>

            {module?.amount !== undefined && (
              <div style={{ marginLeft: 8 }}>
                <PriceTag medium amount={module.amount} />
              </div>
            )}

            {module?.productType && (
              <Tag style={{ fontSize: 14, marginLeft: 8 }}>
                {module.productType}
              </Tag>
            )}
          </div>

          {!isDesktop && <div style={{ width: "100%" }} />}

          {/* Move Back */}
          {!viewOnly &&
            reachedProduction &&
            !isDelayed &&
            currentStatus !== "Shipped" && (
              <Tooltip
                title={`Move Back to ${
                  getTargetStatus(currentStatus, "backward") || "Previous Stage"
                }`}
              >
                <Button
                  icon={<ArrowLeftOutlined />}
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    showMoveModal("backward");
                  }}
                />
              </Tooltip>
            )}

          {/* Status Tag */}
          <StatusTag status={currentStatus} />

          {/* Move Forward */}
          {!viewOnly &&
            !isDelayed &&
            (reachedProduction || currentStatus === "Issued for Production") && // allow only if production has started or if you want to allow transition from Issued for Production
            currentStatus !== "Completed" &&
            currentStatus !== "Shipped" && (
              <Tooltip
                title={`Move to ${
                  getTargetStatus(currentStatus, "forward") || "Next Stage"
                }`}
              >
                <Button
                  icon={<ArrowRightOutlined />}
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    showMoveModal("forward");
                  }}
                />
              </Tooltip>
            )}

          {/* History */}
          {module.production?.history?.length > 0 && (
            <Tooltip title="View History" hidden={!isDesktop}>
              <Button
                type="link"
                icon={<HistoryOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsHistoryModalVisible(true);
                  setHistoryModule(module);
                }}
              />
            </Tooltip>
          )}

          {/* Edit Planned Dates */}
          {!viewOnly &&
            reachedProduction &&
            !isDelayed &&
            currentStatus !== "Shipped" && (
              <Tooltip title="Edit Planned Dates">
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    showEditModal();
                  }}
                />
              </Tooltip>
            )}
        </div>

        {/* Right side: Steps timeline */}
        {!isDesktop && <div style={{ width: "100%", margin: "10px 0" }} />}
        <div style={{ marginLeft: 20, flex: 1 }}>
          <Steps size="small" direction="horizontal">
            {finalFlow.map((stage) => {
              if (stage === "Completed") return null; // skip "Completed"

              const stepStatus = getStepStatus(stage);
              const production = module.production || {};
              const plannedField =
                stage === "Fabrication"
                  ? "plannedFabricationComplete"
                  : `planned${stage.replace(/\s+/g, "")}Complete`;
              const actualField = `actual${stage.replace(/\s+/g, "")}Complete`;

              const plannedDate =
                stage === "Fabrication"
                  ? production.plannedFabricationComplete
                    ? dayjs(production.plannedFabricationComplete)
                    : null
                  : production[plannedField]
                  ? dayjs(production[plannedField])
                  : null;
              const actualDate = production[actualField]
                ? dayjs(production[actualField])
                : null;

              // Icon logic
              const IconOrFa = getIconForStage(stage);
              let iconEl = null;

              if (stepStatus === "finish") {
                iconEl = (
                  <CheckCircleFilled style={{ color: COLORS.PRIMARY }} />
                );
              } else if (stepStatus === "process") {
                if (IconOrFa === WeldingIcon) {
                  iconEl = (
                    <WeldingIcon
                      width={WELDING_ICON_SIZE}
                      height={WELDING_ICON_SIZE}
                      style={{ color: COLORS.PRIMARY }}
                    />
                  );
                } else if (IconOrFa === FramingIcon) {
                  iconEl = (
                    <FramingIcon
                      width={FRAMING_ICON_SIZE}
                      height={FRAMING_ICON_SIZE}
                      style={{ color: COLORS.PRIMARY }}
                    />
                  );
                } else {
                  // fallback FontAwesome
                  iconEl = (
                    <FontAwesomeIcon
                      icon={IconOrFa}
                      style={{ color: COLORS.PRIMARY }}
                    />
                  );
                }
              } else {
                // 'wait'
                if (IconOrFa === WeldingIcon) {
                  iconEl = (
                    <WeldingIcon
                      width={WELDING_ICON_SIZE}
                      height={WELDING_ICON_SIZE}
                      style={{ color: "gray", opacity: 0.5 }}
                    />
                  );
                } else if (IconOrFa === FramingIcon) {
                  iconEl = (
                    <FramingIcon
                      width={FRAMING_ICON_SIZE}
                      height={FRAMING_ICON_SIZE}
                      style={{ color: "gray", opacity: 0.5 }}
                    />
                  );
                } else {
                  iconEl = (
                    <FontAwesomeIcon
                      icon={IconOrFa}
                      style={{ color: "gray", opacity: 0.5 }}
                    />
                  );
                }
              }

              // Title & description
              const titleStyle =
                stepStatus === "process"
                  ? { fontWeight: "bold" }
                  : stepStatus === "finish"
                  ? {}
                  : { color: "lightgray" };

              const stepTitle = (
                <div style={titleStyle}>
                  <div>{stage}</div>
                </div>
              );

              const stepDescription = (
                <div style={{ fontSize: 12, width: 300 }}>
                  {plannedDate ? formatDate(plannedDate) : "N/A"}
                  {" | "}
                  <span style={{ fontWeight: "bold" }}>
                    {actualDate ? formatDate(actualDate) : "N/A"}
                  </span>
                </div>
              );

              return (
                <Step
                  key={stage}
                  title={stepTitle}
                  icon={iconEl}
                  status={stepStatus}
                  description={stepDescription}
                  style={{ pointerEvents: "none" }}
                />
              );
            })}
          </Steps>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {showRibbon ? (
        <Badge.Ribbon
          text={ribbonText}
          color={ribbonColor}
          style={{ zIndex: 5, fontSize: 12 }}
          size="small"
        >
          {cardContent}
        </Badge.Ribbon>
      ) : (
        cardContent
      )}

      {/* History Modal */}
      <Modal
        visible={isHistoryModalVisible}
        title={`Production History - ${
          project.projectName + "-" + module?.number || ""
        }`}
        onCancel={() => {
          setIsHistoryModalVisible(false);
          setHistoryModule(null);
        }}
        footer={[
          <Button
            key="close"
            onClick={() => {
              setIsHistoryModalVisible(false);
              setHistoryModule(null);
            }}
          >
            Close
          </Button>,
        ]}
        destroyOnClose
      >
        {renderHistoryModal()}
      </Modal>

      {/* Move Modal */}
      {!viewOnly && (
        <Modal
          visible={isMoveModalVisible}
          title={`Move Module - ${project.projectName}-${module?.number || ""}`}
          onCancel={handleMoveCancel}
          footer={null}
          destroyOnClose
        >
          <Form form={moveForm} layout="vertical" onFinish={handleMove}>
            {/* if going backward */}
            {moveForm.getFieldValue("direction") === "backward" &&
              reachedProduction && (
                <div style={{ marginBottom: 16 }}>
                  {currentStatus === "Fabrication" ? (
                    <>
                      All planned data will be removed. Move back to{" "}
                      {getTargetStatus(currentStatus, "backward") ||
                        "Previous Stage"}
                      ?
                    </>
                  ) : (
                    <>
                      Only the actual date for the stage you're returning to
                      will be removed. Move back to{" "}
                      {getTargetStatus(currentStatus, "backward") ||
                        "Previous Stage"}
                      ?
                    </>
                  )}
                </div>
              )}

            {/* if going forward */}
            {moveForm.getFieldValue("direction") === "forward" && (
              <>
                {/* from Issued => Fabrication */}
                {!reachedProduction &&
                  atIssuedForProduction &&
                  !module.production?.startDate && (
                    <>
                      <Form.Item
                        name="skipFramingRoughin"
                        valuePropName="checked"
                      >
                        <Checkbox>Skip Framing and Roughin</Checkbox>
                      </Form.Item>

                      <Form.Item
                        name="startDate"
                        label="Planned Fabrication Complete Date"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please select planned Fabrication complete date",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          disabledDate={disableWeekends}
                          format="YYYY-MM-DD"
                        />
                      </Form.Item>

                      <Form.Item label="Planned Days for Subsequent Stages">
                        {DEFAULT_FLOW.map((st) => {
                          if (st === "Fabrication" || st === "Completed")
                            return null;
                          return (
                            <Form.Item shouldUpdate key={st} noStyle>
                              {({ getFieldValue, setFieldsValue }) => {
                                const skipFrame =
                                  getFieldValue("skipFramingRoughin");
                                let rules = [
                                  {
                                    required: true,
                                    message: `Days for ${st}`,
                                  },
                                  { type: "number", min: 1 },
                                ];
                                let disabled = false;
                                if (st === "Framing and Roughin" && skipFrame) {
                                  rules = [];
                                  disabled = true;
                                  const pd = getFieldValue("plannedDays") || {};
                                  if (pd["Framing and Roughin"] !== null) {
                                    setFieldsValue({
                                      plannedDays: {
                                        ...pd,
                                        "Framing and Roughin": null,
                                      },
                                    });
                                  }
                                }
                                return (
                                  <Form.Item
                                    name={["plannedDays", st]}
                                    label={`Planned ${st} Complete (Business Days)`}
                                    rules={rules}
                                  >
                                    <InputNumber
                                      style={{ width: "100%" }}
                                      min={1}
                                      addonAfter="days"
                                      disabled={disabled}
                                    />
                                  </Form.Item>
                                );
                              }}
                            </Form.Item>
                          );
                        })}
                      </Form.Item>
                    </>
                  )}

                {/* Already in production => pick next stage */}
                {reachedProduction && (
                  <>
                    <Form.Item
                      name="actualCompleteDate"
                      label={`Actual ${currentStatus} Complete Date`}
                      rules={[
                        {
                          required: true,
                          message: `Select actual completion date for ${currentStatus}`,
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        disabledDate={disableFutureAndWeekends}
                        format="YYYY-MM-DD"
                      />
                    </Form.Item>

                    {/* If leaving Fabrication & not skipping Framing => user chooses nextStage */}
                    {currentStatus === "Fabrication" &&
                      !module.production?.skippedStages?.includes(
                        "Framing and Roughin"
                      ) && (
                        <Form.Item
                          name="nextStageChoice"
                          label="Next Stage"
                          initialValue="Framing and Roughin"
                          rules={[
                            { required: true, message: "Pick next stage" },
                          ]}
                        >
                          <Select>
                            <Option value="Framing and Roughin">
                              Framing and Roughin
                            </Option>
                            <Option value="Painting">Painting</Option>
                          </Select>
                        </Form.Item>
                      )}
                  </>
                )}
              </>
            )}

            <Form.Item name="direction" initialValue="forward" hidden>
              <Select>
                <Option value="forward">Forward</Option>
                <Option value="backward">Backward</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <div style={{ textAlign: "right", marginTop: 16 }}>
                <Button
                  onClick={handleMoveCancel}
                  style={{ marginRight: 8 }}
                  disabled={moveLoading}
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={moveLoading}>
                  {(() => {
                    const dir = moveForm.getFieldValue("direction");
                    if (dir === "backward") {
                      const back = getTargetStatus(currentStatus, "backward");
                      return back ? `Move Back to ${back}` : "Move Backward";
                    } else {
                      // forward
                      if (!reachedProduction) {
                        return "Move to Fabrication";
                      }
                      const choice = nextStageChoice || "Framing and Roughin";
                      let next = getTargetStatus(
                        currentStatus,
                        "forward",
                        choice
                      );
                      if (
                        currentStatus === "Fabrication" &&
                        !module.production?.skippedStages?.includes(
                          "Framing and Roughin"
                        ) &&
                        choice === "Painting"
                      ) {
                        next = "Painting";
                      }
                      return next ? `Move to ${next}` : "Move to Next Stage";
                    }
                  })()}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      )}

      {/* Edit Planned Days Modal */}
      {!viewOnly && (
        <Modal
          visible={isEditModalVisible}
          title={`Edit Planned Dates - ${module?.moduleDescription || ""}`}
          onCancel={handleEditCancel}
          footer={null}
          destroyOnClose
        >
          <Form form={editForm} layout="vertical" onFinish={handleEdit}>
            {module && (
              <>
                <Form.Item
                  name="skipFramingRoughin"
                  valuePropName="checked"
                  shouldUpdate
                >
                  <Checkbox
                    disabled={
                      !module.production?.skippedStages?.includes(
                        "Framing and Roughin"
                      ) && module.production?.plannedFabricationComplete
                    }
                  >
                    Skip Framing and Roughin
                  </Checkbox>
                </Form.Item>

                {/* Fabrication Date */}
                <Form.Item
                  name="plannedFabricationComplete"
                  label="Planned Fabrication Complete Date"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please select planned Fabrication complete date",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    disabledDate={disableWeekends}
                    format="YYYY-MM-DD"
                  />
                </Form.Item>

                {/* Planned Days */}
                <Form.Item label="Planned Days for Production Stages">
                  {DEFAULT_FLOW.map((st) => {
                    if (st === "Completed" || st === "Fabrication") return null;
                    return (
                      <Form.Item shouldUpdate key={st} noStyle>
                        {({ getFieldValue, setFieldsValue }) => {
                          const skipFrame = getFieldValue("skipFramingRoughin");
                          let rules = [
                            { required: true, message: `Days for ${st}` },
                            { type: "number", min: 1 },
                          ];
                          let disabled = false;
                          if (st === "Framing and Roughin" && skipFrame) {
                            rules = [];
                            disabled = true;
                            const pd = getFieldValue("plannedDays") || {};
                            if (pd["Framing and Roughin"] != null) {
                              setFieldsValue({
                                plannedDays: {
                                  ...pd,
                                  "Framing and Roughin": null,
                                },
                              });
                            }
                          }
                          return (
                            <Form.Item
                              name={["plannedDays", st]}
                              label={`Planned ${st} Complete (Business Days)`}
                              rules={rules}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                min={1}
                                addonAfter="days"
                                disabled={disabled}
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    );
                  })}
                </Form.Item>
              </>
            )}

            <Form.Item>
              <div style={{ textAlign: "right" }}>
                <Button
                  onClick={handleEditCancel}
                  style={{ marginRight: 8 }}
                  disabled={editLoading}
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={editLoading}>
                  Save Changes
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
}

export default ProductionCard;
