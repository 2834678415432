// NavigationHeader.js
import React, { useState, useMemo, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu, Badge, Alert, Tag, Modal, Drawer, Button } from "antd";
import {
  UserOutlined,
  SettingOutlined,
  CalculatorOutlined,
  HomeOutlined,
  BellOutlined,
  MenuOutlined,
  GoogleOutlined,
} from "@ant-design/icons";
import { UserButton, useUser } from "@clerk/clerk-react";
import IconOnly from "./images/iconOnly.png"; // Adjust the path if necessary
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStore,
  faMoneyBill1,
  faTag,
  faFilter,
  faChartLine,
  faPersonWalkingArrowLoopLeft,
  faBoxesPacking,
  faTicket,
  faClockRotateLeft,
  faFileInvoice,
  faCommentDollar,
  faHammer,
  faListCheck,
  faDiagramProject,
  faPenRuler,
  faCompassDrafting,
  faBuilding,
  faCity,
  faHandshake,
  faHelmetSafety,
  faTruckPlane,
  faTruckFast,
  faPeopleArrows,
  faTruckFront,
  faTruckRampBox,
  faPlaneDeparture,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";
import {
  useTickets,
  useReminders,
  usePurchaseOrders,
  useVendors,
} from "./Contexts/useSpecificData";
import HelpMenu from "./Components/Support/HelpMenu";
import { useMediaQueryContext } from "./Contexts/MediaQueryContext";
import { FaRegHandshake } from "react-icons/fa";
import dayjs from "dayjs";
import Marquee from "react-fast-marquee";
import { COLORS } from "./constants";
import Reminders from "./Pages/reminders/Reminders";
import EmployeeSignature from "./Components/Signature/EmployeeSignature"; // Ensure correct path
import { Header } from "antd/es/layout/layout";
import { text } from "@fortawesome/fontawesome-svg-core";
import HeaderText from "./Styled/HeaderText";

// Import useCurrentUser hook
import useCurrentUser from "./hooks/useCurrentUser"; // Adjust the path based on your project structure

// Import the signature icon from Tabler Icons or any other source
import { IconSignature } from "@tabler/icons-react";

// Import Confetti and Fireworks
import Confetti from "react-confetti";
import { Fireworks } from "@fireworks-js/react";

// Import welcomeGoogle image
import welcomeGoogle from "./images/welcomeGoogle.png"; // Adjust the path if necessary

const { Header: AntHeader } = Layout;

function NavigationHeader({ roles = [] }) {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.replace(/\/$/, "");
  const currentModule = path.split("/")[1] || "";
  const { data: tickets = [] } = useTickets();
  const { user } = useUser();
  const userEmail = user?.primaryEmailAddress?.emailAddress || "";
  const isHome = location.pathname === "/";

  const [errorLogs, setErrorLogs] = useState([]);
  const { isDesktop, isMobile, isTablet } = useMediaQueryContext();
  const { data: reminders = [] } = useReminders();

  // Celebration overlay states
  const [showCelebration, setShowCelebration] = useState(false); // Overall overlay
  const [canToggleCelebration, setCanToggleCelebration] = useState(true);

  // Fireworks fade-out states
  const [fireworksVisible, setFireworksVisible] = useState(true); // If fireworks are in DOM
  const [fireworksFade, setFireworksFade] = useState(false); // If fireworks are fading

  // Fireworks ref if we want to call .stop()
  const fireworksRef = useRef(null);

  // Basic date logic for reminders
  const today = dayjs().format("MM/DD/YYYY");
  const moduleRemindersForToday = reminders.filter(
    (reminder) =>
      reminder.active &&
      reminder.module === currentModule &&
      reminder.date <= today
  );
  const moduleActiveReminders = reminders.filter(
    (reminder) => reminder.active && reminder.module === currentModule
  );
  const remindersCount = moduleActiveReminders.length;

  // Modal for Reminders
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleBellClick = () => setIsModalVisible(true);

  // "Preview-only" logic
  const previewUsers = [
    "cade.slayton@bmarko.com",
    "antony.kountouris@bmarko.com",
  ];
  const canViewPreview = previewUsers.includes(userEmail);

  // Sterling user check
  const isSterlingUser = useMemo(() => {
    return userEmail.toLowerCase().includes("sterling");
  }, [userEmail]);

  // Filter tickets for the current user
  const userTickets = useMemo(() => {
    if (!Array.isArray(tickets)) return [];
    return tickets.filter((ticket) => ticket.userEmail === userEmail);
  }, [tickets, userEmail]);

  // Build dynamic menu items for each module
  const menuItems = useMemo(() => {
    let items = [];

    // =========== ADMIN ===========
    if (currentModule === "admin" && roles.includes("admin")) {
      items.push(
        {
          key: "/admin/users",
          label: "Users",
          icon: <UserOutlined />,
        },
        {
          key: "/admin/tickets",
          label: "Tickets",
          icon: <FontAwesomeIcon style={{ marginRight: 4 }} icon={faTicket} />,
        },
        {
          key: "/admin/manufacturing-locations",
          label: "Manufacturing Locations",
          icon: <FontAwesomeIcon style={{ marginRight: 4 }} icon={faCity} />,
        }
      );

      // =========== ESTIMATING ===========
    } else if (currentModule === "estimating") {
      if (roles.includes("estimator") || roles.includes("admin")) {
        items.push(
          {
            key: "/estimating/estimates",
            label: "Estimates",
            icon: <CalculatorOutlined style={{ marginRight: 4 }} />,
          },
          {
            key: "/estimating/assemblies",
            label: "Assemblies",
            icon: <SettingOutlined style={{ marginRight: 4 }} />,
          },
          {
            key: "/estimating/customers",
            label: "Customers",
            icon: <FaRegHandshake style={{ marginRight: 4, fontSize: 16 }} />,
          },
          {
            key: "/estimating/vendors",
            label: "Vendors",
            icon: <FontAwesomeIcon icon={faStore} style={{ marginRight: 4 }} />,
          },
          {
            key: "/estimating/aria-items",
            label: "Aria Items",
            icon: <FontAwesomeIcon icon={faTag} style={{ marginRight: 4 }} />,
          },
          {
            key: "/estimating/custom-items",
            label: "Custom Items",
            icon: <FontAwesomeIcon icon={faTag} style={{ marginRight: 4 }} />,
          }
        );
      }

      // =========== PRODUCTION ===========
    } else if (currentModule === "production") {
      if (roles.includes("admin") || roles.includes("production")) {
        items.push(
          {
            key: "/production/home",
            label: "Production",
            icon: (
              <FontAwesomeIcon
                icon={faHelmetSafety}
                style={{ marginRight: 4 }}
              />
            ),
          },
          {
            key: "/production/preconstruction",
            label: "Preconstruction",
            icon: (
              <FontAwesomeIcon
                icon={faCompassDrafting}
                style={{ marginRight: 4 }}
              />
            ),
          },
          {
            key: "/production/projects",
            label: "Projects",
            icon: (
              <FontAwesomeIcon icon={faPenRuler} style={{ marginRight: 4 }} />
            ),
          }
        );
      }

      // =========== PROJECT MANAGEMENT ===========
    } else if (currentModule === "operations") {
      if (roles.includes("admin") || roles.includes("operations")) {
        items.push(
          {
            key: "/operations/production",
            label: "Production",
            icon: (
              <FontAwesomeIcon
                icon={faHelmetSafety}
                style={{ marginRight: 4 }}
              />
            ),
          },
          {
            key: "/operations/preconstruction",
            label: "Preconstruction",
            icon: (
              <FontAwesomeIcon
                icon={faCompassDrafting}
                style={{ marginRight: 4 }}
              />
            ),
          },
          {
            key: "/operations/projects",
            label: "Projects",
            icon: (
              <FontAwesomeIcon icon={faPenRuler} style={{ marginRight: 4 }} />
            ),
          }
        );
      }

      // =========== PROJECT MANAGEMENT ===========
    } else if (currentModule === "project-management") {
      if (roles.includes("admin") || roles.includes("projectmgmt")) {
        items.push(
          {
            key: "/project-management/preconstruction",
            label: "Preconstruction",
            icon: (
              <FontAwesomeIcon
                icon={faCompassDrafting}
                style={{ marginRight: 4 }}
              />
            ),
          },
          {
            key: "/project-management/projects",
            label: "Projects",
            icon: (
              <FontAwesomeIcon icon={faPenRuler} style={{ marginRight: 4 }} />
            ),
          },
          {
            key: "/project-management/customers",
            label: "Customers",
            icon: (
              <FontAwesomeIcon icon={faHandshake} style={{ marginRight: 4 }} />
            ),
          }
        );
      }

      // =========== SHIPPING ===========
    } else if (currentModule === "shipping") {
      if (roles.includes("admin") || roles.includes("shipping")) {
        items.push({
          key: "/shipping/home",
          label: "Shipping",
          icon: (
            <FontAwesomeIcon icon={faTruckFast} style={{ marginRight: 4 }} />
          ),
        });
      }

      // =========== LOGISTICS ===========
    } else if (currentModule === "accounts-receivable") {
      if (roles.includes("admin") || roles.includes("shipping")) {
        items.push({
          key: "/accounts-receivable/home",
          label: "Accounts Receivable",
          icon: (
            <FontAwesomeIcon
              icon={faFileInvoiceDollar}
              style={{ marginRight: 4 }}
            />
          ),
        });
      }

      // =========== LOGISTICS ===========
    } else if (currentModule === "logistics") {
      if (roles.includes("admin") || roles.includes("logistics")) {
        items.push(
          {
            key: "/logistics/outbound",
            label: "Outbound",
            icon: (
              <FontAwesomeIcon
                icon={faPlaneDeparture}
                style={{ marginRight: 4 }}
              />
            ),
          },
          {
            key: "/logistics/freight-agents",
            label: "Freight Agents",
            icon: (
              <FontAwesomeIcon
                icon={faTruckRampBox}
                style={{ marginRight: 4 }}
              />
            ),
          },
          {
            key: "/logistics/manufacturing-locations",
            label: "Manufacturing Locations",
            icon: <FontAwesomeIcon icon={faCity} style={{ marginRight: 4 }} />,
          }
        );
      }

      // =========== PURCHASING ===========
    } else if (currentModule === "purchasing") {
      if (roles.includes("purchaser") || roles.includes("admin")) {
        items.push(
          {
            key: "/purchasing/purchase",
            label: "Purchase",
            icon: (
              <FontAwesomeIcon
                icon={faMoneyBill1}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          },
          {
            key: "/purchasing/returns",
            label: "Returns",
            icon: (
              <FontAwesomeIcon
                icon={faPersonWalkingArrowLoopLeft}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          },
          {
            key: "/purchasing/vendors",
            label: "Vendors",
            icon: <FontAwesomeIcon icon={faStore} style={{ marginRight: 4 }} />,
          },
          {
            key: "/purchasing/items",
            label: "Items",
            icon: <FontAwesomeIcon icon={faTag} style={{ marginRight: 4 }} />,
          },
          {
            key: "/purchasing/reporting",
            label: "Reporting",
            icon: (
              <FontAwesomeIcon icon={faFilter} style={{ marginRight: 4 }} />
            ),
          },
          {
            key: "/purchasing/dashboard",
            label: "Dashboard",
            icon: (
              <FontAwesomeIcon icon={faChartLine} style={{ marginRight: 4 }} />
            ),
          },
          {
            key: "/purchasing/invoices",
            label: "Invoices",
            icon: (
              <FontAwesomeIcon
                icon={faFileInvoice}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          },
          {
            key: "/purchasing/transactions",
            label: "Transactions",
            preview: true,
            icon: (
              <FontAwesomeIcon
                icon={faCommentDollar}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          }
        );
      }

      // =========== RECEIVING ===========
    } else if (currentModule === "receiving") {
      if (roles.includes("receiver") || roles.includes("admin")) {
        items.push(
          {
            key: "/receiving/receive/purchase-orders",
            label: "Receive",
            icon: (
              <FontAwesomeIcon
                icon={faBoxesPacking}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          },
          !isSterlingUser && {
            key: "/receiving/unreceive",
            label: "Unreceive",
            icon: (
              <FontAwesomeIcon
                icon={faClockRotateLeft}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          },
          {
            key: "/receiving/dashboard",
            label: "Dashboard",
            icon: (
              <FontAwesomeIcon
                icon={faChartLine}
                style={{ marginRight: 4, fontSize: 16 }}
              />
            ),
          }
        );
      }
    }

    return items.filter((item) => item && (!item.preview || canViewPreview));
  }, [currentModule, roles, canViewPreview, isSterlingUser]);

  // Determine active menu key
  const selectedKey = useMemo(() => {
    let matchedKey = "";
    menuItems.forEach((item) => {
      if (path.startsWith(item.key) && item.key.length > matchedKey.length) {
        matchedKey = item.key;
      }
    });
    return matchedKey;
  }, [path, menuItems]);

  const hasModuleReminders = reminders.some(
    (reminder) => reminder.module === currentModule
  );

  // Drawer for Mobile
  const [drawerVisible, setDrawerVisible] = useState(false);
  const showDrawer = () => setDrawerVisible(true);
  const closeDrawer = () => setDrawerVisible(false);

  // Clicking Google => show overlay (if not shown)
  const handleGoogleClick = () => {
    if (!showCelebration && canToggleCelebration) {
      setShowCelebration(true);
      setCanToggleCelebration(false);
      // Re-enable click after 5s
      setTimeout(() => setCanToggleCelebration(true), 5000);
    }
  };

  // User can close entire overlay by clicking on it
  const handleOverlayClick = () => {
    setShowCelebration(false);
  };

  // -----------------------------
  //  Signature Modal Integration
  // -----------------------------

  // Retrieve user's signature using useCurrentUser
  const { signature } = useCurrentUser(); // Ensure this hook provides 'signature'

  // State to manage signature modal visibility
  const [showSignatureModal, setShowSignatureModal] = useState(false);

  // Handler to open the signature modal
  const handleOpenSignatureModal = () => setShowSignatureModal(true);

  // Handler to close the signature modal
  const handleCloseSignatureModal = () => setShowSignatureModal(false);

  // Callback when signature is saved successfully
  const handleSignatureSaved = () => {
    setShowSignatureModal(false);
    // Optionally, you can display a success message or perform other actions here
  };

  return (
    <>
      <AntHeader
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0 24px",
          backgroundColor: "#001529",
        }}
        className="box-shadow"
      >
        {/* Left Section: Logo & Menu */}
        <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
          <div
            className="logo-wrapper"
            style={{ marginRight: 16, height: "100%" }}
          >
            <img
              src={IconOnly}
              alt="Logo"
              className="logo minimized"
              style={{ width: 40 }}
            />
          </div>

          {/* Desktop Menu */}
          {isDesktop && (
            <Menu
              theme="dark"
              mode="horizontal"
              selectedKeys={[selectedKey]}
              style={{ flex: 1 }}
            >
              {menuItems.map((item) => (
                <Menu.Item
                  key={item.key}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Link to={item.key}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {item.icon}
                      <span style={{ marginLeft: 4 }}>{item.label}</span>
                    </div>
                  </Link>
                </Menu.Item>
              ))}
            </Menu>
          )}

          {/* Mobile/Tablet Menu (Hamburger) */}
          {(isMobile || isTablet) && !isHome && (
            <Button
              type="text"
              icon={<MenuOutlined style={{ color: "white", fontSize: 24 }} />}
              onClick={showDrawer}
            />
          )}
        </div>

        {/* Right Section: Google Icon, Reminders, Signature, etc. */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: !isDesktop ? "32px" : "32px",
          }}
        >
          {/* Signature Icon: Only in Shipping module and if user has a signature */}
          {currentModule === "shipping" && signature && (
            <IconSignature
              style={{ fontSize: 22, color: "white", cursor: "pointer" }}
              onClick={handleOpenSignatureModal}
              title="Edit Signature"
            />
          )}

          
{/* 
          <GoogleOutlined
            style={{ fontSize: 22, color: "white", cursor: "pointer" }}
            onClick={handleGoogleClick}
          /> */}

          {hasModuleReminders && (
            <Badge size="small" count={remindersCount} overflowCount={99}>
              <BellOutlined
                style={{ fontSize: 22, color: "white", cursor: "pointer" }}
                onClick={handleBellClick}
              />
            </Badge>
          )}

          {path !== "/" && (
            <HelpMenu userTickets={userTickets} logs={errorLogs} />
          )}

          {path !== "/" && roles.length > 1 && !isHome && (
            <Link to="/" style={{ display: "flex", alignItems: "center" }}>
              <HomeOutlined style={{ fontSize: 22, color: "white" }} />
            </Link>
          )}

          <UserButton
            appearance={{
              elements: {
                userButtonAvatarBox: { width: "32px", height: "32px" },
              },
            }}
          />
        </div>
      </AntHeader>

      {/* Signature Modal */}
      <Modal
        visible={showSignatureModal}
        closable={true} // Allows the user to close the modal
        maskClosable={false} // Prevents closing the modal by clicking the overlay
        footer={null} // Removes the default footer (buttons)
        centered // Centers the modal vertically
        width={600} // Adjust the width as needed
        onCancel={handleCloseSignatureModal}
        title="Your Signature"
      >
        <EmployeeSignature onSave={handleSignatureSaved} />
      </Modal>

      {/* Mobile Drawer */}
      <Drawer
        title="Menu"
        placement="left"
        onClose={closeDrawer}
        visible={drawerVisible}
        bodyStyle={{ padding: 0 }}
      >
        <Menu
          mode="inline"
          selectedKeys={[selectedKey]}
          style={{ height: "100%" }}
          onClick={closeDrawer}
        >
          {menuItems.map((item) => (
            <Menu.Item
              key={item.key}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Link to={item.key}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {item.icon}
                  <span style={{ marginLeft: 4 }}>{item.label}</span>
                </div>
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </Drawer>

      {/* Reminders Modal */}
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width="90%"
        destroyOnClose
      >
        <Reminders />
      </Modal>

      {/* Alerts for today's reminders */}
      {moduleRemindersForToday.length > 0 && (
        <Alert
          onClick={handleBellClick}
          style={{ cursor: "pointer" }}
          banner
          type="info"
          icon={<BellOutlined style={{ fontSize: 22 }} />}
          message={
            <Marquee pauseOnHover gradient={false} speed={60}>
              {moduleRemindersForToday.map((reminder, index) => (
                <div
                  key={reminder.id}
                  style={{
                    marginRight: 24,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tag>
                    <strong style={{ marginRight: 8, color: COLORS.PRIMARY }}>
                      REMINDER {index + 1}:
                    </strong>
                    {reminder.body && reminder.body.length > 0 ? (
                      reminder.body.map((item, idx) => (
                        <span key={idx} style={{ marginRight: 8 }}>
                          {item.key}: <strong>{item.value}</strong>
                        </span>
                      ))
                    ) : (
                      <span>No Details Available</span>
                    )}
                    {reminder.notes && (
                      <span style={{ marginLeft: 8 }}>
                        <strong>Notes:</strong>
                        <span
                          style={{
                            color: COLORS.PRIMARY,
                            fontStyle: "italic",
                            marginLeft: 4,
                          }}
                        >
                          {reminder.notes}
                        </span>
                      </span>
                    )}
                  </Tag>
                </div>
              ))}
            </Marquee>
          }
        />
      )}

      {/* Celebration Overlay: only if showCelebration === true */}
      {showCelebration && (
        <div
          onClick={handleOverlayClick}
          style={{
            position: "fixed",
            inset: 0,
            zIndex: 9999,
            background: "rgba(255, 255, 255, 0.7)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          {/* Fireworks Container with Fade Effect */}
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              transition: "opacity 1s ease",
              opacity: fireworksFade ? 0 : 1,
            }}
          >
            {fireworksVisible && (
              <Fireworks
                ref={fireworksRef}
                style={{ width: "100%", height: "100%" }}
                options={
                  {
                    // Add any Fireworks options you want here
                  }
                }
              />
            )}
          </div>

          {/* Confetti Animation */}
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            numberOfPieces={400}
            recycle={false}
            gravity={0.3}
          />

          {/* Centered Welcome Image */}
          <img
            src={welcomeGoogle}
            alt="Welcome Google"
            style={{
              maxWidth: "50%",
              maxHeight: "50%",
              objectFit: "contain",
              zIndex: 1,
            }}
          />
        </div>
      )}
    </>
  );
}

export default NavigationHeader;
