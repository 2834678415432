import React, { useState } from "react";
import { Card, Row, Col, Tag, Space, Typography, Button, Modal } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import {
  IconWeight,
  IconRulerMeasure,
  IconRulerMeasure2,
} from "@tabler/icons-react";
import { formatCommas } from "../../Formatters/helpers";
import DimensionsCell from "../../Styled/DimensionCell";
import { ContainerIcon, PalletIcon } from "../../Styled/CustomIcons";

const { Text } = Typography;

const ShippingItemCard = ({ item, project, isModule, onRemove }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    console.log("View Items button clicked");
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Card
      size="small"
      bordered
      style={{
        backgroundColor: "#fff",
        marginBottom: 8,
        position: "relative",
      }}
    >
      {/* "View Items" Button for Pallets */}
      {!isModule && item.assignedItems && item.assignedItems.length > 0 && (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={showModal}
          style={{
            position: "absolute",
            top: 8,
            right: 8,
            padding: 0,
            zIndex: 1,
          }}
        >
          View Items
        </Button>
      )}

      <Row gutter={[16, 16]}>
        {/* Left Side - Icon and Tag */}
        <Col
          span={6}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Space
            direction="vertical"
            size={4}
            style={{ width: "100%", textAlign: "center" }}
          >
            <Tag
              color={isModule ? "blue" : "purple"}
              onClose={onRemove}
              style={{ marginBottom: 4, marginRight: 0 }}
            >
              {isModule
                ? `${project?.projectName || "Project"}-${item.number || "N/A"}`
                : item.palletName || "Pallet"}
            </Tag>
            {isModule ? (
              <ContainerIcon
                height={36}
                width={36}
                style={{ marginBottom: -6, marginTop: -4 }}
              />
            ) : (
              <PalletIcon size={20} />
            )}
          </Space>
        </Col>

        {/* Right Side - Weight and Dimensions */}
        <Col span={18}>
          <Space
            direction="vertical"
            size={4}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              height: "100%",
            }}
          >
            {/* Weight */}
            <Space align="center" size={4}>
              <IconWeight size={14} />
              <Text>{formatCommas(item.weight)} lbs</Text>
            </Space>

            {/* Dimensions */}
            <Space align="center" size={4}>
              <IconRulerMeasure size={14} />
              <IconRulerMeasure2 size={14} />
              <DimensionsCell
                length={item.length}
                lengthIn={item.lengthIn}
                width={item.width}
                widthIn={item.widthIn}
                height={item.height}
                heightIn={item.heightIn}
              />
            </Space>
          </Space>
        </Col>
      </Row>

      {/* Modal for Viewing Items */}
      {!isModule && item.assignedItems && item.assignedItems.length > 0 && (
        <Modal
          title={`Items in ${item.palletName || "Pallet"}`}
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="close" type="primary" onClick={handleOk}>
              Close
            </Button>,
          ]}
        >
          <ul style={{ paddingLeft: 20, fontSize: 14 }}>
            {item.assignedItems.map((assignedItem, idx) => (
              <li key={idx}>
                {assignedItem.quantity}x{" "}
                {assignedItem.itemDescription || "Unknown Item"}
              </li>
            ))}
          </ul>
        </Modal>
      )}
    </Card>
  );
};

export default ShippingItemCard;
