// Projects.jsx
import React, { useState, useEffect } from "react";
import { Table, Row, Col, Button } from "antd";
import { useProjects, useCustomers } from "../../Contexts/useSpecificData";
import HeaderText from "../../Styled/HeaderText";
import { SearchInput } from "../../Styled/SearchInput";
import { PlusOutlined, StopOutlined } from "@ant-design/icons";
import { COLORS } from "../../constants";
import DisplayPhoneNumber from "../../Formatters/DisplayPhoneNumber";
import AddProjectModal from "../../Components/Projects/AddProjectModal";
import { getFirstNameFromEmail } from "../../Formatters/getNameFromEmail";
import { formatDate } from "../../Formatters/helpers";
import { useManufacturingLocations } from "../../Contexts/useSpecificData";
import { getCustomerCompanyName } from "../../utils/getCustomerCompanyName";
import { Link } from "react-router-dom"; // **Import Link**
import { useLocation } from "react-router-dom";

function Projects({viewOnly=false}) { 
  const location = useLocation(); // Get current location
  const { data: projects, refresh: refreshProjects } = useProjects();
  const { data: customers } = useCustomers();

  // Determine base path based on URL structure
  const currentModule = location.pathname.split("/")[1]; // 'production' or 'project-management'
  const basePath = `/${currentModule}/projects`;

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    document.title = "Projects";
  }, []);

  const handleAddSuccess = () => {
    refreshProjects();
  };

  const columns = [
    {
      title: "Date Created",
      key: "created",
      dataIndex: "created",
      sorter: (a, b) =>
        new Date(a.created || 0).getTime() - new Date(b.created || 0).getTime(),
      render: (text) =>
        text ? (
          formatDate(text)
        ) : (
          <StopOutlined style={{ color: COLORS.WARNING }} />
        ),
    },
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      sorter: (a, b) =>
        (a.projectName || "").localeCompare(b.projectName || ""),
      render: (text, record) => (
        <Link to={`${basePath}/${record.id}/purchase-and-change-orders`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Company Name",
      key: "companyName",
      dataIndex: "customerId", // Using customerId to derive companyName
      sorter: (a, b) => {
        const companyA = getCustomerCompanyName(a.customerId, customers) || "";
        const companyB = getCustomerCompanyName(b.customerId, customers) || "";
        return companyA.localeCompare(companyB);
      },
      render: (customerId) =>
        getCustomerCompanyName(customerId, customers) || "N/A",
    },
    {
      title: "Contact Name",
      key: "contactName",
      render: (text, record) =>
        `${record.contactFirstName || ""} ${record.contactLastName || ""}`,
    },
    {
      title: "Contact Email",
      dataIndex: "contactEmail",
      key: "contactEmail",
    },
    {
      title: "Phone Number",
      dataIndex: "contactPhoneNumber",
      key: "contactPhoneNumber",
      render: (text) => <DisplayPhoneNumber phoneNumber={text} />,
    },
    {
      title: "Project Description",
      dataIndex: "projectDescription",
      key: "projectDescription",
    },
    {
      title: "Delivery Address",
      key: "formattedAddress",
      render: (text, record) => (
        <div>{record?.formattedAddress?.split(", USA")[0]}</div>
      ),
    },
    {
      title: "Submitted By",
      dataIndex: "submittedBy",
      key: "submittedBy",
      render: (text, record) => getFirstNameFromEmail(text),
    },
    // Add more columns as needed
  ];

  // First, filter out projects with projectName INTERNAL or SPLIT
  const validProjects = projects.filter((project) => {
    const name = (project.projectName || "").toUpperCase();
    return name !== "INTERNAL" && name !== "SPLIT";
  });

  // Then sort the remaining projects by project name (A-Z)
  const sortedProjects = validProjects.sort((a, b) =>
    (a.projectName || "").localeCompare(b.projectName || "")
  );

  // Finally, filter by the search term as before
  const filteredProjects = sortedProjects.filter((project) => {
    const fullName = `${project.contactFirstName || ""} ${
      project.contactLastName || ""
    }`.toLowerCase();
    const term = searchTerm.toLowerCase();
    return (
      (project.projectName || "").toLowerCase().includes(term) ||
      (project.companyName || "").toLowerCase().includes(term) ||
      fullName.includes(term) ||
      (project.contactEmail || "").toLowerCase().includes(term) ||
      (project.contactPhoneNumber || "").toLowerCase().includes(term) ||
      (project.formattedAddress || "").toLowerCase().includes(term) ||
      (project.projectDescription || "").toLowerCase().includes(term) ||
      (project.submittedBy || "").toLowerCase().includes(term)
    );
  });

  // Prepare the data source for the table
  const dataSource = filteredProjects.map((project) => ({
    key: project.id,
    ...project,
  }));

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <HeaderText text={"Projects"} />
      </Col>
      <Col span={12} style={{ textAlign: "right" }}>
        {!viewOnly && (
          <AddProjectModal onAddSuccess={handleAddSuccess}>
            <Button type="primary" icon={<PlusOutlined />}>
              Add Project
            </Button>
          </AddProjectModal>
        )}
      </Col>
      <Col span={24}>
        <SearchInput
          placeholder="Search Projects"
          onChange={(e) => setSearchTerm(e.target.value)}
          resultsLength={filteredProjects.length}
        />
      </Col>
      <Col span={24}>
        <Table columns={columns} dataSource={dataSource} />
      </Col>
    </Row>
  );
}

export default Projects;
