import React, { useState, useEffect, useMemo } from "react";
import {
  Form,
  Input,
  Modal,
  Button,
  Select,
  Row,
  Col,
  DatePicker,
  Switch,
  message,
  Card,
  Tag,
} from "antd";
import dayjs from "dayjs";
import { addItemsToTable } from "../../Functions/addAria";
import { TABLES, COLORS } from "../../constants";
import { getEstimateNumber } from "../../Functions/getEstimateNumber";
import { useEstimates, useCustomers } from "../../Contexts/useSpecificData";
import { useUser } from "@clerk/clerk-react";
import useUsers from "../../Contexts/useUsers";
import PhoneNumberInput from "../../Formatters/PhoneNumberInput";

const { Option } = Select;

const DuplicateEstimateModal = ({ children, onAddSuccess, title }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [selectedEstimate, setSelectedEstimate] = useState(null);

  const { user } = useUser();
  const userEmail = user?.primaryEmailAddress?.emailAddress;
  const userName = user?.fullName;
  const users = useUsers();

  const { data: customers } = useCustomers();
  const { data: estimates } = useEstimates();

  // Sort estimates by created date
  const sortedEstimates = useMemo(() => {
    if (!estimates) return [];
    return [...estimates].sort(
      (a, b) => new Date(a.created) - new Date(b.created)
    );
  }, [estimates]);

  // Sort customers A-Z by companyName
  const sortedCustomers = useMemo(() => {
    if (!customers) return [];
    return [...customers].sort((a, b) =>
      a.companyName.localeCompare(b.companyName)
    );
  }, [customers]);

  // Determine dev environment
  const isDev = process.env.REACT_APP_STAGE === "_dev";

  // Filter users with role "estimator" (exclude admin if not dev)
  const estimatorUsers = useMemo(() => {
    if (!users.data) return [];
    return users.data.filter(
      (u) =>
        u.publicMetadata.roles.includes("estimator") &&
        (isDev || !u.publicMetadata.roles.includes("admin"))
    );
  }, [users.data, isDev]);

  /**
   * Validate that the 3-letter prefix is unused.
   */
  const validatePrefixUniqueness = async (_, value) => {
    if (!value) {
      return Promise.resolve(); // required rule handles empties
    }

    const trimmed = value.trim().toUpperCase();

    // If not 3 letters, the pattern rule handles it
    if (!/^[A-Z]{3}$/.test(trimmed)) {
      return Promise.resolve();
    }

    // Check if prefix is already in use
    const exists = customers?.some((c) => c.prefix === trimmed);
    if (exists) {
      return Promise.reject(
        new Error("That prefix is already used by another customer.")
      );
    }

    return Promise.resolve();
  };

  /**
   * When modal becomes visible, reset or fill the form.
   */
  useEffect(() => {
    if (visible) {
      if (selectedEstimate) {
        // Prefill from the selected estimate
        const estimate = selectedEstimate;
        // Find user by assignedTo email
        const assignedUser = users.data.find(
          (u) =>
            u.emailAddresses[0]?.emailAddress === estimate.assignedTo?.email
        );

        form.setFieldsValue({
          estimateName: estimate.estimateName,
          estimateDescription: estimate.estimateDescription,
          estimateStatus: "Needs Quote", // reset
          quoteBy: estimate.quoteBy ? dayjs(estimate.quoteBy) : null,
          customerId: estimate.customerId || null,
          // requestor = current user, so no prefill needed
          // assignedTo set below if we want to copy from original
        });

        setIsNewCustomer(false);
      } else {
        form.resetFields();
        setIsNewCustomer(false);
      }
    }
  }, [selectedEstimate, form, visible, users.data]);

  /**
   * Submit / Duplicate the estimate.
   */
  const handleOk = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      // Generate new estimate number
      const newEstimateNumber = await getEstimateNumber();

      // Prepare duplication data
      const estimateData = {
        estimateNumber: newEstimateNumber,
        estimateName: values.estimateName,
        estimateDescription: values.estimateDescription,
        estimateStatus: "Needs Quote",
        quoteBy: values.quoteBy ? values.quoteBy.toISOString() : null,
        customerId: values.customerId, // Might get overridden if new customer
        assignedTo: null,
        requestor: {
          fullName: userName,
          email: userEmail,
        },
        // Duplicate assemblies/modules/costs from original
        assemblies: selectedEstimate?.assemblies || [],
        modules: selectedEstimate?.modules || [],
        costs: selectedEstimate?.costs || { contingency: 5, profitMargin: 50 },
        // Only quote.lines
        quote: {
          lines: selectedEstimate?.quote?.lines || [],
        },
      };

      let customerId = values.customerId;

      // If new customer, create it first
      if (isNewCustomer) {
        const newCustomerPayload = {
          companyName: values.companyName,
          prefix: values.prefix.toUpperCase(), // store prefix
          contactEmail: values.contactEmail,
          contactFirstName: values.contactFirstName,
          contactLastName: values.contactLastName,
          contactPhoneNumber: values.contactPhoneNumber,
        };
        // Create the new customer
        const newCustomer = await addItemsToTable(
          TABLES.CUSTOMERS,
          newCustomerPayload
        );
        customerId = newCustomer.id;
        estimateData.customerId = customerId;
      }

      // Assign to user
      const assignedToUser = users.data.find((u) => u.id === values.assignedTo);
      if (!assignedToUser) {
        throw new Error("Selected user not found");
      }
      const fullName = `${assignedToUser.firstName} ${assignedToUser.lastName}`;
      const email = assignedToUser.emailAddresses[0]?.emailAddress || "";
      estimateData.assignedTo = { fullName, email };

      // Finally, add the new estimate
      const newEstimate = await addItemsToTable(TABLES.ESTIMATES, estimateData);

      setVisible(false);
      onAddSuccess({ ...estimateData, id: newEstimate.id });
    } catch (error) {
      console.error("Validation Failed:", error);
      message.error(error.message || "Please fill in all required fields.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
    setIsNewCustomer(false);
    setSelectedEstimate(null);
  };

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { onClick: () => setVisible(true) })
      )}
      <Modal
        title={title || "Duplicate Estimate"}
        visible={visible}
        onOk={handleOk}
        width={700}
        onCancel={handleCancel}
        confirmLoading={loading}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Add Estimate
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          {/* ESTIMATE SELECTION */}
          <Form.Item
            name="selectedEstimate"
            label="Select Existing Estimate"
            rules={[{ required: true, message: "Please select an estimate" }]}
          >
            <Select
              showSearch
              placeholder="Select an estimate to duplicate"
              optionFilterProp="label"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              optionLabelProp="label"
              onChange={(value) => {
                const estimate = sortedEstimates.find((e) => e.id === value);
                setSelectedEstimate(estimate);
              }}
            >
              {sortedEstimates.map((estimate) => (
                <Option
                  key={estimate.id}
                  value={estimate.id}
                  label={`${estimate.estimateNumber} ${estimate.estimateName}`}
                >
                  <Tag color="blue" style={{ marginRight: 8 }}>
                    {estimate.estimateNumber}
                  </Tag>
                  {estimate.estimateName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Row gutter={16}>
            <Col span={9}>
              <Form.Item label="Requestor">
                <Input value={userName} disabled />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="quoteBy"
                label="Quote By"
                rules={[{ required: true, message: "Please enter a date" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                name="assignedTo"
                label="Assign To"
                rules={[{ required: true, message: "Please assign to a user" }]}
              >
                <Select
                  showSearch
                  placeholder={
                    estimatorUsers.length > 0
                      ? "Select an estimator"
                      : "No estimators available"
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  disabled={estimatorUsers.length === 0}
                >
                  {estimatorUsers.map((u) => (
                    <Option key={u.id} value={u.id}>
                      {`${u.firstName} ${u.lastName}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="estimateName"
                label="Estimate Name"
                rules={[{ required: true, message: "Please enter a name" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="estimateDescription"
                label="Description"
                rules={[
                  { required: true, message: "Please enter a description" },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>

          {/* CUSTOMER SELECTION */}
          <Card
            title="Customer"
            extra={
              <>
                <span
                  style={{
                    marginRight: 8,
                    fontStyle: "italic",
                    opacity: !isNewCustomer ? 1 : 0.7,
                    color: !isNewCustomer ? COLORS.PRIMARY : "black",
                  }}
                >
                  Existing Customer
                </span>
                <Switch
                  checked={isNewCustomer}
                  onChange={(checked) => setIsNewCustomer(checked)}
                />
                <span
                  style={{
                    marginLeft: 8,
                    fontStyle: "italic",
                    opacity: isNewCustomer ? 1 : 0.7,
                    color: isNewCustomer ? COLORS.PRIMARY : "black",
                  }}
                >
                  New Customer
                </span>
              </>
            }
            style={{ marginBottom: 16 }}
          >
            {!isNewCustomer ? (
              <Form.Item
                name="customerId"
                label="Company Name"
                rules={[
                  { required: true, message: "Please select a customer" },
                ]}
              >
                <Select
                  placeholder="Select a customer"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {sortedCustomers.map((customer) => (
                    <Option key={customer.id} value={customer.id}>
                      {customer.companyName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <>
                {/* -- New Customer Fields -- */}
                <Form.Item
                  name="companyName"
                  label="Company Name"
                  rules={[
                    { required: true, message: "Please enter company name" },
                  ]}
                >
                  <Input />
                </Form.Item>

                {/* -- NEW: Project Prefix Field -- */}
                <Form.Item
                  name="prefix"
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>Project Prefix</div>
                      <div
                        style={{
                          opacity: 0.8,
                          fontStyle: "italic",
                          marginLeft: 4,
                          fontSize: 13,
                        }}
                      >
                        (e.g., VTV, CAP)
                      </div>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter a prefix",
                    },
                    {
                      pattern: /^[A-Z]{3}$/,
                      message: "Prefix must be exactly three uppercase letters",
                    },
                    {
                      validator: validatePrefixUniqueness,
                    },
                  ]}
                  normalize={(value) => (value ? value.toUpperCase() : value)}
                >
                  <Input maxLength={3} placeholder="Enter prefix" />
                </Form.Item>

                <Form.Item
                  name="contactFirstName"
                  label="Contact First Name"
                  rules={[
                    { required: true, message: "Please enter first name" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="contactLastName"
                  label="Contact Last Name"
                  rules={[
                    { required: true, message: "Please enter last name" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="contactEmail"
                  label="Contact Email"
                  rules={[
                    { required: true, message: "Please enter email" },
                    { type: "email", message: "Enter a valid email" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="contactPhoneNumber"
                  label="Contact Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter phone number",
                    },
                  ]}
                >
                  <PhoneNumberInput />
                </Form.Item>
              </>
            )}
          </Card>
        </Form>
      </Modal>
    </>
  );
};

export default DuplicateEstimateModal;
