import React, { useState, useMemo, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Typography,
  Tag,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Upload,
} from "antd";
import {
  FilePdfOutlined,
  InboxOutlined,
  FileOutlined,
  DollarCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { useProjects } from "../../Contexts/useSpecificData";
import HeaderText from "../../Styled/HeaderText";
import { SearchInput } from "../../Styled/SearchInput";
import PriceTag from "../../Styled/PriceTag";
import {
  COLORS,
  NET_TERMS_OPTIONS,
  TABLES,
  SLACK_CHANNELS,
} from "../../constants";
import { updateById } from "../../Functions/updateById";
import { s3Upload } from "../../Functions/s3Upload";
import useCurrentUser from "../../hooks/useCurrentUser";
import { sendSlackMessage } from "../../Functions/sendSlackMessage";
import { formatMoney } from "../../Formatters/helpers";

const { Text } = Typography;
const { Dragger } = Upload;

const getNetTermsLabel = (netTerms) => {
  const option = NET_TERMS_OPTIONS.find((opt) => opt.value === netTerms);
  return option ? option.label : netTerms;
};

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const slackChannel =
  process.env.REACT_APP_STAGE === "_dev"
    ? SLACK_CHANNELS.ACCOUNTS_RECEIVABLE_DEV
    : SLACK_CHANNELS.ACCOUNTS_RECEIVABLE_PROD;

function AccountsReceivable() {
  const { data: projects, refresh } = useProjects();
  const [searchTerm, setSearchTerm] = useState("");
  const { userEmail } = useCurrentUser();

  // Modal, selection, and form states
  const [billingModalVisible, setBillingModalVisible] = useState(false);
  const [markPaidModalVisible, setMarkPaidModalVisible] = useState(false);
  const [selectedAR, setSelectedAR] = useState(null);
  const [billingForm] = Form.useForm();
  const [paidForm] = Form.useForm();
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    document.title = "Accounts Receivable";
  }, []);

  // Helper to format line items for Slack
  const formatLineItemsForSlack = (lines) => {
    if (!lines || lines.length === 0) {
      return "No line items available.";
    }
    let text = "*Line Items:*\n";
    lines.forEach((line) => {
      text += `• ${line.lineNumber} - ${line.description}\n`
    });
    const overallAmount = lines.reduce(
      (sum, line) => sum + (line.amount || 0),
      0
    );
    const overallTax = lines.reduce((sum, line) => sum + (line.tax || 0), 0);
    const overallTotal = overallAmount + overallTax;
    text += `\n *Amount:* ${formatMoney(overallAmount)}, *Tax:* ${formatMoney(overallTax)}, *Total:* ${formatMoney(overallTotal)}`;
    return text;
  };

  /**
   * Build dataSource with status, actionLabel, and invoiceNumber
   */
  const dataSource = useMemo(() => {
    if (!projects) return [];
    const rows = [];

    projects.forEach((project) => {
      if (project.billing && project.billing.length > 0) {
        project.billing.forEach((ar) => {
          const status = ar.paid ? "PAID" : ar.billed ? "BILLED" : "NOT BILLED";
          let actionLabel = "NONE";
          if (status === "NOT BILLED") {
            actionLabel = "BILL";
          } else if (status === "BILLED") {
            actionLabel = "MARK PAID";
          }

          // Grab invoiceNumber if billed is defined
          const invoiceNumber = ar.billed?.invoiceNumber || "";

          rows.push({
            key: `${project.id}-${ar.arNumber}`,
            projectId: project.id,
            projectNumber: project.projectNumber,
            projectName: project.projectName,
            arNumber: ar.arNumber,
            invoiceNumber, // Store for searching
            dateToBill: ar.dateToBill,
            total: ar.total,
            netTerms: getNetTermsLabel(ar.netTerms),
            lines: ar.lines || [],
            status,
            actionLabel,
            arEntry: ar,
            project,
            taxTotal: ar.taxTotal,
            amountTotal: ar.amountTotal,
          });
        });
      }
    });
    return rows;
  }, [projects]);

  /**
   * Filter by searchTerm:
   *   projectName, arNumber, or invoiceNumber
   */
  const filteredData = useMemo(() => {
    if (!searchTerm) return dataSource;
    const term = searchTerm.toLowerCase();

    return dataSource.filter((row) => {
      return (
        row.projectName.toLowerCase().includes(term) ||
        (row.arNumber && row.arNumber.toLowerCase().includes(term)) ||
        (row.invoiceNumber && row.invoiceNumber.toLowerCase().includes(term))
      );
    });
  }, [dataSource, searchTerm]);

  /**
   * Project Name filter options
   */
  const projectNameFilters = useMemo(() => {
    const uniqueNames = [...new Set(dataSource.map((d) => d.projectName))];
    return uniqueNames.map((name) => ({ text: name, value: name }));
  }, [dataSource]);

  // Table columns
  const columns = [
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      filters: projectNameFilters,
      onFilter: (value, record) => record.projectName === value,
      filterSearch: true,
      sorter: (a, b) => a.projectName.localeCompare(b.projectName),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "AR Number",
      dataIndex: "arNumber",
      key: "arNumber",
      sorter: (a, b) => (a.arNumber || "").localeCompare(b.arNumber || ""),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Net Terms",
      dataIndex: "netTerms",
      key: "netTerms",
    },
    {
      title: "Date to Bill",
      dataIndex: "dateToBill",
      key: "dateToBill",
      sorter: (a, b) =>
        dayjs(a.dateToBill).valueOf() - dayjs(b.dateToBill).valueOf(),
      sortDirections: ["descend", "ascend"],
      render: (date, record) => {
        const formatted = date ? dayjs(date).format("MM/DD/YYYY") : "N/A";
        const isPastDue =
          date &&
          dayjs().isAfter(dayjs(date), "day") &&
          record.status === "NOT BILLED";

        return (
          <>
            <span>{formatted}</span>
            {isPastDue && (
              <Tag color="red" style={{ marginLeft: 8 }}>
                Past Due
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Amount Total",
      dataIndex: "amountTotal",
      key: "amountTotal",
      sorter: (a, b) => a.total - b.total,
      sortDirections: ["descend", "ascend"],
      render: (amountTotal) => <PriceTag amount={amountTotal} />,
    },
    {
      title: "Tax Total",
      dataIndex: "taxTotal",
      key: "taxTotal",
      sorter: (a, b) => a.total - b.total,
      sortDirections: ["descend", "ascend"],
      render: (taxTotal) => <PriceTag amount={taxTotal} />,
    },
    {
      title: "Overall Total",
      dataIndex: "total",
      key: "total",
      sorter: (a, b) => a.total - b.total,
      sortDirections: ["descend", "ascend"],
      render: (total) => <PriceTag amount={total} />,
    },
    {
      title: "Invoice",
      key: "invoice",
      render: (_, record) => {
        const billed = record.arEntry?.billed;
        if (billed && billed.invoiceFile && billed.invoiceNumber) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>{billed.invoiceNumber}</span>
              <FilePdfOutlined
                style={{
                  marginLeft: 8,
                  color: COLORS.PRIMARY,
                  fontSize: 16,
                  cursor: "pointer",
                }}
                onClick={() => window.open(billed.invoiceFile, "_blank")}
              />
            </div>
          );
        } else {
          return <StopOutlined style={{ color: "red" }} />;
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "NOT BILLED", value: "NOT BILLED" },
        { text: "BILLED", value: "BILLED" },
        { text: "PAID", value: "PAID" },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => {
        let color = "default";
        if (status === "NOT BILLED") color = "orange";
        if (status === "BILLED") color = "blue";
        if (status === "PAID") color = "green";
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "Action",
      dataIndex: "actionLabel",
      key: "actionLabel",
      filters: [
        { text: "BILL", value: "BILL" },
        { text: "MARK PAID", value: "MARK PAID" },
        { text: "NONE", value: "NONE" },
      ],
      onFilter: (value, record) => record.actionLabel === value,
      sorter: (a, b) => a.actionLabel.localeCompare(b.actionLabel),
      sortDirections: ["ascend", "descend"],
      render: (actionLabel, record) => {
        if (actionLabel === "BILL") {
          return (
            <Button
              size="small"
              icon={<FileOutlined />}
              type="primary"
              onClick={() => openBillingModal(record)}
            >
              BILL
            </Button>
          );
        }
        if (actionLabel === "MARK PAID") {
          return (
            <Button
              size="small"
              icon={<DollarCircleOutlined />}
              type="primary"
              style={{ background: COLORS.SUCCESS, padding: "12px" }}
              onClick={() => openMarkPaidModal(record)}
            >
              Mark Paid
            </Button>
          );
        }
        return <StopOutlined style={{ color: "red" }} />;
      },
    },
  ];

  const expandedRowRender = (record) => {
    if (record.lines && record.lines.length > 0) {
      const lineColumns = [
        { title: "Line #", dataIndex: "lineNumber", key: "lineNumber" },
        { title: "Description", dataIndex: "description", key: "description" },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          render: (amount) => <PriceTag amount={amount} />,
        },
        {
          title: "Tax",
          dataIndex: "tax",
          key: "tax",
          render: (tax) => <PriceTag amount={tax} />,
        },
        {
          title: "Total",
          key: "total",
          render: (_, rec) => {
            const amt = rec.amount || 0;
            const t = rec.tax || 0;
            return <PriceTag amount={amt + t} />;
          },
        },
        {
          title: "Source",
          dataIndex: "source",
          key: "source",
          render: (source, rec) => (
            <>
              <Text>{source}</Text>
              {rec.sourceFile && (
                <FilePdfOutlined
                  style={{
                    marginLeft: 8,
                    color: COLORS.PRIMARY,
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                  onClick={() => window.open(rec.sourceFile, "_blank")}
                />
              )}
            </>
          ),
        },
      ];

      lineColumns.forEach((col) => {
        col.className = "expanded-row";
      });
      return (
        <Table
          columns={lineColumns}
          dataSource={record.lines}
          pagination={false}
          rowKey="id"
          size="small"
        />
      );
    }
    return <p>No line items available.</p>;
  };

  const openBillingModal = (record) => {
    setSelectedAR(record);
    billingForm.setFieldsValue({
      invoiceNumber: "",
      amountTotal: record.arEntry.amountTotal || record.total || 0,
      taxTotal: record.arEntry.taxTotal || 0,
      dateBilled: dayjs(),
      invoiceFile: [],
    });
    setBillingModalVisible(true);
  };

  const openMarkPaidModal = (record) => {
    setSelectedAR(record);
    paidForm.setFieldsValue({
      amountPaid: record.arEntry.amountTotal || record.total || 0,
      taxPaid: record.arEntry.taxTotal || 0,
      datePaid: dayjs(),
    });
    setMarkPaidModalVisible(true);
  };

  const handleBillingSubmit = async () => {
    try {
      const values = await billingForm.validateFields();
      setModalLoading(true);

      const { invoiceNumber, dateBilled, amountTotal, taxTotal, invoiceFile } =
        values;
      const { project, arEntry } = selectedAR;

      if (!invoiceFile || invoiceFile.length === 0) {
        message.error("Please upload the invoice file.");
        return;
      }

      const rawFile = invoiceFile[0].originFileObj;
      const sanitizedProjectName = project.projectName.replace(/\s+/g, "_");
      const ext = rawFile.name.split(".").pop() || "pdf";
      const isoStamp = new Date().toISOString();
      const finalFileName = `INVOICE_${arEntry.arNumber}_${sanitizedProjectName}_${isoStamp}.${ext}`;

      const fileUrl = await s3Upload(rawFile, finalFileName);
      const computedTotal = parseFloat(amountTotal) + parseFloat(taxTotal);

      const updatedAREntry = {
        ...arEntry,
        billed: {
          invoiceNumber,
          dateBilled: dateBilled.toISOString(),
          amountTotal: parseFloat(amountTotal),
          taxTotal: parseFloat(taxTotal),
          total: computedTotal,
          invoiceFile: fileUrl,
          billedBy: userEmail,
        },
      };

      const updatedProject = { ...project };
      updatedProject.billing = updatedProject.billing.map((entry) =>
        entry.arNumber === arEntry.arNumber ? updatedAREntry : entry
      );

      await updateById(TABLES.PROJECTS, updatedProject, project.id);

      // Format the line items text from the .lines array in arEntry
      const lineItemsText = formatLineItemsForSlack(arEntry.lines);

      // Send Slack message for billing with additional line details using :page_facing_up:
      const billingHeader = `:page_facing_up: ${project.projectName} - ${arEntry.arNumber} has been BILLED :page_facing_up:`;
      const billingBlocks = [
        {
          type: "header",
          text: {
            type: "plain_text",
            text: billingHeader,
            emoji: true,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: lineItemsText,
          },
        },
      ];
      sendSlackMessage({
        channel: slackChannel,
        text: billingHeader,
        blocks: billingBlocks,
        attachments: [],
      });

      message.success(`AR ${arEntry.arNumber} updated as BILLED successfully!`);
      setBillingModalVisible(false);
      setSelectedAR(null);
      refresh();
    } catch (err) {
      console.error("Error updating AR billing:", err);
      message.error("Error updating AR billing.");
    } finally {
      setModalLoading(false);
    }
  };

  const handleMarkPaidSubmit = async () => {
    try {
      const values = await paidForm.validateFields();
      setModalLoading(true);

      const { amountPaid, taxPaid, datePaid } = values;
      const { project, arEntry } = selectedAR;
      const computedTotal = parseFloat(amountPaid) + parseFloat(taxPaid);

      const updatedAREntry = {
        ...arEntry,
        paid: {
          amountPaid: parseFloat(amountPaid),
          taxPaid: parseFloat(taxPaid),
          total: computedTotal,
          datePaid: datePaid.toISOString(),
          paidBy: userEmail,
        },
      };

      const updatedProject = { ...project };
      updatedProject.billing = updatedProject.billing.map((entry) =>
        entry.arNumber === arEntry.arNumber ? updatedAREntry : entry
      );

      await updateById(TABLES.PROJECTS, updatedProject, project.id);

      // Format line items for Slack from the .lines array
      const lineItemsText = formatLineItemsForSlack(arEntry.lines);

      // Send Slack message for paid with additional line details using :heavy_dollar_sign:
      const paidHeader = `:heavy_dollar_sign: ${project.projectName} - ${arEntry.arNumber} has been PAID :heavy_dollar_sign:`;
      const paidBlocks = [
        {
          type: "header",
          text: {
            type: "plain_text",
            text: paidHeader,
            emoji: true,
          },
        },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: lineItemsText,
          },
        },
      ];
      sendSlackMessage({
        channel: slackChannel,
        text: paidHeader,
        blocks: paidBlocks,
        attachments: [],
      });

      message.success(`AR ${arEntry.arNumber} marked as PAID successfully!`);
      setMarkPaidModalVisible(false);
      setSelectedAR(null);
      refresh();
    } catch (err) {
      console.error("Error updating AR as PAID:", err);
      message.error("Error updating AR as PAID.");
    } finally {
      setModalLoading(false);
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <HeaderText large text="Accounts Receivable" />
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          {/* Additional header controls can be added here */}
        </Col>

        <Col span={24}>
          <SearchInput
            placeholder="Search by Project Name, AR #, or Invoice #"
            onChange={(e) => setSearchTerm(e.target.value)}
            resultsLength={filteredData.length}
            value={searchTerm}
          />
        </Col>

        <Col span={24}>
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={{ pageSize: 10 }}
            expandedRowRender={expandedRowRender}
            size="small"
          />
        </Col>
      </Row>

      {/* BILLING MODAL */}
      <Modal
        visible={billingModalVisible}
        title={`Bill ${selectedAR ? selectedAR.arNumber : ""}`}
        onCancel={() => {
          setBillingModalVisible(false);
          setSelectedAR(null);
        }}
        confirmLoading={modalLoading}
        onOk={handleBillingSubmit}
        okText="Submit Billing"
      >
        <Form form={billingForm} layout="vertical">
          <Row gutter={16}>
            <Col span={16}>
              <Form.Item
                label="Invoice Number"
                name="invoiceNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input the invoice number.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Date Billed"
                name="dateBilled"
                rules={[
                  { required: true, message: "Please select the date billed." },
                ]}
              >
                <DatePicker format="MM/DD/YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Amount"
            name="amountTotal"
            rules={[
              { required: true, message: "Please enter the billed amount." },
            ]}
          >
            <InputNumber
              type="number"
              style={{ width: "100%" }}
              min={0}
              addonBefore="$"
            />
          </Form.Item>

          <Form.Item
            label="Tax Total"
            name="taxTotal"
            rules={[
              { required: true, message: "Please enter the tax amount." },
            ]}
          >
            <InputNumber
              type="number"
              style={{ width: "100%" }}
              min={0}
              addonBefore="$"
            />
          </Form.Item>

          <Form.Item
            shouldUpdate={(prev, curr) =>
              prev.amountTotal !== curr.amountTotal ||
              prev.taxTotal !== curr.taxTotal
            }
          >
            {({ getFieldValue }) => {
              const amt = getFieldValue("amountTotal") || 0;
              const tax = getFieldValue("taxTotal") || 0;
              return (
                <Form.Item label="Overall Total">
                  <InputNumber
                    disabled
                    value={amt + tax}
                    style={{ width: "100%" }}
                    addonBefore="$"
                  />
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item
            label="Upload Invoice"
            name="invoiceFile"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              { required: true, message: "Please upload the invoice file." },
            ]}
          >
            <Dragger
              name="file"
              beforeUpload={() => false}
              maxCount={1}
              multiple={false}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">Support for a single file only</p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>

      {/* MARK PAID MODAL */}
      <Modal
        visible={markPaidModalVisible}
        title={`Mark AR ${selectedAR ? selectedAR.arNumber : ""} as Paid`}
        onCancel={() => {
          setMarkPaidModalVisible(false);
          setSelectedAR(null);
        }}
        confirmLoading={modalLoading}
        onOk={handleMarkPaidSubmit}
        okText="Mark as Paid"
      >
        <Form form={paidForm} layout="vertical">
          <Form.Item
            label="Payment Amount"
            name="amountPaid"
            rules={[
              { required: true, message: "Please enter the payment amount." },
            ]}
          >
            <InputNumber
              type="number"
              min={0}
              style={{ width: "100%" }}
              addonBefore="$"
            />
          </Form.Item>

          <Form.Item
            label="Tax Total"
            name="taxPaid"
            rules={[
              { required: true, message: "Please enter the tax amount." },
            ]}
          >
            <InputNumber
              type="number"
              min={0}
              style={{ width: "100%" }}
              addonBefore="$"
            />
          </Form.Item>

          <Form.Item
            shouldUpdate={(prev, curr) =>
              prev.amountPaid !== curr.amountPaid ||
              prev.taxPaid !== curr.taxPaid
            }
          >
            {({ getFieldValue }) => {
              const amt = getFieldValue("amountPaid") || 0;
              const tax = getFieldValue("taxPaid") || 0;
              return (
                <Form.Item label="Overall Total">
                  <InputNumber
                    disabled
                    value={amt + tax}
                    style={{ width: "100%" }}
                    addonBefore="$"
                  />
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item
            label="Date Paid"
            name="datePaid"
            rules={[
              { required: true, message: "Please select the payment date." },
            ]}
          >
            <DatePicker format="MM/DD/YYYY" style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AccountsReceivable;
