import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Tag,
  Button,
  Collapse,
  Modal,
  Checkbox,
  Input,
  message,
} from "antd";
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  StopOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  IconWeight,
  IconRulerMeasure,
  IconRulerMeasure2,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";

import flatbedTruck from "../../images/FlatbedTruck4.png";
import { TABLES, SLACK_CHANNELS } from "../../constants";
import { updateById } from "../../Functions/updateById";
import DimensionsCell from "../../Styled/DimensionCell";
import { sendSlackMessage } from "../../Functions/sendSlackMessage";
import dayjs from "dayjs";
import { SearchInput } from "../../Styled/SearchInput";
import { PalletIcon, ContainerIcon } from "../../Styled/CustomIcons";
import useCurrentUser from "../../hooks/useCurrentUser";

// We'll use Collapse.Panel
const { Panel } = Collapse;

const slackChannel =
  process.env.REACT_APP_STAGE === "_dev"
    ? SLACK_CHANNELS.LOGISTICS_DEV
    : SLACK_CHANNELS.LOGISTICS_PROD;

function OutboundFreight({ shipment, project, allShipments = [] }) {
  const [modules, setModules] = useState([]);
  const [pallets, setPallets] = useState([]);

  // If BOL is signed => show read-only truck items
  const isSigned = !!shipment?.bol?.signed;

  // Items actually on THIS truck
  const [truckModules, setTruckModules] = useState([]);
  const [truckPallets, setTruckPallets] = useState([]);

  // Current user info for "submittedBy"
  const { userEmail, userName } = useCurrentUser();

  // Unverified modules modal
  const [isUnverifiedModalVisible, setIsUnverifiedModalVisible] =
    useState(false);
  const [selectedUnverifiedIds, setSelectedUnverifiedIds] = useState([]);
  const [unverifiedOptions, setUnverifiedOptions] = useState([]);
  const [reminderNotes, setReminderNotes] = useState("");

  // Search
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (!project) return;

    // Prepare modules
    const projectModules = (project.modules || []).map((m) => ({
      ...m,
      onTruck: m.onTruck || false,
      shipmentId: m.shipmentId || null,
      verificationRequested: m.verificationRequested || false,
    }));
    // Prepare pallets
    const shipLoosePallets = project.shipLoose?.pallets || [];
    const projectPallets = shipLoosePallets.map((pallet) => ({
      ...pallet,
      onTruck: pallet.onTruck || false,
      shipmentId: pallet.shipmentId || null,
      assignedItems:
        pallet.assignedItems?.map((item) => {
          const projItem = project.shipLoose?.items?.find(
            (i) => i.id === item.itemId
          );
          return {
            ...item,
            itemDescription: projItem?.itemDescription || "Unknown Item",
          };
        }) || [],
    }));

    setModules(projectModules);
    setPallets(projectPallets);

    // Which items are on THIS shipment
    const initialTruckModules = projectModules.filter(
      (mod) => mod.onTruck && mod.shipmentId === shipment.id
    );
    const initialTruckPallets = projectPallets.filter(
      (pal) => pal.onTruck && pal.shipmentId === shipment.id
    );
    setTruckModules(initialTruckModules);
    setTruckPallets(initialTruckPallets);
  }, [project, shipment.id]);

  // Helper to find the shipment number by ID
  function findShipmentNumberById(id) {
    const found = allShipments.find((s) => s.id === id);
    return found ? found.shipmentNumber || "Unknown" : "Unknown";
  }

  // ==============================
  //  HISTORY HELPER
  // ==============================
  /**
   * pushShipmentHistory(actionDescription)
   *
   * Pulls the existing `shipment.history` array (if any),
   * appends a new entry, then calls updateById to store it.
   */
  async function pushShipmentHistory(actionDesc) {
    try {
      // We merge the existing shipment object for the latest "history"
      const existingHistory = shipment.history || [];
      const newHistory = [...existingHistory];
      newHistory.push({
        action: actionDesc,
        submittedBy: userName, // or userName if you prefer
        date: new Date().toISOString(),
      });

      // Update the "history" field on the shipment
      await updateById(
        TABLES.OUTBOUND_SHIPMENTS,
        { history: newHistory },
        shipment.id
      );
    } catch (error) {
      console.error("Error updating shipment history:", error);
      // Not critical enough to block the user, so maybe just a console.error
    }
  }

  // -----------------------------
  //  ADD / REMOVE MODULE
  // -----------------------------
  async function handleAddModuleToTruck(module) {
    if (truckModules.some((m) => m.id === module.id)) return;

    // 1. Update local states
    const updatedTruckModule = {
      ...module,
      onTruck: true,
      shipmentId: shipment.id,
    };
    setTruckModules([...truckModules, updatedTruckModule]);

    const updatedModules = modules.map((m) =>
      m.id === module.id ? { ...m, onTruck: true, shipmentId: shipment.id } : m
    );
    setModules(updatedModules);

    // 2. Update the project in Firestore
    try {
      await updateById(
        TABLES.PROJECTS,
        { modules: updatedModules },
        project.id
      );
    } catch (error) {
      console.error("Error updating project modules:", error);
      message.error("Failed to update project modules.");
    }

    // 3. Update the shipment in Firestore (truckModules[] + history)
    try {
      const updatedTruckModuleIds = [
        ...(shipment.truckModules || []),
        module.id,
      ];

      // Update truckModules and also push to history
      await updateById(
        TABLES.OUTBOUND_SHIPMENTS,
        { truckModules: updatedTruckModuleIds },
        shipment.id
      );
      // Now, record the action in history
      const modName = `${project?.projectName || "Project"}-${
        module.number || ""
      }`;
      await pushShipmentHistory(`Added module: ${modName}`);
    } catch (error) {
      console.error("Error updating outbound shipment (modules):", error);
      message.error("Failed to update outbound shipment modules.");
    }
  }

  async function handleRemoveModuleFromTruck(moduleId) {
    // 1. Update local state
    setTruckModules(truckModules.filter((m) => m.id !== moduleId));
    const removedModule = modules.find((m) => m.id === moduleId);
    const updatedModules = modules.map((m) =>
      m.id === moduleId ? { ...m, onTruck: false, shipmentId: null } : m
    );
    setModules(updatedModules);

    // 2. Update the project
    try {
      await updateById(
        TABLES.PROJECTS,
        { modules: updatedModules },
        project.id
      );
    } catch (error) {
      console.error("Error updating project modules:", error);
      message.error("Failed to update project modules.");
    }

    // 3. Update the shipment (truckModules + history)
    try {
      const updatedTruckModules = (shipment.truckModules || []).filter(
        (id) => id !== moduleId
      );
      await updateById(
        TABLES.OUTBOUND_SHIPMENTS,
        { truckModules: updatedTruckModules },
        shipment.id
      );

      const modName = `${project?.projectName || "Project"}-${
        removedModule?.number || ""
      }`;
      await pushShipmentHistory(`Removed module: ${modName}`);
    } catch (error) {
      console.error("Error updating outbound shipment (modules):", error);
      message.error("Failed to update outbound shipment modules.");
    }
  }

  // -----------------------------
  //  ADD / REMOVE PALLET
  // -----------------------------
  async function handleAddPalletToTruck(pallet) {
    if (truckPallets.some((p) => p.id === pallet.id)) return;

    // 1. Update local
    const updatedTruckPallet = {
      ...pallet,
      onTruck: true,
      shipmentId: shipment.id,
    };
    setTruckPallets([...truckPallets, updatedTruckPallet]);

    const updatedPallets = pallets.map((p) =>
      p.id === pallet.id ? { ...p, onTruck: true, shipmentId: shipment.id } : p
    );
    setPallets(updatedPallets);

    // 2. Update project
    try {
      await updateById(
        TABLES.PROJECTS,
        {
          shipLoose: {
            ...(project.shipLoose || {}),
            pallets: updatedPallets,
          },
        },
        project.id
      );
    } catch (error) {
      console.error("Error updating project pallets:", error);
      message.error("Failed to update project pallets.");
    }

    // 3. Update shipment (truckPallets + history)
    try {
      const updatedTruckPalletIds = [
        ...(shipment.truckPallets || []),
        pallet.id,
      ];
      await updateById(
        TABLES.OUTBOUND_SHIPMENTS,
        { truckPallets: updatedTruckPalletIds },
        shipment.id
      );

      await pushShipmentHistory(
        `Added pallet: ${pallet.palletName || "Pallet"}`
      );
    } catch (error) {
      console.error("Error updating outbound shipment (pallets):", error);
      message.error("Failed to update outbound shipment pallets.");
    }
  }

  async function handleRemovePalletFromTruck(palletId) {
    // 1. Update local
    setTruckPallets(truckPallets.filter((p) => p.id !== palletId));
    const removedPallet = pallets.find((p) => p.id === palletId);
    const updatedPallets = pallets.map((p) =>
      p.id === palletId ? { ...p, onTruck: false, shipmentId: null } : p
    );
    setPallets(updatedPallets);

    // 2. Update project
    try {
      await updateById(
        TABLES.PROJECTS,
        {
          shipLoose: {
            ...(project.shipLoose || {}),
            pallets: updatedPallets,
          },
        },
        project.id
      );
    } catch (error) {
      console.error("Error updating project pallets:", error);
      message.error("Failed to update project pallets.");
    }

    // 3. Update shipment + history
    try {
      const updatedTruckPalletIds = (shipment.truckPallets || []).filter(
        (id) => id !== palletId
      );
      await updateById(
        TABLES.OUTBOUND_SHIPMENTS,
        { truckPallets: updatedTruckPalletIds },
        shipment.id
      );

      await pushShipmentHistory(`Removed pallet: ${removedPallet?.palletName}`);
    } catch (error) {
      console.error("Error updating outbound shipment (pallets):", error);
      message.error("Failed to update outbound shipment pallets.");
    }
  }

  // Identify missing attributes
  function getMissingAttributes(item) {
    const missing = [];
    if (item.length == null) missing.push("length");
    if (item.width == null) missing.push("width");
    if (item.height == null) missing.push("height");
    if (!item.weight) missing.push("weight");
    return missing;
  }

  // Render Tag for modules/pallets
  function renderModuleTag(mod) {
    return (
      <Tag color="blue">
        {`${project?.projectName || "Project"}-${mod.number || "-"}`}
      </Tag>
    );
  }
  function renderPalletTag(pal) {
    return <Tag color="purple">{pal.palletName}</Tag>;
  }

  // Extra content for each card (Add button / status) if not signed
function renderExtraContent(record) {
  // If the BOL is signed, always show "Shipped" (green Tag) and do nothing else
  if (isSigned) {
    return (
      <Tag
        icon={<CheckCircleOutlined />}
        color="green"
        style={{ cursor: "default" }}
      >
        Shipped
      </Tag>
    );
  }

  // Otherwise, continue with your normal logic below:
  const missingAttrs = getMissingAttributes(record);
  const isModule = !!record.number;

  // 1) If the module lacks dimensionsVerified and hasn't requested verification:
  if (isModule && !record.dimensionsVerified && !record.verificationRequested) {
    return (
      <Tag
        color="red"
        style={{ cursor: "pointer" }}
        icon={<ExclamationCircleOutlined />}
        onClick={() => openUnverifiedModal(record.id)}
      >
        Unverified
      </Tag>
    );
  }

  // 2) If the module is unverified but verificationRequested is true:
  if (isModule && !record.dimensionsVerified && record.verificationRequested) {
    return (
      <Tag
        color="gold"
        icon={<ExclamationCircleOutlined />}
        style={{ cursor: "default" }}
      >
        Verification Requested
      </Tag>
    );
  }

  // 3) If missing dimension/weight => hide "Add"
  if (missingAttrs.length > 0) {
    return null;
  }

  // 4) If the item is already on the truck
  if (record.onTruck && record.shipmentId) {
    if (record.shipmentId === shipment.id) {
      return (
        <Tag
          icon={<CheckCircleOutlined />}
          color="green"
          style={{ cursor: "default" }}
        >
          On Truck
        </Tag>
      );
    } else {
      const otherShipmentNumber = findShipmentNumberById(record.shipmentId);
      return (
        <Tag color="orange">
          In{" "}
          <Link
            to={`/logistics/outbound/${record.shipmentId}/freight`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {otherShipmentNumber}
          </Link>
        </Tag>
      );
    }
  }

  // 5) If not on truck => show “Add” button
  return (
    <Button
      size="small"
      icon={<PlusOutlined />}
      type="primary"
      onClick={() =>
        isModule
          ? handleAddModuleToTruck(record)
          : handleAddPalletToTruck(record)
      }
    >
      Add
    </Button>
  );
}


  // Filter records by search
  function matchesSearch(record) {
    if (!searchTerm) return true;
    const lower = searchTerm.toLowerCase();
    if (record.number) {
      const modName = `${project?.projectName || ""}-${
        record.number
      }`.toLowerCase();
      return modName.includes(lower);
    } else {
      const name = (record.palletName || "").toLowerCase();
      const assignedNames =
        record.assignedItems?.map((x) => x.itemDescription.toLowerCase()) || [];
      return (
        name.includes(lower) ||
        assignedNames.some((desc) => desc.includes(lower))
      );
    }
  }

  // If the BOL is signed, only show items that are on THIS truck.
  // Otherwise, show everything (modules + pallets).
  const combinedRecords = isSigned
    ? [
        ...modules.filter(
          (mod) => mod.onTruck && mod.shipmentId === shipment.id
        ),
        ...pallets.filter(
          (pal) => pal.onTruck && pal.shipmentId === shipment.id
        ),
      ]
    : [...modules, ...pallets];

  // Then apply the search filter
  const filteredRecords = combinedRecords.filter(matchesSearch);

  // Unverified modules flow
  const openUnverifiedModal = (moduleId) => {
    const unverified = modules.filter(
      (mod) =>
        mod.number && !mod.dimensionsVerified && !mod.verificationRequested
    );
    setUnverifiedOptions(
      unverified.map((mod) => ({
        label: `${project.projectName}-${mod.number}`,
        value: mod.id,
      }))
    );
    setSelectedUnverifiedIds([moduleId]);
    setIsUnverifiedModalVisible(true);
  };
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = unverifiedOptions.map((opt) => opt.value);
      setSelectedUnverifiedIds(allIds);
    } else {
      setSelectedUnverifiedIds([]);
    }
  };

  const handleSaveReminders = async () => {
    const modulesToRemind = selectedUnverifiedIds
      .map((id) => modules.find((m) => m.id === id))
      .filter(Boolean);

    if (!modulesToRemind.length) {
      message.info("No modules selected.");
      return;
    }

    const moduleList = modulesToRemind
      .map((mod) => `${project.projectName}-${mod.number}`)
      .join(", ");

    // Mark them as verificationRequested
    const updatedModules = modules.map((mod) =>
      selectedUnverifiedIds.includes(mod.id)
        ? { ...mod, verificationRequested: true }
        : mod
    );
    setModules(updatedModules);

    try {
      // 1. Update project modules to verificationRequested
      await updateById(
        TABLES.PROJECTS,
        { modules: updatedModules },
        project.id
      );

      // 2. Also push a history entry to the shipment for each requested module
      //    or a single combined entry. Up to you. Here’s a single combined entry:
      await pushShipmentHistory(
        `Requested dimension verification for: ${moduleList}`
      );

      // 3. Send Slack
      const slackBlocks = [
        {
          type: "header",
          text: {
            type: "plain_text",
            text: `:bell: Dimensions / Weight Verification Requested - ${project.projectName} :bell:`,
            emoji: true,
          },
        },
        { type: "divider" },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `*Modules to Remind:* ${modulesToRemind
              .map((mod) => `*${project.projectName}-${mod.number}*`)
              .join(", ")}`,
          },
        },
        {
          type: "context",
          elements: [
            {
              type: "mrkdwn",
              text: `Reminder sent by ${userName} on ${dayjs().format(
                "MM/DD/YYYY"
              )}`,
            },
          ],
        },
      ];
      await sendSlackMessage({
        channel: slackChannel,
        text: `Dimensions / Weight Verification Requested - ${project.projectName}`,
        blocks: slackBlocks,
      });

      message.success("Verification request saved & Slack notified.");
    } catch (e) {
      console.error(e);
      message.error("Failed to create reminder or send Slack message.");
    }

    setIsUnverifiedModalVisible(false);
  };

  // Compute total gross weight for items on the truck
  const truckedItems = [...truckModules, ...truckPallets];
  const totalGrossWeight = truckedItems.reduce(
    (sum, item) => sum + (item.weight || 0),
    0
  );

  return (
    <div style={{ width: "100%" }}>
      {/* Search Input */}
      <Row gutter={[16, 16]} style={{ marginBottom: 8 }}>
        <Col span={24}>
          <SearchInput
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search modules or pallets..."
          />
        </Col>
      </Row>

      <Row gutter={[8, 8]} wrap>
        {/* Truck Column */}
        <Col
          sm={24}
          md={6}
          lg={6}
          xl={6}
          xxl={4}
          style={{
            position: "sticky",
            top: 16,
            zIndex: 1000,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",

          }}
        >
          <div
            style={{
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: 16,
              backgroundColor: isSigned ? "#F6FFED" : "#fff",

              height: "100%",
            }}
            className="box-shadow"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: 8,
              }}
            >
              {truckModules.map((mod) => (
                <Tag
                  key={mod.id}
                  color="blue"
                  closable={!isSigned} // disable removal if isSigned
                  closeIcon={
                    <CloseCircleOutlined
                      style={{ color: isSigned ? "#999" : "red" }}
                    />
                  }
                  onClose={() =>
                    !isSigned && handleRemoveModuleFromTruck(mod.id)
                  }
                >
                  {`${project?.projectName || "Project"}-${mod.number || "-"}`}
                </Tag>
              ))}
              {truckPallets.map((pal) => (
                <Tag
                  key={pal.id}
                  color="purple"
                  closable={!isSigned}
                  closeIcon={
                    <CloseCircleOutlined
                      style={{ color: isSigned ? "#999" : "red" }}
                    />
                  }
                  onClose={() =>
                    !isSigned && handleRemovePalletFromTruck(pal.id)
                  }
                >
                  {pal.palletName}
                </Tag>
              ))}
            </div>

            <div style={{ width: "100%", marginTop: 16, textAlign: "center" }}>
              <img
                src={flatbedTruck}
                alt="Flatbed Truck"
                style={{ width: "80%", height: "auto" }}
              />
            </div>

            <div
              style={{
                fontWeight: 600,
                fontSize: 16,
                textAlign: "center",
                marginTop: 12,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconWeight size={24} style={{ marginRight: 8 }} />
              Gross Weight: {totalGrossWeight.toLocaleString()} lbs
            </div>
          </div>
        </Col>

        {/* Cards for displayed modules/pallets */}
        {filteredRecords.map((record, idx) => {
          const isModule = !!record.number;
          const missingAttrs = getMissingAttributes(record);

          return (
            <Col
              key={idx}
              sm={24}
              md={6}
              lg={6}
              xl={6}
              xxl={4}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Card
                size="small"
                style={{
                  width: "100%",
                  ...(missingAttrs.length ||
                  (isModule && !record.dimensionsVerified)
                    ? { backgroundColor: "#ffe6e6" }
                    : {}),
                }}
                title={
                  isModule ? renderModuleTag(record) : renderPalletTag(record)
                }
                extra={renderExtraContent(record)}
              >
                {/* Icon + Weight */}
                <div style={{ textAlign: "center", marginBottom: 8 }}>
                  {isModule ? (
                    <div style={{ marginBottom: 8 }}>
                      <ContainerIcon height={42} width={42} />
                      <div style={{ marginTop: -8 }}>Module</div>
                    </div>
                  ) : (
                    <div style={{ marginBottom: 8 }}>
                      <PalletIcon />
                      <div>Pallet</div>
                    </div>
                  )}
                  <IconWeight size={24} />
                  <div>
                    {record.weight ? (
                      `${record.weight.toLocaleString()} lbs`
                    ) : (
                      <StopOutlined style={{ color: "red" }} />
                    )}
                  </div>
                </div>

                {/* Dimensions */}
                <div style={{ textAlign: "center", marginBottom: 16 }}>
                  <IconRulerMeasure size={24} />
                  <IconRulerMeasure2 size={24} />
                  <DimensionsCell
                    length={record.length}
                    lengthIn={record.lengthIn}
                    width={record.width}
                    widthIn={record.widthIn}
                    height={record.height}
                    heightIn={record.heightIn}
                  />
                </div>

                {/* Pallet's assigned items */}
                {!isModule && record.assignedItems?.length > 0 ? (
                  <Collapse size="small" style={{ marginTop: 4 }}>
                    <Panel header="Items" key="1">
                      <ul style={{ margin: 0, paddingLeft: 20 }}>
                        {record.assignedItems.map((item, i) => (
                          <li key={i}>
                            {item.quantity}x{" "}
                            {item.itemDescription || "Unknown Item"}
                          </li>
                        ))}
                      </ul>
                    </Panel>
                  </Collapse>
                ) : (
                  !isModule && (
                    <div
                      style={{
                        fontStyle: "italic",
                        color: "#888",
                        textAlign: "center",
                      }}
                    >
                      <StopOutlined /> No Items
                    </div>
                  )
                )}
              </Card>
            </Col>
          );
        })}
      </Row>

      {/* Unverified Modules Modal */}
      <Modal
        visible={isUnverifiedModalVisible}
        title="Select Modules for Dimensions & Weight Verification"
        onCancel={() => setIsUnverifiedModalVisible(false)}
        onOk={handleSaveReminders}
        okText="Save and Send Reminder"
        cancelText="Cancel"
      >
        <Checkbox
          onChange={handleSelectAll}
          checked={
            selectedUnverifiedIds.length === unverifiedOptions.length &&
            unverifiedOptions.length > 0
          }
        >
          Select All
        </Checkbox>
        <Checkbox.Group
          style={{ display: "block", marginTop: 16 }}
          options={unverifiedOptions}
          value={selectedUnverifiedIds}
          onChange={(vals) => setSelectedUnverifiedIds(vals)}
        />
        <Input.TextArea
          value={reminderNotes}
          onChange={(e) => setReminderNotes(e.target.value)}
          placeholder="Add any additional notes..."
          style={{ marginTop: 16 }}
        />
      </Modal>
    </div>
  );
}

export default OutboundFreight;
