
import { STATUS_COLORS } from "../constants";

/**
 * Determines the current status of a module based on its production, preconstruction, and outboundShipment data.
 * @param {Object} module - The module object containing production and preconstruction data.
 * @param {Object} project - The project object containing preconstruction data.
 * @param {Array} outboundShipments - An array of outbound shipments that may include this module.
 * @returns {string} - The determined status.
 */
export const getModuleStatus = (module, project, outboundShipments) => {
  
  // 1) Check if this module is included in any outboundShipment that has a signed BOL
  const isShipped = outboundShipments?.some((shipment) => {
    const includesModule = shipment?.truckModules?.includes(module.id);

    const hasSignedBol = !!shipment?.bol?.signed;
    // If it includes the module and there's a signed BOL, it's shipped
    return includesModule && hasSignedBol;
  });
  if (isShipped) {
    return "Shipped";
  }

  // 2) If not shipped, check if there's a production status
  if (module?.production?.status) {
    return module.production.status;
  }

  // 3) If no production status, fall back to preconstruction status (if any)
  if (project?.preconstruction) {
    return project.preconstruction.status || "Issued for Preconstruction";
  }

  // 4) Default status
  return "Issued for Preconstruction";
};

/**
 * Determines the color for a given status.
 * @param {string} status - The status string.
 * @returns {string} - The corresponding color string (or "default" if not found).
 */
export const getStatusColor = (status) => {
  return STATUS_COLORS[status] || "default";
};
