// EmployeeSignature.js

import React, { useState, useEffect } from "react";
import { message, Spin, Button } from "antd";
import Signature from "./Signature";

import { updateById } from "../../Functions/updateById";
import { addItemsToTable } from "../../Functions/addAria";
import { TABLES } from "../../constants";
import useCurrentUser from "../../hooks/useCurrentUser";
import { EditOutlined } from "@ant-design/icons";

function EmployeeSignature({ signOnly = false, typeOnly = false }) {
  const { userId, signature } = useCurrentUser(); // Assume useCurrentUser provides 'signature'
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [localSignature, setLocalSignature] = useState(signature);

  // Update localSignature when user.signature changes
  useEffect(() => {
    setLocalSignature(signature);
  }, [signature]);

  // Handle the save operation from Signature.js
  const handleSave = async (signatureDataUrl) => {
    setIsSaving(true);

    try {
      await updateById(
        TABLES.ARIA_USERS,
        { signature: signatureDataUrl },
        userId
      );
      message.success("Signature saved successfully!");
      setLocalSignature(signatureDataUrl); // Update local signature
      setIsEditing(false); // Exit editing mode
    } catch (updateError) {
      console.error("Update failed, attempting to add new entry:", updateError);
      try {
        await addItemsToTable(TABLES.ARIA_USERS, {
          id: userId,
          signature: signatureDataUrl,
        });
        message.success("Signature saved successfully!");
        setLocalSignature(signatureDataUrl); // Update local signature
        setIsEditing(false); // Exit editing mode
      } catch (addError) {
        console.error("Failed to save signature:", addError);
        message.error("Error saving signature.");
      }
    } finally {
      setIsSaving(false);
    }
  };

  // Handle the clear operation from Signature.js
  const handleClear = () => {
    // Clear the local signature
    setLocalSignature(null);
    message.info("Signature cleared.");
  };

  // Handle edit button click
  const handleEdit = () => {
    setIsEditing(true);
  };

  return (
    <div>
      {/* Display signature if exists and not editing */}
      {!isEditing && localSignature && (
        <div style={{ textAlign: "center" }}>
          <img
            src={localSignature}
            alt="Employee Signature"
            style={{
              maxWidth: "100%",
              maxHeight: "150px",
              margin:"24px 0px"
              
            }}
          />
          <div style={{ marginTop: 16 }}>
            <Button icon={<EditOutlined/>} type="primary" onClick={handleEdit}>
              Edit Signature
            </Button>
          </div>
        </div>
      )}

      {/* Show Signature component when editing or when no signature exists */}
      {(isEditing || !localSignature) && (
        <Signature
          signOnly={signOnly}
          typeOnly={typeOnly}
          onSave={handleSave}
          onClear={handleClear}
          isSaving={isSaving}
        />
      )}

      {/* Display a loading spinner overlay while saving */}
      {isSaving && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <Spin tip="Saving signature..." size="large" />
        </div>
      )}
    </div>
  );
}

export default EmployeeSignature;
