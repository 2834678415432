import React, { useState, useMemo, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  message,
  Radio,
  Empty,
  Pagination,
  Space,
  Typography,
} from "antd";
import {
  useOutboundShipments,
  useProjects,
  useFreightAgents,
  useCustomers,
} from "../../Contexts/useSpecificData";
import HeaderText from "../../Styled/HeaderText";
import { SearchInput } from "../../Styled/SearchInput";
import { getShipmentItems } from "../../Components/Logistics/getShipmentItems";
import { ArrowRightOutlined, PrinterOutlined } from "@ant-design/icons";
import { getCustomerName } from "../../Formatters/getCustomerName";
import { Loader } from "../../Styled/Loader";
import { useNavigate } from "react-router-dom";
import ShipmentDetailsCard from "../../Components/Shipping/ShipmentDetailsCard";
import { IconTruck } from "@tabler/icons-react";
import debounce from "lodash.debounce";

const { Text } = Typography;

function Shipping() {
  // ---------------------
  //  Data Hooks
  // ---------------------
  const { data: outboundShipments, loading: loadingShipments } =
    useOutboundShipments();
  const { data: projects, loading: loadingProjects } = useProjects();
  const { data: freightAgents, loading: loadingFreightAgents } =
    useFreightAgents();
  const { data: customers, loading: loadingCustomers } = useCustomers();

  const navigate = useNavigate();

  // State for search query
  const [searchQuery, setSearchQuery] = useState("");

  // State for shipment status (current or past)
  const [shipmentStatus, setShipmentStatus] = useState("current"); // 'current' or 'past'

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of shipments per page

  // Handler for shipment status change
  const handleShipmentStatusChange = (e) => {
    setShipmentStatus(e.target.value);
    setCurrentPage(1);
  };

  // Debounced handler for search input change
  const debouncedSetSearchQuery = useMemo(
    () =>
      debounce((value) => {
        setSearchQuery(value);
        setCurrentPage(1);
      }, 300),
    []
  );

  const handleSearchChange = (e) => {
    debouncedSetSearchQuery(e.target.value);
  };

  useEffect(() => {
    return () => {
      debouncedSetSearchQuery.cancel();
    };
  }, [debouncedSetSearchQuery]);

  useEffect(() => {
    document.title = "Shipping";
  }, []);

  // ---------------------
  //  Create Maps for Projects and Freight Agents
  // ---------------------
  const projectMap = useMemo(() => {
    const map = {};
    if (projects) {
      projects.forEach((project) => {
        map[project.id] = project;
      });
    }
    return map;
  }, [projects]);

  const freightAgentMap = useMemo(() => {
    const map = {};
    if (freightAgents) {
      freightAgents.forEach((agent) => {
        map[agent.id] = agent;
      });
    }
    return map;
  }, [freightAgents]);

  // ---------------------
  //  Precompute Searchable Strings for Shipments
  // ---------------------
  const outboundShipmentsWithSearch = useMemo(() => {
    if (!outboundShipments) return [];

    return outboundShipments.map((shipment) => {
      const project = projectMap[shipment.projectId] || {};
      const freightAgent = freightAgentMap[shipment.bol?.freightAgentId] || {};
      const customerName =
        getCustomerName(project?.customerId, customers) || "";

      // Retrieve modules/pallets on this truck
      const shipmentItems = getShipmentItems(project, shipment.id) || {
        truckModules: [],
        truckPallets: [],
      };
      // For searching, gather descriptions:
      const modItems = (shipmentItems.truckModules || []).map((m) => ({
        description: project?.projectName + "-" + (m.number ?? "Module"),
      }));
      const palItems = (shipmentItems.truckPallets || []).map((p) => ({
        description: p.palletName || "Pallet",
      }));

      const shipmentItemDescriptions = [
        ...modItems.map((m) => m.description.toLowerCase()),
        ...palItems.map((p) => p.description.toLowerCase()),
      ].join(" ");

      const searchableString = [
        (shipment.shipmentNumber || "").toLowerCase(),
        (shipment.bol?.bolNumber || "").toLowerCase(),
        (project.projectName || "").toLowerCase(),
        (freightAgent.companyName || "").toLowerCase(),
        (shipment.truckType || "").toLowerCase(),
        customerName.toLowerCase(),
        (shipment.origin?.address || "").toLowerCase(),
        (shipment.destination?.address || "").toLowerCase(),
        shipmentItemDescriptions,
      ].join(" ");

      const isPast = Boolean(shipment.bol?.signed);

      return {
        ...shipment,
        searchableString,
        isPast,
        modItems, // array of modules for quick reference
        palItems, // array of pallets for quick reference
      };
    });
  }, [outboundShipments, projectMap, freightAgentMap, customers]);

  // ---------------------
  //  Filter Shipments with BOL, Status, and Search
  // ---------------------
const filteredShipments = useMemo(() => {
  if (!outboundShipmentsWithSearch) return [];
  const query = searchQuery.toLowerCase();

  // Filter shipments based on BOL presence, shipment status, and search query
  const filtered = outboundShipmentsWithSearch.filter((shipment) => {
    const statusMatch =
      (shipmentStatus === "current" && !shipment.isPast) ||
      (shipmentStatus === "past" && shipment.isPast);

    const matchesQuery = shipment.searchableString.includes(query);

    return shipment.bol?.bolNumber && statusMatch && matchesQuery;
  });

  // Sort the filtered shipments by shipmentNumber (e.g., "OS-4", "OS-5")
  return [...filtered].sort((a, b) => {
    // Extract the number from shipmentNumber
    const extractNumber = (s) => {
      const parts = (s.shipmentNumber || "").split("-");
      return parts[1] ? parseInt(parts[1], 10) : 0;
    };

    return extractNumber(a) - extractNumber(b);
  });
}, [outboundShipmentsWithSearch, searchQuery, shipmentStatus]);

  // ---------------------
  //  Pagination Logic
  // ---------------------
  const paginatedShipments = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredShipments.slice(startIndex, endIndex);
  }, [filteredShipments, currentPage, pageSize]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // ---------------------
  //  Loading State
  // ---------------------
  if (
    loadingShipments ||
    loadingProjects ||
    loadingFreightAgents ||
    loadingCustomers
  ) {
    return <Loader />;
  }

  // ---------------------
  //  Render
  // ---------------------
  return (
    <div>
      {/* Header and Radio Group */}
      <Row
        justify="center"
        align="middle"
        style={{ marginBottom: 16, flexWrap: "wrap" }}
      >
        <Col>
          <Radio.Group
            onChange={handleShipmentStatusChange}
            value={shipmentStatus}
            buttonStyle="solid"
          >
            <Radio.Button value="current">Current Shipments</Radio.Button>
            <Radio.Button value="past">Past Shipments</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>

      {/* Search Input */}
      <Row style={{ marginBottom: 16 }}>
        <Col span={24}>
          <SearchInput
            placeholder="Search Shipments"
            onChange={handleSearchChange}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>

      {filteredShipments.length > 0 ? (
        <>
          <Row gutter={[16, 16]}>
            {paginatedShipments.map((shipment) => {
              const project = projectMap[shipment.projectId] || {};
              const agent = freightAgentMap[shipment.bol?.freightAgentId] || {};
              const customerName = getCustomerName(
                project?.customerId,
                customers
              );

              // Summation of modules + pallets for "no items" check:
              const totalItems =
                (shipment.modItems?.length || 0) +
                (shipment.palItems?.length || 0);
              const noItemsOnTruck = totalItems === 0;

              // Determine if the BOL is signed
              const isBolSigned = shipment?.bol?.signed;
              const bolPdfUrl = isBolSigned
                ? shipment.bol.signed?.pdfUrl
                : null;

              return (
                <Col xs={24} key={shipment.id}>
                  <Card
                    bordered
                    title={shipment.shipmentNumber}
                    style={{
                      backgroundColor: "#fff",
                      position: "relative",
                      overflow: "hidden", // ensures overlay is clipped to card
                    }}
                    extra={
                      isBolSigned ? (
                        <Button
                          type="default"
                          icon={<PrinterOutlined />}
                          onClick={() => {
                            if (bolPdfUrl) {
                              window.open(bolPdfUrl, "_blank");
                            } else {
                              message.error("PDF URL is not available.");
                            }
                          }}
                        >
                          Print
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          icon={<IconTruck />}
                          disabled={noItemsOnTruck} // disable if no items
                          onClick={() => {
                            navigate(`/shipping/bol/${shipment.id}`, {
                              state: {
                                shipment,
                                project,
                                freightAgent: agent,
                                customer: customerName,
                              },
                            });
                          }}
                        >
                          Ship Now
                        </Button>
                      )
                    }
                  >
                    {/* If there are NO items, show the red overlay */}
                    {noItemsOnTruck && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: "rgba(255, 0, 0, 0.3)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          zIndex: 2,
                          pointerEvents: "none", // so that the user can’t click through the overlay
                        }}
                      >
                        <div
                          style={{
                            color: "#fff",
                            fontWeight: "bold",
                            fontSize: "16px",
                            textAlign: "center",
                            textShadow: "1px 1px 2px rgba(0,0,0,0.6)",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            padding: "24px",
                          }}
                        >
                          No Items On Truck - Please ask Logistics to add the
                          Items to Ship
                        </div>
                      </div>
                    )}

                    {/* Shipment Info Component */}
                    <ShipmentDetailsCard shipment={shipment} />
                  </Card>
                </Col>
              );
            })}
          </Row>

          {/* Pagination */}
          {filteredShipments.length > pageSize && (
            <Row justify="center" style={{ marginTop: 16 }}>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={filteredShipments.length}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </Row>
          )}
        </>
      ) : (
        <Row justify="center">
          <Col>
            <Empty
              description={
                shipmentStatus === "current"
                  ? "No current shipments available."
                  : "No past shipments available."
              }
            />
          </Col>
        </Row>
      )}
    </div>
  );
}

export default Shipping;
