import React, { useState, useMemo, useEffect } from "react";
import {
  Card,
  Select,
  Checkbox,
  Button,
  InputNumber,
  message,
  Row,
  Col,
  Tag,
  Spin,
  DatePicker,
  Input,
} from "antd";
import {
  WarningOutlined,
  FilePdfOutlined,
  RocketOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  useFreightAgents,
  useOutboundShipments,
} from "../../Contexts/useSpecificData";
import { sendEmail } from "../../Functions/sendEmail";
import { generatePdf } from "../../Functions/generatePdf";
import { getShipmentItems } from "./getShipmentItems";
import HeaderText from "../../Styled/HeaderText";
import useCurrentUser from "../../hooks/useCurrentUser";
import { updateById } from "../../Functions/updateById";
import { TABLES, COLORS } from "../../constants";
import dayjs from "dayjs";
import generateHtmlOutboundPurchaseOrder from "./generateHtmlOutboundPurchaseOrder";
import { formatDate, formatMoney } from "../../Formatters/helpers";
import { formatPhoneNumber } from "../../Formatters/formatPhoneNumber";
import { useCustomers } from "../../Contexts/useSpecificData";

// Utility function to parse the PO number
function parsePoNumber(poNum) {
  const match = poNum.match(/^B-(\d+)$/);
  if (!match) return null;
  return parseInt(match[1], 10);
}

// Utility function to get the next PO number from an array of existing PO numbers
function getNextPoNumber(existingPoNumbers = []) {
  let maxNumber = 0;
  existingPoNumbers.forEach((poNum) => {
    const num = parsePoNumber(poNum);
    if (num && num > maxNumber) {
      maxNumber = num;
    }
  });
  const nextNumber = maxNumber === 0 ? 4200 : maxNumber + 1;
  return `B-${nextNumber}`;
}

// Check if a contact has missing info
function isMissingContactInfo(contact) {
  const noEmail = !contact.email;
  const noName =
    (!contact.firstName || !contact.firstName.trim()) &&
    (!contact.lastName || !contact.lastName.trim());
  return noEmail || noName;
}

function OutboundPurchaseOrder({ shipment, project }) {
  const { data: freightAgents = [] } = useFreightAgents();
  const { data: customers } = useCustomers();
  const { data: allShipments = [] } = useOutboundShipments();

  const { userName, userEmail } = useCurrentUser();
  const today = dayjs().format("YYYY-MM-DD HH:mm:ss");

  // ================ HISTORY HELPER ================
  /**
   * pushShipmentHistory(actionDesc)
   * Appends a new entry to `shipment.history` with:
   * { action, submittedBy, date }
   */
  async function pushShipmentHistory(actionDesc) {
    try {
      const existingHistory = shipment.history || [];
      const newHistory = [
        ...existingHistory,
        {
          action: actionDesc,
          submittedBy: userName, // use userName instead of userEmail
          date: new Date().toISOString(),
        },
      ];
      await updateById(
        TABLES.OUTBOUND_SHIPMENTS,
        { history: newHistory },
        shipment.id
      );
    } catch (error) {
      console.error("Error updating shipment history:", error);
    }
  }

  // PO fields
  const [poNumber, setPoNumber] = useState(null);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [agentExtraContacts, setAgentExtraContacts] = useState([]);
  const [notes, setNotes] = useState("");
  const [pickupDate, setPickupDate] = useState(null);
  const [amount, setAmount] = useState(null);
  const [htmlPreview, setHtmlPreview] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfLoading, setPdfLoading] = useState(false);

  // Revision logic
  const [isRevising, setIsRevising] = useState(false);
  const [savedPO, setSavedPO] = useState(null);

  // On mount or if shipment changes, fill the fields from existing PO or generate a new number
  useEffect(() => {
    if (!shipment) return;
    const existingPO = shipment.purchaseOrder || {};
    setSavedPO(existingPO);
    setSelectedAgentId(existingPO.freightAgentId || null);
    setNotes(existingPO.notes || "");
    setPickupDate(existingPO.pickupDate ? dayjs(existingPO.pickupDate) : null);
    setAmount(existingPO.amount || null);
    setAgentExtraContacts(existingPO.extraContacts || []);

    if (existingPO.poNumber) {
      // If we already have a PO number, use it
      setPoNumber(existingPO.poNumber);
    } else {
      // Otherwise generate next PO number
      const allPoNumbers = [];
      allShipments.forEach((s) => {
        const sPO = s.purchaseOrder;
        if (sPO?.poNumber) {
          allPoNumbers.push(sPO.poNumber);
        }
      });
      const nextNum = getNextPoNumber(allPoNumbers);
      setPoNumber(nextNum);
    }
  }, [shipment, allShipments]);

  // Gather items on truck
  const { truckModules, truckPallets } = useMemo(() => {
    if (!project || !shipment?.id) {
      return { truckModules: [], truckPallets: [] };
    }
    return getShipmentItems(project, shipment.id);
  }, [project, shipment?.id]);

  const emptyShipment = !truckModules.length && !truckPallets.length;

  // Combine items for PDF preview
  const onTruckItems = useMemo(() => {
    const modItems = truckModules.map((m) => ({
      type: "Module",
      id: m.id,
      weight: m.weight,
      weightIn: m.weightIn,
      heightIn: m.heightIn,
      lengthIn: m.lengthIn,
      widthIn: m.widthIn,
      length: m.length,
      width: m.width,
      height: m.height,
      description: project?.projectName + "-" + (m.number ?? "Module"),
    }));
    const palItems = truckPallets.map((p) => ({
      type: "Pallet",
      id: p.id,
      weight: p.weight,
      weightIn: p.weightIn,
      heightIn: p.heightIn,
      lengthIn: p.lengthIn,
      widthIn: p.widthIn,
      length: p.length,
      width: p.width,
      height: p.height,
      description: p.palletName || "Pallet",
    }));
    return [...modItems, ...palItems];
  }, [truckModules, truckPallets, project?.projectName]);

  // Build HTML for preview whenever relevant fields change
  useEffect(() => {
    if (!shipment) return;

    let freightAgentCompanyName = "N/A";
    let freightAgentName = "N/A";
    let freightAgentEmail = "N/A";
    let freightAgentPhone = "N/A";

    const filteredAgents = freightAgents.filter((fa) => !fa.disabled);
    const agent = filteredAgents.find((fa) => fa.id === selectedAgentId);
    if (agent) {
      freightAgentCompanyName = agent.companyName || "N/A";
      const primaryContact = (agent.contacts || []).find(
        (c) => c.primary && !isMissingContactInfo(c)
      );
      if (primaryContact) {
        freightAgentName = `${primaryContact.firstName} ${primaryContact.lastName}`;
        freightAgentEmail = primaryContact.email || "N/A";
        freightAgentPhone = formatPhoneNumber(primaryContact.phone) || "N/A";
      }
    }

    const html = generateHtmlOutboundPurchaseOrder({
      poNumber,
      type: shipment?.type || "Unknown Type",
      origin: shipment?.origin?.address || "Unknown Origin",
      destination: shipment?.destination?.address || "Unknown Destination",
      projectName: project?.projectName || "Untitled Project",
      items: onTruckItems,
      customDescription: notes,
      pickupDate: pickupDate ? pickupDate.format("dddd MMMM DD, YYYY") : "🚫",
      amount,
      tripDistance: shipment?.tripDistance || "Unknown",
      shipmentNumber: shipment?.shipmentNumber,
      freightAgentCompanyName,
      freightAgentName,
      freightAgentEmail,
      freightAgentPhone,
    });

    setHtmlPreview(html);
    setPdfUrl("");
  }, [
    shipment,
    project,
    onTruckItems,
    notes,
    pickupDate,
    amount,
    selectedAgentId,
    freightAgents,
    poNumber,
  ]);

  // Figure out the revision suffix for display
  const fileCount = shipment?.purchaseOrder?.files?.length || 0;
  const hasSubmittedPo = fileCount > 0;
  const displayPoNumber = `${poNumber}`;

  // Generate PDF
  async function handleGeneratePdf(openTab = true) {
    if (!htmlPreview) {
      message.warning("No HTML to generate PDF from!");
      return null;
    }

    let newTab;
    if (openTab) {
      newTab = window.open("", "_blank");
      if (newTab) {
        newTab.document.write(`
          <html>
            <head>
              <title>Generating PDF...</title>
            </head>
            <body>
              <p style="font-family: Arial; text-align: center; margin-top: 20%;">
                Generating your PDF, please wait...
              </p>
            </body>
          </html>
        `);
      }
    }

    if (pdfUrl && openTab) {
      if (newTab) {
        newTab.location = pdfUrl;
      } else {
        window.open(pdfUrl, "_blank");
      }
      return pdfUrl;
    }

    setPdfLoading(true);
    try {
      const fileName = `LOGISTICS_PURCHASE_ORDER_${project?.projectName}_${poNumber}_${today}.pdf`;
      const result = await generatePdf(htmlPreview, fileName);

      if (result && result.location) {
        setPdfUrl(result.location);
        message.success("PDF generated successfully!");
        if (openTab && newTab) {
          newTab.location = result.location;
        }
        return result.location;
      } else {
        message.error("PDF generation failed. No location returned.");
        return null;
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      message.error("Failed to generate PDF. Check console for details.");
      return null;
    } finally {
      setPdfLoading(false);
    }
  }

  // Submit purchase order => email + save => update history
  async function handleSendEmails() {
    if (!htmlPreview) {
      message.warning("No HTML to send!");
      return;
    }

    let updatedPO = {
      poNumber,
      pickupDate: pickupDate ? pickupDate.toISOString() : null,
      notes,
      amount,
      freightAgentId: selectedAgentId,
      extraContacts: agentExtraContacts,
      lines: onTruckItems,
      files: (shipment.purchaseOrder && shipment.purchaseOrder.files) || [],
    };

    // Make sure we have a PDF
    let currentPdfUrl = pdfUrl;
    if (!currentPdfUrl) {
      currentPdfUrl = await handleGeneratePdf(false);
      if (!currentPdfUrl) {
        message.error("PDF generation failed. Cannot submit without PDF.");
        return;
      }
    }

    // Check freight agent
    if (!selectedAgentId) {
      message.warning("No Freight Agent selected!");
      return;
    }
    const agent = freightAgents.find((fa) => fa.id === selectedAgentId);
    if (!agent) {
      message.error("Selected agent not found.");
      return;
    }

    // Gather contact emails
    const primaryContacts = (agent.contacts || []).filter((c) => c.primary);
    const additionalContacts = (agent.contacts || []).filter((c) => !c.primary);
    const extraEmails = additionalContacts
      .filter((c) => agentExtraContacts.includes(c.email))
      .map((c) => c.email);
    const primaryContact = primaryContacts.find(
      (c) => !isMissingContactInfo(c)
    );
    const greetingName = primaryContact
      ? `${primaryContact.firstName}`
      : agent.companyName;
    const primaryEmails = primaryContacts.map((c) => c.email).filter(Boolean);
    const allEmails = Array.from(new Set([...primaryEmails, ...extraEmails]));
    if (!allEmails.length) {
      message.warning(
        `No valid contacts selected for ${agent.companyName}. Skipping.`
      );
      return;
    }

    const pickupDateStr = pickupDate
      ? pickupDate.format("dddd MMMM DD, YYYY")
      : "TBD";
    const grossWeight = onTruckItems.reduce(
      (sum, item) => sum + (item.weight || 0),
      0
    );

    const isDev = process.env.REACT_APP_STAGE === "_dev";
    const devEmailsSection = isDev
      ? `<p><strong>Sending to emails:</strong> ${allEmails.join(", ")}</p>`
      : "";
    const emailBody = `
      <p>Hello ${greetingName},</p>
      <p>Please see attached Purchase Order <strong>${poNumber}</strong> regarding a shipment.</p>
      <p><strong>Pickup Date:</strong> ${pickupDateStr}</p>
      <p><strong>Pickup Address:</strong> ${
        shipment?.origin?.address || "N/A"
      }</p>
      <p><strong>Drop-off Address:</strong> ${
        shipment?.destination?.address || "N/A"
      }</p>
      <p><strong>Gross Weight:</strong> ${grossWeight} lbs</p>
      <p><strong>Total:</strong> ${formatMoney(amount) || "N/A"}</p>
      ${devEmailsSection}
      <p>Thanks,</p>
      <p>${userName}</p>
    `;
    const subject = `BMarko Structures Purchase Order ${poNumber} - [${project.projectName}] [${shipment.shipmentNumber}] ${today}`;

    try {
      // 1. Send email with PDF
      const resp = await sendEmail({
        subject,
        htmlBody: emailBody,
        emailAddresses: allEmails,
        sender: userEmail,
        attachmentUrls: [currentPdfUrl],
        manager: "christina.parker@bmarko.com",
      });
      if (!resp.success) {
        throw new Error(`Failed to send email to agent: ${agent.companyName}`);
      }

      // 2. Append newly-sent PDF to PO
      updatedPO.files = [
        ...(updatedPO.files || []),
        { url: currentPdfUrl, dateSent: new Date().toISOString() },
      ];

      // 3. Save purchaseOrder in Firestore
      await updateById(
        TABLES.OUTBOUND_SHIPMENTS,
        { purchaseOrder: updatedPO },
        shipment.id
      );
      setSavedPO(updatedPO);

      // 4. Show success msg
      message.success("Purchase Order Email sent successfully!");
      setIsRevising(false);

      // 5. Push to history with revision suffix
      const agentName = agent.companyName || "Unknown Agent";
      const dateStr = pickupDate
        ? pickupDate.format("MM/DD/YYYY")
        : "No pickup date";

      // Now the updatedPO has the new file, so we can see how many total
      const newFileCount = updatedPO.files.length; // e.g. 1 => "Rev_1"
      const revSuffix = newFileCount > 0 ? ` Rev_${newFileCount-1}` : "";

      const actionString = `Submitted Purchase Order ${poNumber}${revSuffix} to ${agentName}. Pickup: ${dateStr}, Amount: ${formatMoney(
        amount
      )}.`;
      await pushShipmentHistory(actionString);
    } catch (error) {
      console.error("Error sending Purchase Order email:", error);
      message.error("Email sending failed. Check console for details.");
    }
  }

  // Check if we can enable "Submit Purchase Order"
  const canSendEmails = useMemo(() => {
    if (!selectedAgentId) return false;
    const agent = freightAgents.find((fa) => fa.id === selectedAgentId);
    if (!agent) return false;
    const validContacts = (agent.contacts || []).filter(
      (c) => !isMissingContactInfo(c)
    );
    return validContacts.length > 0;
  }, [selectedAgentId, freightAgents]);

  // Agent options
  const filteredAndSortedAgents = useMemo(() => {
    return freightAgents
      .filter((fa) => !fa.disabled)
      .sort((a, b) => (a.companyName || "").localeCompare(b.companyName || ""));
  }, [freightAgents]);

  const freightAgentOptions = useMemo(() => {
    return filteredAndSortedAgents.map((fa) => ({
      label: fa.companyName,
      value: fa.id,
    }));
  }, [filteredAndSortedAgents]);

  function handleSelectAgent(value) {
    setSelectedAgentId(value);
    setAgentExtraContacts([]);
  }

  const rightCardExtra = (
    <Button
      icon={<RocketOutlined />}
      type="primary"
      onClick={handleSendEmails}
      disabled={
        !canSendEmails ||
        emptyShipment ||
        !pickupDate ||
        !amount ||
        !selectedAgentId
      }
    >
      Submit Purchase Order
    </Button>
  );

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} lg={12}>
        <Card
          title="Build Purchase Order"
          // We'll show the revision suffix in the Tag
          extra={<Tag style={{ fontSize: 14 }}>PO# {displayPoNumber}</Tag>}
        >
          {/* If there's an existing PO with files, show the "Revisions" card */}
          {shipment?.purchaseOrder && shipment?.purchaseOrder?.files && (
            <Card style={{ marginBottom: 16 }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "16px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <HeaderText small text="Purchase Order Revisions" />
                </div>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "32px" }}>
                  {shipment?.purchaseOrder?.files.map((entry, index) => {
                    const { url, dateSent } = entry;
                    return (
                      <div
                        key={`po-pdf-${index}`}
                        style={{ textAlign: "center" }}
                      >
                        <a href={url} target="_blank" rel="noopener noreferrer">
                          <FilePdfOutlined
                            style={{
                              fontSize: "24px",
                              color: COLORS.PRIMARY,
                              margin: "4px 0px",
                            }}
                          />
                        </a>
                        <div style={{ fontSize: "0.85rem" }}>
                          {`Rev_${index}`}
                        </div>
                        {dateSent && (
                          <div style={{ fontSize: "0.75rem" }}>
                            {formatDate(dateSent)}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Card>
          )}

          {shipment?.purchaseOrder && !isRevising ? (
            <div style={{ marginBottom: 16, textAlign: "center" }}>
              <Button
                type="primary"
                onClick={() => setIsRevising(true)}
                style={{ marginBottom: 16 }}
                icon={<EditOutlined />}
              >
                Revise Purchase Order
              </Button>
            </div>
          ) : (
            <>
              <div>
                <HeaderText small text="Pickup Date" />
                <DatePicker
                  style={{ width: "100%", marginTop: 8 }}
                  value={pickupDate}
                  onChange={(date) => setPickupDate(date)}
                />
              </div>
              <div style={{ marginTop: 16 }}>
                <HeaderText small text="Amount" />
                <InputNumber
                  type="number"
                  style={{ width: "100%", marginTop: 8 }}
                  prefix="$"
                  value={amount}
                  onChange={setAmount}
                />
              </div>
              <div style={{ marginBottom: 16, marginTop: 16 }}>
                <HeaderText small text="Freight Agent" />
                <Select
                  style={{ width: "100%", marginTop: 8 }}
                  placeholder="Select Freight Agent"
                  value={selectedAgentId}
                  onChange={handleSelectAgent}
                  options={freightAgentOptions}
                />
              </div>
              {selectedAgentId &&
                (() => {
                  const agent = filteredAndSortedAgents.find(
                    (fa) => fa.id === selectedAgentId
                  );
                  if (!agent) return null;
                  const primaryContacts = (agent.contacts || []).filter(
                    (c) => c.primary
                  );
                  const additionalContacts = (agent.contacts || []).filter(
                    (c) => !c.primary
                  );
                  return (
                    <Card
                      size="small"
                      title={agent.companyName}
                      style={{ marginBottom: 16 }}
                    >
                      {primaryContacts.length > 0 ? (
                        primaryContacts.map((pc) => {
                          if (isMissingContactInfo(pc)) {
                            return (
                              <div
                                key={pc.id}
                                style={{
                                  marginBottom: 8,
                                  backgroundColor: "#ffe6e6",
                                  padding: 4,
                                }}
                              >
                                <Tag color="red" icon={<WarningOutlined />}>
                                  No name/email — cannot send
                                </Tag>
                              </div>
                            );
                          }
                          return (
                            <div key={pc.id} style={{ marginBottom: 8 }}>
                              <strong>Primary:</strong>
                              <br />
                              {pc.firstName} {pc.lastName}
                              <br />
                              <small>{pc.email}</small>
                            </div>
                          );
                        })
                      ) : (
                        <i>No primary contact</i>
                      )}
                      {additionalContacts.length > 0 && (
                        <>
                          <div style={{ fontWeight: 500, marginTop: 8 }}>
                            Additional Contacts:
                          </div>
                          <Checkbox.Group
                            style={{ display: "block", marginTop: 4 }}
                            value={agentExtraContacts}
                            onChange={(vals) => setAgentExtraContacts(vals)}
                          >
                            {additionalContacts.map((ac) => {
                              if (isMissingContactInfo(ac)) {
                                return (
                                  <div
                                    key={ac.id}
                                    style={{
                                      marginLeft: 16,
                                      marginTop: 4,
                                      backgroundColor: "#ffe6e6",
                                      padding: 4,
                                    }}
                                  >
                                    <Tag color="red" icon={<WarningOutlined />}>
                                      No name/email — cannot send
                                    </Tag>
                                  </div>
                                );
                              }
                              return (
                                <div
                                  key={ac.id}
                                  style={{ marginLeft: 16, marginTop: 4 }}
                                >
                                  <Checkbox value={ac.email}>
                                    {ac.firstName} {ac.lastName}
                                    <br />
                                    <small>{ac.email}</small>
                                  </Checkbox>
                                </div>
                              );
                            })}
                          </Checkbox.Group>
                        </>
                      )}
                    </Card>
                  );
                })()}
              <div>
                <HeaderText small text="Notes" />
                <Input.TextArea
                  rows={3}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Enter any special instructions or message here"
                  style={{ marginTop: 8 }}
                />
              </div>
            </>
          )}
        </Card>
      </Col>
      <Col xs={24} lg={12}>
        <Card
          title="Preview & Send"
          style={{ height: "100%" }}
          extra={rightCardExtra}
        >
          <Spin spinning={pdfLoading} tip="Generating PDF...">
            {htmlPreview ? (
              <div
                style={{
                  border: "1px solid #ccc",
                  padding: 16,
                  minHeight: 300,
                }}
                dangerouslySetInnerHTML={{ __html: htmlPreview }}
              />
            ) : (
              <div style={{ color: "#999" }}>No preview yet.</div>
            )}
          </Spin>
        </Card>
      </Col>
    </Row>
  );
}

export default OutboundPurchaseOrder;
