// Signature.js

import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, Input, message, Modal } from "antd";
import SignatureCanvas from "react-signature-canvas";
import { IconKeyboard, IconSignature } from "@tabler/icons-react";
import PropTypes from "prop-types";
import { CheckOutlined } from "@ant-design/icons";

const Signature = forwardRef(
  (
    {
      signOnly = false,
      typeOnly = false,
      touchOnly = false,
      onSave,
      onClear, // Ensure onClear is destructured from props
      loading,
    },
    ref
  ) => {
    const sigCanvasRef = useRef(null);
    const [mode, setMode] = useState(() => {
      if (typeOnly) return "type";
      if (signOnly) return "sign";
      return "sign"; // Default to "sign" if both modes are available
    });
    const [typedName, setTypedName] = useState("");
    const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);

    // Expose clearSignature method to parent via ref
    useImperativeHandle(ref, () => ({
      clearSignature: () => {
        handleClearExternal();
      },
    }));

    // Function to handle external clear
    const handleClearExternal = () => {
      Modal.confirm({
        title: "Are you sure you want to clear the signature?",
        onOk: () => {
          if (mode === "type") {
            setTypedName("");
          } else if (mode === "sign") {
            if (sigCanvasRef.current) {
              sigCanvasRef.current.clear();
              setIsCanvasEmpty(true);
            }
          }
          // Notify parent after clearing if onClear is provided
          if (onClear && typeof onClear === "function") {
            onClear();
          }
        },
      });
    };

    // Update isCanvasEmpty based on canvas content when mode changes
    useEffect(() => {
      if (mode === "sign" && sigCanvasRef.current) {
        setIsCanvasEmpty(sigCanvasRef.current.isEmpty());
      }
    }, [mode]);

    // Function to generate a cursive signature image from typed text using an offscreen canvas
    const generateTypedSignature = (text) => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      const fontSize = 48;
      const fontFamily = "'Dancing Script', cursive";
      context.font = `${fontSize}px ${fontFamily}`;

      const textMetrics = context.measureText(text);
      canvas.width = textMetrics.width + 20; // Add padding
      canvas.height = fontSize * 1.2;

      context.font = `${fontSize}px ${fontFamily}`;
      context.textBaseline = "top";
      context.fillStyle = "black";
      context.fillText(text, 10, 0); // Add padding

      return canvas.toDataURL("image/png");
    };

    // Handle Save Signature
    const handleSave = () => {
      let signatureData;
      if (mode === "type") {
        if (!typedName.trim()) {
          message.warning("Please enter your name for the signature.");
          return;
        }
        signatureData = generateTypedSignature(typedName.trim());
      } else if (mode === "sign") {
        if (!sigCanvasRef.current || sigCanvasRef.current.isEmpty()) {
          message.warning("Please provide a signature to save.");
          return;
        }
        signatureData = sigCanvasRef.current
          .getTrimmedCanvas()
          .toDataURL("image/png");
      }

      if (onSave && typeof onSave === "function") {
        onSave(signatureData);
      }

      // message.success("Signature captured successfully!");
    };

    // Handle Clear Signature with Confirmation
    const handleClear = () => {
      handleClearExternal();
    };

    // Monitor canvas changes to enable/disable Save button
    const handleEnd = () => {
      if (sigCanvasRef.current) {
        setIsCanvasEmpty(sigCanvasRef.current.isEmpty());

        if (touchOnly) {
          // Automatically save when touch ends in touchOnly mode
          handleSave();
        }
      }
    };

    // Handle Mode Change
    const handleModeChange = (newMode) => {
      setMode(newMode);
      // Clear previous input or signature when switching modes
      if (newMode === "type") {
        setTypedName("");
        if (sigCanvasRef.current) {
          sigCanvasRef.current.clear();
          setIsCanvasEmpty(true);
        }
      } else if (newMode === "sign") {
        setTypedName("");
        if (sigCanvasRef.current) {
          sigCanvasRef.current.clear();
          setIsCanvasEmpty(true);
        }
      }
    };

    // Determine if Save button should be disabled
    const isSaveDisabled =
      mode === "type"
        ? !typedName.trim()
        : !sigCanvasRef.current || sigCanvasRef.current.isEmpty();

    return (
      <div style={styles.container}>
        {/* Mode selection buttons, shown only if both modes are available and not touchOnly */}
        {!touchOnly && !signOnly && !typeOnly && (
          <div style={styles.modeButtonsContainer}>
            <Button
              type={mode === "sign" ? "primary" : "default"}
              style={styles.modeButton}
              onClick={() => handleModeChange("sign")}
              aria-label="Sign Signature"
            >
              <IconSignature size={24} style={{ marginRight: 8 }} />
              <span>Sign</span>
            </Button>
            <Button
              type={mode === "type" ? "primary" : "default"}
              style={styles.modeButton}
              onClick={() => handleModeChange("type")}
              aria-label="Type Signature"
            >
              <IconKeyboard size={24} style={{ marginRight: 8 }} />
              <span>Type</span>
            </Button>
          </div>
        )}

        <div style={styles.signatureArea}>
          {mode === "type" ? (
            // Typed Mode: Typing directly inside the signature area
            <Input
              placeholder="Your Name"
              value={typedName}
              onChange={(e) => setTypedName(e.target.value)}
              style={styles.inputField}
              onFocus={(e) => {
                // Optionally, select all text when focused
                e.target.select();
              }}
              autoFocus
            />
          ) : (
            // Sign Mode: Signature canvas filling the container
            <>
                <SignatureCanvas
                  clearOnResize={false}
                  willReadFrequently
                ref={sigCanvasRef}
                canvasProps={{
                  className: "sigCanvas",
                  style: styles.signatureCanvas,
                }}
                penColor="black"
                onEnd={handleEnd} // Trigger when user stops drawing
              />
              {isCanvasEmpty && (
                <div style={styles.signaturePlaceholder}>Sign Here</div>
              )}
            </>
          )}
        </div>

        {/* Save and Clear Buttons: Only show if not touchOnly */}
        {!touchOnly && (
          <div style={styles.actionButtonsContainer}>
            <Button danger onClick={handleClear} type="default">
              Clear Signature
            </Button>
            <Button
              onClick={handleSave}
              loading={loading}
              type="primary"
              disabled={isSaveDisabled}
              icon={<CheckOutlined size={18} />}
            >
              Save Signature
            </Button>
          </div>
        )}
      </div>
    );
  }
);

const styles = {
  container: {
    width: "100%",
    maxWidth: "1000px",
    boxSizing: "border-box",
    margin: "0 auto", // Center the container
  },
  modeButtonsContainer: {
    display: "flex",
    gap: "8px",
    justifyContent: "center",
    marginBottom: "16px",
    flexWrap: "wrap",
  },
  modeButton: {
    flex: "1 1 120px", // Allow buttons to shrink/grow
    maxWidth: "120px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    padding: "8px",
  },
  signatureArea: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "180px",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "16px",
    position: "relative",
    overflow: "hidden",
  },
  inputField: {
    width: "100%",
    height: "100%",
    textAlign: "center",
    fontSize: "36px",
    border: "none",
    background: "transparent",
    outline: "none",
    fontFamily: "'Dancing Script', cursive",
    color: "black", // Display typed text
    caretColor: "black", // Show cursor in black
    padding: "0 10px",
    boxSizing: "border-box",
  },
  signatureCanvas: {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    backgroundColor: "white",
  },
  signaturePlaceholder: {
    fontFamily: "'Dancing Script', cursive",
    fontSize: "36px",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "none", // Allows clicks to pass through
    color: "gray",
    fontStyle: "italic",
    opacity: "0.3",
    userSelect: "none",
    padding: "0 10px",
    boxSizing: "border-box",
  },
  actionButtonsContainer: {
    display: "flex",
    gap: "8px",
    justifyContent: "center",
    flexWrap: "wrap",
  },
};

Signature.propTypes = {
  signOnly: PropTypes.bool,
  typeOnly: PropTypes.bool,
  touchOnly: PropTypes.bool, // New prop
  onSave: PropTypes.func.isRequired,
  onClear: PropTypes.func, // Optional prop
  loading: PropTypes.bool,
};

export default Signature;
