import axios from "axios";
import imageCompression from "browser-image-compression";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL + "/aria/s3Upload";

// Maximum file size in bytes (4MB)
const MAX_FILE_SIZE = 4 * 1024 * 1024;

/**
 * Converts bytes to megabytes (MB) with two decimal places.
 *
 * @param {number} bytes - The size in bytes.
 * @returns {string} - The size formatted in MB.
 */
function bytesToMB(bytes) {
  return (bytes / (1024 * 1024)).toFixed(2);
}

/**
 * Uploads a file to S3 with optional compression for images.
 *
 * @param {File} file - The file to upload.
 * @param {string} fileName - The name to assign to the uploaded file.
 * @returns {Promise<string>} - A promise that resolves to the uploaded file's URL.
 */
export async function s3Upload(file, fileName) {

  try {
    let processedFile = file;

    // Log the file size in MB for all file types
    console.log(`File size is (${bytesToMB(file.size)} MB)`);

    // Check if the file is an image based on MIME type
    const isImage = file.type.startsWith("image/");
    if (isImage) {
      console.log(`File is an image. Proceeding to check for compression.`);

      // Check if the image file size exceeds 4MB
      if (file.size > MAX_FILE_SIZE) {
        console.log(
          `Image file size (${bytesToMB(
            file.size
          )} MB) exceeds 4MB. Compressing...`
        );

        // Compression options
        const options = {
          maxSizeMB: 4,
          maxWidthOrHeight: 1920, // Adjust as needed
          useWebWorker: true,
        };

        try {
          // Compress the image file
          processedFile = await imageCompression(file, options);
          console.log(
            `Image compressed from ${bytesToMB(file.size)} MB to ${bytesToMB(
              processedFile.size
            )} MB`
          );

          // Optional: Rename the compressed file if needed
          // processedFile = new File([processedFile], fileName, { type: processedFile.type });
        } catch (compressionError) {
          console.error("Image compression failed:", compressionError);
          throw new Error("Image compression failed");
        }
      } else {
        console.log(
          "Image file size is within the limit. No compression needed."
        );
      }
    } else {
      console.log("File is not an image. Skipping compression.");
    }

    // Convert the (compressed or original) file content to a base64 string
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.readAsDataURL(processedFile);
      reader.onloadend = async () => {
        try {
          const base64data = reader.result.split(",")[1];

          // Get the file type (MIME type)
          const fileType = processedFile.type;

          // Prepare the payload for the API request
          const payload = {
            fileContent: base64data,
            fileName: fileName, // Use the fileName passed as a parameter
            fileType: fileType, // Include the file type in the payload
          };

          console.log("Payload:", payload);

          // Send a POST request to your Lambda function
          const response = await axios.post(API_BASE_URL, payload, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          // Handle the response
          if (response.status === 200) {
            const { fileUrl } = response.data;
            console.log("Upload successful. File URL:", fileUrl);
            resolve(fileUrl); // Return the URL of the uploaded file
          } else {
            console.error(`Upload failed with status code: ${response.status}`);
            reject(`Upload failed with status code: ${response.status}`);
          }
        } catch (uploadError) {
          console.error(
            `Error uploading file: ${
              uploadError.response
                ? uploadError.response.statusText
                : uploadError.message
            }`
          );
          reject(
            `Error uploading file: ${
              uploadError.response
                ? uploadError.response.statusText
                : uploadError.message
            }`
          );
        }
      };
      reader.onerror = (error) => {
        console.error("FileReader error:", error);
        reject(error);
      };
    });
  } catch (error) {
    console.error("File upload failed:", error);
    throw new Error("File upload failed");
  }
}
