import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  useOutboundShipments,
  useAriaUsers,
  useProjects,
  useFreightAgents,
  useCustomers,
} from "../../Contexts/useSpecificData"; // Adjust path as necessary
import { Form, Input, Button, Typography, Upload, message, Card } from "antd";
import { CloseOutlined, SaveOutlined, UploadOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { v4 as uuidv4 } from "uuid";
import ShipmentDetailsCard from "./ShipmentDetailsCard"; // Adjust path as necessary
import Signature from "../Signature/Signature"; // Adjust path as necessary
import { TABLES, SLACK_CHANNELS, MANAGER_EMAILS } from "../../constants"; // Adjust path as necessary
import { addItemsToTable } from "../../Functions/addAria"; // Adjust path as necessary
import { updateById } from "../../Functions/updateById"; // Adjust path as necessary
import { s3Upload } from "../../Functions/s3Upload"; // Adjust path as necessary
import { MaskedInput } from "antd-mask-input";
import PhoneNumberInput from "../../Formatters/PhoneNumberInput";
import generateHtmlOutboundBOL from "../Logistics/generateHtmlOutboundBOL";
import { generatePdf } from "../../Functions/generatePdf";
import { Loader } from "../../Styled/Loader";
import { getShipmentItems } from "../Logistics/getShipmentItems";
import { formatPhoneNumber } from "../../Formatters/formatPhoneNumber";
import { sendSlackMessage } from "../../Functions/sendSlackMessage";
import useCurrentUser from "../../hooks/useCurrentUser";
import { sendEmail } from "../../Functions/sendEmail";

const { Title } = Typography;

const slackChannel =
  process.env.REACT_APP_STAGE === "_dev"
    ? SLACK_CHANNELS.LOGISTICS_DEV
    : SLACK_CHANNELS.LOGISTICS_PROD;

function BOLSignature() {
  // ====================== HISTORY HELPER (NEW) ======================
  /**
   * pushShipmentHistory(actionDesc)
   *
   * Reads `shipment?.history` and appends a new record of:
   * { action, submittedBy: userName, date }
   * Then updates the shipment in Firestore with the new history array.
   */
  async function pushShipmentHistory(actionDesc) {
    try {
      const existingHistory = shipment?.history || [];
      const newHistory = [
        ...existingHistory,
        {
          action: actionDesc,
          submittedBy: userName,
          date: new Date().toISOString(),
        },
      ];
      await updateById(
        TABLES.OUTBOUND_SHIPMENTS,
        { history: newHistory },
        shipmentId
      );
    } catch (error) {
      console.error("Error updating shipment history:", error);
      // We won't block the user if history fails
    }
  }
  // ==================================================================

  // Extract shipmentId from the URL parameters
  const { id } = useParams();
  const navigate = useNavigate();
  const shipmentId = id;

  // Access navigation state to get shipment data if available
  const location = useLocation();
  const [shipment, setShipment] = useState(location.state?.shipment || null);

  // Current user info
  const { userName, userEmail } = useCurrentUser();
  const { userId, loading: userLoading, error: userError } = useCurrentUser();

  // Fetch shipments
  const {
    data: outboundShipments,
    loading: shipmentsLoading,
    error: shipmentsError,
  } = useOutboundShipments();

  // Fetch ARIA_USERS
  const {
    data: ariaUsers,
    loading: ariaLoading,
    error: ariaError,
    refresh: refreshAriaUsers,
  } = useAriaUsers();

  const { data: projects } = useProjects();
  const { data: freightAgents } = useFreightAgents();
  const { data: customers } = useCustomers();

  // Derive project from shipments
  const project = projects.find((proj) => proj.id === shipment?.projectId);
  const today = new Date().toISOString();

  // Freight agent info
  const selectedAgent = freightAgents?.find(
    (agent) => agent.id === shipment?.bol?.freightAgentId
  );

  const primaryContact = useMemo(() => {
    if (!selectedAgent?.contacts?.length) return {};
    return selectedAgent.contacts.find((c) => c.primary) || {};
  }, [selectedAgent]);

  const freightAgentCompany = selectedAgent?.companyName || "";
  const freightAgentName = [primaryContact.firstName, primaryContact.lastName]
    .filter(Boolean)
    .join(" ");
  const freightAgentPhone = formatPhoneNumber(primaryContact.phone) || "";
  const receiverCompany = project?.companyName || "N/A";

  // Keep track of the user from ARIA_USERS
  const [foundUser, setFoundUser] = useState(null);
  useEffect(() => {
    if (ariaUsers) {
      const userFound = ariaUsers.find((u) => u.id === userId);
      setFoundUser(userFound);
    }
  }, [ariaUsers, userId]);

  // Carrier & Shipper signatures
  const [carrierSignature, setCarrierSignature] = useState("");
  const [shipperSignature, setShipperSignature] = useState("");

  // Refs for clearing
  const carrierSignatureRef = useRef(null);
  const shipperSignatureRef = useRef(null);

  // Form instance
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  const [submitting, setSubmitting] = useState(false);

  // Modules & Pallets
  const { truckModules, truckPallets } = useMemo(() => {
    if (!project || !shipment?.id) {
      return { truckModules: [], truckPallets: [] };
    }
    return getShipmentItems(project, shipment.id);
  }, [project, shipment?.id]);

  const onTruckItems = useMemo(() => {
    const modItems = truckModules.map((m) => ({
      type: "Module",
      id: m.id,
      weight: m.weight,
      weightIn: m.weightIn,
      height: m.height,
      length: m.length,
      width: m.width,
      heightIn: m.heightIn,
      lengthIn: m.lengthIn,
      widthIn: m.widthIn,
      description: `${project?.projectName}-${m.number ?? "Module"}`,
    }));

    const palItems = truckPallets.map((p) => ({
      type: "Pallet",
      id: p.id,
      weight: p.weight,
      height: p.height,
      length: p.length,
      width: p.width,
      weightIn: p.weightIn,
      heightIn: p.heightIn,
      lengthIn: p.lengthIn,
      widthIn: p.widthIn,
      description: p.palletName || "Pallet",
    }));

    return [...modItems, ...palItems];
  }, [truckModules, truckPallets, project?.projectName]);

  // If user didn't pass shipment in location.state, try to fetch from context
  useEffect(() => {
    if (!shipment && outboundShipments) {
      const foundShipment = outboundShipments.find((s) => s.id === shipmentId);
      if (foundShipment) {
        setShipment(foundShipment);
      }
    }
  }, [outboundShipments, shipment, shipmentId]);

  // Handle Upload
  const handleUploadChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 5) {
      message.error("You can only upload up to 5 files.");
      return;
    }
    setFileList(newFileList);
  };
  const handleRemove = (file) => {
    setFileList((prevList) => prevList.filter((f) => f.uid !== file.uid));
  };

  // Validate signatures
  const validateCarrierSignature = (_, value) => {
    if (carrierSignature) return Promise.resolve();
    return Promise.reject(new Error("Carrier signature is required."));
  };
  const validateShipperSignature = (_, value) => {
    if (shipperSignature) return Promise.resolve();
    return Promise.reject(new Error("Shipper signature is required."));
  };

  // Debounced signature saving
  const debouncedCarrierSave = useCallback(
    debounce((signatureDataUrl) => {
      setCarrierSignature(signatureDataUrl);
    }, 500),
    []
  );
  const debouncedShipperSave = useCallback(
    debounce((signatureDataUrl) => {
      setShipperSignature(signatureDataUrl);
    }, 500),
    []
  );

  // Clear signature handlers
  const handleCarrierClear = () => {
    if (carrierSignatureRef.current) {
      carrierSignatureRef.current.clearSignature();
    }
  };
  const handleCarrierClearLocal = () => {
    setCarrierSignature("");
    message.info("Carrier signature cleared.");
  };
  const handleShipperClear = () => {
    if (shipperSignatureRef.current) {
      shipperSignatureRef.current.clearSignature();
    }
  };
  const handleShipperClearLocal = () => {
    setShipperSignature("");
    message.info("Shipper signature cleared.");
  };

  // Main submit
const handleFinish = async (values) => {
  setSubmitting(true);

  // Open a new tab to show PDF generation progress.
  const newTab = window.open("", "_blank");
  if (newTab) {
    newTab.document.write(`
      <html>
        <head><title>Generating PDF for Outbound Shipment...</title></head>
        <body>
          <p style="font-family: Arial; text-align: center; margin-top: 20%;">
            Generating your BOL PDF, please wait...
          </p>
        </body>
      </html>
    `);
  } else {
    message.error("Pop-up blocked! Please allow pop-ups to view the PDF.");
  }

  try {
    // 1. Upload images.
    const uploadedFileUrls = await Promise.all(
      fileList.map(async (file) => {
        const uniqueFileName = `${userId}_${shipmentId}_${uuidv4()}_${
          file.name
        }`;
        const url = await s3Upload(file.originFileObj, uniqueFileName);
        return url;
      })
    );

    // 2. Build the signed BOL entry.
    const newBOLEntry = {
      pickupDate: new Date().toISOString(),
      carrierName: values.carrierName,
      carrierCompany: values.carrierCompany,
      carrierPhone: values.carrierPhone,
      carrierSignature,
      shipperSignature: foundUser?.signature || shipperSignature,
      shipmentImages: uploadedFileUrls,
      userId,
      timestamp: new Date().toISOString(),
      shippedBy: userEmail,
    };

    // Ensure the ARIA user exists and update their signature if needed.
    const existingBOL = shipment.bol || {};
    if (!foundUser) {
      await addItemsToTable(TABLES.ARIA_USERS, {
        id: userId,
        signature: shipperSignature,
      });
      await refreshAriaUsers();
      setFoundUser(ariaUsers.find((u) => u.id === userId));
    } else {
      if (!foundUser.signature && shipperSignature) {
        await updateById(
          TABLES.ARIA_USERS,
          { signature: shipperSignature },
          userId
        );
        await refreshAriaUsers();
        setFoundUser(ariaUsers.find((u) => u.id === userId));
      }
    }

    // 3. Build and update the shipment.
    const updatedShipment = {
      ...shipment,
      bol: {
        ...existingBOL,
        signed: newBOLEntry,
      },
    };

    // 4. Generate HTML and then a PDF of the BOL.
    const htmlContent = generateHtmlOutboundBOL({
      shipperName: "BMarko Structures, LLC",
      shipperContact: existingBOL?.createdBy || "Christina Parker",
      shipperAddress1: "2624 Weaver Way, Suite 200",
      shipperAddress2: "Atlanta, GA 30340",
      freightAgentCompany,
      freightAgentName,
      freightAgentPhone,
      carrierName: values.carrierName,
      carrierCompany: values.carrierCompany,
      carrierPhone: values.carrierPhone,
      pickupDate: new Date().toLocaleDateString(),
      receiverCompany,
      receiverName: shipment?.bol?.recipientName || "",
      receiverPhone: shipment?.bol?.recipientPhone || "",
      customDescription: shipment?.bol?.notes || "",
      items: onTruckItems,
      bolNumber: existingBOL?.bolNumber || `BOL-${Date.now()}`,
      projectName: project?.projectName || "Unknown Project",
      origin: shipment?.origin?.address || "Unknown Origin",
      destination: shipment?.destination?.address || "Unknown Destination",
      customDescription: shipment?.notes || "",
      userSignature: foundUser?.signature || shipperSignature,
      carrierSignature: carrierSignature,
    });

    const fileName = `BOL_CARRIER_SIGNED_${project?.projectName}_${existingBOL.bolNumber}_${today}.pdf`;
    const pdfResult = await generatePdf(htmlContent, fileName);
    if (pdfResult?.location) {
      updatedShipment.bol.signed.pdfUrl = pdfResult.location;
    }

    await updateById(TABLES.OUTBOUND_SHIPMENTS, updatedShipment, shipment.id);

    // Show the PDF in the new tab.
    if (newTab && pdfResult?.location) {
      newTab.location.href = pdfResult.location;
    }

    // 5. Post a Slack message.
    const itemDescriptions = onTruckItems
      .map((item, index) => `\n${index + 1}. ${item.type}: ${item.description}`)
      .join("");
    const slackMessagePayload = {
      channel: slackChannel,
      blocks: [
        {
          type: "header",
          text: {
            type: "plain_text",
            text: `Shipping Out for ${project.projectName} - ${itemDescriptions} 🚚 ➡️`,
            emoji: true,
          },
        },
        { type: "divider" },
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: `
*Shipment Number:* ${shipment.shipmentNumber}
*BOL Number:* ${shipment?.bol?.bolNumber || "N/A"} (<${
              pdfResult.location
            }|view here>)
*Pick Up Location:* ${shipment?.origin?.nickname || "N/A"}
*Drop Off Location:* ${shipment?.destination?.address || "N/A"}
            `,
          },
        },
        { type: "divider" },
        ...uploadedFileUrls.map((url, index) => ({
          type: "image",
          image_url: url,
          alt_text: `Shipment Image ${project.projectName} ${
            shipment.shipmentNumber
          } ${shipment.bol.bolNumber} ${index + 1}`,
          title: {
            type: "plain_text",
            text: `Shipment Image ${project.projectName} ${
              shipment.shipmentNumber
            } ${shipment.bol.bolNumber} ${index + 1}`,
          },
        })),
      ],
      attachments: [],
    };
    await sendSlackMessage(slackMessagePayload);

    // 6. ===== NEW: Send Email =====

    // Compute a comma-separated string of item descriptions.
    const descriptions = onTruckItems
      .map((item) => item.description)
      .join(", ");
    const unitText = onTruckItems.length > 1 ? "Units" : "Unit";
    const verb = onTruckItems.length > 1 ? "are" : "is";

    // Compose the subject line.
    const subjectLine =
      onTruckItems.length > 1
        ? `${unitText} ${descriptions} are on their way to you`
        : `${unitText} ${descriptions} is on its way to you`;

    // Retrieve the customer and determine the primary contact's first name.
    const customerData = customers.find((c) => c.id === project?.customerId);
    const customerPrimaryContact =
      (customerData &&
        customerData.contacts &&
        customerData.contacts.find((c) => c.primary)) ||
      (customerData && customerData.contacts && customerData.contacts[0]) ||
      {};
    const customerFirstName = project?.emailTo || "";

    // Compose the email body with two line breaks after the customer's first name
    // and a new line after the sentence-ending period.
    const emailBody = `${customerFirstName},<br/><br/>${unitText} <b>${descriptions}</b> under <b>PO# ${
      project?.purchaseOrder?.number
    }</b> ${verb} loaded on the truck and is headed to you.<br/><br/>Please find attached pictures of the loaded ${unitText.toLowerCase()} on the truck as well as the Bill of Lading for this shipment.<br/><br/>Sincerely,<br/><br/>Christina`;

    // Combine attachments: uploaded image URLs and the PDF URL (if available).
    const attachments = [...uploadedFileUrls];
    if (pdfResult?.location) {
      attachments.push(pdfResult.location);
    }

    // Send the email. Both sender and manager are set to MANAGER_EMAILS.LOGISTICS.
    const emailResponse = await sendEmail({
      subject: subjectLine,
      htmlBody: emailBody,
      emailAddresses: project?.distributionEmails || [],
      attachmentUrls: attachments,
      sender: MANAGER_EMAILS.LOGISTICS,
      manager: MANAGER_EMAILS.LOGISTICS,
    });

    console.log("Email response:", emailResponse);
    // ===== END NEW: Send Email =====

    // 7. Update shipment history.
    await pushShipmentHistory(`Shipped via ${values.carrierCompany}`);

    // 8. Optionally mark modules as shipped.
    if (project && shipment?.truckModules?.length > 0 && project.modules) {
      const updatedModules = project.modules.map((mod) => {
        if (shipment.truckModules.includes(mod.id)) {
          return {
            ...mod,
            status: "Shipped",
            production: {
              ...mod.production,
              status: "Shipped",
              history: [
                ...(mod.production?.history || []),
                {
                  action: "Shipped",
                  date: new Date().toISOString(),
                  submittedBy: userEmail,
                },
              ],
            },
          };
        }
        return mod;
      });
      const updatedProject = { ...project, modules: updatedModules };
      await updateById(TABLES.PROJECTS, updatedProject, project.id);
    }

    message.success("BOL submitted successfully!");

    // Cleanup and navigate.
    form.resetFields();
    setCarrierSignature("");
    setShipperSignature("");
    setFileList([]);
    navigate("/shipping/home");
  } catch (error) {
    console.error("BOL submission error:", error);
    message.error(
      `Failed to submit BOL: ${error.response?.data?.message || error.message}`
    );
    if (newTab) newTab.close();
  } finally {
    setSubmitting(false);
  }
};


  if (shipmentsLoading) return <Loader absolute loading />;
  if (!shipment) return <p>No shipment data found.</p>;

  return (
    <>
      <div style={{ margin: "0 auto", maxWidth: "1000px" }}>
        {/* Display Shipment Info using ShipmentDetailsCard */}
        <Card style={{ marginBottom: 16 }}>
          <ShipmentDetailsCard shipment={shipment} />
        </Card>

        <Form
          layout="vertical"
          form={form}
          onFinish={handleFinish}
          initialValues={{
            carrierName: "",
            carrierCompany: "",
            carrierPhone: "",
          }}
        >
          {/* Carrier Name */}
          <Form.Item
            label={<span className="mobile-label">Name (First and Last)</span>}
            name="carrierName"
            rules={[{ required: true, message: "Please enter carrier name" }]}
          >
            <Input className="mobile-input" placeholder="e.g. John Doe" />
          </Form.Item>

          {/* Carrier Company */}
          <Form.Item
            label={<span className="mobile-label">Company Name</span>}
            name="carrierCompany"
            rules={[
              { required: true, message: "Please enter carrier company" },
            ]}
          >
            <Input className="mobile-input" placeholder="e.g. ACME Logistics" />
          </Form.Item>

          {/* Carrier Phone */}
          <Form.Item
            label={<span className="mobile-label">Phone Number</span>}
            name="carrierPhone"
            rules={[
              { required: true, message: "Please enter carrier phone" },
              { message: "Please enter a valid phone number" },
            ]}
          >
            <MaskedInput
              inputMode="numeric"
              placeholder={"e.g. 888-867-5309"}
              mask={"000-000-0000"}
              className="mobile-input"
            />
          </Form.Item>

          {/* Carrier Signature */}
          <Form.Item
            label={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "1000px",
                }}
              >
                <div className="mobile-label">Carrier Signature</div>
                <Button
                  icon={<CloseOutlined />}
                  danger
                  onClick={handleCarrierClear}
                  disabled={!carrierSignature}
                >
                  Clear Signature
                </Button>
              </div>
            }
            name="carrierSignature"
            rules={[{ validator: validateCarrierSignature }]}
            required
          >
            <Signature
              ref={carrierSignatureRef}
              touchOnly={true}
              onSave={debouncedCarrierSave}
              onClear={handleCarrierClearLocal}
              loading={false}
            />
          </Form.Item>

          {/* Shipper Signature */}
          {!ariaLoading && !ariaError && (
            <>
              {foundUser && foundUser.signature ? (
                <Form.Item hidden label="Shipper Signature">
                  <img
                    src={foundUser.signature}
                    alt={`${foundUser.name || "Shipper"}'s Signature`}
                    style={{ width: "100%", maxWidth: "300px" }}
                    loading="lazy"
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "1000px",
                      }}
                    >
                      <div className="mobile-label">Shipper Signature</div>
                      <Button
                        icon={<CloseOutlined />}
                        danger
                        onClick={handleShipperClear}
                        disabled={!shipperSignature}
                      >
                        Clear Signature
                      </Button>
                    </div>
                  }
                  name="shipperSignature"
                  rules={[{ validator: validateShipperSignature }]}
                  required
                >
                  <Signature
                    ref={shipperSignatureRef}
                    touchOnly={true}
                    onSave={debouncedShipperSave}
                    onClear={handleShipperClearLocal}
                    loading={false}
                  />
                </Form.Item>
              )}
            </>
          )}

          {/* Customized Image Upload */}
          <Form.Item
            label={
              <div className="mobile-label">
                <span>Upload Images of Shipment</span>
              </div>
            }
            name="shipmentImages"
            rules={[
              {
                required: true,
                message: "Please upload at least one file.",
                validator: () => {
                  if (fileList.length >= 1) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Please upload at least one file.")
                  );
                },
              },
            ]}
            required
          >
            <Upload
              size="large"
              className="custom-upload"
              listType="picture-card"
              fileList={fileList}
              onChange={handleUploadChange}
              onRemove={handleRemove}
              beforeUpload={() => false}
              accept=".jpg,.jpeg,.png,.gif,.mp4,.mov,.heic,.heif"
              showUploadList={{
                showPreviewIcon: false,
                showRemoveIcon: true,
                removeIcon: (
                  <span style={{ color: "red" }}>
                    <CloseOutlined />
                  </span>
                ),
              }}
            >
              {fileList.length < 5 && (
                <div>
                  <UploadOutlined />
                  <div style={{ marginTop: 8 }}>+ Upload</div>
                </div>
              )}
            </Upload>
            {fileList.length >= 5 && (
              <div style={{ color: "red", marginTop: 8 }}>
                Maximum of 5 files can be uploaded.
              </div>
            )}
          </Form.Item>

          {/* Submit Button */}
          <Form.Item>
            <Button
              icon={<SaveOutlined />}
              type="primary"
              htmlType="submit"
              block
              loading={submitting}
              style={{ fontSize: "16px", padding: 16 }}
              disabled={submitting || !form.isFieldsTouched()}
            >
              Save BOL
            </Button>
          </Form.Item>
        </Form>
      </div>

      <Loader absolute loading={submitting} />
    </>
  );
}

export default BOLSignature;
