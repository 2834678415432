import React, { useState, useMemo, useEffect } from "react";
import {
  Modal,
  Form,
  Select,
  Button,
  Typography,
  Row,
  Col,
  Input,
  message,
} from "antd";
import {
  WarningOutlined,
  SortAscendingOutlined,
  FilterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useProjects } from "../../Contexts/useSpecificData";
import { updateById } from "../../Functions/updateById";
import {
  TABLES,
  PRECON_STATUS_FLOW,
  COLORS,
  PRODUCT_TYPES,
} from "../../constants";
import HeaderText from "../../Styled/HeaderText";
import PreconCard from "./PreconCard";
import { useUser } from "@clerk/clerk-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faPencil,
  faMagnifyingGlass,
  faClipboardList,
  faTag,
  faHandHoldingHand,
  faCheck,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { formatMoney } from "../../Formatters/helpers";
import { useMediaQueryContext } from "../../Contexts/MediaQueryContext";

const { Text } = Typography;
const { Option } = Select;

const statusIconMapping = {
  "Issued for Preconstruction": faDownload,
  Drawing: faPencil,
  "Client Review": faMagnifyingGlass,
  BOM: faClipboardList,
  Materials: faTag,
  Handoff: faHandHoldingHand,
  "Issued for Production": faCheck,
};

const getIconForStatus = (status) =>
  statusIconMapping[status] || faClipboardList;

const ACTIVE_STATUSES = [
  "Client Delay",
  "Drawing",
  "Client Review",
  "BOM",
  "Materials",
  "Handoff",
];
const STAGE_GROUPS = [
  "ACTIVE",
  "ISSUED FOR PRECONSTRUCTION",
  "ISSUED FOR PRODUCTION",
];

function Preconstruction({ viewOnly = false }) {
  useEffect(() => {
    document.title = "Preconstruction";
  }, []);

  const { data: projects, refresh: refreshProjects } = useProjects();
  const { isMobile, isDesktop } = useMediaQueryContext();

  // Client Delay modal states
  const [isClientDelayModalVisible, setIsClientDelayModalVisible] =
    useState(false);
  const [clientDelayForm] = Form.useForm();
  const [clientDelayLoading, setClientDelayLoading] = useState(false);

  // Sorting – default is alphabetical (A‑Z)
  const [order, setOrder] = useState("alphabetical");

  // Stage filter – default selection includes ACTIVE and ISSUED FOR PRECONSTRUCTION
  const defaultSelectedStageGroup = useMemo(
    () => ["ACTIVE", "ISSUED FOR PRECONSTRUCTION"],
    []
  );
  const [filterStatuses, setFilterStatuses] = useState(
    defaultSelectedStageGroup
  );

  // Multi‑Select for Product Types remains unchanged.
  const ALL_PRODUCT_TYPES = PRODUCT_TYPES;
  const [filterProductTypes, setFilterProductTypes] =
    useState(ALL_PRODUCT_TYPES);

  // Search input state
  const [searchQuery, setSearchQuery] = useState("");

  // Clerk user
  const { user, isLoaded: isUserLoaded } = useUser();
  const userEmail =
    isUserLoaded && user && user.primaryEmailAddress
      ? user.primaryEmailAddress.emailAddress
      : "Unknown";

  // Determine projects eligible for client delay.
  const eligibleProjects = useMemo(() => {
    if (!projects) return [];
    return projects.filter(
      (p) =>
        p.preconstruction &&
        !p.preconstruction.clientDelay &&
        !p.preconstruction.actualHandoffComplete
    );
  }, [projects]);

  /**
   * Compute the sorted and filtered projects.
   * Projects are filtered as follows:
   *
   * 1. Filter out any project where preconstruction.noPrecon === true.
   * 2. Filter by stage group.
   * 3. Filter by product types (include projects with no productType).
   * 4. Filter by the search query (on projectName).
   * Finally, sort the projects alphabetically.
   */
  const sortedAndFilteredProjects = useMemo(() => {
    if (!projects) return [];
    if (filterStatuses.length === 0) return [];

    let filtered = [...projects];

    // Filter out projects with noPrecon === true
    filtered = filtered.filter((proj) => !proj.noPrecon);

    // Filter by stage group.
    filtered = filtered.filter((proj) => {
      let group = "";
      if (proj.preconstruction?.clientDelay === true) {
        group = "ACTIVE";
      } else {
        // Treat missing status as "Issued for Preconstruction"
        const status =
          proj.preconstruction?.status || "Issued for Preconstruction";
        if (ACTIVE_STATUSES.includes(status)) {
          group = "ACTIVE";
        } else if (status === "Issued for Preconstruction") {
          group = "ISSUED FOR PRECONSTRUCTION";
        } else if (status === "Issued for Production") {
          // *** NEW CHECK: Only assign "ISSUED FOR PRODUCTION" if no module has production
          if (
            proj.modules &&
            proj.modules.some((module) => module.production)
          ) {
            return false; // Exclude this project from the Issued for Production group
          }
          group = "ISSUED FOR PRODUCTION";
        } else {
          group = "ACTIVE"; // fallback
        }
      }
      return filterStatuses.includes(group);
    });

    // Filter by product types (include projects with no productType).
    if (filterProductTypes.length > 0) {
      filtered = filtered.filter((proj) => {
        const projType = proj.preconstruction?.productType;
        if (!projType) return true;
        return filterProductTypes.includes(projType);
      });
    } else {
      filtered = [];
    }

    // Filter by search query.
    if (searchQuery.trim()) {
      const q = searchQuery.toLowerCase().trim();
      filtered = filtered.filter(
        (p) => p.projectName && p.projectName.toLowerCase().includes(q)
      );
    }

    // Sort alphabetically by projectName.
    if (order === "alphabetical") {
      filtered.sort((a, b) => {
        const nameA = a.projectName ? a.projectName.toLowerCase() : "";
        const nameB = b.projectName ? b.projectName.toLowerCase() : "";
        return nameA.localeCompare(nameB);
      });
    }

    return filtered;
  }, [projects, filterStatuses, filterProductTypes, searchQuery, order]);

  const statistics = useMemo(() => {
    const totalProjects = sortedAndFilteredProjects.length;
    const totalAmount = sortedAndFilteredProjects.reduce(
      (acc, p) => acc + (p.preconstruction?.amount || 0),
      0
    );
    return { totalProjects, totalAmount };
  }, [sortedAndFilteredProjects]);

  // Client Delay handling
  const handleClientDelaySubmit = async (values) => {
    const selectedIds = values.projects || [];
    if (selectedIds.length === 0) {
      message.warning("Please select at least one project to delay.");
      return;
    }
    setClientDelayLoading(true);
    try {
      const promises = selectedIds.map((id) => {
        const proj = projects.find((p) => p.id === id);
        if (!proj) return Promise.resolve();
        const updatedPrecon = {
          ...(proj.preconstruction || {}),
          clientDelay: true,
          history: [
            ...(proj.preconstruction?.history || []),
            {
              action: "Client Delay",
              submittedBy: userEmail,
              date: new Date().toISOString(),
            },
          ],
        };
        return updateById(
          TABLES.PROJECTS,
          { ...proj, preconstruction: updatedPrecon },
          id
        );
      });
      await Promise.all(promises);
      message.success("Selected projects have been marked as delayed.");
      setIsClientDelayModalVisible(false);
      clientDelayForm.resetFields();
      refreshProjects();
    } catch (err) {
      console.error(err);
      message.error("Failed to mark selected projects as delayed.");
    } finally {
      setClientDelayLoading(false);
      refreshProjects();
    }
  };

  const removeClientDelay = async (project) => {
    Modal.confirm({
      title: "Remove Client Delay",
      icon: <WarningOutlined />,
      content:
        "Are you sure you want to remove the client delay from this project?",
      onOk: async () => {
        try {
          const updatedPrecon = {
            ...(project.preconstruction || {}),
            clientDelay: false,
            history: [
              ...(project.preconstruction?.history || []),
              {
                action: "Client Delay Removed",
                submittedBy: userEmail,
                date: new Date().toISOString(),
              },
            ],
          };
          await updateById(
            TABLES.PROJECTS,
            { ...project, preconstruction: updatedPrecon },
            project.id
          );
          message.success("Client delay removed successfully.");
          refreshProjects();
        } catch (err) {
          console.error(err);
          message.error("Failed to remove client delay.");
        }
      },
    });
  };

  // Helpers for "Select All / Deselect All" in the Stage dropdown
  const handleSelectAllStatuses = () => {
    setFilterStatuses(STAGE_GROUPS);
  };
  const handleDeselectAllStatuses = () => {
    setFilterStatuses([]);
  };

  // Helpers for "Select All / Deselect All" in the Product Types dropdown
  const handleSelectAllProductTypes = () => {
    setFilterProductTypes(ALL_PRODUCT_TYPES);
  };
  const handleDeselectAllProductTypes = () => {
    setFilterProductTypes([]);
  };

  return (
    <div style={{ width: "100%" }}>
      {/* Statistics Row */}
      <Row gutter={isDesktop ? [16, 16] : [8, 8]} style={{ marginBottom: 8 }}>
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          style={{ textAlign: isDesktop ? "left" : "center" }}
        >
          <HeaderText text="Preconstruction" />
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} style={{ textAlign: "center" }}>
          <Text style={{ opacity: 0.7 }}>Total Projects:</Text>{" "}
          <Text strong style={{ fontSize: 18 }}>
            {statistics.totalProjects}
          </Text>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} style={{ textAlign: "center" }}>
          <Text style={{ opacity: 0.7 }}>Total Amount:</Text>{" "}
          <Text
            strong
            style={{
              fontSize: 18,
              color: statistics.totalAmount > 0 ? "#3f8600" : "black",
            }}
          >
            {formatMoney(statistics.totalAmount)}
          </Text>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          style={{
            textAlign: isDesktop ? "right" : "center",
            marginTop: isDesktop ? 0 : 8,
          }}
        >
          {!viewOnly && (
            <Button
              danger
              icon={<WarningOutlined />}
              onClick={() => setIsClientDelayModalVisible(true)}
              disabled={eligibleProjects.length === 0}
              style={{ marginTop: isDesktop ? 0 : 8 }}
            >
              Client Delay
            </Button>
          )}
        </Col>
      </Row>

      {/* Filters & Sorting */}
      <div
        style={{
          position: isDesktop ? "sticky" : "static",
          top: 16,
          zIndex: 6,
          padding: "8px 0",
          borderBottom: "1px solid #f0f0f0",
          backgroundColor: "white",
          marginBottom: 8,
          borderRadius: 8,
        }}
      >
        <Row
          gutter={[16, 8]}
          style={{ padding: "0 12px", alignItems: "flex-end" }}
        >
          <Col xs={24} sm={24} md={6} lg={6}>
            <Text style={{ fontSize: 12 }}>Search</Text>
            <Input
              placeholder="Search by Project Name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              allowClear
              prefix={<SearchOutlined />}
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <Text style={{ fontSize: 12 }}>Sort</Text>
            <Select
              value={order}
              onChange={(val) => setOrder(val)}
              style={{ width: "100%" }}
              placeholder="Order Projects"
              suffixIcon={<SortAscendingOutlined />}
            >
              <Option value="alphabetical">Alphabetical (A-Z)</Option>
            </Select>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <Text style={{ fontSize: 12 }}>Filter Stage</Text>
            <Select
              maxTagCount="responsive"
              value={filterStatuses}
              onChange={(vals) => setFilterStatuses(vals)}
              style={{ width: "100%" }}
              dropdownStyle={{ overflowY: "auto" }}
              placeholder="Filter by Stage Group(s)"
              listHeight={320}
              suffixIcon={<FilterOutlined />}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children?.toLowerCase?.().includes(input.toLowerCase())
              }
            >
              {STAGE_GROUPS.map((group) => (
                <Option key={group} value={group}>
                  <FontAwesomeIcon
                    icon={
                      group === "ACTIVE"
                        ? faPencil
                        : group === "ISSUED FOR PRECONSTRUCTION"
                        ? faDownload
                        : faCheck
                    }
                    style={{ marginRight: 8, color: COLORS.PRIMARY }}
                  />
                  {group}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6}>
            <Text style={{ fontSize: 12 }}>Filter Product Types</Text>
            <Select
              mode="multiple"
              maxTagCount="responsive"
              value={filterProductTypes}
              onChange={(vals) => setFilterProductTypes(vals)}
              style={{ width: "100%" }}
              dropdownStyle={{ overflowY: "auto" }}
              placeholder="Filter by Product Type(s)"
              listHeight={320}
              suffixIcon={<FilterOutlined />}
              showSearch={isDesktop}
              optionFilterProp="children"
              dropdownRender={(menu) => (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "4px 8px",
                    }}
                  >
                    <a onClick={handleSelectAllProductTypes}>Select All</a>
                    <a onClick={handleDeselectAllProductTypes}>Deselect All</a>
                  </div>
                  {menu}
                </div>
              )}
              filterOption={(input, option) =>
                option?.children?.toLowerCase?.().includes(input.toLowerCase())
              }
            >
              {ALL_PRODUCT_TYPES.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>

      {/* Display All Filtered Projects */}
      {sortedAndFilteredProjects.map((project) => (
        <PreconCard
          key={project.id}
          project={project}
          viewOnly={viewOnly}
          onClientDelayRemove={removeClientDelay}
        />
      ))}

      <Modal
        visible={isClientDelayModalVisible}
        title="Mark Projects as Delayed"
        onCancel={() => setIsClientDelayModalVisible(false)}
        onOk={() => clientDelayForm.submit()}
        okText="Mark as Delayed"
        cancelText="Cancel"
        confirmLoading={clientDelayLoading}
      >
        <Form
          form={clientDelayForm}
          layout="vertical"
          onFinish={handleClientDelaySubmit}
        >
          <Form.Item
            name="projects"
            label="Select Projects to Delay"
            rules={[
              {
                required: true,
                message: "Please select at least one project to delay.",
              },
            ]}
          >
            <Select
              mode="multiple"
              showSearch={isDesktop}
              filterOption={(input, option) =>
                (option.children || "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              dropdownStyle={{ maxHeight: 240, overflowY: "auto" }}
              placeholder="Projects to mark as delayed"
            >
              {[...eligibleProjects]
                .sort((a, b) => a.projectName.localeCompare(b.projectName))
                .map((proj) => (
                  <Option key={proj.id} value={proj.id}>
                    {proj.projectName}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Preconstruction;
