import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
  Modal,
  Form,
  Select,
  Button,
  Typography,
  Row,
  Col,
  Spin,
  Input,
  message,
  Empty,
} from "antd";
import {
  WarningOutlined,
  SortAscendingOutlined,
  FilterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { updateById } from "../../Functions/updateById";
import {
  TABLES,
  PRODUCTION_STATUS_FLOW,
  COLORS,
  PRODUCT_TYPES,
} from "../../constants";
import HeaderText from "../../Styled/HeaderText";
import ProductionCard from "../../Components/Production/ProductionCard";
import { useUser } from "@clerk/clerk-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faClipboardList,
  faHandHoldingHand,
  faTag,
  faCheck,
  faDownload,
  faQuestionCircle,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import PriceTag from "../../Styled/PriceTag";
import { formatMoney } from "../../Formatters/helpers";
import { useMediaQueryContext } from "../../Contexts/MediaQueryContext";
import debounce from "lodash.debounce";
import useLastThreeMonthsProjects from "../../Contexts/useLastThreeMonthsProjects";

const { Text } = Typography;
const { Option } = Select;

function Production({ viewOnly = false }) {
  useEffect(() => {
    document.title = "Production";
  }, []);

  const navigate = useNavigate();
  const { isMobile, isDesktop } = useMediaQueryContext();
  const {
    data: projects,
    refresh: refreshProjects,
    isLoading: isProjectsLoading,
  } = useLastThreeMonthsProjects();

  // Track initial load for infinite scroll initialization
  const [initialLoad, setInitialLoad] = useState(false);

  // Only include projects with modules
  const eligibleProjects = useMemo(() => {
    if (!projects) return [];
    return projects.filter((p) => p.modules && p.modules.length > 0);
  }, [projects]);

  // Client Delay Modal State
  const [
    isProjectClientDelayModalVisible,
    setIsProjectClientDelayModalVisible,
  ] = useState(false);
  const [projectClientDelayForm] = Form.useForm();
  const [projectClientDelayLoading, setProjectClientDelayLoading] =
    useState(false);

  // Infinite Scroll state (not modified here)
  const [displayedGroups, setDisplayedGroups] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const ITEMS_PER_LOAD = 10;

  // Ordering
  const [order, setOrder] = useState("alphabetical");

  // --- New Stage Filter: Single Select ---
  // Options: ACTIVE, IN PRECONSTRUCTION, SHIPPED.
  // "ACTIVE" here means all modules whose status is NOT "IN PRECONSTRUCTION" or "SHIPPED".
  const [filterStatus, setFilterStatus] = useState("Active");

  // Define the stage filter options with capitalized labels and icons.
  const stageFilterOptions = [
    { value: "Active", label: "ACTIVE", icon: faPencil },
    {
      value: "In Preconstruction",
      label: "IN PRECONSTRUCTION",
      icon: faDownload,
    },
    { value: "Shipped", label: "SHIPPED", icon: faTruck },
  ];

  // --- Product Type Filter ---
  const ALL_PRODUCT_TYPES = useMemo(() => {
    if (!projects) return [];
    const typesSet = new Set();
    projects.forEach((project) => {
      (project.modules || []).forEach((module) => {
        if (module.productType && typeof module.productType === "string") {
          typesSet.add(module.productType);
        }
      });
    });
    return Array.from(typesSet).sort();
  }, [projects]);
  const [filterProductTypes, setFilterProductTypes] = useState(PRODUCT_TYPES);

  // --- Search (with debounce) ---
  const [searchInput, setSearchInput] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSetSearchQuery = useCallback(
    debounce((value) => setSearchQuery(value), 300),
    []
  );
  useEffect(() => {
    debouncedSetSearchQuery(searchInput);
    return () => debouncedSetSearchQuery.cancel();
  }, [searchInput, debouncedSetSearchQuery]);

  // Clerk user
  const { user, isLoaded: isUserLoaded } = useUser();
  const userEmail =
    isUserLoaded && user && user.primaryEmailAddress
      ? user.primaryEmailAddress.emailAddress
      : "Unknown";

  // --- Flatten and Filter Modules ---
  const flattenedModules = useMemo(() => {
    if (!projects) return [];
    let modules = projects.flatMap((project) =>
      (project.modules || []).map((module) => {
        let status = "";
        if (module.production && module.production.status) {
          status = module.production.status;
        } else {
          const preconStatusRaw = project.preconstruction?.status || "";
          const preconStatus = preconStatusRaw.trim().toLowerCase();
          const completedPreconStatuses = ["issued for production"];
          status = completedPreconStatuses.includes(preconStatus)
            ? "Issued for Production"
            : "In Preconstruction";
        }
        return {
          ...module,
          projectId: project.id,
          projectName: project.projectName,
          status,
          clientDelay: project.clientDelay,
        };
      })
    );

    // Filter by stage using our single-select filter.
    if (filterStatus === "Active") {
      modules = modules.filter(
        (m) => m.status !== "In Preconstruction" && m.status !== "Shipped"
      );
    } else if (filterStatus === "In Preconstruction") {
      modules = modules.filter((m) => m.status === "In Preconstruction");
    } else if (filterStatus === "Shipped") {
      modules = modules.filter((m) => m.status === "Shipped");
    }

    // Filter by product type
    if (filterProductTypes.length > 0) {
      modules = modules.filter((m) =>
        filterProductTypes.some(
          (ptype) => ptype.toLowerCase() === m.productType?.toLowerCase()
        )
      );
    } else {
      modules = [];
    }
    // Filter by search query (project name or module number)
    if (searchQuery.trim()) {
      const q = searchQuery.trim().toLowerCase();
      modules = modules.filter((m) => {
        const projectName = m.projectName || "";
        const moduleNumber = m.number || "";
        return (
          projectName.toLowerCase().includes(q) ||
          `${projectName}-${moduleNumber}`.toLowerCase().includes(q)
        );
      });
    }
    return modules;
  }, [projects, filterStatus, filterProductTypes, searchQuery]);

  // --- Sort Modules ---
  const sortedModules = useMemo(() => {
    if (!flattenedModules) return [];
    let modulesCopy = [...flattenedModules];
    switch (order) {
      case "alphabetical":
        modulesCopy.sort((a, b) =>
          (a.projectName || "").localeCompare(b.projectName || "")
        );
        break;
      case "most":
        modulesCopy.sort((a, b) => {
          const aIdx = PRODUCTION_STATUS_FLOW.indexOf(a.status);
          const bIdx = PRODUCTION_STATUS_FLOW.indexOf(b.status);
          return bIdx - aIdx;
        });
        break;
      case "least":
        modulesCopy.sort((a, b) => {
          const aIdx = PRODUCTION_STATUS_FLOW.indexOf(a.status);
          const bIdx = PRODUCTION_STATUS_FLOW.indexOf(b.status);
          return aIdx - bIdx;
        });
        break;
      default:
        break;
    }
    return modulesCopy;
  }, [flattenedModules, order]);

  // --- Group by Project Name ---
  const groupedModules = useMemo(() => {
    const groups = {};
    sortedModules.forEach((mod) => {
      const name = mod.projectName || "Unnamed Project";
      if (!groups[name]) groups[name] = [];
      groups[name].push(mod);
    });
    return groups;
  }, [sortedModules]);
  const groupNames = useMemo(
    () => Object.keys(groupedModules),
    [groupedModules]
  );

  // --- Statistics ---
  const statistics = useMemo(() => {
    const totalModules = sortedModules.length;
    const totalAmount = sortedModules.reduce(
      (acc, mod) => acc + (mod.amount || 0),
      0
    );
    const totalProjects = new Set(sortedModules.map((mod) => mod.projectId))
      .size;
    return { totalModules, totalAmount, totalProjects };
  }, [sortedModules]);

  // --- Map projectId to project ---
  const projectIdMap = useMemo(() => {
    if (!projects) return {};
    const map = {};
    projects.forEach((p) => {
      map[p.id] = p;
    });
    return map;
  }, [projects]);

  // --- Initialize Infinite Scroll ---
  useEffect(() => {
    if (!isProjectsLoading && projects) {
      setDisplayedGroups(groupNames);
      setHasMore(groupNames.length > ITEMS_PER_LOAD);
    }
  }, [groupNames, isProjectsLoading, projects]);

  // --- Client Delay Handlers ---
  const handleProjectClientDelaySubmit = async (vals) => {
    const selectedProjectIds = vals.projects || [];
    if (selectedProjectIds.length === 0) {
      message.warning("Please select at least one project to mark as delayed.");
      return;
    }
    setProjectClientDelayLoading(true);
    try {
      const promises = selectedProjectIds.map((projectId) => {
        const project = projects.find((p) => p.id === projectId);
        if (!project || !project.modules) return Promise.resolve();
        const updatedModules = project.modules.map((mod) => ({
          ...mod,
          production: {
            ...mod.production,
            history: [
              ...(mod.production?.history || []),
              {
                action: "Client Delay",
                date: new Date().toISOString(),
                submittedBy: userEmail,
              },
            ],
          },
        }));
        const updatedProject = {
          ...project,
          clientDelay: true,
          modules: updatedModules,
        };
        return updateById(TABLES.PROJECTS, updatedProject, project.id);
      });
      await Promise.all(promises);
      message.success("Selected projects have been marked as delayed.");
      setIsProjectClientDelayModalVisible(false);
      projectClientDelayForm.resetFields();
      refreshProjects();
    } catch (err) {
      console.error(err);
      message.error("Failed to mark selected projects as delayed.");
    } finally {
      setProjectClientDelayLoading(false);
    }
  };

  const removeProjectClientDelay = async (project) => {
    Modal.confirm({
      title: "Remove Client Delay",
      icon: <WarningOutlined />,
      content:
        "Are you sure you want to remove the client delay from this project?",
      onOk: async () => {
        try {
          if (!project.modules) throw new Error("Project has no modules.");
          const updatedModules = project.modules.map((mod) => ({
            ...mod,
            production: {
              ...mod.production,
              history: [
                ...(mod.production?.history || []),
                {
                  action: "Client Delay Removed",
                  date: new Date().toISOString(),
                  submittedBy: userEmail,
                },
              ],
            },
          }));
          const updatedProject = {
            ...project,
            clientDelay: false,
            modules: updatedModules,
          };
          await updateById(TABLES.PROJECTS, updatedProject, project.id);
          message.success("Client delay removed successfully.");
          refreshProjects();
        } catch (err) {
          console.error(err);
          message.error("Failed to remove client delay.");
        }
      },
    });
  };

  // --- Project Selection Handlers ---
  const handleSelectAllProjects = () => {
    const allProjectIds = eligibleProjects.map((p) => p.id);
    projectClientDelayForm.setFieldsValue({ projects: allProjectIds });
  };
  const handleDeselectAllProjects = () => {
    projectClientDelayForm.setFieldsValue({ projects: [] });
  };

  // --- Helpers for Product Types ---
  const handleSelectAllProductTypes = () => {
    setFilterProductTypes(ALL_PRODUCT_TYPES);
  };
  const handleDeselectAllProductTypes = () => {
    setFilterProductTypes([]);
  };

  // --- New state for project modal that shows all modules ---
  const [selectedProject, setSelectedProject] = useState(null);
  const [isProjectModalVisible, setIsProjectModalVisible] = useState(false);
  const handleProjectNameClick = (project) => {
    setSelectedProject(project);
    setIsProjectModalVisible(true);
  };

  const isInitialLoading = isProjectsLoading || !projects;

  return (
    <div style={{ width: "100%" }}>
      {isInitialLoading ? (
        <div style={{ textAlign: "center", padding: "50px" }}>
          <Spin tip="Loading projects..." />
        </div>
      ) : (
        <>
          {/* STATISTICS ROW */}
          {isDesktop ? (
            <Row
              gutter={[16, 8]}
              style={{
                borderRadius: 8,
                marginBottom: 8,
                backgroundColor: "#fafafa",
                alignItems: "center",
                padding: "8px 16px",
              }}
            >
              <Col xs={24} sm={24} md={6} lg={6} style={{ textAlign: "left" }}>
                <HeaderText text="Production" />
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <span style={{ marginRight: 8, opacity: 0.7 }}>
                  Total Projects:
                </span>
                <span style={{ fontSize: 18 }}>{statistics.totalProjects}</span>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <span style={{ marginRight: 8, opacity: 0.7 }}>
                  Total Modules:
                </span>
                <span style={{ fontSize: 18 }}>{statistics.totalModules}</span>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4}>
                <span style={{ marginRight: 8, opacity: 0.7 }}>
                  Total Amount:
                </span>
                <span
                  style={{
                    fontSize: 18,
                    color: statistics.totalAmount > 0 ? "#3f8600" : "black",
                  }}
                >
                  {formatMoney(statistics.totalAmount)}
                </span>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} style={{ textAlign: "right" }}>
                {!viewOnly && (
                  <Button
                    danger
                    icon={<WarningOutlined />}
                    onClick={() => setIsProjectClientDelayModalVisible(true)}
                    disabled={eligibleProjects.length === 0}
                  >
                    Client Delay
                  </Button>
                )}
              </Col>
            </Row>
          ) : (
            <>
              <Row
                gutter={[16, 8]}
                style={{
                  borderRadius: 8,
                  marginBottom: 8,
                  backgroundColor: "#fafafa",
                  alignItems: "center",
                  padding: "8px 16px",
                }}
              >
                <Col span={24} style={{ textAlign: "center" }}>
                  <HeaderText text="Production" />
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  <Text style={{ opacity: 0.7 }}>
                    Projects: {statistics.totalProjects}
                  </Text>
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  <Text style={{ opacity: 0.7 }}>
                    Modules: {statistics.totalModules}
                  </Text>
                </Col>
                <Col span={8} style={{ textAlign: "center" }}>
                  <Text
                    style={{
                      opacity: 0.7,
                      color: statistics.totalAmount > 0 ? "#3f8600" : "black",
                    }}
                  >
                    {formatMoney(statistics.totalAmount)}
                  </Text>
                </Col>
              </Row>
              <Row
                gutter={[16, 8]}
                style={{ marginBottom: 8, padding: "0 16px" }}
              >
                <Col span={24} style={{ textAlign: "center" }}>
                  {!viewOnly && (
                    <Button
                      danger
                      icon={<WarningOutlined />}
                      onClick={() => setIsProjectClientDelayModalVisible(true)}
                      disabled={eligibleProjects.length === 0}
                      block
                    >
                      Client Delay
                    </Button>
                  )}
                </Col>
              </Row>
            </>
          )}

          {/* ORDERING AND FILTERING CONTROLS */}
          <div
            style={{
              position: isDesktop ? "sticky" : "static",
              top: 16,
              zIndex: 6,
              padding: "8px 0",
              borderBottom: "1px solid #f0f0f0",
              backgroundColor: "white",
              marginBottom: 8,
              borderRadius: 8,
            }}
          >
            <Row
              gutter={[16, 8]}
              style={{ padding: "0 12px", alignItems: "flex-end" }}
            >
              {/* Search Input */}
              <Col xs={24} sm={24} md={6} lg={6}>
                <Text
                  style={{ fontSize: 12, display: "block", marginBottom: 4 }}
                >
                  Search
                </Text>
                <Input
                  placeholder="Search by Project or Module Name"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  allowClear
                  prefix={<SearchOutlined />}
                />
              </Col>
              {/* Ordering */}
              <Col xs={24} sm={24} md={6} lg={6}>
                <Text
                  style={{ fontSize: 12, display: "block", marginBottom: 4 }}
                >
                  Sort
                </Text>
                <Select
                  value={order}
                  onChange={setOrder}
                  style={{ width: "100%" }}
                  placeholder="Order Modules"
                  suffixIcon={<SortAscendingOutlined />}
                >
                  <Option value="alphabetical">Alphabetical (A–Z)</Option>
                  <Option value="most">Most Progressed</Option>
                  <Option value="least">Least Progressed</Option>
                </Select>
              </Col>
              {/* Single-Select Stage Filter with Icons */}
              <Col xs={24} sm={24} md={6} lg={6}>
                <Text
                  style={{ fontSize: 12, display: "block", marginBottom: 4 }}
                >
                  Filter Stage
                </Text>
                <Select
                  value={filterStatus}
                  onChange={(val) => setFilterStatus(val)}
                  style={{ width: "100%" }}
                  placeholder="Select Stage"
                  showSearch={false}
                >
                  {stageFilterOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      <FontAwesomeIcon
                        icon={option.icon}
                        style={{ marginRight: 8, color: COLORS.PRIMARY }}
                      />
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Col>
              {/* Multi‑Select Product Type Filter */}
              <Col xs={24} sm={24} md={6} lg={6}>
                <Text
                  style={{ fontSize: 12, display: "block", marginBottom: 4 }}
                >
                  Filter Product Types
                </Text>
                <Select
                  mode="multiple"
                  value={filterProductTypes}
                  maxTagCount="responsive"
                  onChange={(vals) => setFilterProductTypes(vals)}
                  style={{ width: "100%" }}
                  dropdownStyle={{ overflowY: "auto" }}
                  placeholder="Filter by Product Type(s)"
                  listHeight={320}
                  suffixIcon={<FilterOutlined />}
                  showSearch={isDesktop}
                  optionFilterProp="children"
                  dropdownRender={(menu) => (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "4px 8px",
                        }}
                      >
                        <a onClick={handleSelectAllProductTypes}>Select All</a>
                        <a onClick={handleDeselectAllProductTypes}>
                          Deselect All
                        </a>
                      </div>
                      {menu}
                    </div>
                  )}
                  filterOption={(input, option) =>
                    option?.children
                      ?.toLowerCase?.()
                      .includes(input.toLowerCase())
                  }
                  disabled={ALL_PRODUCT_TYPES.length === 0}
                >
                  {ALL_PRODUCT_TYPES.length > 0 ? (
                    ALL_PRODUCT_TYPES.map((type) => (
                      <Option key={type} value={type}>
                        {type}
                      </Option>
                    ))
                  ) : (
                    <Option disabled>No Product Types Available</Option>
                  )}
                </Select>
              </Col>
            </Row>
          </div>

          {/* GROUPED PROJECTS / INFINITE SCROLL */}
          {displayedGroups.length === 0 ? (
            isProjectsLoading ? (
              <div style={{ textAlign: "center", padding: "20px" }}>
                <Spin tip="Loading projects..." />
              </div>
            ) : (
              <div style={{ textAlign: "center", padding: "20px" }}>
                <Empty description="No modules found." />
              </div>
            )
          ) : (
            displayedGroups.map((projectName) => {
              const modulesInGroup = groupedModules[projectName];
              if (!modulesInGroup || modulesInGroup.length === 0) return null;
              const firstModule = modulesInGroup[0];
              const proj = projectIdMap[firstModule.projectId];
              if (!proj) {
                console.warn(
                  `Project not found for ID: ${firstModule.projectId}`
                );
                return null;
              }
              const isProjectDelayed = proj.clientDelay === true;
              const rowBg = isProjectDelayed
                ? "rgba(255, 0, 0, 0.05)"
                : "white";
              const totalModuleCount = proj.modules?.length || 0;
              const totalAmount =
                proj.modules?.reduce(
                  (acc, mod) => acc + (mod.amount || 0),
                  0
                ) || 0;
              const sortedModulesInProject = [...modulesInGroup].sort(
                (a, b) => parseInt(a.number, 10) - parseInt(b.number, 10)
              );
              return (
                <div key={proj.id}>
                  <Row
                    className="box-shadow"
                    style={{
                      borderRadius: 8,
                      backgroundColor: rowBg,
                      marginBottom: 12,
                    }}
                  >
                    <div
                      style={{
                        padding: "8px 16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        borderBottom: "1px solid #f0f0f0",
                      }}
                    >
                      <div>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(
                              `/production/projects/${proj.id}/purchase-and-change-orders`
                            )
                          }
                        >
                          <HeaderText large text={projectName} />
                        </span>
                      </div>
                      <div>
                        <strong>Modules:</strong> {totalModuleCount}{" "}
                        &nbsp;|&nbsp;
                        <strong>Amount:</strong>
                        <PriceTag
                          amount={totalAmount}
                          style={{ marginLeft: 4 }}
                        />
                      </div>
                    </div>
                    {sortedModulesInProject.map((module) => (
                      <Col key={module.id} span={24}>
                        <ProductionCard
                          project={proj}
                          module={module}
                          viewOnly={viewOnly}
                          onClientDelayRemove={() =>
                            removeProjectClientDelay(proj)
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              );
            })
          )}

          {/* CLIENT DELAY MODAL */}
          <Modal
            visible={isProjectClientDelayModalVisible}
            title="Mark Projects as Delayed"
            onCancel={() => setIsProjectClientDelayModalVisible(false)}
            onOk={() => projectClientDelayForm.submit()}
            okText="Mark as Delayed"
            cancelText="Cancel"
            confirmLoading={projectClientDelayLoading}
          >
            <Form
              form={projectClientDelayForm}
              layout="vertical"
              onFinish={handleProjectClientDelaySubmit}
            >
              <Form.Item
                name="projects"
                label="Select Projects to Delay"
                rules={[
                  {
                    required: true,
                    message: "Please select at least one project to delay.",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch={isDesktop}
                  placeholder="Projects to mark as delayed"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option.children || "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  dropdownStyle={{ maxHeight: 240, overflowY: "auto" }}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                    </div>
                  )}
                >
                  {[...eligibleProjects]
                    .sort((a, b) => a.projectName.localeCompare(b.projectName))
                    .map((proj) => (
                      <Option key={proj.id} value={proj.id}>
                        {proj.projectName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Form>
          </Modal>

          {/* PROJECT MODAL: SHOWS ALL MODULES OF THE SELECTED PROJECT */}
          <Modal
            width={isDesktop ? "95%" : "98%"}
            visible={isProjectModalVisible}
            title={selectedProject ? selectedProject.projectName : ""}
            onCancel={() => {
              setIsProjectModalVisible(false);
              setSelectedProject(null);
            }}
            footer={null}
          >
            {selectedProject &&
              selectedProject.modules &&
              selectedProject.modules.map((mod) => (
                <div key={mod.id} style={{ marginBottom: 0 }}>
                  <ProductionCard
                    viewOnly
                    module={mod}
                    project={selectedProject}
                  />
                </div>
              ))}
          </Modal>
        </>
      )}
    </div>
  );
}

export default Production;
