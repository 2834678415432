// src/components/BuildProject.jsx

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Row, Tabs, Card, Space, Typography, Spin, Tag } from "antd";
import { EditOutlined, InfoCircleOutlined, EyeOutlined } from "@ant-design/icons";
import {
  useProjects,
  useManufacturingLocations,
  useCustomers,
} from "../../Contexts/useSpecificData";
import { getCustomerCompanyName } from "../../utils/getCustomerCompanyName";
import { COLORS } from "../../constants";
import AddProjectModal from "./AddProjectModal";
import ProjectDeliveryDetails from "./ProjectDeliveryDetails"; // Newly created component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake, faUser } from "@fortawesome/free-regular-svg-icons";
import PurchaseAndChangeOrders from "./PurchaseAndChangeOrders"; // Ensure this component is correctly implemented
import ProjectModules from "./ProjectModules";
import { Loader } from "../../Styled/Loader";
import PreconCard from "../../Pages/projectmanagement/PreconCard";
import ShipLoose from "./ShipLoose";
import Progress from "./ProjectProgress";
import ProjectProgress from "./ProjectProgress";
import { useLocation } from "react-router-dom";


const { Title, Text } = Typography;

const BuildProject = ({viewOnly=false}) => {
  const { id, tab } = useParams(); // Retrieve project ID and tab from URL
  const navigate = useNavigate();
  const location = useLocation(); // Get current location

  


    const currentModule = location.pathname.split("/")[1]; // 'production' or 'project-management'
    const basePath = `/${currentModule}/projects`;

  const { data: projects, refresh: refreshProjects } = useProjects();
  const { data: manufacturingLocations } = useManufacturingLocations();
  const { data: customers } = useCustomers();

  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);

  // 1. Define tab items first to ensure they are available for useEffect
  const tabItems = [
    {
      key: "purchase-and-change-orders",
      label: "Purchase / Change Orders",
      children: <PurchaseAndChangeOrders viewOnly={viewOnly} project={project} />, // Ensure this component displays relevant content
    },
    {
      key: "progress",
      label: "Progress",
      children: <ProjectProgress project={project} viewOnly={viewOnly} />,
    },
    {
      key: "modules",
      label: "Modules",
      children: <ProjectModules project={project} viewOnly={viewOnly} />,
    },
    {
      key: "ship-loose",
      label: "Ship Loose",
      children: <ShipLoose project={project} viewOnly={viewOnly} />,
    },

    // {
    //   key: "transportation",
    //   label: "Transportation",
    //   children: (
    //     <ProjectDeliveryDetails
    //       project={project}
    //       originPlaceId={project?.manufacturingLocation?.placeId || null}
    //       destinationPlaceId={project?.placeId || null}
    //     />
    //   ),
    // },

    // Add more tabs here as needed
  ];

  // 2. Set currentTab based on 'tab' param, default to 'purchase-and-change-orders'
  const currentTab = tab || "purchase-and-change-orders";

  // 3. Handle project loading and setting
  useEffect(() => {
    if (projects === undefined) {
      // Projects are still loading
      setLoading(true);
      return;
    }

    if (projects === null) {
      // An error occurred while fetching projects or data is null
      // Keep loading indefinitely; do not show error.
      return;
    }

    const foundProject = projects.find((proj) => proj.id === id);
    if (foundProject) {
      setProject(foundProject);
      setLoading(false);
    } else {
      // If no project is found, do not update loading to false and do not show error
      // The spinner will remain indefinitely.
    }
  }, [projects, id]);

  // 4. Update document title based on project
  useEffect(() => {
    if (project) {
      const projectName = project.projectName || "Unnamed Project";
      document.title = `${projectName} - Project Details`;
    } else {
      document.title = "Build Project";
    }
  }, [project]);

  // 5. Redirect to default tab if 'tab' param is invalid
  useEffect(() => {
    const tabKeys = tabItems.map((tab) => tab.key); // Now tabItems is defined

    if (tab && !tabKeys.includes(tab)) {
      // Navigate to default tab
      navigate(
        `/${currentModule}/projects/${id}/purchase-and-change-orders`,
        { replace: true }
      );
    }
  }, [tab, id, navigate, tabItems]);

  const onUpdateSuccess = () => {
    refreshProjects();
    // Optionally, handle success messages within the UI
  };

  // 6. Handle tab change
  const handleTabChange = (activeKey) => {
    navigate(`/${currentModule}/projects/${id}/${activeKey}`);
  };


  // 7. Handle loading state
  if (loading) {
    return (
<Loader/>
    );
  }

  // Get Company Name
  const companyName =
    getCustomerCompanyName(project.customerId, customers) || "N/A";

  // Get Project Description
  const projectDescription =
    project.projectDescription || "No description provided.";

  return (
    <div>
      <Row gutter={[16, 16]}>
        {/* Header Section */}
        <Col span={24}>
          {/* Instead of always rendering AddProjectModal, conditionally wrap the Card */}
          {!viewOnly ? (
            <AddProjectModal
              title="Edit Project"
              project={project}
              onUpdateSuccess={onUpdateSuccess}
            >
              <Card
                style={{
                  backgroundColor: "white",
                  cursor: "pointer",
                  position: "relative",
                }}
                size="small"
              >
                <EditOutlined
                  style={{
                    fontSize: "18px",
                    color: "#08c",
                    position: "absolute",
                    top: 16,
                    right: 16,
                  }}
                />
                <Row gutter={16}>
                  <Col span={24}>
                    <Space
                      direction="horizontal"
                      size="large"
                      align="center"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <div style={{ fontSize: 18, fontWeight: 600 }}>
                          {project.projectName || "N/A"}
                        </div>
                      </div>
                      {project?.assignedTo && (
                      <div>
                        <FontAwesomeIcon
                          icon={faUser}
                          style={{ color: "#555", marginRight: 8 }}
                        />
                        <Text>{project?.assignedTo?.fullName}</Text>
                      </div>
                    )}
                      <div>
                        <FontAwesomeIcon
                          icon={faHandshake}
                          style={{ color: "#555", marginRight: 8 }}
                        />
                        <Text>{companyName}</Text>
                      </div>
                      <div>
                        <InfoCircleOutlined
                          style={{ color: "#555", marginRight: 8 }}
                        />
                        <Text>{projectDescription}</Text>
                      </div>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </AddProjectModal>
          ) : (
            <Card
              style={{
                backgroundColor: "white",
                cursor: "default",
                position: "relative",
              }}
              size="small"
            >
              {/* Optionally show a view-only indicator */}
              <Row gutter={16}>
                <Col span={24}>
                  <Space
                    direction="horizontal"
                    size="large"
                    align="center"
                    style={{ width: "100%" }}
                  >
                    <div>
                      <div style={{ fontSize: 18, fontWeight: 600 }}>
                        {project.projectName || "N/A"}
                      </div>
                    </div>
                    <div>
                      <Tag color="blue" icon={<EyeOutlined />}>
                        View Only
                      </Tag>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faHandshake}
                        style={{ color: "#555", marginRight: 8 }}
                      />
                      <Text>{companyName}</Text>
                    </div>
                    <div>
                      <InfoCircleOutlined
                        style={{ color: "#555", marginRight: 8 }}
                      />
                      <Text>{projectDescription}</Text>
                    </div>
                  </Space>
                </Col>
              </Row>
            </Card>
          )}
        </Col>

        {/* Tabs Section */}
        <Col span={24}>
          <Tabs
            activeKey={currentTab} // Dynamically set based on URL param
            onChange={handleTabChange}
            type="card"
            items={tabItems}
          />
        </Col>
      </Row>
    </div>
  );
};

export default BuildProject;
