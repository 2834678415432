import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Tabs,
  Table,
  message,
  Button,
  Modal,
  Tag,
  Row,
  Col,
  Typography,
  Space,
  Tooltip,
  Select,
  Input,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  StopOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { useModuleOptions, useItems } from "../../../Contexts/useSpecificData";
import HeaderText from "../../../Styled/HeaderText";
import { updateById } from "../../../Functions/updateById";
import { addItemsToTable } from "../../../Functions/addAria";
import { TABLES } from "../../../constants";
import debounce from "lodash.debounce";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../../Styled/Loader";
import AddModuleOptionModal from "./AddModuleOptionModal";
import useCurrentUser from "../../../hooks/useCurrentUser";

const { TabPane } = Tabs;
const { Text } = Typography;
const { Option } = Select;

// -------------- DUPLICATE MODAL COMPONENT --------------
function DuplicateModuleOptionModal({
  visible,
  onCancel,
  onOk,
  moduleOptions,
  currentCategory,
}) {
  const [selectedId, setSelectedId] = useState(null);
  const [newDescription, setNewDescription] = useState("");
  const [loading, setLoading] = useState(false);

  // Filter only module options from the current category (and not deleted),
  // then sort them A–Z by moduleOptionDescription.
  const optionsInCategory = useMemo(() => {
    return (moduleOptions || [])
      .filter((opt) => opt.category === currentCategory && !opt.deleted)
      .sort((a, b) =>
        a.moduleOptionDescription.localeCompare(b.moduleOptionDescription)
      );
  }, [moduleOptions, currentCategory]);

  // When a user selects an option from <Select>, copy its description
  const handleSelectChange = (id) => {
    setSelectedId(id);
    const found = optionsInCategory.find((opt) => opt.id === id);
    setNewDescription(found ? found.moduleOptionDescription : "");
  };

  // The main duplicate operation
  const handleDuplicate = async () => {
    // Safety checks (we also disable the button in the UI)
    if (!selectedId) {
      message.warning("Please select a module option to duplicate.");
      return;
    }
    if (!newDescription.trim()) {
      message.warning("Please enter a new module option description.");
      return;
    }

    setLoading(true);
    try {
      const optionToDuplicate = optionsInCategory.find(
        (opt) => opt.id === selectedId
      );
      if (!optionToDuplicate) {
        message.error("Could not find the selected module option.");
        setLoading(false);
        return;
      }

      // Omit fields you don't want to copy
      const { id, created, updated, updatedDate, ...rest } = optionToDuplicate;

      // Overwrite the description with user input
      rest.moduleOptionDescription = newDescription;
      rest.duplicated = true;
      rest.duplicatedFrom = optionToDuplicate.id;

      await addItemsToTable(TABLES.MODULE_OPTIONS, rest);
      message.success("Module option duplicated successfully.");
      onOk(); // Trigger parent refresh and close
    } catch (error) {
      console.error(error);
      message.error("Failed to duplicate module option.");
    } finally {
      setLoading(false);
    }
  };

  // Disable "Duplicate" button if no option selected or description is empty
  const isDuplicateDisabled = !selectedId || !newDescription.trim() || loading;

  return (
    <Modal
      title="Duplicate Module Option"
      visible={visible}
      onCancel={onCancel}
      onOk={handleDuplicate}
      confirmLoading={loading}
      okText="Duplicate"
      width="55%"
      okButtonProps={{
        disabled: isDuplicateDisabled,
      }}
    >
      <p>Select a module option from the current category to duplicate:</p>
      <Select
        showSearch
        placeholder="-- Select Option --"
        style={{ width: "100%", marginTop: "8px" }}
        value={selectedId ?? undefined}
        onChange={handleSelectChange}
        filterOption={(input, option) =>
          option?.children?.toLowerCase().includes(input.toLowerCase())
        }
      >
        {optionsInCategory.map((opt) => (
          <Option key={opt.id} value={opt.id}>
            {opt.moduleOptionDescription}
          </Option>
        ))}
      </Select>

      <p style={{ marginTop: "1rem" }}>New Module Option Description:</p>
      <Input.TextArea
        placeholder="Enter new description"
        autoSize
        style={{ width: "100%" }}
        value={newDescription}
        onChange={(e) => setNewDescription(e.target.value)}
      />
    </Modal>
  );
}
// -------------------------------------------------

function ModuleOptions() {
  const { data: moduleOptions, refresh: refreshModules } = useModuleOptions();
  const { data: items } = useItems();

  const navigate = useNavigate();
  const { category: urlCategory } = useParams();

  // ----------- Set Page/Tab Title -----------
  useEffect(() => {
    document.title = "Module Options";
  }, []);
  // ------------------------------------------

  const availableCategories = useMemo(() => {
    if (!moduleOptions) return [];
    const categoriesSet = new Set(
      moduleOptions.map((option) => option.category)
    );
    return Array.from(categoriesSet);
  }, [moduleOptions]);

  const categorizedOptions = useMemo(() => {
    if (!moduleOptions) return {};
    return moduleOptions.reduce((acc, item) => {
      if (item.deleted) return acc;
      const { category } = item;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});
  }, [moduleOptions]);

  const categories = useMemo(
    () => availableCategories.filter((cat) => categorizedOptions[cat]),
    [availableCategories, categorizedOptions]
  );

  const [activeCategory, setActiveCategory] = useState(() => {
    return categories.includes(urlCategory)
      ? urlCategory
      : categories[0] || null;
  });

  useEffect(() => {
    if (categories.length === 0) {
      setActiveCategory(null);
      return;
    }
    if (categories.includes(urlCategory)) {
      setActiveCategory(urlCategory);
    } else {
      setActiveCategory(categories[0]);
      if (urlCategory) {
        navigate(
          `/estimating/module-options/${encodeURIComponent(categories[0])}`,
          { replace: true }
        );
      }
    }
  }, [urlCategory, categories, navigate]);

  const [updatingIds, setUpdatingIds] = useState([]);

  // Modal states for Add/Edit
  const [isModuleModalVisible, setIsModuleModalVisible] = useState(false);
  const [moduleModalInitialData, setModuleModalInitialData] = useState(null);

  // Modal states for Delete
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [moduleOptionToDelete, setModuleOptionToDelete] = useState(null);
  const [confirmRemoveLoading, setConfirmRemoveLoading] = useState(false);

  // DUPLICATE MODAL STATE
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);

  // Debounced update function (e.g., for labor cost)
  const debouncedUpdate = useCallback(
    debounce(async (value, record) => {
      const { id } = record;
      setUpdatingIds((prev) => [...prev, id]);
      try {
        await updateById(TABLES.MODULE_OPTIONS, { laborCost: value }, id);
      } catch (err) {
        console.error(err);
        message.error(
          `Failed to update labor cost for "${record.moduleOptionDescription}".`
        );
      } finally {
        setUpdatingIds((prev) => prev.filter((itemId) => itemId !== id));
      }
    }, 500),
    []
  );

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  const handleLaborCostChange = (value, record) => {
    debouncedUpdate(value, record);
  };

  const showEditModuleModal = (record) => {
    setModuleModalInitialData(record);
    setIsModuleModalVisible(true);
  };

  const showDeleteModuleModal = (record) => {
    setModuleOptionToDelete({
      moduleOptionId: record.id,
      moduleOptionName: record.moduleOptionDescription,
    });
    setIsConfirmVisible(true);
  };

  const handleModuleModalOk = async () => {
    refreshModules();
    setIsModuleModalVisible(false);
    setModuleModalInitialData(null);
  };

  const handleModuleModalCancel = () => {
    setIsModuleModalVisible(false);
    setModuleModalInitialData(null);
  };

  const handleDeleteModalOk = async () => {
    if (!moduleOptionToDelete) {
      message.error("No module option selected for deletion.");
      return;
    }

    const { moduleOptionId, moduleOptionName } = moduleOptionToDelete;
    setConfirmRemoveLoading(true);
    setUpdatingIds((prev) => [...prev, moduleOptionId]);

    try {
      await updateById(
        TABLES.MODULE_OPTIONS,
        { deleted: true },
        moduleOptionId
      );
      message.success(
        `Module Option "${moduleOptionName}" deleted successfully.`
      );
      refreshModules();
    } catch (err) {
      console.error(err);
      message.error("Failed to delete module option.");
    } finally {
      setUpdatingIds((prev) => prev.filter((id) => id !== moduleOptionId));
      setIsConfirmVisible(false);
      setModuleOptionToDelete(null);
      setConfirmRemoveLoading(false);
    }
  };

  const handleDeleteModalCancel = () => {
    setIsConfirmVisible(false);
    setModuleOptionToDelete(null);
  };

  // Tab switching
  const handleTabChange = (key) => {
    if (key === activeCategory) return;
    navigate(`/estimating/module-options/${encodeURIComponent(key)}`);
  };

  // DUPLICATE MODAL HANDLERS
  const showDuplicateModal = () => {
    setIsDuplicateModalVisible(true);
  };

  const handleDuplicateModalCancel = () => {
    setIsDuplicateModalVisible(false);
  };

  const handleDuplicateModalOk = () => {
    refreshModules();
    setIsDuplicateModalVisible(false);
  };

  // Table columns
  const columns = useMemo(() => {
    return [
      {
        title: "Module Option Description",
        dataIndex: "moduleOptionDescription",
        key: "moduleOptionDescription",
        sorter: (a, b) =>
          a.moduleOptionDescription.localeCompare(b.moduleOptionDescription),
        defaultSortOrder: "ascend",
      },
      {
        title: "Expense ID",
        dataIndex: "ExpenseId",
        key: "ExpenseId",
      },
      {
        title: "Labor Formula",
        dataIndex: "laborFormula",
        key: "laborFormula",
        render: (laborFormula) => laborFormula || "Please select UOMs",
      },
      {
        title: "Attached Material Items",
        dataIndex: "materialItems",
        key: "materialItems",
        render: (materialItems) =>
          Array.isArray(materialItems) && materialItems.length > 0 ? (
            materialItems.map((item, index) => {
              const selectedItem = items.find((itm) => itm.id === item.item);
              return (
                <Row
                  key={item.id}
                  gutter={[16, 8]}
                  style={{
                    marginBottom: "8px",
                    alignItems: "center",
                    border: "1px solid #f0f0f0",
                    padding: 8,
                  }}
                >
                  <Col span={4}>
                    <Typography.Text>{item.name || "N/A"}</Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Tag
                      style={{
                        cursor: "default",
                        backgroundColor: "#fafafa",
                        borderColor: "#d9d9d9",
                      }}
                    >
                      {selectedItem ? selectedItem.itemNumber : "N/A"}
                    </Tag>
                  </Col>
                  <Col span={8}>
                    <span style={{ wordBreak: "break-word" }}>
                      {selectedItem ? selectedItem.itemDescription : "N/A"}
                    </span>
                  </Col>
                  <Col span={8}>
                    <Tooltip title="Pre-saved material formula.">
                      <Typography.Text strong>
                        {item.formula || "N/A"}
                      </Typography.Text>
                    </Tooltip>
                  </Col>
                </Row>
              );
            })
          ) : (
            <>
              <StopOutlined style={{ color: "red", marginRight: "8px" }} />
              <span style={{ opacity: 0.7, fontStyle: "italic" }}>
                No material items attached
              </span>
            </>
          ),
      },
      {
        title: "Actions",
        key: "actions",
        render: (_, record) => (
          <Space>
            <Button
              type="primary"
              onClick={() => showEditModuleModal(record)}
              disabled={updatingIds.includes(record.id)}
              icon={<EditOutlined />}
            >
              Edit
            </Button>
            <Button
              danger
              onClick={() => showDeleteModuleModal(record)}
              disabled={updatingIds.includes(record.id)}
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Space>
        ),
      },
    ];
  }, [items, updatingIds]);

  return (
    <div>
      <Row gutter={16} style={{ marginBottom: "16px", alignItems: "center" }}>
        <Col span={12}>
          <HeaderText text={"Module Options"} />
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Button type="primary" onClick={() => setIsModuleModalVisible(true)}>
            Add Module Option
          </Button>
          {/* DUPLICATE BUTTON */}
          <Button
            style={{ marginLeft: 8 }}
            icon={<CopyOutlined />}
            onClick={showDuplicateModal}
          >
            Duplicate Module Option
          </Button>
        </Col>
      </Row>

      {categories.length > 0 ? (
        <Tabs activeKey={activeCategory} onChange={handleTabChange} centered>
          {categories.map((category) => (
            <TabPane tab={category} key={category}>
              <Table
                dataSource={categorizedOptions[category]}
                columns={columns}
                rowKey="id"
                pagination={{ pageSize: 10 }}
              />
            </TabPane>
          ))}
        </Tabs>
      ) : (
        <Loader />
      )}

      {/* Existing modal for Add/Edit */}
      <AddModuleOptionModal
        visible={isModuleModalVisible}
        onOk={handleModuleModalOk}
        onCancel={handleModuleModalCancel}
        initialData={moduleModalInitialData}
      />

      {/* DUPLICATE MODAL */}
      <DuplicateModuleOptionModal
        visible={isDuplicateModalVisible}
        onCancel={handleDuplicateModalCancel}
        onOk={handleDuplicateModalOk}
        moduleOptions={moduleOptions}
        currentCategory={activeCategory}
      />

      {/* Delete Confirmation Modal */}
      <Modal
        title="Confirm Delete"
        visible={isConfirmVisible}
        onOk={handleDeleteModalOk}
        onCancel={handleDeleteModalCancel}
        okText="Delete"
        cancelText="Cancel"
        confirmLoading={confirmRemoveLoading}
      >
        <p>
          Are you sure you want to delete the module option "
          {moduleOptionToDelete?.moduleOptionName}"?
        </p>
      </Modal>
    </div>
  );
}

export default ModuleOptions;
