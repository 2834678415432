import React, { useState, useMemo, useEffect } from "react";
import dayjs from "dayjs";
import { updateById } from "../../Functions/updateById";
import { COLORS, TABLES } from "../../constants";
import { useCustomers } from "../../Contexts/useSpecificData";
import {
  Modal,
  Form,
  InputNumber,
  Input,
  Select,
  Switch,
  Button,
  DatePicker,
  Row,
  Col,
  Typography,
  Upload,
  message,
  Card,
  Table,
  Tag,
  Empty,
  Divider,
} from "antd";
import {
  InboxOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  ScissorOutlined,
} from "@ant-design/icons";
import { s3Upload } from "../../Functions/s3Upload";
import HeaderText from "../../Styled/HeaderText";
import FileIconComponent from "../../Styled/FileIconComponent";
import { formatDate, formatMoney } from "../../Formatters/helpers";
import AddProjectModulesModal from "./AddProjectModulesModal";
import ProjectBillingModal from "./ProjectBillingModal";
import { v4 as uuidv4 } from "uuid";
import useCurrentUser from "../../hooks/useCurrentUser";
import { has } from "lodash";

const { Title, Text } = Typography;
const { Option } = Select;
const { Dragger } = Upload;

const DELTA_TOLERANCE = 0.01;

function getLineStatus(line, orderType, billing = []) {
  if (!line) return "REMAINING";

  // Only match on id (assuming new lines always get a new id)
  for (const ar of billing) {
    if (!ar || !ar.lines) continue;
    const match = ar.lines.find(
      (l) => l.id === line.id && l.source === orderType
    );
    if (match) {
      if (ar.paid) return "PAID";
      if (ar.billed) return "BILLED";
      return "PENDING";
    }
  }
  return "REMAINING";
}


function getOrderBillingStats(order, orderType, billing = []) {
  const result = { remaining: 0, pending: 0, billed: 0, paid: 0 };
  if (!order || !order.lines) return result;

  for (const line of order.lines) {
    const status = getLineStatus(line, orderType, billing);
    const amt = line.total || 0;
    switch (status) {
      case "REMAINING":
        result.remaining += amt;
        break;
      case "PENDING":
        result.pending += amt;
        break;
      case "BILLED":
        result.billed += amt;
        break;
      case "PAID":
        result.paid += amt;
        break;
      default:
        break;
    }
  }
  return result;
}

function getProjectBillingSummary(project) {
  let remaining = 0,
    pending = 0,
    billed = 0,
    paid = 0;

  if (project.purchaseOrder && project.purchaseOrder.lines) {
    const stats = getOrderBillingStats(
      project.purchaseOrder,
      "Purchase Order",
      project.billing
    );
    remaining += stats.remaining;
    pending += stats.pending;
    billed += stats.billed;
    paid += stats.paid;
  }

  if (project.changeOrders && project.changeOrders.length) {
    for (const co of project.changeOrders) {
      const stats = getOrderBillingStats(co, "Change Order", project.billing);
      remaining += stats.remaining;
      pending += stats.pending;
      billed += stats.billed;
      paid += stats.paid;
    }
  }

  const total = remaining + pending + billed + paid;
  return { remaining, pending, billed, paid, total };
}

const getTagColorForStatus = (status, value) => {
  if (parseFloat(value) === 0) return undefined;
  switch (status) {
    case "REMAINING":
      return "red";
    case "PENDING":
      return "orange";
    case "BILLED":
      return "blue";
    case "PAID":
      return "green";
    default:
      return undefined;
  }
};

const renderMoneyTag = (value, record, orderType, projectBilling) => {
  const status = getLineStatus(record, orderType, projectBilling);
  const color = getTagColorForStatus(status, value);
  return <Tag color={color}>{formatMoney(value)}</Tag>;
};

function PurchaseAndChangeOrders({ project, viewOnly = false }) {
  const { data: customers } = useCustomers();
  const { userEmail } = useCurrentUser();

  const [modalVisible, setModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editType, setEditType] = useState("purchaseOrder");
  const [formType, setFormType] = useState("Purchase Order");
  const [formData] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);

  const [addModulesModalVisible, setAddModulesModalVisible] = useState(false);
  const [deltaInfo, setDeltaInfo] = useState(null);
  const [pendingPOCOData, setPendingPOCOData] = useState(null);

  const [billingModalVisible, setBillingModalVisible] = useState(false);
  const [editingAr, setEditingAr] = useState(null);

  // STATE FOR BREAKING LINES
  const [breakModalVisible, setBreakModalVisible] = useState(false);
  const [eligibleLines, setEligibleLines] = useState([]);
  const [selectedLineIndices, setSelectedLineIndices] = useState([]);
  const [breakForm] = Form.useForm();

  const hasPurchaseOrder = !!project.purchaseOrder;
  const customer = customers?.find((c) => c.id === project.customerId);
  const defaultNetTerms = customer?.netTerms;

  useEffect(() => {
    if (isEditing && modalVisible) {
      if (editType === "purchaseOrder" && project.purchaseOrder) {
        const po = project.purchaseOrder;
        formData.setFieldsValue({
          id: po.id || uuidv4(),
          createdBy: po.createdBy || userEmail,
          type: po.type || "Purchase Order",
          number: po.number || null,
          taxExempt: po.taxExempt || false,
          taxPercentage: po.taxExempt ? 0 : po.taxPercentage,
          netTerms: po.netTerms,
          lines: (po.lines || []).map((line) => ({
            ...line,
            id: line.id || uuidv4(),
            plannedDateToBill: line.plannedDateToBill
              ? dayjs(line.plannedDateToBill)
              : null,
          })),
          files: (po.files || []).map((f) => ({
            url: f.url,
            name: f.originalName || f.name,
            status: "done",
            uid: f.name,
            originalName: f.originalName || f.name,
          })),
        });
        setFormType(po.type || "Purchase Order");
      } else if (editType === "changeOrder" && project.changeOrders) {
        const idx = editingIndex !== null ? editingIndex : 0;
        const co = project.changeOrders[idx];
        formData.setFieldsValue({
          id: co.id || uuidv4(),
          createdBy: co.createdBy || userEmail,
          type: co.type || "Change Order",
          number: co.number || null,
          taxExempt: co.taxExempt || false,
          taxPercentage: co.taxExempt ? 0 : co.taxPercentage,
          netTerms: project.purchaseOrder?.netTerms ?? 0,
          lines: (co.lines || []).map((line) => ({
            ...line,
            id: line.id || uuidv4(),
            plannedDateToBill: line.plannedDateToBill
              ? dayjs(line.plannedDateToBill)
              : null,
          })),
          files: (co.files || []).map((f) => ({
            url: f.url,
            name: f.originalName || f.name,
            status: "done",
            uid: f.name,
            originalName: f.originalName || f.name,
          })),
        });
        setFormType(co.type || "Change Order");
      }
    } else if (!isEditing && modalVisible) {
      const defaultType =
        editType === "purchaseOrder" ? "Purchase Order" : "Change Order";
      formData.resetFields();
      formData.setFieldsValue({
        id: uuidv4(),
        createdBy: userEmail,
        type: defaultType,
        netTerms:
          defaultType === "Change Order"
            ? project.purchaseOrder?.netTerms ?? defaultNetTerms
            : defaultNetTerms,
        taxExempt: false,
        lines: [
          {
            lineNumber: null,
            amount: null,
            plannedDateToBill: null,
            description: "",
            id: uuidv4(),
            tax: 0,
            total: 0,
          },
        ],
        files: [],
      });
      setFormType(defaultType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEditing,
    modalVisible,
    project,
    defaultNetTerms,
    editType,
    formData,
    editingIndex,
    userEmail,
  ]);

  const handleAddClick = (type) => {
    if (type === "changeOrder" && !hasPurchaseOrder) {
      message.warning(
        "You must have a Purchase Order before adding Change Orders."
      );
      return;
    }
    setEditType(type);
    setIsEditing(false);
    setEditingIndex(null);
    setModalVisible(true);
  };

  const handleEditClick = (type, idx = null) => {
    setEditType(type);
    setIsEditing(true);
    setEditingIndex(idx);
    setModalVisible(true);
  };

  const handleDeleteAR = async (arId) => {
    try {
      const updatedBilling = project.billing.filter((ar) => ar.id !== arId);
      const updatedProject = { ...project, billing: updatedBilling };
      await updateById(TABLES.PROJECTS, updatedProject, project.id);
      message.success("AR entry deleted successfully!");
    } catch (error) {
      console.error(error);
      message.error("Failed to delete AR entry.");
    }
  };

  const buildARTitle = (ar) => (
    <>
      <Tag color="purple">Accounts Receivable</Tag> {ar.arNumber}
      <span style={{ marginLeft: 8 }}>
        <Tag color="green">{formatMoney(ar.total)}</Tag>
      </span>
      {!ar.billed && !ar.paid && (
        <Button
          type="link"
          danger
          icon={<DeleteOutlined />}
          onClick={() =>
            Modal.confirm({
              title: "Confirm Delete",
              content:
                "Are you sure you want to delete this AR entry? This action cannot be undone.",
              okText: "Delete",
              okButtonProps: { danger: true },
              cancelText: "Cancel",
              onOk: () => handleDeleteAR(ar.id),
            })
          }
          style={{ marginLeft: 16 }}
        >
          Delete
        </Button>
      )}
    </>
  );

  const handleOk = async () => {
    setLoading(true);
    try {
      const values = await formData.validateFields();
      const { lines = [], files, taxExempt, taxPercentage } = values;

      const updatedLines = lines.map((line) => {
        const amt = parseFloat(line.amount) || 0;
        const lineTax = taxExempt
          ? 0
          : parseFloat(((amt * taxPercentage) / 100).toFixed(2));
        const total = parseFloat((amt + lineTax).toFixed(2));
        return { ...line, tax: lineTax, total: total };
      });

      const overallTotal = updatedLines.reduce(
        (acc, l) => acc + (l.total || 0),
        0
      );

      let poCoTotal = 0;
      const currentProject = { ...project };

      if (currentProject.purchaseOrder) {
        if (!(editType === "purchaseOrder" && isEditing)) {
          poCoTotal += parseFloat(currentProject.purchaseOrder.total || 0);
        }
      }

      if (
        currentProject.changeOrders &&
        currentProject.changeOrders.length > 0
      ) {
        currentProject.changeOrders.forEach((co, i) => {
          if (editType === "changeOrder" && isEditing && i === editingIndex)
            return;
          poCoTotal += parseFloat(co.total || 0);
        });
      }

      poCoTotal += overallTotal;

      const modulesTotal = parseFloat(
        (
          project.modules?.reduce(
            (acc, mod) => acc + (parseFloat(mod.amount) || 0),
            0
          ) || 0
        ).toFixed(2)
      );

      if (Math.abs(poCoTotal - modulesTotal) > DELTA_TOLERANCE) {
        setModalVisible(false);
        const delta = (poCoTotal - modulesTotal).toFixed(2);
        message.warning(
          `Total of Purchase Order + Change Orders ($${poCoTotal.toFixed(
            2
          )}) does not match Modules total ($${modulesTotal.toFixed(
            2
          )}). Please reconcile a $${delta}.`
        );
        setPendingPOCOData({ values, overallTotal });
        setDeltaInfo({ requiredTotal: poCoTotal });
        setAddModulesModalVisible(true);
      } else {
        const uploadedFiles = [];
        for (let fObj of files) {
          if (!fObj.url) {
            const original = fObj.originFileObj;
            const uniqueName = `${project.projectName}-${
              project.projectDescription
            }-${formType}-${new Date().toISOString()}-${original.name}`;
            const url = await s3Upload(original, uniqueName);
            uploadedFiles.push({
              name: uniqueName,
              url,
              originalName: original.name,
              created: new Date().toISOString(),
            });
          } else {
            uploadedFiles.push({
              name: fObj.name,
              url: fObj.url,
              originalName: fObj.originalName,
              created: fObj.created || new Date().toISOString(),
            });
          }
        }

        const now = new Date().toISOString();
        let updatedData = {
          id: values.id,
          createdBy: values.createdBy,
          type: values.type,
          number: values.number,
          taxExempt: values.taxExempt,
          taxPercentage: taxExempt ? 0 : values.taxPercentage,
          netTerms: values.netTerms,
          lines: updatedLines,
          files: uploadedFiles,
          total: parseFloat(overallTotal.toFixed(2)),
        };

        const updatedProject = { ...project };
        if (editType === "purchaseOrder") {
          if (isEditing && updatedProject.purchaseOrder) {
            updatedData.created = updatedProject.purchaseOrder.created || now;
            updatedData.updated = now;
          } else {
            updatedData.created = now;
          }
          updatedProject.purchaseOrder = updatedData;

          if (
            updatedProject.changeOrders &&
            updatedProject.changeOrders.length > 0
          ) {
            updatedProject.changeOrders = updatedProject.changeOrders.map(
              (co) => ({
                ...co,
                netTerms: updatedData.netTerms,
              })
            );
          }
          if (updatedProject.billing && updatedProject.billing.length > 0) {
            updatedProject.billing = updatedProject.billing.map((ar) => ({
              ...ar,
              netTerms: updatedData.netTerms,
            }));
          }
        } else {
          let cos = [...(updatedProject.changeOrders || [])];
          if (isEditing && editingIndex !== null) {
            const existing = cos[editingIndex] || {};
            updatedData.created = existing.created || now;
            updatedData.updated = now;
            cos[editingIndex] = updatedData;
          } else {
            updatedData.created = now;
            cos.push(updatedData);
          }
          updatedProject.changeOrders = cos;
        }

        await updateById(TABLES.PROJECTS, updatedProject, project.id);
        message.success("Form saved successfully!");
        formData.resetFields();
        setModalVisible(false);
      }
    } catch (err) {
      console.error("Error saving form:", err);
      message.error("Error saving form.");
    } finally {
      setLoading(false);
    }
  };

  const handleAddModulesSave = async (updatedModules) => {
    try {
      setLoading(true);
      const { values, overallTotal } = pendingPOCOData;
      const { taxExempt, files } = values;

      const uploadedFiles = [];
      for (let fObj of files) {
        if (!fObj.url) {
          const original = fObj.originFileObj;
          const uniqueName = `${project.projectName}-${
            project.projectDescription
          }-${formType}-${new Date().toISOString()}-${original.name}`;
          const url = await s3Upload(original, uniqueName);
          uploadedFiles.push({
            name: uniqueName,
            url,
            originalName: original.name,
            created: new Date().toISOString(),
          });
        } else {
          uploadedFiles.push({
            name: fObj.name,
            url: fObj.url,
            originalName: fObj.originalName,
            created: fObj.created || new Date().toISOString(),
          });
        }
      }

      const updatedLines = (values.lines || []).map((ln) => {
        const amt = parseFloat(ln.amount) || 0;
        const taxRate = taxExempt ? 0 : parseFloat(values.taxPercentage) || 0;
        const lineTax = parseFloat((amt * (taxRate / 100)).toFixed(2));
        const totalVal = parseFloat((amt + lineTax).toFixed(2));
        return { ...ln, tax: lineTax, total: totalVal };
      });

      const now = new Date().toISOString();
      let updatedData = {
        id: values.id,
        createdBy: values.createdBy,
        type: values.type,
        number: values.number,
        taxExempt: values.taxExempt,
        taxPercentage: taxExempt ? 0 : values.taxPercentage,
        netTerms: values.netTerms,
        lines: updatedLines,
        files: uploadedFiles,
        total: overallTotal,
      };

      const updatedProject = { ...project, modules: updatedModules };
      if (editType === "purchaseOrder") {
        if (isEditing && updatedProject.purchaseOrder) {
          updatedData.created = updatedProject.purchaseOrder.created || now;
          updatedData.updated = now;
        } else {
          updatedData.created = now;
        }
        updatedProject.purchaseOrder = updatedData;

        if (
          updatedProject.changeOrders &&
          updatedProject.changeOrders.length > 0
        ) {
          updatedProject.changeOrders = updatedProject.changeOrders.map(
            (co) => ({
              ...co,
              netTerms: updatedData.netTerms,
            })
          );
        }
        if (updatedProject.billing && updatedProject.billing.length > 0) {
          updatedProject.billing = updatedProject.billing.map((ar) => ({
            ...ar,
            netTerms: updatedData.netTerms,
          }));
        }
      } else {
        let cos = [...(updatedProject.changeOrders || [])];
        if (isEditing && editingIndex !== null) {
          const existing = cos[editingIndex] || {};
          updatedData.created = existing.created || now;
          updatedData.updated = now;
          cos[editingIndex] = updatedData;
        } else {
          updatedData.created = now;
          cos.push(updatedData);
        }
        updatedProject.changeOrders = cos;
      }

      await updateById(TABLES.PROJECTS, updatedProject, project.id);
      message.success("Purchase/Change Order and Modules saved successfully!");
      setAddModulesModalVisible(false);
      setDeltaInfo(null);
      setPendingPOCOData(null);
      formData.resetFields();
    } catch (error) {
      console.error(error);
      message.error("Failed to save Purchase/Change Order and Modules.");
    } finally {
      setLoading(false);
    }
  };

  const handleAddModulesCancel = () => {
    setAddModulesModalVisible(false);
    setDeltaInfo(null);
    setPendingPOCOData(null);
  };

  const handleCancel = () => {
    formData.resetFields();
    setModalVisible(false);
  };

  const handleFormTypeChange = (val) => {
    setFormType(val);
  };

  const uploadProps = {
    multiple: true,
    showUploadList: true,
    beforeUpload: () => false,
  };

  const normFile = (e) => {
    if (Array.isArray(e)) return e;
    return e?.fileList?.map((file) => ({ ...file, status: "done" })) || [];
  };

  const renderLines = (lines, orderType) => {
    if (!lines || lines.length === 0) {
      return <Text>No lines available.</Text>;
    }

    const columns = [
      {
        title: "Line #",
        dataIndex: "lineNumber",
        key: "lineNumber",
        width: 70,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        width: 100,
        render: (val, record) =>
          renderMoneyTag(val, record, orderType, project.billing),
      },
      {
        title: "Tax",
        dataIndex: "tax",
        key: "tax",
        width: 80,
        render: (val, record) =>
          renderMoneyTag(val, record, orderType, project.billing),
      },
      {
        title: "Line Total",
        dataIndex: "total",
        key: "total",
        width: 100,
        render: (_, record) => {
          const total =
            (parseFloat(record.amount) || 0) + (parseFloat(record.tax) || 0);
          return renderMoneyTag(total, record, orderType, project.billing);
        },
      },
      {
        title: "Planned Date to Bill",
        dataIndex: "plannedDateToBill",
        key: "plannedDateToBill",
        width: 130,
        render: (val) => (val ? dayjs(val).format("MM/DD/YYYY") : "N/A"),
      },
      {
        title: "Status",
        key: "status",
        width: 140,
        render: (_, record) => {
          const status = getLineStatus(record, orderType, project.billing);
          switch (status) {
            case "REMAINING":
              return <Tag color="red">Remaining to be Billed</Tag>;
            case "PENDING":
              return <Tag color="orange">Pending Billing</Tag>;
            case "BILLED":
              return <Tag color="blue">Billed</Tag>;
            case "PAID":
              return <Tag color="green">Paid</Tag>;
            default:
              return "";
          }
        },
      },
    ];

    return (
      <Table
        size="small"
        bordered
        dataSource={lines}
        columns={columns}
        pagination={false}
        rowKey={(record, idx) => record.id || idx}
        style={{ marginTop: 8 }}
        scroll={{ x: 800 }}
      />
    );
  };

  const renderFiles = (files) => {
    if (!files || files.length === 0) {
      return <div>No files attached.</div>;
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginTop: 8,
        }}
      >
        {files.map((file) => (
          <div
            key={file.url}
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #ccc",
              padding: "8px",
              borderRadius: "8px",
            }}
          >
            <FileIconComponent file={file} style={{ width: 50 }} />
            <div style={{ marginLeft: "10px" }}>{file.originalName}</div>
          </div>
        ))}
      </div>
    );
  };

  const buildTitle = (order, orderType, billing) => {
    if (!order) return "";
    const { remaining, pending, billed, paid } = getOrderBillingStats(
      order,
      orderType,
      billing
    );
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: 16,
          alignItems: "flex-end",
        }}
      >
        <span>
          {order.type} #{order.number}
        </span>
        {remaining > 0 && (
          <span style={{ opacity: 0.8, fontSize: 14 }}>
            Remaining to be Billed:{" "}
            <Tag color="red">{formatMoney(remaining)}</Tag>
          </span>
        )}
        {pending > 0 && (
          <span style={{ opacity: 0.8, fontSize: 14 }}>
            Pending Billing: <Tag color="orange">{formatMoney(pending)}</Tag>
          </span>
        )}
        {billed > 0 && (
          <span style={{ opacity: 0.8, fontSize: 14 }}>
            Billed: <Tag color="blue">{formatMoney(billed)}</Tag>
          </span>
        )}
        {paid > 0 && (
          <span style={{ opacity: 0.8, fontSize: 14 }}>
            Paid: <Tag color="green">{formatMoney(paid)}</Tag>
          </span>
        )}
        <span style={{ marginLeft: "auto", marginRight: 24 }}>
          Total: <Tag color="green">{formatMoney(order.total)}</Tag>
        </span>
      </div>
    );
  };

  const summary = getProjectBillingSummary(project);
  const { remaining, pending, billed, paid } = summary;
  const sumAll = summary.total || 0;
  const remainingPct = sumAll ? ((remaining / sumAll) * 100).toFixed(0) : 0;
  const pendingPct = sumAll ? ((pending / sumAll) * 100).toFixed(0) : 0;
  const billedPct = sumAll ? ((billed / sumAll) * 100).toFixed(0) : 0;
  const paidPct = sumAll ? ((paid / sumAll) * 100).toFixed(0) : 0;

  // --- HANDLERS FOR BREAKING LINES ---
  const handleOpenBreakModal = () => {
    const currentLines = formData.getFieldValue("lines") || [];
    const eligible = currentLines
      .map((line, index) => ({ ...line, formIndex: index }))
      .filter(
        (line) =>
          line.description &&
          parseFloat(line.amount) > 0 &&
          line.lineNumber != null &&
          getLineStatus(line, formType, project.billing) !== "BILLED" &&
          getLineStatus(line, formType, project.billing) !== "PAID"
      );
    if (eligible.length === 0) {
      message.info("No eligible lines to break");
      return;
    }
    setEligibleLines(eligible);
    setSelectedLineIndices([]); // reset selection
    setBreakModalVisible(true);
    breakForm.resetFields();
    breakForm.setFieldsValue({
      breakSegments: [{ percentage: null, plannedDateToBill: null }],
    });
  };

  // Updated handleBreakOk: loop over eligibleLines (which includes formIndex) and replace them with new segments that get a fresh id.
  const handleBreakOk = async () => {
    try {
      const values = await breakForm.validateFields();
      const segments = values.breakSegments;
      const totalPercentage = segments.reduce(
        (acc, seg) => acc + Number(seg.percentage),
        0
      );
      if (totalPercentage !== 100) {
        message.error("The segments must add up to 100%");
        return;
      }
      if (selectedLineIndices.length === 0) {
        message.error("Please select at least one line to break");
        return;
      }
      const currentLines = formData.getFieldValue("lines") || [];
      // Create a copy of the current lines array
      let newLines = [...currentLines];
      // For each eligible line that was selected (using its formIndex), replace it with the broken segments
      eligibleLines.forEach((eligibleLine) => {
        if (selectedLineIndices.includes(eligibleLine.formIndex)) {
          const taxExempt = formData.getFieldValue("taxExempt");
          const taxPct = taxExempt
            ? 0
            : formData.getFieldValue("taxPercentage") || 0;
          const brokenSegments = segments.map((seg) => {
            const pct = Number(seg.percentage);
            const newAmount = parseFloat(
              (eligibleLine.amount * (pct / 100)).toFixed(2)
            );
            const newTax = taxExempt
              ? 0
              : parseFloat((newAmount * (taxPct / 100)).toFixed(2));
            const newTotal = parseFloat((newAmount + newTax).toFixed(2));
            return {
              id: uuidv4(),
              lineNumber: eligibleLine.lineNumber,
              description: `${pct}% - ${eligibleLine.description}`,
              amount: newAmount,
              tax: newTax,
              total: newTotal,
              plannedDateToBill: seg.plannedDateToBill,
            };
          });
          // Replace the original line in newLines at the index stored in eligibleLine.formIndex
          newLines.splice(eligibleLine.formIndex, 1, ...brokenSegments);
        }
      });
      formData.setFieldsValue({ lines: newLines });
      setBreakModalVisible(false);
      message.success("Selected lines broken successfully");
    } catch (err) {
      console.error("Error breaking lines:", err);
      message.error("Failed to break lines");
    }
  };

  const handleBreakCancel = () => {
    setBreakModalVisible(false);
    setEligibleLines([]);
    setSelectedLineIndices([]);
  };

  return (
    <div>
      <Row
        style={{ marginBottom: 16, fontWeight: "bold", fontSize: "1rem" }}
      ></Row>

      {/* PURCHASE ORDER SECTION */}
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: 8, alignItems: "flex-end" }}
      >
        <Col>
          <HeaderText large text="Purchase Order" />
        </Col>
        <Col>
          {hasPurchaseOrder && (
            <div
              style={{ display: "flex", alignItems: "center", marginRight: 16 }}
            >
              <span style={{ marginRight: 20 }}>
                <strong>Remaining to be Billed:</strong> {remainingPct}%
              </span>
              <span style={{ marginRight: 20 }}>
                <strong>Pending Billing:</strong> {pendingPct}%
              </span>
              <span style={{ marginRight: 20 }}>
                <strong>Billed:</strong> {billedPct}%
              </span>
              <span>
                <strong>Paid:</strong> {paidPct}%
              </span>
            </div>
          )}
          {!hasPurchaseOrder && !viewOnly && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => handleAddClick("purchaseOrder")}
            >
              Add Purchase Order
            </Button>
          )}
        </Col>
      </Row>

      {hasPurchaseOrder ? (
        <Card
          title={buildTitle(
            project.purchaseOrder,
            "Purchase Order",
            project.billing
          )}
          style={{ marginBottom: 20 }}
          extra={
            !viewOnly && (
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={() => handleEditClick("purchaseOrder")}
              />
            )
          }
        >
          {renderLines(project.purchaseOrder.lines, "Purchase Order")}
          {renderFiles(project.purchaseOrder.files)}
        </Card>
      ) : (
        <div style={{ marginBottom: 20 }}>
          <Empty description="No Purchase Order Found" />
        </div>
      )}

      {/* CHANGE ORDERS SECTION */}
      {hasPurchaseOrder && (
        <>
          <Divider />
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: 8 }}
          >
            <Col>
              <HeaderText large text="Change Orders" />
            </Col>
            <Col>
              {!viewOnly && (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => handleAddClick("changeOrder")}
                >
                  Add Change Order
                </Button>
              )}
            </Col>
          </Row>

          {project.changeOrders && project.changeOrders.length > 0 ? (
            project.changeOrders.map((co, idx) => (
              <Card
                key={idx}
                title={buildTitle(co, "Change Order", project.billing)}
                style={{ marginBottom: 20 }}
                extra={
                  !viewOnly && (
                    <EditOutlined
                      style={{ cursor: "pointer" }}
                      onClick={() => handleEditClick("changeOrder", idx)}
                    />
                  )
                }
              >
                {renderLines(co.lines, "Change Order")}
                {renderFiles(co.files)}
              </Card>
            ))
          ) : (
            <Text>No Change Orders Found</Text>
          )}

          {/* ACCOUNTS RECEIVABLE SECTION */}
          <Divider />
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: 8 }}
          >
            <Col>
              <HeaderText large text="Accounts Receivable (AR)" />
            </Col>
            <Col>
              {!viewOnly && (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setEditingAr(null);
                    setBillingModalVisible(true);
                  }}
                >
                  Create AR
                </Button>
              )}
            </Col>
          </Row>
          {project.billing && project.billing.length > 0 ? (
            project.billing.map((ar, idx) => (
              <Card
                key={idx}
                style={{ marginBottom: 10 }}
                title={buildARTitle(ar)}
                extra={
                  ar.paid ? (
                    <Tag color="green">PAID</Tag>
                  ) : ar.billed ? (
                    <Tag color="blue">BILLED</Tag>
                  ) : (
                    !viewOnly && (
                      <EditOutlined
                        style={{ cursor: "pointer", marginRight: 8 }}
                        onClick={() => {
                          setEditingAr(ar);
                          setBillingModalVisible(true);
                        }}
                      />
                    )
                  )
                }
              >
                {ar.lines && ar.lines.length > 0 && (
                  <Table
                    dataSource={ar.lines}
                    columns={[
                      {
                        title: "Line #",
                        dataIndex: "lineNumber",
                        key: "lineNumber",
                        width: 70,
                      },
                      {
                        title: "Source",
                        dataIndex: "source",
                        key: "source",
                        width: 120,
                      },
                      {
                        title: "Order Number",
                        dataIndex: "orderNumber",
                        key: "orderNumber",
                        width: 120,
                      },
                      {
                        title: "Description",
                        dataIndex: "description",
                        key: "description",
                      },
                      {
                        title: "Amount",
                        dataIndex: "amount",
                        key: "amount",
                        width: 100,
                        render: (val, rec) =>
                          renderMoneyTag(val, rec, "", project.billing),
                      },
                      {
                        title: "Tax",
                        dataIndex: "tax",
                        key: "tax",
                        width: 80,
                        render: (val, rec) =>
                          renderMoneyTag(val, rec, "", project.billing),
                      },
                      {
                        title: "Line Total",
                        dataIndex: "total",
                        key: "total",
                        width: 100,
                        render: (_, rec) => {
                          const amt = parseFloat(rec.amount) || 0;
                          const tx = parseFloat(rec.tax) || 0;
                          return renderMoneyTag(
                            amt + tx,
                            rec,
                            "",
                            project.billing
                          );
                        },
                      },
                    ]}
                    rowKey="lineNumber"
                    pagination={false}
                    style={{ marginTop: 10 }}
                    scroll={{ x: 700 }}
                  />
                )}
              </Card>
            ))
          ) : (
            <Text>No AR entries found.</Text>
          )}
        </>
      )}

      {/* MAIN MODAL FOR PURCHASE ORDER / CHANGE ORDER */}
      <Modal
        open={modalVisible}
        title={
          editType === "purchaseOrder"
            ? isEditing
              ? "Edit Purchase Order"
              : "Add Purchase Order"
            : isEditing
            ? "Edit Change Order"
            : "Add Change Order"
        }
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        width="95%"
        okText={isEditing ? "Save" : "Create"}
      >
        <Form form={formData} layout="vertical">
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                label="Type"
                name="type"
                rules={[{ required: true, message: "Type is required" }]}
              >
                <Select onChange={handleFormTypeChange}>
                  {editType === "changeOrder" ? (
                    <Option value="Change Order">Change Order</Option>
                  ) : (
                    <>
                      <Option value="Purchase Order">Purchase Order</Option>
                      <Option value="Proposal">Proposal</Option>
                    </>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={`${formType} Number`}
                name="number"
                rules={[{ required: true, message: "Number is required" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Net Terms"
                name="netTerms"
                rules={[{ required: true, message: "Net Terms is required" }]}
              >
                <Select allowClear disabled={formType === "Change Order"}>
                  {[
                    { value: 0, label: "Due on Receipt" },
                    { value: 15, label: "15 Days" },
                    { value: 30, label: "30 Days" },
                    { value: 45, label: "45 Days" },
                    { value: 60, label: "60 Days" },
                    { value: 90, label: "90 Days" },
                  ].map((t) => (
                    <Option key={t.value} value={t.value}>
                      {t.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Tax Exempt"
                name="taxExempt"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item noStyle shouldUpdate>
                {() => {
                  const taxExempt = formData.getFieldValue("taxExempt");
                  if (!taxExempt) {
                    return (
                      <Form.Item
                        name="taxPercentage"
                        label="Tax %"
                        rules={[
                          {
                            required: true,
                            message: "Tax % is required if not exempt",
                          },
                          {
                            type: "number",
                            min: 0,
                            max: 100,
                            message: "Tax % must be between 0 and 100",
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          max={100}
                          addonAfter="%"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    );
                  }
                  return null;
                }}
              </Form.Item>
            </Col>
          </Row>

          {/* Lines Header with Break Lines button */}
          <Row
            justify="space-between"
            align="middle"
            style={{ marginTop: 16, marginBottom: 8 }}
          >
            <Col>
              <Title level={5}>Lines</Title>
            </Col>
            <Col>
              <Button icon={<ScissorOutlined />} onClick={handleOpenBreakModal}>
                Break Lines
              </Button>
            </Col>
          </Row>

          <Form.List
            name="lines"
            rules={[
              {
                validator: async (_, val) => {
                  if (!val || val.length === 0) {
                    return Promise.reject(
                      new Error("At least one line is required")
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name }) => {
                  const currentLine =
                    formData.getFieldValue(["lines", name]) || {};
                  const lineStatus = getLineStatus(
                    currentLine,
                    formType,
                    project.billing
                  );
                  const disabledFields = ["BILLED", "PAID"].includes(
                    lineStatus
                  );
                  return (
                    <React.Fragment key={key}>
                      <Row gutter={8} align="middle">
                        <Col span={2}>
                          <Form.Item
                            label="Line #"
                            name={[name, "lineNumber"]}
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <InputNumber disabled={disabledFields} />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item
                            label="Description"
                            name={[name, "description"]}
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Input.TextArea
                              autoSize={{ minRows: 1, maxRows: 3 }}
                              disabled={disabledFields}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Form.Item
                            label="Amount"
                            name={[name, "amount"]}
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <InputNumber
                              prefix="$"
                              min={0}
                              style={{ width: "100%" }}
                              disabled={disabledFields}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Form.Item label="Tax" shouldUpdate>
                            {() => {
                              const tEx = formData.getFieldValue("taxExempt");
                              const tPct = tEx
                                ? 0
                                : formData.getFieldValue("taxPercentage") || 0;
                              const amt =
                                formData.getFieldValue([
                                  "lines",
                                  name,
                                  "amount",
                                ]) || 0;
                              const lineTax = parseFloat(
                                ((amt * tPct) / 100).toFixed(2)
                              );
                              return <Input value={`$${lineTax}`} disabled />;
                            }}
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Form.Item label="Line Total" shouldUpdate>
                            {() => {
                              const tEx = formData.getFieldValue("taxExempt");
                              const tPct = tEx
                                ? 0
                                : formData.getFieldValue("taxPercentage") || 0;
                              const amt =
                                formData.getFieldValue([
                                  "lines",
                                  name,
                                  "amount",
                                ]) || 0;
                              const lineTax = parseFloat(
                                ((amt * tPct) / 100).toFixed(2)
                              );
                              const total = parseFloat(
                                (amt + lineTax).toFixed(2)
                              );
                              return <Input value={`$${total}`} disabled />;
                            }}
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            label="Planned Date to Bill"
                            name={[name, "plannedDateToBill"]}
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <DatePicker
                              inputReadOnly
                              style={{ width: "100%" }}
                              disabled={disabledFields}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          {fields.length > 1 && !disabledFields && (
                            <Button
                              danger
                              icon={<DeleteOutlined />}
                              onClick={() => remove(name)}
                            />
                          )}
                        </Col>
                      </Row>
                    </React.Fragment>
                  );
                })}
                <Row justify="center">
                  <Button
                    type="dashed"
                    onClick={() =>
                      add({
                        id: uuidv4(),
                        lineNumber: null,
                        amount: null,
                        plannedDateToBill: null,
                        description: "",
                        tax: 0,
                        total: 0,
                      })
                    }
                    icon={<PlusOutlined />}
                    style={{
                      width: 300,
                      color: COLORS.PRIMARY,
                      borderColor: COLORS.PRIMARY,
                    }}
                  >
                    Add Line
                  </Button>
                </Row>
              </>
            )}
          </Form.List>

          <Form.Item shouldUpdate noStyle>
            {() => {
              const linesVal = formData.getFieldValue("lines") || [];
              const tEx = formData.getFieldValue("taxExempt");
              const tPct = tEx
                ? 0
                : formData.getFieldValue("taxPercentage") || 0;
              const total = linesVal.reduce((acc, l) => {
                const amt = parseFloat(l.amount) || 0;
                const tx = parseFloat(((amt * tPct) / 100).toFixed(2));
                return acc + amt + tx;
              }, 0);
              return (
                <div style={{ marginTop: 20 }}>
                  <Title level={5}>
                    Total: <Tag color="green">{formatMoney(total)}</Tag>
                  </Title>
                </div>
              );
            }}
          </Form.Item>

          <Title level={5} style={{ marginTop: 20 }}>
            Files (At least one required)
          </Title>
          <Form.Item
            name="files"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              { required: true, message: "At least one file is required." },
            ]}
          >
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag files here to upload
              </p>
              <p className="ant-upload-hint">
                They won’t be uploaded until the form is submitted.
              </p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>

      {/* BREAK LINES MODAL */}
      <Modal
        open={breakModalVisible}
        title="Break Lines"
        onOk={handleBreakOk}
        onCancel={handleBreakCancel}
        okText="Apply Break"
        width={800}
      >
        <Title level={5}>Select Lines to Break</Title>
        <Table
          dataSource={eligibleLines}
          columns={[
            {
              title: "Line #",
              dataIndex: "lineNumber",
              key: "lineNumber",
              width: 70,
            },
            {
              title: "Description",
              dataIndex: "description",
              key: "description",
            },
            {
              title: "Amount",
              dataIndex: "amount",
              key: "amount",
              width: 100,
              render: (val) => (
                <Tag color={val > 0 ? "green" : undefined}>
                  {formatMoney(val)}
                </Tag>
              ),
            },
            {
              title: "Planned Date",
              dataIndex: "plannedDateToBill",
              key: "plannedDateToBill",
              width: 130,
              render: (val) => (val ? dayjs(val).format("MM/DD/YYYY") : "N/A"),
            },
          ]}
          rowKey="formIndex"
          pagination={false}
          rowSelection={{
            type: "checkbox",
            onChange: (selectedRowKeys) => {
              setSelectedLineIndices(selectedRowKeys);
            },
          }}
          style={{ marginBottom: 24 }}
        />

        <Title level={5}>
          Define Break Segments (Percentages must add up to 100%)
        </Title>
        <Form form={breakForm} layout="vertical">
          <Form.List
            name="breakSegments"
            initialValue={[{ percentage: null, plannedDateToBill: null }]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Row gutter={16} key={key}>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        name={[name, "percentage"]}
                        fieldKey={[fieldKey, "percentage"]}
                        label="Percentage"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <InputNumber
                          min={1}
                          max={100}
                          style={{ width: "100%" }}
                          addonAfter="%"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        name={[name, "plannedDateToBill"]}
                        fieldKey={[fieldKey, "plannedDateToBill"]}
                        label="Planned Date to Bill"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <DatePicker style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        danger
                        onClick={() => remove(name)}
                        icon={<DeleteOutlined />}
                      />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    block
                  >
                    Add Segment
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>

      {deltaInfo && (
        <AddProjectModulesModal
          visible={addModulesModalVisible}
          onSave={handleAddModulesSave}
          onCancel={handleAddModulesCancel}
          projectId={project.id}
          initialModules={project.modules || []}
          requiredTotal={deltaInfo.requiredTotal}
        />
      )}

      {hasPurchaseOrder && (
        <ProjectBillingModal
          visible={billingModalVisible}
          onCancel={() => {
            setBillingModalVisible(false);
            setEditingAr(null);
          }}
          project={project}
          editingAr={editingAr}
        />
      )}
    </div>
  );
}

export default PurchaseAndChangeOrders;
