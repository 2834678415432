// src/components/FreightAgents/FreightAgents.js

import React, { useState, useMemo, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Tooltip,
  Tag,
  Space,
  Modal,
  List,
} from "antd";
import { useFreightAgents } from "../../Contexts/useSpecificData";
import HeaderText from "../../Styled/HeaderText";
import { SearchInput } from "../../Styled/SearchInput";
import {
  PlusOutlined,
  StopOutlined,
  QuestionCircleOutlined,
  FilePdfOutlined,
  WarningOutlined, // Imported PDF icon
} from "@ant-design/icons";
import dayjs from "dayjs";
import { COLORS } from "../../constants";
import DisplayPhoneNumber from "../../Formatters/DisplayPhoneNumber";
import { formatDate } from "../../Formatters/helpers";
import AddFreightAgentModal from "./AddFreightAgentModal";

function FreightAgents() {
  const {
    data: freightAgents,
    refresh: refreshFreightAgents,
    loading,
  } = useFreightAgents();

  useEffect(() => {
    document.title = "Freight Agents";
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [documentsModal, setDocumentsModal] = useState({
    visible: false,
    documents: [],
    title: "",
  }); // New state for Documents Modal

  const handleAddOrEditSuccess = () => {
    refreshFreightAgents();
    setIsModalVisible(false);
    setSelectedAgent(null);
  };

  /**
   * Extracts the primary contact from the contacts array.
   * If no contact is marked as primary, defaults to the first contact.
   * @param {Array} contacts - Array of contact objects.
   * @returns {Object|null} - The primary contact object or null if no contacts exist.
   */
  const getPrimaryContact = (contacts) => {
    if (!contacts || contacts.length === 0) return null;
    const primary = contacts.find((contact) => contact.primary);
    return primary || contacts[0];
  };

  // Memoize sorted freight agents to optimize performance
  const sortedFreightAgents = useMemo(() => {
    if (!freightAgents) return [];
    return [...freightAgents].sort((a, b) =>
      (a.companyName || "").localeCompare(b.companyName || "")
    );
  }, [freightAgents]);

  // Memoize filtered freight agents based on search term
  const filteredFreightAgents = useMemo(() => {
    if (!sortedFreightAgents) return [];

    return sortedFreightAgents.filter((agent) => {
      const term = searchTerm.toLowerCase();
      const companyName = (agent.companyName || "").toLowerCase();

      // Extract primary contact
      const primaryContact = getPrimaryContact(agent.contacts);

      // If no primary contact, skip matching contact fields
      if (!primaryContact) {
        return companyName.includes(term);
      }

      const contactName = `${(primaryContact.firstName || "").toLowerCase()} ${(
        primaryContact.lastName || ""
      ).toLowerCase()}`;
      const contactEmail = (primaryContact.email || "").toLowerCase();
      const contactPhone = (primaryContact.phone || "").toLowerCase();

      return (
        companyName.includes(term) ||
        contactName.includes(term) ||
        contactEmail.includes(term) ||
        contactPhone.includes(term)
      );
    });
  }, [sortedFreightAgents, searchTerm]);

  // Extract unique types for dynamic filters
  const uniqueTypes = useMemo(() => {
    if (!sortedFreightAgents) return [];
    return [
      ...new Set(
        sortedFreightAgents
          .map((agent) => agent.type)
          .filter((type) => type && type.trim() !== "")
      ),
    ];
  }, [sortedFreightAgents]);

  // Prepare the data source for the table with primary contact fields
  const dataSource = useMemo(() => {
    if (!filteredFreightAgents) return [];

    return filteredFreightAgents.map((agent) => {
      const primaryContact = getPrimaryContact(agent.contacts);

      return {
        key: agent.id,
        ...agent,
        // Flatten primary contact fields for easier access in table columns
        primaryFirstName: primaryContact ? primaryContact.firstName : "",
        primaryLastName: primaryContact ? primaryContact.lastName : "",
        primaryEmail: primaryContact ? primaryContact.email : "",
        primaryPhone: primaryContact ? primaryContact.phone : "",
        primaryPhoneExtension: primaryContact
          ? primaryContact.phoneExtension
          : "",
      };
    });
  }, [filteredFreightAgents]);

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      sorter: (a, b) =>
        (a.companyName || "").localeCompare(b.companyName || ""),
      render: (text, record) => (
        <>
          <a
            onClick={() => {
              setSelectedAgent(record); // Pass the entire record for editing
              setIsModalVisible(true);
            }}
          >
            {text || (
              <Tooltip title="No Company Name">
                <QuestionCircleOutlined />
              </Tooltip>
            )}
          </a>
          {record.disabled && (
            <Tag
              color="black"
              style={{
                marginLeft: 8,
                position: "absolute",
                top: "50%", // Move to 50% from the top of the parent
                right: -50,
                zIndex: 100,
                transform: "translateY(-50%)", // Shift up by 50% of its own height
              }}
              icon={<StopOutlined  />}
            >
              BLACKLISTED
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Name",
      key: "contactName",
      render: (text, record) =>
        record.primaryFirstName || record.primaryLastName ? (
          `${record.primaryFirstName || ""} ${record.primaryLastName || ""}`
        ) : (
          <Tooltip title="No Contact Name">
            <StopOutlined style={{ color: COLORS.WARNING }} />
          </Tooltip>
        ),
    },
    {
      title: "Email",
      dataIndex: "primaryEmail",
      key: "primaryEmail",
      render: (text) =>
        text ? (
          <div>{text}</div>
        ) : (
          <Tooltip title="No Email">
            <StopOutlined style={{ color: COLORS.WARNING }} />
          </Tooltip>
        ),
    },
    {
      title: "Phone Number",
      dataIndex: "primaryPhone",
      key: "primaryPhone",

      render: (text, record) =>
        text ? (
          <div style={{ minWidth: "150px" }}>
            <DisplayPhoneNumber
              phoneNumber={text}
              ext={record.primaryPhoneExtension}
            />
          </div>
        ) : (
          <Tooltip title="No Phone Number">
            <StopOutlined style={{ color: COLORS.WARNING }} />
          </Tooltip>
        ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      filters: uniqueTypes.map((type) => ({
        text: type,
        value: type,
      })),
      onFilter: (value, record) => record.type === value,
      render: (text) => {
        if (!text) return <Tag color="default">-</Tag>; // Handle empty type
        return <Tag color="blue">{text}</Tag>;
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (text) =>
        text ? (
          <span>{text}</span>
        ) : (
          <Tooltip title="No Notes">
            <StopOutlined style={{ color: COLORS.WARNING }} />
          </Tooltip>
        ),
    },
    {
      title: "COI Expiration",
      dataIndex: "coiExpiration",
      key: "coiExpiration",
      sorter: (a, b) =>
        new Date(a.coiExpiration || 0).getTime() -
        new Date(b.coiExpiration || 0).getTime(),
      render: (text) =>
        text ? (
          dayjs(text).isBefore(dayjs(), "day") ? (
            <Tooltip title="COI Expired">
              <Tag color="red">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <WarningOutlined style={{ marginRight: 4 }} />
                  {formatDate(text)}
                </div>
              </Tag>
            </Tooltip>
          ) : (
            formatDate(text)
          )
        ) : (
          <Tooltip title="No COI Expiration">
            <StopOutlined style={{ color: COLORS.WARNING }} />
          </Tooltip>
        ),
    },
    {
      title: "COI Documents",
      dataIndex: "documents",
      key: "documents",
      render: (documents, record) =>
        documents && documents.length > 0 ? (
          <FilePdfOutlined
            style={{
              fontSize: "18px",
              cursor: "pointer",
              color: COLORS.PRIMARY,
            }}
            onClick={() => {
              setDocumentsModal({
                visible: true,
                documents: documents,
                title: `${record.companyName} COI Documents`,
              });
            }}
          />
        ) : (
          <Tooltip title="No Documents">
            <StopOutlined style={{ color: COLORS.WARNING }} />
          </Tooltip>
        ),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <HeaderText text={"Freight Agents"} />
      </Col>
      <Col span={12} style={{ textAlign: "right" }}>
        <AddFreightAgentModal onAddSuccess={handleAddOrEditSuccess}>
          <Button type="primary" icon={<PlusOutlined />}>
            Add Freight Agent
          </Button>
        </AddFreightAgentModal>
      </Col>
      <Col span={24}>
        <SearchInput
          placeholder="Search Freight Agents"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          resultsLength={filteredFreightAgents.length}
        />
      </Col>
      <Col span={24}>
        <Table
          size="small"
          columns={columns}
          dataSource={dataSource}
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
          }}
          loading={loading}
          rowKey="id"
          rowClassName={(record) => (record.disabled ? "disabled" : "")} // Added line
        />
      </Col>

      {/* Add/Edit FreightAgent Modal */}
      <AddFreightAgentModal
        agent={selectedAgent}
        visible={isModalVisible}
        onAddSuccess={handleAddOrEditSuccess}
        onCancel={() => {
          setIsModalVisible(false);
          setSelectedAgent(null);
        }}
      />

      {/* Documents Modal */}
      <Modal
        title={documentsModal.title}
        visible={documentsModal.visible}
        onCancel={() =>
          setDocumentsModal({ ...documentsModal, visible: false })
        }
        footer={[
          <Button
            key="close"
            onClick={() =>
              setDocumentsModal({ ...documentsModal, visible: false })
            }
          >
            Close
          </Button>,
        ]}
        width={600}
      >
        {documentsModal.documents && documentsModal.documents.length > 0 ? (
          <List
            dataSource={documentsModal.documents}
            renderItem={(docUrl, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<FilePdfOutlined />}
                  title={
                    <a href={docUrl} target="_blank" rel="noopener noreferrer">
                      COI Document {index + 1}
                    </a>
                  }
                />
              </List.Item>
            )}
          />
        ) : (
          <p>No documents available.</p>
        )}
      </Modal>
    </Row>
  );
}

export default FreightAgents;
