// useTitleModifier.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function useTitleModifier() {
  const location = useLocation();

  useEffect(() => {
    // Determine if we're in dev mode.
    const isDev = process.env.REACT_APP_STAGE === "_dev";

    // Define your route-to-prefix mappings.
    const routePrefixes = [
      { path: "/project-management", prefix: "[PM] " },
      { path: "/production", prefix: "[PRO] " },
      { path: "/admin", prefix: "[ADM] " },
      { path: "/accounts-receivable", prefix: "[AR] " },
      { path: "/estimating", prefix: "[EST] " },
      { path: "/logistics", prefix: "[LOG] " },
      { path: "/purchasing", prefix: "[PUR] " },
      { path: "/receiving", prefix: "[REC] " },
      { path: "/shipping", prefix: "[SHIP] " },
      {path: "/operations", prefix: "[OPS] "},
    ];

    // Find the appropriate route prefix based on the current pathname.
    let routePrefix = "";
    for (const { path, prefix } of routePrefixes) {
      if (location.pathname.startsWith(path)) {
        routePrefix = prefix;
        break;
      }
    }

    // Compose the desired prefix.
    // If not in dev mode and no route prefix is found, desiredPrefix will be empty.
    const desiredPrefix = (isDev ? "[DEV] " : "") + routePrefix;

    // Ensure the document has a <title> element.
    let titleElement = document.querySelector("title");
    if (!titleElement) {
      titleElement = document.createElement("title");
      document.head.appendChild(titleElement);
    }

    // This function will update the title by removing any known prefixes
    // and then prepending the current desiredPrefix.
    const updateTitle = () => {
      const currentTitle = document.title;
      // This regex strips off any leading [DEV], [PM], [PRO], etc. prefixes.
      const cleanedTitle = currentTitle.replace(
        /^(?:\[DEV\]\s*)?(?:\[PM\]\s*|\[PRO\]\s*|\[ADM\]\s*|\[AR\]\s*|\[EST\]\s*|\[LOG\]\s*|\[PUR\]\s*|\[REC\]\s*|\[SHIP\]\s*|\[OPS\]\s*)?/,
        ""
      );
      document.title = desiredPrefix + cleanedTitle;
    };

    // Run an initial update.
    updateTitle();

    // Set up a MutationObserver to watch for changes to the title.
    const observer = new MutationObserver(() => {
      if (!document.title.startsWith(desiredPrefix)) {
        updateTitle();
      }
    });
    observer.observe(titleElement, { childList: true });

    // Clean up the observer on unmount or when location changes.
    return () => {
      observer.disconnect();
    };
  }, [location.pathname]);

  // (Optional) Return nothing — this hook simply modifies the title.
}

export default useTitleModifier;
