import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Row, Card, Space, Typography, Tabs, Tag, } from "antd";
import { ArrowRightOutlined, CheckCircleOutlined, EditOutlined, InfoCircleOutlined, TruckOutlined } from "@ant-design/icons";
import {
  useOutboundShipments,
    useManufacturingLocations,
  useProjects,
} from "../../Contexts/useSpecificData";
import { Loader } from "../../Styled/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-regular-svg-icons";
import AddOutboundShipmentModal from "./AddOutboundShipmentModal";
import OutboundFreight from "./OutboundFreight"; // Updated tab component
import { FaRProject } from "react-icons/fa";
import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons";
import OutboundBOL from "./OutboundBOL";
import OutboundRFQ from "./OutboundRFQ";
import OutboundPurchaseOrder from "./OutboundPurchaseOrder";
import { getShipmentItems } from "./getShipmentItems";
import OutboundTimeline from "./OutboundTimeline";



const { Text } = Typography;

function BuildOutboundShipment() {
  const { id, tab } = useParams(); // Retrieve shipment ID and tab from URL
  const navigate = useNavigate();

  const { data: outboundShipments, refresh: refreshShipments } =
    useOutboundShipments();
    const { data: manufacturingLocations } = useManufacturingLocations();
    const { data: projects } = useProjects();

  const [shipment, setShipment] = useState(null);
  const [loading, setLoading] = useState(true);
    const [editModalVisible, setEditModalVisible] = useState(false);
    
    const matchedProject = projects.find((project) =>   
        project.id === shipment?.projectId
  );
  
  const isSigned = shipment?.bol?.signed;

  // Tab definitions
  const tabItems = [
    {
      key: "freight",
      label: "Freight",
      children: (
        <OutboundFreight
          shipment={shipment}
          project={matchedProject}
          allShipments={outboundShipments}
        />
      ), // Pass shipment to OutboundFreight
    },

    {
      key: "rfq",
      label: "RFQ",
      children: (
        <OutboundRFQ
          shipment={shipment}
          project={matchedProject}
          allShipments={outboundShipments}
        />
      ),
    },
    {
      key: "purchase-order",
      label: "Purchase Order",
      children: (
        <OutboundPurchaseOrder
          shipment={shipment}
          project={matchedProject}
          allShipments={outboundShipments}
        />
      ),
    },
    {
      key: "bol",
      label: "BOL",
      children: (
        <OutboundBOL
          shipment={shipment}
          project={matchedProject}
          allShipments={outboundShipments}
        />
      ),
    },
    {
      key: "timeline",
      label: "Timeline",
      children: <OutboundTimeline shipment={shipment} />,
    },
  ];

  const currentTab = tab || "freight";

  // Handle shipment loading and setting
  useEffect(() => {
    if (outboundShipments === undefined) {
      setLoading(true);
      return;
    }

    if (outboundShipments === null) {
      return;
    }

    const foundShipment = outboundShipments.find((ship) => ship.id === id);
    if (foundShipment) {
      setShipment(foundShipment);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [outboundShipments, id]);

  // Update document title based on shipment
  useEffect(() => {
    if (shipment) {
      const shipmentNumber = shipment.shipmentNumber || "Unnamed Shipment";
      document.title = `${shipmentNumber} - Shipment Details`;
    } else {
      document.title = "Build Outbound Shipment";
    }
  }, [shipment]);

  // Redirect to default tab if 'tab' param is invalid
  useEffect(() => {
    const tabKeys = tabItems.map((tab) => tab.key);

    if (tab && !tabKeys.includes(tab)) {
      navigate(`/logistics/outbound/${id}/freight`, {
        replace: true,
      });
    }
  }, [tab, id, navigate, tabItems]);

  const handleTabChange = (activeKey) => {
    navigate(`/logistics/outbound/${id}/${activeKey}`);
  };

  const onUpdateSuccess = () => {
    refreshShipments();
    setEditModalVisible(false);
  };

  if (loading) {
    return <Loader />;
  }

  const manufacturingLocation =
    manufacturingLocations.find(
      (loc) => loc.id === shipment.manufacturingLocationId
    ) || {};
  
    let truckModules = [];
    let truckPallets = [];
    if (matchedProject && shipment) {
      const result = getShipmentItems(matchedProject, shipment.id);
      truckModules = result.truckModules || [];
      truckPallets = result.truckPallets || [];
    }


  return (
    <div>
      {/* Header Section */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card
            style={{
              backgroundColor: "white",
              cursor: isSigned ? "default" : "pointer",
              position: "relative",
            }}
            size="small"
            onClick={() => (isSigned ? null : setEditModalVisible(true))} // Open modal on card click
          >
            {isSigned ? (
              <Tag
                color="green"
                icon={<CheckCircleOutlined />}
                style={{ position: "absolute", top: 16, right: 16 }}
              >
                Shipped
              </Tag>
            ) : (
              <EditOutlined
                style={{
                  fontSize: "18px",
                  color: "#08c",
                  position: "absolute",
                  top: 16,
                  right: 16,
                }}
              />
            )}

            <Row gutter={16}>
              <Col span={24}>
                <Space
                  direction="horizontal"
                  size="large"
                  align="center"
                  style={{ width: "100%" }}
                >
                  {/* Shipment Number */}
                  <div>
                    <div style={{ fontSize: 18, fontWeight: 600 }}>
                      {shipment.shipmentNumber || "N/A"}
                    </div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <InfoCircleOutlined
                      style={{ marginRight: 8, fontWeight: 600 }}
                    />
                    <Text style={{ fontWeight: 600 }}>
                      {matchedProject?.projectName || "Unknown Project"}
                    </Text>
                  </div>

                  {/* Manufacturing Location and Destination */}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                      icon={faMap}
                      style={{ color: "#555", marginRight: 8 }}
                    />
                    <Text>
                      {manufacturingLocation.nickname ||
                        "Unknown Manufacturing Location"}
                    </Text>
                    <ArrowRightOutlined style={{ margin: "0 8px" }} />
                    <Text>
                      {shipment?.destination.address ||
                        "Unknown Destination Address"}
                    </Text>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TruckOutlined style={{ marginRight: 8 }} />
                    <Text style={{}}>
                      {shipment?.truckType || "Truck Type Unknown"}
                    </Text>
                  </div>

                  <div>
                    {truckModules.map((module) => (
                      <Tag color="blue" key={`module-${module.id}`}>
                        {matchedProject?.projectName +
                          "-" +
                          (module.number || module.id)}
                      </Tag>
                    ))}
                    {truckPallets.map((pallet) => (
                      <Tag color="purple" key={`pallet-${pallet.id}`}>
                        {pallet.palletName || pallet.id}
                      </Tag>
                    ))}
                  </div>
                </Space>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      {/* Tabs Section */}
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col span={24}>
          <Tabs
            activeKey={currentTab}
            onChange={handleTabChange}
            type="card"
            items={tabItems}
          />
        </Col>
      </Row>

      {/* Edit Modal */}
      {editModalVisible && (
        <AddOutboundShipmentModal
          visible={editModalVisible}
          onCancel={() => setEditModalVisible(false)}
          shipment={shipment}
          manufacturingLocations={manufacturingLocations || []}
          existingShipments={shipment || []}
          onUpdateSuccess={onUpdateSuccess}
        />
      )}
    </div>
  );
}

export default BuildOutboundShipment;
