// src/hooks/useCurrentUser.js
import { useUser } from "@clerk/clerk-react";
import { useAriaUsers } from "../Contexts/useSpecificData";

export default function useCurrentUser() {
  const { data: users } = useAriaUsers();
  const { user } = useUser();
  
  const ariaUser = users.find((u) => u.id === user?.id);
  const signature = ariaUser?.signature

  const userName = user?.fullName;
    const userEmail = user?.primaryEmailAddress?.emailAddress;
    const userFirstName = user?.firstName;
    const userLastName = user?.lastName;
  const userId = user?.id;

  return { userName, userEmail, userFirstName, userLastName, userId, signature };
}
