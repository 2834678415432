import React, { useState, useMemo, useEffect } from "react";
import {
  Card,
  Select,
  Button,
  Input,
  message,
  Row,
  Col,
  Spin,
  Tag,
  DatePicker,
  Modal,
  Upload,
} from "antd";
import {
  FilePdfOutlined,
  MailOutlined,
  PlusOutlined,
  StopOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { IconSignature, IconClockShare } from "@tabler/icons-react";

import {
  useFreightAgents,
  useOutboundShipments,
  useCustomers,
} from "../../Contexts/useSpecificData";
import { generatePdf } from "../../Functions/generatePdf";
import { getShipmentItems } from "./getShipmentItems";
import HeaderText from "../../Styled/HeaderText";
import useCurrentUser from "../../hooks/useCurrentUser";
import { updateById } from "../../Functions/updateById";
import { TABLES, COLORS } from "../../constants";
import { formatPhoneNumber } from "../../Formatters/formatPhoneNumber";
import generateHtmlOutboundBOL from "./generateHtmlOutboundBOL";
import { s3Upload } from "../../Functions/s3Upload";
import DisplayPhoneNumber from "../../Formatters/DisplayPhoneNumber";

function OutboundBOL({ shipment, project }) {
  const { data: freightAgents } = useFreightAgents();
  const { data: shipments } = useOutboundShipments();
  const { data: customers } = useCustomers();
  const { userName } = useCurrentUser();
  const [loading, setLoading] = useState(false);

  // Helper for updating the history
  async function pushShipmentHistory(actionDesc) {
    try {
      const existingHistory = shipment.history || [];
      const newHistory = [
        ...existingHistory,
        {
          action: actionDesc,
          submittedBy: userName,
          date: new Date().toISOString(),
        },
      ];
      await updateById(
        TABLES.OUTBOUND_SHIPMENTS,
        { history: newHistory },
        shipment.id
      );
    } catch (error) {
      console.error("Error updating shipment history:", error);
    }
  }

  const today = dayjs().format("YYYY-MM-DD HH:mm:ss");

  const isSigned = !!shipment?.bol?.signed?.pdfUrl;
  const isCountersigned = !!shipment?.bol?.countersigned?.pdfUrl;

  const customer = customers.find((c) => c.id === project?.customerId);

  // Freight Agent
  const initialAgentId =
    shipment?.bol?.freightAgentId ??
    shipment?.purchaseOrder?.freightAgentId ??
    null;

  const filteredAgents = useMemo(() => {
    return (freightAgents || [])
      .filter((fa) => !fa.disabled)
      .sort((a, b) => (a.companyName || "").localeCompare(b.companyName || ""));
  }, [freightAgents]);

  const [selectedAgentId, setSelectedAgentId] = useState(initialAgentId);

  const selectedAgent = useMemo(() => {
    if (!selectedAgentId) return null;
    return filteredAgents.find((fa) => fa.id === selectedAgentId) || null;
  }, [filteredAgents, selectedAgentId]);

  const primaryContact = useMemo(() => {
    if (!selectedAgent?.contacts?.length) return {};
    return selectedAgent.contacts.find((contact) => contact.primary) || {};
  }, [selectedAgent]);

  const freightAgentName =
    [primaryContact.firstName, primaryContact.lastName]
      .filter(Boolean)
      .join(" ") || "";
  const freightAgentCompany = selectedAgent?.companyName || "";
  const freightAgentPhone = formatPhoneNumber(primaryContact.phone) || "";

  // Customer contacts
  const customerContacts = useMemo(() => {
    if (!customer?.contacts) return [];
    return customer.contacts;
  }, [customer]);

  const initialRecipientContactId = useMemo(() => {
    if (shipment?.bol?.recipientContactId) {
      return shipment.bol.recipientContactId;
    }
    const primary = customerContacts.find((c) => c.primary);
    return primary?.id || null;
  }, [shipment, customerContacts]);
  const [selectedRecipientContactId, setSelectedRecipientContactId] = useState(
    initialRecipientContactId
  );

  const initialRecipientName =
    shipment?.bol?.recipientName ??
    [project?.contactFirstName, project?.contactLastName]
      .filter(Boolean)
      .join(" ");
  const initialRecipientPhone =
    shipment?.bol?.recipientPhone ?? project?.contactPhoneNumber ?? "";

  const [recipientName, setRecipientName] = useState(initialRecipientName);
  const [recipientPhone, setRecipientPhone] = useState(
    formatPhoneNumber(initialRecipientPhone)
  );

  // New state for recipient email
  const initialRecipientEmail =
    shipment?.bol?.recipientEmail ??
    (() => {
      if (customerContacts.length && selectedRecipientContactId) {
        const contact = customerContacts.find(
          (c) => c.id === selectedRecipientContactId
        );
        return contact?.email || "";
      }
      return "";
    })();
  const [recipientEmail, setRecipientEmail] = useState(initialRecipientEmail);

  useEffect(() => {
    if (!customerContacts.length || !selectedRecipientContactId) return;
    const contact = customerContacts.find(
      (c) => c.id === selectedRecipientContactId
    );
    if (contact) {
      const newName = [contact.firstName, contact.lastName]
        .filter(Boolean)
        .join(" ");
      const newPhone = formatPhoneNumber(contact.phone || "");
      setRecipientName(newName);
      setRecipientPhone(newPhone);
      if (contact.email) {
        setRecipientEmail(contact.email);
      }
    }
  }, [selectedRecipientContactId, customerContacts]);

  const initialNotes = shipment?.bol?.notes ?? "";
  const [notes, setNotes] = useState(initialNotes);

  // NEW: State for "Address To" field (first name for email distribution)
  const [addressTo, setAddressTo] = useState(project?.emailTo || "");

  function computeNextBolNumber() {
    const existingNums = shipments
      .filter((s) => s?.bol?.bolNumber)
      .map((s) => {
        const parts = (s.bol.bolNumber || "").replace("BOL-", "");
        const num = parseInt(parts, 10);
        return Number.isNaN(num) ? 0 : num;
      });
    const highest = existingNums.length ? Math.max(...existingNums) : 0;
    return `BOL-${highest + 1}`;
  }
  const initialBolNumber = shipment?.bol?.bolNumber || computeNextBolNumber();
  const [bolNumber, setBolNumber] = useState(initialBolNumber);

  // ----------- Email Distribution -----------
  // Default distribution emails: if project.distributionEmails exist, use them;
  // otherwise, add freight agent primary email, recipient email, and project.assignedTo.email.
  const [distributionEmails, setDistributionEmails] = useState(() => {
    if (project?.distributionEmails && project.distributionEmails.length) {
      return project.distributionEmails;
    }
    const emails = [];
    if (primaryContact?.email) emails.push(primaryContact.email);
    if (initialRecipientEmail) emails.push(initialRecipientEmail);
    if (project?.assignedTo?.email) emails.push(project.assignedTo.email);
    return [...new Set(emails)];
  });

  // State for new email input
  const [newEmail, setNewEmail] = useState("");

  const handleRemoveEmail = (emailToRemove) => {
    // Prevent removal if it is the assignedTo email
    if (emailToRemove === project?.assignedTo?.email) return;
    setDistributionEmails(
      distributionEmails.filter((e) => e !== emailToRemove)
    );
  };

  const handleAddEmail = () => {
    if (!newEmail) return;
    if (!newEmail.includes("@")) {
      message.warning("Please enter a valid email address");
      return;
    }
    if (distributionEmails.includes(newEmail)) {
      message.warning("This email is already added");
      return;
    }
    setDistributionEmails([...distributionEmails, newEmail]);
    setNewEmail("");
  };

  // Items for PDF
  const { truckModules, truckPallets } = useMemo(() => {
    if (!project || !shipment?.id) {
      return { truckModules: [], truckPallets: [] };
    }
    return getShipmentItems(project, shipment.id);
  }, [project, shipment?.id]);

  const emptyShipment = !truckModules.length && !truckPallets.length;

  const onTruckItems = useMemo(() => {
    const modItems = truckModules.map((m) => ({
      type: "Module",
      id: m.id,
      weight: m.weight,
      weightIn: m.weightIn,
      height: m.height,
      length: m.length,
      width: m.width,
      heightIn: m.heightIn,
      lengthIn: m.lengthIn,
      widthIn: m.widthIn,
      description: project?.projectName + "-" + (m.number ?? "Module"),
    }));
    const palItems = truckPallets.map((p) => ({
      type: "Pallet",
      id: p.id,
      weight: p.weight,
      height: p.height,
      length: p.length,
      width: p.width,
      weightIn: p.weightIn,
      heightIn: p.heightIn,
      lengthIn: p.lengthIn,
      widthIn: p.widthIn,
      description: p.palletName || "Pallet",
    }));
    return [...modItems, ...palItems];
  }, [truckModules, truckPallets, project?.projectName]);

  const receiverCompany = project?.companyName || "N/A";

const formattedAddressTo =
  addressTo.trim().length > 0
    ? addressTo
        .trim()
        .split(/\s+/)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : "";



  const [htmlPreview, setHtmlPreview] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfLoading, setPdfLoading] = useState(false);

  useEffect(() => {
    const html = generateHtmlOutboundBOL({
      projectName: project?.projectName || "Untitled Project",
      origin: shipment?.origin?.address || "Unknown Origin",
      destination: shipment?.destination?.address || "Unknown Destination",
      items: onTruckItems,
      customDescription: notes,
      shipperContact: userName,
      freightAgentCompany: freightAgentCompany,
      freightAgentName: freightAgentName,
      freightAgentPhone: freightAgentPhone,
      receiverCompany,
      receiverName: recipientName,
      receiverPhone: formatPhoneNumber(recipientPhone),
      bolNumber,
    });
    setHtmlPreview(html);
    setPdfUrl("");
  }, [
    project,
    shipment,
    onTruckItems,
    notes,
    userName,
    freightAgentName,
    receiverCompany,
    recipientName,
    recipientPhone,
    bolNumber,
  ]);

  // -------------- Generate PDF --------------
  async function handleGeneratePdf() {
    if (!selectedAgentId) {
      message.warning("Please select a Freight Agent first.");
      return;
    }
    if (!recipientName || !recipientPhone) {
      message.warning("Recipient Name and Phone are required.");
      return;
    }
    if (!htmlPreview) {
      message.warning("No HTML to generate PDF from!");
      return;
    }

    if (pdfUrl) {
      window.open(pdfUrl, "_blank");
      return pdfUrl;
    }

    const newTab = window.open("", "_blank");
    if (newTab) {
      newTab.document.write(`
        <html>
          <head><title>Generating PDF...</title></head>
          <body>
            <p style="font-family: Arial; text-align: center; margin-top: 20%;">
              Generating your PDF, please wait...
            </p>
          </body>
        </html>
      `);
    }

    setPdfLoading(true);
    try {
      const fileName = `BOL_${project?.projectName}_${bolNumber}_${today}.pdf`;
      const result = await generatePdf(htmlPreview, fileName);

      if (result && result.location) {
        setPdfUrl(result.location);
        message.success("PDF generated successfully!");

        const contact = recipientName || "N/A";
        const notesSnippet = notes ? `, Notes: ${notes}` : "";
        const actionDesc = `BOL PDF Generated (Agent: ${freightAgentName}, Recipient: ${contact}${notesSnippet})`;
        await pushShipmentHistory(actionDesc);

        if (newTab) {
          newTab.location = result.location;
        }
      } else {
        message.error("PDF generation failed. No location returned.");
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
      message.error("Failed to generate PDF. Check console for details.");
    } finally {
      setPdfLoading(false);
    }
  }

  // -------------- Release BOL --------------
  async function handleSave() {
    if (!selectedAgentId) {
      message.warning("Select a Freight Agent before saving.");
      return;
    }
    if (!recipientName || !recipientPhone) {
      message.warning("Recipient Name and Phone cannot be empty.");
      return;
    }

    try {
      const existingBol = shipment.bol ? { ...shipment.bol } : {};

      existingBol.freightAgentId = selectedAgentId;
      existingBol.recipientContactId = selectedRecipientContactId || null;
      existingBol.recipientName = recipientName;
      existingBol.recipientPhone = recipientPhone;
      existingBol.recipientEmail = recipientEmail;
      existingBol.notes = notes;
      existingBol.bolNumber = bolNumber;
      existingBol.createdBy = userName;

      await updateById(
        TABLES.OUTBOUND_SHIPMENTS,
        { bol: existingBol },
        shipment.id
      );
      message.success("BOL Released to Shipping!");

      // Update project with distribution emails and "Address To"
      await updateById(
        TABLES.PROJECTS,
        { distributionEmails: distributionEmails, emailTo: formattedAddressTo },
        project.id
      );

      const contact = recipientName || "N/A";
      const notesSnippet = notes ? `, Notes: ${notes}` : "";
      const actionDesc = `BOL Released to Shipping (Agent: ${freightAgentName}, Recipient: ${contact}${notesSnippet})`;
      await pushShipmentHistory(actionDesc);
    } catch (error) {
      console.error("Error saving BOL info:", error);
      message.error("Failed to save. Check console for details.");
    }
  }

  // -------------- Countersign BOL --------------
  const [countersignModalVisible, setCountersignModalVisible] = useState(false);
  const [countersignDate, setCountersignDate] = useState(null);
  const [countersignFileList, setCountersignFileList] = useState([]);

  const handleCountersignUploadSave = async () => {
    if (!countersignFileList.length) {
      message.warning("Please select a PDF file first.");
      return;
    }
    try {
      setLoading(true);
      const countersignFileName = `BOL_${project?.projectName}_${bolNumber}_${today}_CUSTOMER_SIGNED.pdf`;
      const fileObj = countersignFileList[0].originFileObj;
      const s3Url = await s3Upload(fileObj, countersignFileName);

      if (!s3Url) {
        message.error(
          "Failed to upload Customer Signed BOL PDF. No URL returned."
        );
        return;
      }

      const existingBol = shipment.bol ? { ...shipment.bol } : {};
      if (!existingBol.countersigned) {
        existingBol.countersigned = {};
      }
      existingBol.countersigned.pdfUrl = s3Url;
      existingBol.countersigned.dateSigned = countersignDate
        ? countersignDate.toISOString()
        : new Date().toISOString();
      existingBol.countersigned.dateAdded = new Date().toISOString();

      await updateById(
        TABLES.OUTBOUND_SHIPMENTS,
        { bol: existingBol },
        shipment.id
      );

      message.success("Customer Signed BOL uploaded successfully!");
      await pushShipmentHistory("Delivered BOL Uploaded");
      setCountersignModalVisible(false);
      setLoading(false);
    } catch (error) {
      console.error("Error uploading countersigned BOL:", error);
      message.error(
        "Error uploading countersigned BOL. Check console for details."
      );
    }
  };

  // Compute if Release BOL should be disabled
  const isReleaseDisabled =
    emptyShipment ||
    !bolNumber ||
    !selectedAgentId ||
    !selectedRecipientContactId ||
    !addressTo.trim() ||
    distributionEmails.length === 0;

  // Render Signed & Countersigned PDFs
  function renderBOLDocuments() {
    const signedBOL = shipment?.bol?.signed;
    const countersignedBOL = shipment?.bol?.countersigned;

    return (
      <Card title="BOL documents" style={{ marginBottom: 16 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", flex: 1 }}>
            {signedBOL?.pdfUrl ? (
              <>
                <div>
                  <a
                    href={signedBOL.pdfUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FilePdfOutlined
                      style={{ fontSize: 40, color: COLORS.PRIMARY }}
                    />
                  </a>
                </div>
                <div style={{ marginTop: 8 }}>
                  <div>Carrier BOL</div>
                  {signedBOL.pickupDate && (
                    <div style={{ marginTop: 4 }}>
                      {dayjs(signedBOL.pickupDate).format("MMM D, YYYY")}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div style={{ position: "relative", display: "inline-block" }}>
                <FilePdfOutlined style={{ fontSize: 40, color: "gray" }} />
                <StopOutlined
                  style={{
                    fontSize: 40,
                    color: "gray",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
            )}
          </div>

          <div style={{ textAlign: "center", flex: 1 }}>
            {countersignedBOL?.pdfUrl ? (
              <>
                <div>
                  <a
                    href={countersignedBOL.pdfUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FilePdfOutlined
                      style={{ fontSize: 40, color: COLORS.PRIMARY }}
                    />
                  </a>
                </div>
                <div style={{ marginTop: 8 }}>
                  <div>Delivered BOL</div>
                  {countersignedBOL.dateSigned && (
                    <div style={{ marginTop: 4 }}>
                      {dayjs(countersignedBOL.dateSigned).format("MMM D, YYYY")}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div style={{ position: "relative", display: "inline-block" }}>
                  <FilePdfOutlined style={{ fontSize: 40, color: "gray" }} />
                </div>
                <div style={{ marginTop: 8 }}>
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => setCountersignModalVisible(true)}
                  >
                    Add Delivered BOL
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </Card>
    );
  }

  // Compute last BOL release history (if any)
  const releaseHistory = shipment?.history?.filter((h) =>
    h.action.startsWith("BOL Released to Shipping")
  );
  const lastBOLRelease =
    releaseHistory && releaseHistory.length
      ? releaseHistory[releaseHistory.length - 1]
      : null;

  // Right Card extra
const rightCardExtra = (
  <div style={{ display: "flex", alignItems: "center" }}>
    <Button
      icon={<FilePdfOutlined />}
      onClick={handleGeneratePdf}
      disabled={emptyShipment}
    >
      Generate PDF
    </Button>
    {isSigned ? (
      <Button
        disabled
        style={{ marginLeft: 8 }}
        icon={<IconSignature size={16} />}
      >
        Signed
      </Button>
    ) : (
      <Button
        type="primary"
        style={{ marginLeft: 8 }}
        onClick={handleSave}
        disabled={isReleaseDisabled}
        icon={<IconClockShare size={14} />}
      >
        Release BOL
      </Button>
    )}
  </div>
);


  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} lg={12}>
        <Card
          title={
            <div>
              Build BOL
              {lastBOLRelease && (
                <div style={{ fontSize: 12, color: "#555", marginTop: 4 }}>
                  BOL last released by {lastBOLRelease.submittedBy} on{" "}
                  {dayjs(lastBOLRelease.date).format("MMM D, YYYY h:mm A")}
                </div>
              )}
            </div>
          }
          extra={<Tag>{bolNumber}</Tag>}
          style={{ marginBottom: 16 }}
        >
          {shipment?.bol?.signed?.pdfUrl && renderBOLDocuments()}

          {/* Freight Agent */}
          <div style={{ marginBottom: 16 }}>
            <HeaderText small text="Freight Agent" />
            <Select
              showSearch
              style={{ width: "100%", marginTop: 8 }}
              placeholder="Select Freight Agent"
              value={selectedAgentId || undefined}
              onChange={(val) => setSelectedAgentId(val)}
              options={filteredAgents.map((fa) => ({
                label: fa.companyName,
                value: fa.id,
              }))}
              allowClear
              disabled={isSigned}
              optionFilterProp="label"
            />
          </div>

          {/* Recipient Contact Dropdown */}
          {/* <div style={{ marginBottom: 16 }}>
            <HeaderText small text="Recipient Contact" />
            <Select
              style={{ width: "100%", marginTop: 8 }}
              placeholder={
                customerContacts.length
                  ? "Select a contact"
                  : "No contacts found"
              }
              value={selectedRecipientContactId || undefined}
              onChange={(val) => setSelectedRecipientContactId(val)}
              options={customerContacts.map((c) => {
                const multipleContacts = customerContacts.length > 1;
                const contactName = [c.firstName, c.lastName]
                  .filter(Boolean)
                  .join(" ");
                const label =
                  multipleContacts && c.primary
                    ? `${contactName} (Primary)`
                    : contactName;
                return {
                  label,
                  value: c.id,
                };
              })}
              allowClear
              disabled={isSigned || !customerContacts.length}
            />
          </div> */}

          {/* Hidden Recipient Name (if needed) */}
          <HeaderText small text="Recipient Name" style={{ display: "none" }} />
          <Input
            style={{ marginTop: 8, display: "none" }}
            placeholder="Recipient Name"
            value={recipientName}
            onChange={(e) => setRecipientName(e.target.value)}
            disabled={isSigned}
          />
          <Row gutter={16} style={{ marginBottom: 16 }}>
            <Col xs={24} md={12}>
              <HeaderText small text="Recipient Contact" />
              <Select
                style={{ width: "100%", marginTop: 8 }}
                placeholder={
                  customerContacts.length
                    ? "Select a contact"
                    : "No contacts found"
                }
                value={selectedRecipientContactId || undefined}
                onChange={(val) => setSelectedRecipientContactId(val)}
                options={customerContacts.map((c) => {
                  const multipleContacts = customerContacts.length > 1;
                  const contactName = [c.firstName, c.lastName]
                    .filter(Boolean)
                    .join(" ");
                  const label =
                    multipleContacts && c.primary
                      ? `${contactName} (Primary)`
                      : contactName;
                  return {
                    label,
                    value: c.id,
                  };
                })}
                allowClear
                disabled={isSigned || !customerContacts.length}
              />
            </Col>
            <Col xs={24} md={12}>
              <HeaderText small text="Recipient Phone" />
              <DisplayPhoneNumber
                phoneNumber={recipientPhone}
                style={{ width: "100%", marginTop: 8 }}
                placeholder="Recipient Phone Number"
                initialValue={recipientPhone}
                onChange={setRecipientPhone}
                disabled={isSigned}
              />
            </Col>
          </Row>

          {/* Notes Field (restored) */}
          <div style={{ marginTop: 16 }}>
            <HeaderText small text="Notes" />
            <Input.TextArea
              autoSize
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Enter any special instructions here"
              style={{ marginTop: 8 }}
              disabled={isSigned}
            />
          </div>

          {/* Email Distribution Card */}
          <Card
            title={<div style={{display:"flex", alignItems:"center"}}><MailOutlined style={{ fontSize: 16, marginRight:8 }} /><div>Email Distribution List</div></div>}

            style={{ marginBottom: 16, marginTop: 16 }}
          >
            {/* NEW: Address To Input */}
            {/* NEW: Address To Input */}
            <div style={{ marginBottom: 8 }}>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <HeaderText small text="Address To" />
                {addressTo && (
                  <div
                    style={{
                      color: "#888",
                      fontStyle: "italic",
                      marginLeft: 8,
                    }}
                  >
                    Email will start with: {formattedAddressTo},
                  </div>
                )}
              </div>
              <Input
                placeholder="Enter first name (e.g., Tom)"
                value={addressTo}
                onChange={(e) => setAddressTo(e.target.value)}
                style={{ marginTop: 8, marginBottom: 16 }}
              />
            </div>

            <div style={{ marginBottom: 8 }}>
              <HeaderText small text="Emails" style={{ marginBottom: 8 }} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 16,
                }}
              >
                <Input
                  placeholder="Add email address"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  onPressEnter={handleAddEmail}
                />
                <div>
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={handleAddEmail}
                    style={{ marginLeft: 8 }}
                  >
                    Add Email
                  </Button>
                </div>
              </div>
              {distributionEmails.map((email) => (
                <Tag
                  key={email}
                  closable={email !== project?.assignedTo?.email}
                  onClose={() => handleRemoveEmail(email)}
                  style={{ marginBottom: 4 }}
                >
                  {email}
                </Tag>
              ))}
            </div>
          </Card>
        </Card>
      </Col>

      <Col xs={24} lg={12}>
        <Card title="Preview" style={{ height: "100%" }} extra={rightCardExtra}>
          <Spin spinning={pdfLoading} tip="Generating PDF...">
            {htmlPreview ? (
              <div
                style={{
                  border: "1px solid #ccc",
                  padding: 16,
                  minHeight: 300,
                }}
                dangerouslySetInnerHTML={{ __html: htmlPreview }}
              />
            ) : (
              <div style={{ color: "#999" }}>No preview yet.</div>
            )}
          </Spin>
        </Card>
      </Col>

      {/* Modal for uploading a "Customer Signed BOL" */}
      <Modal
        open={countersignModalVisible}
        title="Add Customer Signed BOL"
        onOk={handleCountersignUploadSave}
        onCancel={() => setCountersignModalVisible(false)}
        confirmLoading={loading}
      >
        <div style={{ marginBottom: 16 }}>
          <HeaderText small text="Date Signed" />
          <DatePicker
            style={{ marginTop: 8, width: "100%" }}
            value={countersignDate}
            onChange={(val) => setCountersignDate(val)}
          />
        </div>

        <div style={{ marginBottom: 16 }}>
          <HeaderText small text="Upload Customer Signed BOL PDF" />
          <Upload
            multiple={false}
            accept="application/pdf"
            fileList={countersignFileList}
            onChange={({ fileList }) => setCountersignFileList(fileList)}
            beforeUpload={() => false}
          >
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </div>
      </Modal>
    </Row>
  );
}

export default OutboundBOL;
