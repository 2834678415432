import React from "react";
import { Timeline, Typography } from "antd";
import dayjs from "dayjs";

const { Text } = Typography;

/**
 * OutboundTimeline
 *
 * A simple timeline for a shipment, showing "Shipment Created" first
 * (based on shipment.created), followed by any events in shipment.history.
 * Everything aligns on the left, with no icons.
 */
function OutboundTimeline({ shipment }) {
  // If shipment is missing, we can't render anything
  if (!shipment) return null;

  // If there's no "created" timestamp, just skip creation event
  const creationEvent = shipment.created
    ? {
        action: "Shipment Created",
        submittedBy: shipment.createdBy || "System",
        date: shipment.created,
      }
    : null;

  const historyEvents = shipment.history || [];
  // Sort them by date ascending
  const sortedHistory = historyEvents.slice().sort((a, b) => {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();
    return dateA - dateB;
  });

  // Build our final timeline array:
  // 1) The creationEvent (if present), then
  // 2) The sorted history.
  const timelineItems = [];

  // Then append each item from sortedHistory
  timelineItems.push(...sortedHistory);

  return (
    <div style={{ padding: "16px", backgroundColor: "#fff" }}>
      <Timeline mode="left">
        {timelineItems.map((evt, index) => {
          const { action, submittedBy, date } = evt;
          return (
            <Timeline.Item key={index}>
              <div style={{ marginBottom: 4 }}>
                <Text strong>{action}</Text>
              </div>
              <div>
                <Text type="secondary">
                  {submittedBy ? `By ${submittedBy} ` : ""}
                  on {dayjs(date).format("MMMM D, YYYY h:mm A")}
                </Text>
              </div>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </div>
  );
}

export default OutboundTimeline;
