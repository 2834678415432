export function getFirstNameFromEmail(input) {
  if (!input) return null;

  // Check if the input contains a space (not an email)
  if (input.includes(" ")) {
    // Split by space and return the first word
    const firstName = input.split(" ")[0];
    return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
  }

  // If no space, assume it's an email and proceed as before
  const namePart = input.split("@")[0];
  const firstName = namePart.split(".")[0];

  // Capitalize the first letter of the first name
  return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
}

export const getFirstAndLastNameFromEmail = (input) => {
  if (!input) return null;

  // Helper function to capitalize names
  const capitalize = (name) => {
    if (!name) return "";
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  // Check if the input contains a space (indicating a full name)
  if (input.includes(" ")) {
    const nameParts = input.trim().split(/\s+/);
    const firstName = capitalize(nameParts[0]);
    const lastName = capitalize(nameParts[nameParts.length - 1]);

    return lastName ? `${firstName} ${lastName}` : firstName;
  }

  // Assume input is an email
  const namePart = input.split("@")[0];
  const nameParts = namePart.split(".");

  const firstName = capitalize(nameParts[0]);
  const lastName = nameParts[1] ? capitalize(nameParts[1]) : "";

  return lastName ? `${firstName} ${lastName}` : firstName;
};


