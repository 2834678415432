import React, { useState, useEffect, useMemo } from "react";
import {
  Modal,
  Table,
  Button,
  Select,
  message,
  Form,
  InputNumber,
  DatePicker,
  Row,
  Col,
} from "antd";
import dayjs from "dayjs";
import { updateById } from "../../Functions/updateById";
import { TABLES } from "../../constants";
import { PlusOutlined } from "@ant-design/icons";
import PriceTag from "../../Styled/PriceTag";
import { v4 as uuidv4 } from "uuid"; // <--- Added import

const { Option } = Select;
const DATE_FORMAT = "MM/DD/YYYY";

const ProjectBillingModal = ({
  visible,
  onCancel,
  project,
  onBillingUpdate,
  editingAr, // if provided, the modal is in AR editing mode
}) => {
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("All");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filteredLines, setFilteredLines] = useState([]);
  // Root-level Date to Bill for AR (required)
  const [arDate, setArDate] = useState(dayjs());

  // Helper: get all available lines from Purchase Order and Change Orders using line.id.
  const getAllLines = () => {
    let lines = [];
    if (project.purchaseOrder && project.purchaseOrder.lines) {
      project.purchaseOrder.lines.forEach((line) => {
        lines.push({
          key: line.id,
          id: line.id,
          source: "Purchase Order",
          orderNumber: project.purchaseOrder.number,
          plannedDateToBill: line.plannedDateToBill,
          description: line.description || "",
          amount: line.amount,
          tax: line.tax,
          lineNumber: line.lineNumber,
          sourceFile: project.purchaseOrder?.files[0]?.url,
        });
      });
    }
    if (project.changeOrders && project.changeOrders.length > 0) {
      project.changeOrders.forEach((co) => {
        if (co.lines && co.lines.length > 0) {
          co.lines.forEach((line) => {
            lines.push({
              key: line.id,
              id: line.id,
              source: "Change Order",
              orderNumber: co.number,
              plannedDateToBill: line.plannedDateToBill,
              description: line.description || "",
              amount: line.amount,
              tax: line.tax,
              lineNumber: line.lineNumber,
              sourceFile: co?.files[0]?.url,
            });
          });
        }
      });
    }
    return lines;
  };

  // Helper: build a composite key for filtering.
  const compositeKey = (line) => `${line.source}-${line.orderNumber}`;

  // Compute available lines.
  const computeAvailableLines = () => {
    const allLines = getAllLines();
    if (editingAr) {
      const otherBilledIds = new Set();
      if (project.billing && project.billing.length > 0) {
        project.billing.forEach((arEntry) => {
          if (arEntry.arNumber !== editingAr.arNumber) {
            (arEntry.lines || []).forEach((line) => {
              otherBilledIds.add(line.id);
            });
          }
        });
      }
      return allLines.filter((line) => {
        if (editingAr.lines.some((l) => l.id === line.id)) return true;
        return !otherBilledIds.has(line.id);
      });
    } else {
      const billedIds = new Set();
      if (project.billing && project.billing.length > 0) {
        project.billing.forEach((arEntry) => {
          (arEntry.lines || []).forEach((line) => {
            billedIds.add(line.id);
          });
        });
      }
      return allLines.filter((line) => !billedIds.has(line.id));
    }
  };

  const availableFilters = useMemo(() => {
    const available = computeAvailableLines();
    const filterSet = new Set();
    available.forEach((line) => {
      filterSet.add(compositeKey(line));
    });
    return Array.from(filterSet);
  }, [project, editingAr]);

  useEffect(() => {
    const available = computeAvailableLines();
    let filtered = available;
    if (filterValue !== "All") {
      filtered = available.filter((line) => compositeKey(line) === filterValue);
    }
    setFilteredLines(filtered);
  }, [project, filterValue, visible, editingAr]);

  useEffect(() => {
    if (editingAr) {
      const available = computeAvailableLines();
      const mySelected = available
        .filter((line) => editingAr.lines.some((l) => l.id === line.id))
        .map((line) => line.id);
      setSelectedRowKeys(mySelected);
      if (editingAr.dateToBill) {
        setArDate(dayjs(editingAr.dateToBill));
      }
    } else {
      setSelectedRowKeys([]);
      setArDate(dayjs());
    }
  }, [editingAr, project, visible]);

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    getCheckboxProps: (record) => {
      if (editingAr) {
        const otherBilledIds = new Set();
        if (project.billing && project.billing.length > 0) {
          project.billing.forEach((arEntry) => {
            if (arEntry.arNumber !== editingAr.arNumber) {
              (arEntry.lines || []).forEach((line) =>
                otherBilledIds.add(line.id)
              );
            }
          });
        }
        if (
          !editingAr.lines.some((l) => l.id === record.id) &&
          otherBilledIds.has(record.id)
        ) {
          return { disabled: true };
        }
      }
      return {};
    },
  };

  const getNextARNumber = () => {
    let nextNum = 1;
    if (project.billing && project.billing.length > 0) {
      const nums = project.billing.map((ar) => {
        const match = ar.arNumber.match(/AR-(\d+)/);
        return match ? parseInt(match[1], 10) : 0;
      });
      nextNum = Math.max(...nums) + 1;
    }
    return `AR-${nextNum}`;
  };

  const creationColumns = [
    { title: "Line #", dataIndex: "lineNumber", key: "lineNumber" },
    { title: "Source", dataIndex: "source", key: "source" },
    { title: "Order Number", dataIndex: "orderNumber", key: "orderNumber" },
    { title: "Description", dataIndex: "description", key: "description" },
    {
      title: "Planned Date to Bill",
      dataIndex: "plannedDateToBill",
      key: "plannedDateToBill",
      render: (date) => dayjs(date).format(DATE_FORMAT),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => <PriceTag amount={amount} />,
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      render: (tax) => <PriceTag amount={tax || 0} />,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (total, record) => (
        <PriceTag amount={record.amount + (record.tax || 0)} />
      ),
    },
  ];

  // Handler for creation mode.
  const handleAddSelected = async () => {
    if (selectedRowKeys.length === 0) {
      message.warning("Please select at least one line item to add.");
      return;
    }
    if (!arDate) {
      message.error("Date to Bill is required.");
      return;
    }
    setLoading(true);
    const selectedLines = filteredLines.filter((line) =>
      selectedRowKeys.includes(line.id)
    );
    // Compute totals
    const total = selectedLines.reduce(
      (acc, line) =>
        acc + (parseFloat(line.amount) || 0) + (parseFloat(line.tax) || 0),
      0
    );
    const taxTotal = selectedLines.reduce(
      (acc, line) => acc + (parseFloat(line.tax) || 0),
      0
    );
    const amountTotal = selectedLines.reduce(
      (acc, line) => acc + (parseFloat(line.amount) || 0),
      0
    );

    const billingEntry = {
      id: uuidv4(), // <--- New UUID for the billing entry
      arNumber: getNextARNumber(),
      total: parseFloat(total.toFixed(2)),
      taxTotal: parseFloat(taxTotal.toFixed(2)),
      amountTotal: parseFloat(amountTotal.toFixed(2)),
      dateToBill: arDate.toISOString(),
      netTerms: project.purchaseOrder.netTerms,
      lines: selectedLines.map((line) => ({
        id: line.id,
        source: line.source,
        orderNumber: line.orderNumber,
        lineNumber: line.lineNumber,
        amount: line.amount,
        tax: line.tax || 0,
        total: (parseFloat(line.amount) || 0) + (parseFloat(line.tax) || 0),
        plannedDateToBill: line.plannedDateToBill,
        description: line.description,
        sourceFile: line.sourceFile,
      })),
      created: new Date().toISOString(),
    };
    const updatedProject = { ...project };
    updatedProject.billing = updatedProject.billing
      ? [...updatedProject.billing, billingEntry]
      : [billingEntry];
    try {
      await updateById(TABLES.PROJECTS, updatedProject, project.id);
      message.success(
        `Billing AR ${billingEntry.arNumber} created successfully!`
      );
      setSelectedRowKeys([]);
      if (onBillingUpdate) {
        onBillingUpdate(updatedProject);
      }
      onCancel();
    } catch (err) {
      console.error("Error creating billing AR:", err);
      message.error("Error creating billing AR.");
    } finally {
      setLoading(false);
    }
  };

  // Handler for editing mode.
  const handleSaveEdit = async () => {
    if (selectedRowKeys.length === 0) {
      message.warning("Please select at least one line item.");
      return;
    }
    if (!arDate) {
      message.error("Date to Bill is required.");
      return;
    }
    setLoading(true);
    const selectedLines = filteredLines.filter((line) =>
      selectedRowKeys.includes(line.id)
    );
    const total = selectedLines.reduce(
      (acc, line) =>
        acc + (parseFloat(line.amount) || 0) + (parseFloat(line.tax) || 0),
      0
    );
    const taxTotal = selectedLines.reduce(
      (acc, line) => acc + (parseFloat(line.tax) || 0),
      0
    );
    const amountTotal = selectedLines.reduce(
      (acc, line) => acc + (parseFloat(line.amount) || 0),
      0
    );
    const updatedAREntry = {
      ...editingAr,
      dateToBill: arDate.toISOString(),
      netTerms: project.purchaseOrder.netTerms,
      lines: selectedLines.map((line) => ({
        id: line.id,
        source: line.source,
        orderNumber: line.orderNumber,
        lineNumber: line.lineNumber,
        amount: line.amount,
        tax: line.tax || 0,
        total: (parseFloat(line.amount) || 0) + (parseFloat(line.tax) || 0),
        plannedDateToBill: line.plannedDateToBill,
        description: line.description,
        sourceFile: line.sourceFile,
      })),
      total: parseFloat(total.toFixed(2)),
      taxTotal: parseFloat(taxTotal.toFixed(2)),
      amountTotal: parseFloat(amountTotal.toFixed(2)),
      updated: new Date().toISOString(),
    };
    const updatedProject = { ...project };
    updatedProject.billing = updatedProject.billing.map((entry) =>
      entry.arNumber === editingAr.arNumber ? updatedAREntry : entry
    );
    try {
      await updateById(TABLES.PROJECTS, updatedProject, project.id);
      message.success("AR entry updated successfully!");
      if (onBillingUpdate) {
        onBillingUpdate(updatedProject);
      }
      onCancel();
    } catch (err) {
      console.error(err);
      message.error("Failed to update AR entry");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title={
        editingAr
          ? `Edit Accounts Receivable (AR) - ${editingAr.arNumber}`
          : "Create Accounts Receivable (AR)"
      }
      onCancel={onCancel}
      footer={
        editingAr
          ? [
              <Button key="cancel" onClick={onCancel}>
                Cancel
              </Button>,
              <Button
                key="save"
                type="primary"
                loading={loading}
                onClick={handleSaveEdit}
              >
                Save
              </Button>,
            ]
          : [
              <Button key="cancel" onClick={onCancel}>
                Cancel
              </Button>,
              <Button
                key="add"
                type="primary"
                loading={loading}
                onClick={handleAddSelected}
              >
                Add Selected Lines
              </Button>,
            ]
      }
      width={"90%"}
    >
      {/* Filter Section and Root-level Date to Bill */}
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col span={12}>
          <span style={{ marginRight: 8 }}>Filter by Order:</span>
          <Select
            placeholder="All"
            style={{ width: 200 }}
            value={filterValue}
            onChange={(value) => setFilterValue(value)}
            allowClear={false}
          >
            <Option value="All">All</Option>
            {availableFilters.map((f) => {
              const [source, orderNumber] = f.split("-");
              return (
                <Option key={f} value={f}>
                  {source === "Purchase Order" ? "PO" : "CO"} - {orderNumber}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <span style={{ marginRight: 8 }}>Date to Bill:</span>
          <DatePicker
            value={arDate}
            onChange={setArDate}
            format={DATE_FORMAT}
            required
          />
        </Col>
      </Row>
      <Table
        dataSource={filteredLines}
        columns={creationColumns}
        rowSelection={rowSelection}
        pagination={false}
        rowKey="id"
      />
    </Modal>
  );
};

export default ProjectBillingModal;
