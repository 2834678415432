// AppContent.js
import React from "react";
import { Route, Routes, Navigate, useParams } from "react-router-dom";
import { Layout } from "antd";
import { SignIn, useUser } from "@clerk/clerk-react";
import Home from "./Pages/Home";
import Admin from "./Pages/admin/Admin";
import Estimates from "./Pages/estimating/Estimates";
import BuildEstimate from "./Pages/estimating/BuildEstimate";
import Assemblies from "./Pages/estimating/Assemblies";
import BuildAssembly from "./Pages/estimating/BuildAssembly";
import Purchasing from "./Pages/purchasing/Purchasing";
import Vendors from "./Pages/purchasing/Vendors";
import Items from "./Pages/purchasing/Items";
import Reporting from "./Pages/purchasing/Reporting";
import Dashboard from "./Pages/purchasing/Dashboard";
import NavigationHeader from "./NavigationHeader";
import Returns from "./Pages/purchasing/Returns";
import AddPurchaseOrder from "./Components/Purchase/AddPurchaseOrder";
import AddReturnOrder from "./Components/Returns/AddReturnOrder";
import Receive from "./Pages/receiving/Receive";
import ReceiveItemDetailPurchase from "./Components/Receiving/ReceiveItemDetailPurchase";
import ReceiveItemPurchase from "./Components/Receiving/ReceiveItemPurchase";
import ReceiveItemReturn from "./Components/Receiving/ReceiveItemReturn";
import ReceiveItemDetailReturn from "./Components/Receiving/ReceiveItemDetailReturn";
import VendorItems from "./Components/Items/VendorItems";
import Tickets from "./Pages/admin/Tickets";
import Unreceive from "./Components/Receiving/Unreceive";
import PostHogUserIdentification from "./Contexts/PostHogUserIdentification";
import { Loader } from "./Styled/Loader";
import Invoices from "./Components/Invoices/Invoices";
import Transactions from "./Components/Invoices/Transactions";
import InvoicesDetail from "./Components/Invoices/InvoicesDetail";
import Customers from "./Components/Customers/Customers";
import ModuleOptions from "./Components/Estimating/ModuleOptions/ModuleOptions";
import ManageCustomItems from "./Components/Estimating/CustomItems/ManageCustomItems";
import Reminders from "./Pages/reminders/Reminders";
import Projects from "./Pages/projectmanagement/Projects";
import Preconstruction from "./Pages/projectmanagement/Preconstruction";
import ManufacturingLocations from "./Pages/admin/ManufacturingLocations";
import BuildProject from "./Components/Projects/BuildProject";
import Production from "./Pages/production/Production";
import Shipping from "./Pages/shipping/Shipping";
import Logistics from "./Pages/logistics/OutboundShipments";
import FreightAgents from "./Components/Logistics/FreightAgents";
import Outbound from "./Pages/logistics/OutboundShipments";
import BuildOutboundShipment from "./Components/Logistics/BuildOutboundShipment";
import BOLSignature from "./Components/Shipping/BOLSignature";
import AccountsReceivable from "./Pages/accountsreceivable/AccountsReceivable";
import useTitleModifier from "./Mutations/useTitleModifier";
import { use } from "react";


const { Content } = Layout;

function RedirectToModules() {
  const { id } = useParams();
  return <Navigate to={`/estimating/estimates/${id}/modules`} replace />;
}



function AppContent() {
  const { isLoaded, isSignedIn, user } = useUser();

  useTitleModifier();

  if (!isLoaded) {
    // Optionally render a loading state
    return <Loader />;
  }

  if (!isSignedIn) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          background: "#f3f3f3",
        }}
      >
        <SignIn />
      </div>
    );
  }

  const roles = user.publicMetadata.roles || [];
  const hasMultipleRoles = roles.length > 1 || roles.includes("admin");

  return (
    <Layout
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        minHeight: "100vh",
        background: "#f3f3f3",
      }}
    >
      {/* Include the PosthogUserIdentification component */}
      <PostHogUserIdentification />

      <NavigationHeader roles={roles} />

      <Content style={{ padding: 16, paddingTop: 16 }}>
        <Routes>
          {/* HOME ROUTE */}

          <Route path="/" element={<Home />} />
          <Route path="/reminders" element={<Reminders />} />

          {/* ADMIN ROUTES */}

          <Route
            path="/admin/users"
            element={
              <ProtectedRoute roles={["admin"]}>
                <Admin />
              </ProtectedRoute>
            }
          />

          <Route
            path="/admin/manufacturing-locations"
            element={
              <ProtectedRoute roles={["admin"]}>
                <ManufacturingLocations />
              </ProtectedRoute>
            }
          />

          <Route path="/admin/tickets" element={<Tickets />} />

          {/* ESTIMATING ROUTES */}

          <Route
            path="/estimating/customers"
            element={
              <ProtectedRoute roles={["estimator", "admin"]}>
                <Customers />
              </ProtectedRoute>
            }
          />

          <Route
            path="/estimating/module-options/:category?"
            element={
              <ProtectedRoute roles={["estimator", "admin"]}>
                <ModuleOptions />
              </ProtectedRoute>
            }
          />

          <Route
            path="/estimating/estimates"
            element={
              <ProtectedRoute roles={["estimator", "admin"]}>
                <Estimates />
              </ProtectedRoute>
            }
          />
          <Route
            path="/estimating/estimates/:id/:tab"
            element={
              <ProtectedRoute roles={["estimator", "admin"]}>
                <BuildEstimate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/estimating/estimates/:id"
            element={<RedirectToModules />}
          />
          <Route
            path="/estimating/assemblies"
            element={
              <ProtectedRoute roles={["estimator", "admin"]}>
                <Assemblies />
              </ProtectedRoute>
            }
          />
          <Route
            path="/estimating/assemblies/:id"
            element={
              <ProtectedRoute roles={["estimator", "admin"]}>
                <BuildAssembly />
              </ProtectedRoute>
            }
          />

          <Route
            path="/estimating/aria-items"
            element={
              <ProtectedRoute roles={["purchaser", "admin"]}>
                <Items viewOnly />
              </ProtectedRoute>
            }
          />

          <Route
            path="/estimating/custom-items"
            element={
              <ProtectedRoute roles={["purchaser", "admin"]}>
                <ManageCustomItems />
              </ProtectedRoute>
            }
          />

          <Route
            path="/estimating/vendors"
            element={
              <ProtectedRoute roles={["purchaser", "admin"]}>
                <Vendors viewOnly />
              </ProtectedRoute>
            }
          />

          <Route
            path="/estimating/manage-custom-items"
            element={
              <ProtectedRoute roles={["purchaser", "admin"]}>
                <ManageCustomItems />
              </ProtectedRoute>
            }
          />

          {/* PRODUCTION ROUTES */}
          {/* this is a view only version of production + project management */}

          <Route
            path="/production/preconstruction/*"
            element={
              <ProtectedRoute roles={["production", "admin"]}>
                <Preconstruction viewOnly />
              </ProtectedRoute>
            }
          />

          <Route
            path="/production/home/*"
            element={
              <ProtectedRoute roles={["production", "admin"]}>
                <Production />
              </ProtectedRoute>
            }
          />

          <Route
            path="/production/projects/*"
            element={
              <ProtectedRoute roles={["production", "admin"]}>
                <Projects viewOnly />
              </ProtectedRoute>
            }
          />

          {/* OPERATIONS ROUTES */}
          {/* this is a view only version of operations production + precon + project management */}

          <Route
            path="/operations/preconstruction/*"
            element={
              <ProtectedRoute roles={["operations", "admin"]}>
                <Preconstruction viewOnly />
              </ProtectedRoute>
            }
          />

          <Route
            path="/operations/production/*"
            element={
              <ProtectedRoute roles={["operations", "admin"]}>
                <Production viewOnly/>
              </ProtectedRoute>
            }
          />

          <Route
            path="/operations/projects/*"
            element={
              <ProtectedRoute roles={["operations", "admin"]}>
                <Projects viewOnly />
              </ProtectedRoute>
            }
          />

          <Route
            path="/operations/projects/:id/:tab"
            element={
              <ProtectedRoute roles={["production", "admin"]}>
                <BuildProject viewOnly />
              </ProtectedRoute>
            }
          />

          {/* LOGISTICS ROUTES */}

          <Route
            path="/logistics/outbound/*"
            element={
              <ProtectedRoute roles={["logistics", "admin"]}>
                <Outbound />
              </ProtectedRoute>
            }
          />

          <Route
            path="/logistics/outbound/:id/:tab"
            element={
              <ProtectedRoute roles={["logistics", "admin"]}>
                <BuildOutboundShipment />
              </ProtectedRoute>
            }
          />

          <Route
            path="/logistics/freight-agents/*"
            element={
              <ProtectedRoute roles={["logistics", "admin"]}>
                <FreightAgents />
              </ProtectedRoute>
            }
          />
          <Route
            path="/logistics/manufacturing-locations/*"
            element={
              <ProtectedRoute roles={["logistics", "admin"]}>
                <ManufacturingLocations viewOnly />
              </ProtectedRoute>
            }
          />

          {/* SHIPPING ROUTES */}

          <Route
            path="/shipping/home/*"
            element={
              <ProtectedRoute roles={["shipping", "admin"]}>
                <Shipping />
              </ProtectedRoute>
            }
          />

          <Route
            path="/shipping/bol/:id"
            element={
              <ProtectedRoute roles={["shipping", "admin"]}>
                <BOLSignature />
              </ProtectedRoute>
            }
          />

          {/* PROJECT MANAGEMENT ROUTES */}

          <Route
            path="/project-management/preconstruction/*"
            element={
              <ProtectedRoute roles={["projectmgmt", "admin"]}>
                <Preconstruction />
              </ProtectedRoute>
            }
          />

          <Route
            path="/project-management/projects/*"
            element={
              <ProtectedRoute roles={["projectmgmt", "admin"]}>
                <Projects />
              </ProtectedRoute>
            }
          />

          <Route
            path="/project-management/projects/:id/:tab"
            element={
              <ProtectedRoute roles={["projectmgmt", "admin"]}>
                <BuildProject />
              </ProtectedRoute>
            }
          />

          <Route
            path="/project-management/customers/*"
            element={
              <ProtectedRoute roles={["projectmgmt", "admin"]}>
                <Customers />
              </ProtectedRoute>
            }
          />

          {/* ACCOUNTS RECEIVABLE ROUTES */}
          <Route
            path="/accounts-receivable/home/*"
            element={
              <ProtectedRoute roles={["accountsreceivable", "admin"]}>
                <AccountsReceivable />
              </ProtectedRoute>
            }
          />

          {/* PURCHASING ROUTES */}

          <Route
            path="/purchasing/purchase/*"
            element={
              <ProtectedRoute roles={["purchaser", "admin"]}>
                <Purchasing />
              </ProtectedRoute>
            }
          />
          <Route
            path="/purchasing/returns/*"
            element={
              <ProtectedRoute roles={["purchaser", "admin"]}>
                <Returns />
              </ProtectedRoute>
            }
          />
          <Route
            path="/purchasing/vendors"
            element={
              <ProtectedRoute roles={["purchaser", "admin"]}>
                <Vendors />
              </ProtectedRoute>
            }
          />
          <Route
            path="/purchasing/items"
            element={
              <ProtectedRoute roles={["purchaser", "admin"]}>
                <Items />
              </ProtectedRoute>
            }
          />
          <Route
            path="/purchasing/items/vendor-items/:id"
            element={
              <ProtectedRoute roles={["purchaser", "admin"]}>
                <VendorItems />
              </ProtectedRoute>
            }
          />
          <Route
            path="/purchasing/reporting/*"
            element={
              <ProtectedRoute roles={["purchaser", "admin"]}>
                <Reporting />
              </ProtectedRoute>
            }
          />
          <Route
            path="/purchasing/dashboard"
            element={
              <ProtectedRoute roles={["purchaser", "admin"]}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/purchasing/purchase/edit-purchase-orders/:id"
            element={
              <ProtectedRoute roles={["purchaser", "admin"]}>
                <AddPurchaseOrder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/purchasing/returns/edit-return-orders/:id"
            element={
              <ProtectedRoute roles={["purchaser", "admin"]}>
                <AddReturnOrder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/purchasing/returns/edit-return-orders/:id"
            element={
              <ProtectedRoute roles={["purchaser", "admin"]}>
                <AddReturnOrder />
              </ProtectedRoute>
            }
          />

          <Route
            path="/purchasing/invoices"
            element={
              <ProtectedRoute roles={["purchaser", "admin"]}>
                <Invoices />
              </ProtectedRoute>
            }
          />
          <Route
            path="/purchasing/invoices/:id"
            element={
              <ProtectedRoute roles={["purchaser", "admin"]}>
                <InvoicesDetail />
              </ProtectedRoute>
            }
          />

          <Route
            path="/purchasing/transactions"
            element={
              <ProtectedRoute roles={["purchaser", "admin"]}>
                <Transactions />
              </ProtectedRoute>
            }
          />

          {/* RECEIVING ROUTES */}
          <Route
            path="/receiving/receive/purchase-orders"
            element={
              <ProtectedRoute roles={["receiver", "admin"]}>
                <Receive />
              </ProtectedRoute>
            }
          />
          <Route
            path="/receiving/receive/purchase-orders/:id"
            element={
              <ProtectedRoute roles={["receiver", "admin"]}>
                <ReceiveItemPurchase />
              </ProtectedRoute>
            }
          />
          <Route
            path="/receiving/receive/purchase-orders/:id/item/:itemNumber"
            element={
              <ProtectedRoute roles={["receiver", "admin"]}>
                <ReceiveItemDetailPurchase />
              </ProtectedRoute>
            }
          />
          <Route
            path="/receiving/receive/return-orders"
            element={
              <ProtectedRoute roles={["receiver", "admin"]}>
                <Receive />
              </ProtectedRoute>
            }
          />
          <Route
            path="/receiving/receive/return-orders/:id"
            element={
              <ProtectedRoute roles={["receiver", "admin"]}>
                <ReceiveItemReturn />
              </ProtectedRoute>
            }
          />
          <Route
            path="/receiving/receive/return-orders/:id/item/:itemNumber"
            element={
              <ProtectedRoute roles={["receiver", "admin"]}>
                <ReceiveItemDetailReturn />
              </ProtectedRoute>
            }
          />
          <Route
            path="/receiving/unreceive"
            element={
              <ProtectedRoute roles={["receiver", "admin"]}>
                <Unreceive />
              </ProtectedRoute>
            }
          />
          <Route
            path="/receiving/dashboard"
            element={
              <ProtectedRoute roles={["receiver", "admin"]}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Content>
    </Layout>
  );
}

function ProtectedRoute({ children, roles }) {
  const { user } = useUser();

  if (!user) {
    return <Navigate to="/" />;
  }

  if (
    roles &&
    !roles.some((role) => user.publicMetadata.roles?.includes(role))
  ) {
    return <Navigate to="/" />;
  }

  return children;
}

export default AppContent;
